<template>

    <file-list-view 
        :user="user"
        :share-allowed="true"
    ></file-list-view>

</template>
<script>

import fileListView from './filelist-view.vue'
import { NamespaceService } from '@/services/namespace-service.js'

export default {
    props: ['user'],
    components: { fileListView },
    created(){
        NamespaceService.ns_type = NamespaceService.NS_TYPE_DEFAULT
    }
}
</script>

