<template>
    <div id='data-location-flags-container'>
        <span v-if="textStart" v-html="textStart"></span>
        <div>
            <span v-if="teamName">
                Every file in this shared folder is encrypted and distributed in the following locations,
                selected by the administrators of {{ teamName }}
            </span>
            <b v-else>
                Your files are distributed in {{ locations.length }} different locations
            </b>
        </div>

        <ul>
            <li v-for="loc in locations" :key="loc.id">
                <img :src="Utils.flag_img_src(loc.countrycode)" class='mr-1' />{{ loc.name }}, {{ loc.country }}
            </li>
        </ul>

        <div v-if="teamName">
            SkyFlok's unique patented technology allows you to choose where your data is stored.
            Keep it close to you or spread it across the globe, it is s up to you.<br/><br/>
            Visit <a href="https://skyflok.com" target="_BLANK" class="text-bold">skyflok.com</a> to learn more and try SkyFlok for free!
        </div>
        <div v-else>
            <i class='text-sm'>These locations were selected by your Team's Administrators</i>
        </div>
    </div>
</template>
<script>
import { Utils } from '@/helpers/utils';

export default {
    props: ["textStart", "locations", "teamName"],
    data(){
        return {
            Utils: Utils
        }
    }
}
</script>
<style scoped>
    ul{
        list-style-type: none
    }
</style>
