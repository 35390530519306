import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'
import platform from 'platform'

// This is not exported
const base_url = process.env.VUE_APP_audit_log_service_url

const AuditLogService = {

    query_events(params){
        return bus.$http.get(base_url + "/list", { params: params, headers: Utils.get_auth_header() })
    },

    ENTITY_TYPE_USER: 1,
    ENTITY_TYPE_TEAM: 2,
    ENTITY_TYPE_FILE: 3,
    ENTITY_TYPE_FOLDER: 4,
    ENTITY_TYPE_SHARE: 5,
    ENTITY_TYPE_CLIENT_SPACE: 6,
    ENTITY_TYPE_PLAN_CONFIG: 7,
    ENTITY_TYPE_MFA: 8,

    USER_CREATED: 1,
    USER_DELETED_OWN: 2,
    USER_REMOVED: 3,
    USER_PASSWORD_CHANGED: 4,
    USER_PRIMARY_EMAIL_CONFIRMED_CHANGED: 5,
    USER_SUSPEND_CHANGED: 6,
    USER_ROLE_CHANGED: 7,
    USER_PROFILE_CHANGED: 8,
    USER_OPERATOR_ADDED: 9,

    COMPANY_CREATED: 1,
    COMPANY_DELETED: 2,
    COMPANY_PROFILE_CHANGED: 3,
    COMPANY_ADDITIONAL_EMAILS_CHANGED: 4,
    COMPANY_BACKEND_CHANGED: 5,
    COMPANY_CODING_CONFIG_CHANGED_EVENT: 6,
    COMPANY_CONFIG_CHANGED_EVENT: 7,
    COMPANY_PLAN_CHANGED_EVENT: 8,
    COMPANY_DISABLED_EVENT: 9,
    COMPANY_ENABLED_EVENT: 10,
    COMPANY_STORAGE_QUOTA_CHANGED_EVENT: 11,
    COMPANY_USER_QUOTA_CHANGED_EVENT: 12,
    COMPANY_PLAN_TIME_LIMIT_CHANGED: 13,

    FILE_CREATED: 1,
    FILE_VERSION_CREATED: 2,
    FILE_REVERTED: 3,
    FILE_DELETED: 4,
    FILE_UNDELETED: 5,
    FILE_REMOVED: 6,
    FILE_ATTRIBUTE_CHANGED: 7,
    FILE_DOWNLOADED: 8,
    FILE_DOWNLOADED_SYNC: 9,

    FOLDER_CREATED: 1,
    FOLDER_DELETED: 2,
    FOLDER_UNDELETED: 3,
    FOLDER_REMOVED: 4,
    FOLDER_ATTRIBUTE_CHANGED: 5,

    SHARE_CREATED: 1,
    SHARE_REVOKED: 2,
    SHARE_PASSWORD_CHANGED: 3,
    SHARE_ATTRIBUTE_CHANGED: 4,
    SHARE_FILES_CHANGED: 5,
    SHARE_ACCESSED: 6,
    SHARE_FILE_DOWNLOADED: 7,

    CLIENT_SPACE_CREATED: 1,
    CLIENT_SPACE_ARCHIVED: 2,
    CLIENT_SPACE_ACTIVATED: 3,
    CLIENT_SPACE_EDITED_BY_MANAGER: 4,
    CLIENT_SPACE_EDITED_BY_CLIENT: 5,
    CLIENT_SPACE_ACCESSED_BY_CLIENT: 6,
    CLIENT_SPACE_CLIENT_JOINS: 7,


    PLAN_CREATED: 1,
    PLAN_UPDATED: 2,
    PLAN_DELETED: 3,

    MFA_ENABLED: 1,
    MFA_DISABLED: 2,
    MFA_BACKUP_CODES_GENERATED: 3,
    MFA_SUCCESS: 4,
    MFA_FAILED_ATTEMPT: 5,

}

export { AuditLogService }