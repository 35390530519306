<template>
    <div
        class="d-flex align-items-center justify-contents-middle fw py-2 mr-2"
        :class="{'c-pointer': is_clickable}"
        @click="()=>{ is_clickable ? $emit('file_clicked', event.file) : false }"
        :title="is_clickable ? 'Click to show file' : false"
         >
        <div v-if="event_user" class="thumb32">
            <img 
                v-if="event_user.avatar_url || event_user.logo_url" 
                :src="event_user.avatar_url ? (event_user.is_builtin_logo ? Utils.client_space_logo_src(event_user.avatar_url) : event_user.avatar_url) : event_user.logo_url" 
                class="thumb32 fit-contain"  
                :class="{'rounded-circle':!event_user.is_client, 'rounded': event_user.is_client}"
            />
        </div>
        <div class="ml-2 fw text-sm nowrap" style="line-height: 1.1em; overflow: hidden">
            <div v-if="event.event_type == 'file_download' || event.event_type == 'file_upload'">
                <i class="mr-1" 
                    :class="{
                        'ion-arrow-down-a text-success': event.event_type == 'file_download', 
                        'ion-arrow-up-a text-info': event.event_type == 'file_upload'
                    }"/>
                <img v-if="event.file" :src="get_extension(event_file.name) | filetype_img_src" height="20" class="mr-1">
                <span v-html="event_file.name"></span>
            </div>
            <small class="text-muted">
                {{ event.timestamp | timestamp_rel }}
            </small>
        </div>
    </div>
</template>
<script>

import { Utils } from '@/helpers/utils';

export default {
    props: ["event", "space", "skyflok_team", "client", "is_clickable"],

    data(){
        return {
            Utils: Utils
        }
    },

    computed: {

        team_logo_url(){
            return this.space && this.space.team_info && this.space.team_info.logo_url ? this.space.team_info.logo_url : ''
        },

        team_name(){
            return this.space && this.space.team_info && this.space.team_info.name ? this.space.team_info.name : ''
        },

        event_user(){
            if(!this.event){
                return null
            }

            if(this.event.user_id > 0){
                // A team member
                const user = this.skyflok_team ? this.skyflok_team.find(u => { return u.user_id === this.event.user_id || u.id === this.event.user_id }) : null
                if(!user){
                    return {
                        "name": "Someone from " + this.team_name,
                        "avatar_url": this.team_logo_url
                    }
                }
                return user
            }
            // The client
            return {
                "name": this.space.client_name,
                "email_address": this.space.client_email,
                "avatar_url": this.space.client_logo,
                "is_client": true,
                "is_builtin_logo": this.space.client_logo.search('client_space_logos') >= 0
            }

        },

        event_verb(){
            switch(this.event.event_type){
                case 'file_download': return 'downloaded'
                case 'file_upload': return 'uploaded'
            }
            return ''
        },

        event_html(){
            switch(this.event.event_type){
               case 'file_download':
                   return "Downloaded " + this.event.file_id
           }
           return ""

            /*
            switch(this.event.entity_type){
                case AuditLogService.ENTITY_TYPE_CLIENT_SPACE:
                    switch(this.event.event_type){
                        case AuditLogService.CLIENT_SPACE_CREATED:
                            return "Created this space"
                        case AuditLogService.CLIENT_SPACE_ARCHIVED:
                            return "Archived this space"
                        case AuditLogService.CLIENT_SPACE_ACTIVATED:
                            return "Activated this space"
                        case AuditLogService.CLIENT_SPACE_CLIENT_JOINS:
                            return "Joined this space"
                        default:
                            return "Space event " +this.event.event_type
                    }
                    break
                case AuditLogService.ENTITY_TYPE_FILE:
                    switch(this.event.event_type){
                        case AuditLogService.FILE_CREATED:
                        case AuditLogService.FILE_VERSION_CREATED:
                            return "Uploaded file " + this.event.entity_id
                        case AuditLogService.FILE_DELETED:
                            return "Deleted file " + this.event.entity_id
                        case AuditLogService.FILE_UNDELETED:
                            return "Undeleted file " + this.event.entity_id
                        case AuditLogService.FILE_DOWNLOADED:
                            return "Downloaded file " + this.event.entity_id
                        default:
                            return null
                    }
            }
            */



        },

        is_mobile(){
            return Utils.is_mobile()
        },

        popover_content(){
            return this.event_user.name + ' ' +this.event_verb + ' ' + this.event_file.name + (this.event_file.path.length > this.event_file.name.length  ? '<br/><br/>Full path:<br/>' + this.event_file.path : '')
        },

        event_file(){
            return this.event && this.event.file 
                ? this.event.file 
                : {
                    name: "<i>a deleted file</i>",
                    path: "<i>a deleted file</i>",
                }
        }
    },

    methods: {
        get_extension(filename){
            return Utils.get_file_extension(filename)
        }
    }
}
</script>
<style scoped>

.event_row{
    max-width: 50%;
    text-overflow: ellipsis
}
</style>
