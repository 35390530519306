<template>
<div class="d-flex align-items-center" :class="{'gap-2': !Utils.is_mobile()}">
    <img :src="Utils.flag_img_src(location.countrycode)" height="20" class="mr-2" />
    <div v-if="Utils.is_mobile()">
        <div>{{location.city}}</div>
        <div class="text-sm">{{location.country}}</div>
    </div>
    <span v-else>{{location.city}}, {{location.country}}</span>
</div>
</template>
<script>
import { Utils } from '@/helpers/utils'
export default {
    props: {
        location: {
            type: Object
        }
    },
    data(){
        return {
            Utils: Utils
        }
    }
}
</script>