import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'

// This is not exported
const base_url = process.env.VUE_APP_storage_backend_service_url


const CloudProviderService = {
    list(){
        return bus.$http.get(base_url + "/providers", { headers: Utils.get_auth_header() })
    }
}

const StorageLocationService = {
    list(){
        return bus.$http.get(base_url + "/backends", { headers: Utils.get_auth_header() })
    },

    list_my_team_locations(){
        return bus.$http.get(base_url + "/backends/my_team", { headers: Utils.get_auth_header() })
    }
}

const StorageBackendService = {

    get_buckets(){
        return bus.$http.get(base_url + "/buckets", { headers: Utils.get_auth_header() })
    },

    get_suggested_locations(invite_code){
        return bus.$http.get(base_url + "/suggest_locations/invite/"+(invite_code || ''))
    },

    get_all_locations_with_distance(include_markers){
        include_markers = (include_markers === undefined) ? false : include_markers
        let url_params = {}
        if(include_markers){
            url_params['include_markers'] = 1
        }
        return bus.$http.get(base_url + "/all_locations_with_distance", url_params)
    },


	team_backend_setup(backend_ids){

		let payload = {
			backends: []
		};
		backend_ids.forEach( id => {
			payload.backends.push({ id: id })
		}, this);

		return bus.$http.post(base_url + "/customer_setup", payload, { headers: Utils.get_auth_header() })
    },

    send_bugreport(text, reply_requested){
        const payload = {
            text: text,
            reply_requested: reply_requested
        }
        return bus.$http.post(base_url + "/bugreport", payload, { headers: Utils.get_auth_header() })
    },

    get_upload_links(object_name_base, generations_num){
        const payload = {
            object_name_base: object_name_base,
            generations_num: generations_num
        }
        return bus.$http.post(base_url + "/get_upload_links", payload, { headers: Utils.get_auth_header() })
    },

}

export { CloudProviderService, StorageLocationService, StorageBackendService }