<template>
    <section class="section-container" id="team-setup-container">

        <!-- Blue gradient bg -->
        <div class="container-overlap bg-gradient-info d-none d-sm-block"></div>

        <div class="container container-md d-block carousel" id="setup_form_carousel" data-interval='0' data-wrap="false" data-pause="false">
            
            <div class="cardbox fh pt-3" style="min-height: 42em">
                
                <div class="cardbox-body px-5 pb-0">
                    <div class="progress" style="height: 5px" :class="{'bg-white': current_slide_idx == 0 || current_slide_idx > 2}">
                        <div v-if="current_slide_idx <= total_steps_num" class="progress-bar bg-skyflok" :style="{width: (current_slide_idx/total_steps_num)*100 + '%' }" role="progressbar"></div>
                    </div>
                </div>

                <div class="carousel-inner" style="overflow: visible">
                    
                    <!-- Step 0: Welcome -->
                    <div class="carousel-item active p-4">
                        <div class="cardbox-body fw fh text-center welcome-header" :class="{'p-0': Utils.is_mobile(), 'px-5': !Utils.is_mobile()}">
                            
                            <h2 class="py-3 d-flex align-items-center justify-content-center">
                                <span>Welcome to <span class="color-skyflok mx-2">SkyFlok </span></span>
                                <img src="@/assets/img/skyflok-badge.png" width="40px">
                            </h2>
                            
                            <p class="pt-5 mt-5 text-12">Thank you for signing up, we are glad you are here!</p>

                            <p class="pt-2  text-12">Before you can start using SkyFlok, there are a few things to set up.</p>

                            <div class="pt-5 mt-5 text-center">
                                <div v-if="invite_error && !invite_loading" class="alert alert-danger text-bold">{{invite_error}}</div>
                                <button v-else class="btn btn-lg text-12 btn-skyflok wizard-nav px-4" :disabled="invite_loading" data-slide="next" href="#setup_form_carousel">
                                    Start<i :class="{'ion-load-c spin': invite_loading, 'ion-arrow-right-c': invite}"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <!-- Step 1: Technology Explainer -->
                    <div class="carousel-item p-4">
                        <div class="cardbox-body fw" :class="{'p-0': Utils.is_mobile()}">
                            
                            <h4 class="px-4">Our Unique Technology</h4>

                            <p class="px-1 py-3" :class="{'text-center': Utils.is_mobile(), 'px-4': !Utils.is_mobile()}">
                                SkyFlok uses a unique patented technology to store your files. 
                                They are encrypted in your browser and distributed to multiple locations for improved 
                                security, availability and privacy.
                                <br/>You can select these locations in the next step.
                            </p>

                            <div class="fw text-center mt-4" :class="{'mb-3': Utils.is_mobile()}">
                                <video id="explainer-video" autoplay muted loop playsinline :class="{'fw': Utils.is_mobile(), 'width-80p': !Utils.is_mobile()}">
                                    <source src="assets/videos/skyflok-file-upload.webm" type="video/webm">
                                    <source src="assets/videos/skyflok-file-upload.mov" type="video/quicktime">
                                </video>
                            </div>

                            <div class="fw text-right mt-3">
                                <button class="btn btn-lg btn-skyflok wizard-nav" data-slide="next" href="#setup_form_carousel">
                                    Next<i class="ion-arrow-right-c"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <!-- Step 2: File Locations -->
                    <div class="carousel-item p-4">
                        <div class="cardbox-body fw" :class="{'p-0': Utils.is_mobile()}">

                            <h4 class="px-4">File Locations</h4>

                            <p class="px-1 py-3" :class="{'text-center': Utils.is_mobile(), 'px-4': !Utils.is_mobile()}">
                               The following selection of our 50+ worldwide storage locations provide the fastest file transfers in your region.
                            </p>

                            <div v-if="!customize_locations" class="fw d-flex align-items-center justify-content-center" :class="{'px-3': !Utils.is_mobile()}">
                                <div v-if="location_suggestion_loading" class="d-flex align-items-center justify-content-center" style="height: 25em">
                                    <span>Loading recommendation</span>
                                    <i class="ion-load-c spin ml-2 color-skyflok text-md"></i>
                                </div>
                                <table v-else-if="suggested_locations.length" class="table table-skyflok m-0" :class="{'width-75p': !Utils.is_mobile()}">
                                    <thead>
                                        <th class="text-center">Provider</th>
                                        <th>Location</th>
                                        <th class="text-center width-0 nowrap">GDPR <info-icon content="Whether this location is GDPR-compliant"></info-icon></th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="location in suggested_locations" :key="location.id">
                                            <td class="text-center">
                                                <!-- Logos are not the same aspect ratio, this div makes sure the table rows have the same height  -->
                                                <img v-if="location.provider_logo" :src="Utils.cloud_provider_logo_src(location.provider_logo)" class="cloud-provider-logo">
                                                <span v-else>{{location.provider_name}}</span>
                                            </td>
                                            <td>
                                                <storage-location-cell :location="location.location" />
                                            </td>
                                            <td class="text-center">
                                                <i class="text-12" :class="{'ion-checkmark-circled color-skyflok': location.is_gdpr, 'ion-close-circled text-danger': !location.is_gdpr}"></i>
                                            </td>
                                        </tr>
                                    </tbody> 
                                </table>

                            </div>
                            <div v-else>

                                <div v-if="!all_locations.length" class="d-flex align-items-center justify-content-center" style="height: 25em">
                                    <span>Loading all locations...</span>
                                    <i class="ion-load-c spin color-skyflok ml-2 text-md"></i>
                                </div>

                                <div v-else>
                                    <div class="fw d-flex align-items-top justify-content-center" style="min-height: 20em" :class="{'px-3': !Utils.is_mobile()}">
                                        <table v-if="!show_map" class="table table-skyflok m-0">
                                            <thead>
                                                <th class="width-0"></th>
                                                <th class="text-center dropdown">
                                                    <div class="dropdown-toggle c-pointer nowrap" data-toggle="dropdown">
                                                        Provider</div>
                                                    <div class="dropdown-menu mt-1 px-3"  @click.stop="()=>{}">
                                                        <div v-for="provider in location_filter.providers" :key="provider.id"  class="m-3" :class="{'my-3': !Utils.is_mobile()}">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" v-model="provider.checked" :id="`checkbox-provider-${provider.id}`" />
                                                                <label class="form-check-label nowrap" :for="`checkbox-provider-${provider.id}`">{{provider.name}}<small> ({{provider.locations_num}})</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="dropdown">
                                                    <div class="dropdown-toggle c-pointer nowrap" data-toggle="dropdown">
                                                        Location
                                                    </div>
                                                    <div class="dropdown-menu mt-1 px-3" @click.stop="()=>{}">
                                                        <div v-for="country in location_filter.countries" :key="country.countrycode" class="m-2" :class="{'my-3': !Utils.is_mobile()}">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" v-model="country.checked" :id="`checkbox-location-${country.countrycode}`" />
                                                                <label class="form-check-label nowrap" :for="`checkbox-location-${country.countrycode}`">
                                                                    {{country.name}} <img :src="Utils.flag_img_src(country.countrycode)" height="20" class="ml-2"/>
                                                                    <small class="ml-2"> ({{country.num}})</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="text-center width-0 nowrap dropdown">
                                                    <div class="dropdown-toggle c-pointer nowrap" data-toggle="dropdown">
                                                        GDPR
                                                    </div>
                                                    <div class="dropdown-menu mt-1 px-3" :class="{'dropdown-menu-right': Utils.is_mobile()}" @click.stop="()=>{}">
                                                        <div class="mx-2 my-2" :class="{'my-3': !Utils.is_mobile()}">
                                                            <checkbox label="GDPR locations" :value="location_filter.gdpr.yes" @changed="newval => location_filter.gdpr.yes = newval" />
                                                        </div>

                                                        <div class="mx-2 my-2" :class="{'my-3': !Utils.is_mobile()}">
                                                            <checkbox label="Non-GDPR locations" :value="location_filter.gdpr.no" @changed="newval => location_filter.gdpr.no = newval" />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="width-0 d-none d-sm-table-cell nowrap"  v-if="all_locations.length && all_locations[0].distance_km !== null">
                                                    Distance <info-icon content="Approximate distance from you, estimated from your IP address." custom_class="text-gray"/>
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr v-if="is_filtered">
                                                    <td colspan="5">
                                                        <div class="p-1">
                                                            <span v-for="provider in location_filter.providers.filter(p => p.checked)" :key="'provider-'+provider.id" class="btn btn-sm btn-oval btn-skyflok-outline m-1" @click="provider.checked = false">
                                                                {{ provider.name }} <i class="ion-close ml-1"></i>
                                                            </span>

                                                            <span v-for="country in location_filter.countries.filter(p => p.checked)" :key="'country-'+country.countrycode" class="btn btn-sm btn-oval btn-skyflok-outline  m-1" @click="country.checked = false">
                                                                {{ country.name }} <i class="ion-close ml-1"></i>
                                                            </span>

                                                            <span v-if="location_filter.gdpr.yes" class="btn btn-sm btn-oval btn-skyflok-outline m-1" @click="location_filter.gdpr.yes = false">
                                                                GDPR <i class="ion-close ml-1"></i>
                                                            </span>
                                                            <span v-if="location_filter.gdpr.no" class="btn btn-sm btn-oval btn-skyflok-outline m-1" @click="location_filter.gdpr.no = false">
                                                                Not GDPR <i class="ion-close ml-1"></i>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr 
                                                    v-for="location in locations_page" :key="location.id" 
                                                    :class="{'selected-row': location.selected, 'c-pointer': location.selected || custom_locations_selected.length < 6}" 
                                                    @click="location.selected = !location.selected">
                                                    <td>
                                                        <div class="m-0 ml-3 p-0">
                                                            <checkbox :value="location.selected" @changed="newval => location.selected = newval" />
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <!-- Logos are not the same aspect ratio, this div makes sure the table rows have the same height  -->
                                                        <img v-if="!Utils.is_mobile() && location.provider_logo" :src="Utils.cloud_provider_logo_src(location.provider_logo)" class="cloud-provider-logo">
                                                        <span v-else>{{location.provider_name}}</span>
                                                    </td>
                                                    <td>
                                                        <storage-location-cell :location="location.location" />
                                                    </td>
                                                    <td class="text-center">
                                                        <i class="text-12" :class="{'ion-checkmark-circled color-skyflok': location.is_gdpr, 'ion-close-circled': !location.is_gdpr}"></i>
                                                    </td>
                                                    <td class="d-none d-sm-table-cell" v-if="location.distance_km !== null">
                                                        {{location.distance_km | round_up_100 }} km
                                                    </td>
                                                </tr>
                                            </tbody> 
                                        </table>
                                        <!--
                                        <div v-else>
                                            <gmap-map
                                                style="width: 850px; height: 450px"
                                                :center="{ lat: 40.0, lng: -30.0 }"
                                                :zoom="2"
                                                :disableDefaultUI="true"
                                                :options="{ styles: map_style, disableDefaultUI: true }"
                                                ref="map">
                                                    <gmap-marker
                                                        v-for="(marker, index) in markers"
                                                        :key="index"
                                                        :title="marker.title"
                                                        :position="marker.location"
                                                        :icon="marker"
                                                        :draggable="false"
                                                    >
                                                    </gmap-marker>
                                            </gmap-map>
                                        </div>
                                        -->
                                    </div>
                                    
                                    <div class="d-flex items-center justify-content-end mt-1" :class="{'px-3': !Utils.is_mobile()}">
                                        <!-- Disable the map button -->
                                        <button v-if="false" class="btn btn-sm btn-skyflok-outline my-1 ml-0" type="button" @click="() => { show_map = !show_map }"><i class="mr-1" :class="{'ion-map': !show_map, 'ion-navicon-round': show_map}"></i>{{ show_map ? 'Table' : 'Map' }}</button>

                                        <div>
                                            <table-pager 
                                                v-if="!show_map"
                                                :data="all_locations_filtered" 
                                                :is_filtered="is_filtered" 
                                                :update="(data_page) => { locations_page = data_page }"
                                                :initial_page_size="8"
                                                :disable_pagesize_selector="true"
                                                :disable_filtered_label="true"
                                                records_name="locations">
                                            </table-pager>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center text-sm mt-2">
                                <a v-if="!customize_locations" href="#" class="color-skyflok" @click="customize_locations=true">Customize file locations</a>
                                <a v-else-if="!location_suggestion_failed" href="#" class="color-skyflok text-12" @click="customize_locations=false">Back to suggested locations</a>
                            </div>

                            <div class="row mt-3">
                                
                                <div class="col-6 text-left">
                                    <button class="btn btn-lg btn-secondary" type="button" data-slide="prev" href="#setup_form_carousel">
                                        <i class="ion-arrow-left-c"></i><span class="d-none d-md-inline-block ml-2">Back</span>
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-lg btn-skyflok wizard-nav" :disabled="!locations_ok" data-slide="next" href="#setup_form_carousel">
                                        <span v-if="locations_ok">Next <i class="ion-arrow-right-c"></i></span>
                                        <span v-else-if="custom_locations_selected.length < 6">Select {{6-custom_locations_selected.length}} more</span>
                                        <span v-else-if="custom_locations_selected.length > 6">Remove {{custom_locations_selected.length-6}} location{{custom_locations_selected.length-6 == 1 ? '' : 's'}}</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
 
                    <!-- Step 3: Team name & members -->
                    <div class="carousel-item p-4">
                        <div class="cardbox-body fw mx-1" :class="{'p-0': Utils.is_mobile(), 'mx-4': !Utils.is_mobile()}">
                        <form @submit.prevent="submit_form">

                            <h4>Your Account</h4>

                            <p class="pt-1 pb-3" :class="{'text-justify': Utils.is_mobile()}">
                                Choose a name for your SkyFlok Team and fill the details of your user account.
                            </p>

                            <div v-if="user_form" class="row mt-4  user-form">
                                <div v-if="form_error" class="col-12"  :class="{'pr-4': !Utils.is_mobile()}">
                                    <div class="alert alert-danger">{{form_error}}</div>
                                </div>

                                <!-- Form -->
                                <div class="col-12 col-md-6"  :class="{'pr-4': !Utils.is_mobile()}">
                                    <input type="hidden" name="storage_location_ids" :value="selected_location_ids.join(',')" />
                                    <input type="hidden" name="invite_key" :value="invite_key" />

                                    <label for="register_team_name pt-0 mt-0" style="padding-top: 0px">SkyFlok Team Name</label>
                                    <input type="text" class="form-control" id="register_team_name" name="team_name" maxlength="255" v-model="user_form.team_name">

                                    <label for="register_name pt-0 mt-0">Your Name</label>
                                    <input type="text" class="form-control" id="register_name" name="person_name" maxlength="255" autocomplete="name" v-model="user_form.name">
                                    
                                    <label for="register_email">Email Address</label>
                                    <input type="email" class="form-control" id="register_email" readonly name="email" autocomplete="username email" v-model="user_form.email">

                                    <label for="register_password">Password</label>
                                    <input type="password" class="form-control" id="register_password" name="password" autocomplete="new-password" v-model="user_form.password" placeholder="At least 8 characters">
                                    <password-strength-meter :password_strength="password_strength" />

                                    <div class="fw">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="terms_accepted" :value="1" v-model="user_form.terms_accepted" id="terms-accepted" />
                                            <label class="form-check-label nowrap" for="terms-accepted">
                                                I accept the <a href="https://www.skyflok.com/legal/" target="_blank" class="color-skyflok">SkyFlok Terms &amp; Conditions</a>
                                            </label>
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-12 col-md-6" :class="{'pl-4 mt-4': !Utils.is_mobile(), 'pt-3': Utils.is_mobile()}">
                                    <invite-config-box :invite="invite" />
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-4 text-left">
                                    <button v-if="!form_loading" class="btn btn-lg btn-secondary" type="button" data-slide="prev" href="#setup_form_carousel"><i class="ion-arrow-left-c"></i><span class="d-none d-md-inline-block ml-2">Back</span></button>
                                </div>
                                <div class="col-4 text-center">
                                    <button class="btn btn-lg text-12 btn-skyflok" type="submit" :disabled="!user_form_ok || form_loading">
                                        Finish Setup <i v-if="form_loading" class="ml-2 ion-load-c spin"></i>
                                    </button>
                                    <div v-if="submit_error_msg" class="text-danger text-center mt-1 text-sm">{{ submit_error_msg }}</div>
                                </div>
                            </div>

                        </form>
                        </div>
                    </div>

                    <!-- Step 4: Success -->
                    <div class="carousel-item p-4">
                        <div class="cardbox-body fw" style="height: 20em">
                            <div class="fw fh text-center pt-5 ">
                                <h2 class="text-success">Your Account is Ready!</h2>
                                
                                <div class="py-5 d-flex justify-content-center">
                                    <div class="width-25p" :class="{'width-75p': Utils.is_mobile()}">
                                        <img src="@/assets/img/illustrations/moving_forward-success.svg" style="max-width: 100%" />
                                    </div>
                                </div>

                                <div class="mt-5 fw text-center text-14" v-if="invite">
                                    Redirecting to <router-link :to="'/login?email='+encodeURIComponent(invite.invited_email_address)" class="color-skyflok text-underline">Login</router-link> in... {{redirect_timer_sec}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </section>
</template>

<script>

import { Utils } from '@/helpers/utils'
import { StorageBackendService } from '@/services/storage_backend-service'
import { UserService } from '@/services/user-service'

import infoIcon from '@/components/info-icon.vue'
import checkbox from '@/components/checkbox.vue'
import tablePager from '@/components/table-pager'
import passwordStrengthMeter from '@/components/psw-strength-meter.vue'
import inviteConfigBox from '@/components/invite-config-box.vue'
import storageLocationCell from '@/components/team-setup/storage_location.vue'

export default {

    components: { 
        infoIcon, 
        tablePager, 
        inviteConfigBox, 
        storageLocationCell, 
        passwordStrengthMeter,
        checkbox
    },
    
    data(){
        return {
            current_slide_idx: 0,
            total_steps_num: 3,

            invite_key: null,
            invite: null,
            invite_loading: false,
            invite_error: false,

            Utils: Utils,

            customize_locations: false,
            location_suggestion_failed: false,
            location_suggestion_loading: false,
            suggested_locations: [],
            all_locations: [],
            locations_page: [],
            cloud_providers: [],

            location_filter: {
                providers: [],
                countries: [],
                gdpr: {
                    yes: false,
                    no: false
                }
            },

            user_form: null,
            form_loading: false,
            form_error: false,
            redirect_timer_sec: 3,

            $explainer_video: null,
            show_map: false
        }
    },

    computed: {

        unique_provider_ids(){
            if(!this.all_locations.length){
                return []
            }
            let provider_ids = []
            this.all_locations.forEach(l => {
                if(provider_ids.indexOf(l.provider_id) < 0){
                    provider_ids.push(l.provider_id)
                }
            })

            return provider_ids
        },

        all_locations_filtered(){
            if(!this.all_locations){
                return []
            }
            if(this.is_filtered){
                // apply filters
                let locations = this.all_locations
                const filters = this.location_filter
                if(this.are_providers_filtered){
                    locations = locations.filter(l => !!filters.providers.find(fp => fp.checked && fp.id == l.provider_id))
                }
                if(this.are_countries_filtered){
                    locations = locations.filter(l => !!filters.countries.find(c => c.checked && c.countrycode == l.location.countrycode))
                }
                if(filters.gdpr.yes && !filters.gdpr.no){
                    // only gdpr
                    locations = locations.filter(l => l.is_gdpr)
                }
                if(filters.gdpr.no && !filters.gdpr.yes){
                    // only gdpr
                    locations = locations.filter(l => !l.is_gdpr)
                }
                return locations

            }
            else{
                // No filters
                return this.all_locations
            }
        },

        is_filtered(){
            const filters = this.location_filter
            return this.are_providers_filtered || this.are_countries_filtered || filters.gdpr.yes || filters.gdpr.no
        },

        are_providers_filtered(){
            let checked_num = this.location_filter.providers.filter(p => p.checked).length
            return (checked_num > 0 && checked_num < this.location_filter.providers.length)
        },

        are_countries_filtered(){
            let checked_num = this.location_filter.countries.filter(c => c.checked).length
            return (checked_num > 0 && checked_num < this.location_filter.countries.length)
        },

        custom_locations_selected(){
            return this.all_locations.filter(l => l.selected)
        },

        selected_location_ids(){
            const locations = (this.customize_locations && this.all_locations.length) ? this.custom_locations_selected : this.suggested_locations
            return locations.map(l => l.id)
        },

        locations_ok(){
            return this.selected_location_ids.length == 6
        },

        markers(){
            return null
        },

        submit_error_msg(){
            if(!this.team_name_ok) return "SkyFlok Team Name is required!"
            if(!this.user_name_ok) return "Your name is required!"
            if(!this.password_strength) return "The password is not long enough!"
            if(!this.user_form.terms_accepted) return "Terms and Conditions must be accepted!"

            return null
        },

        user_form_ok(){
            return this.user_form && this.team_name_ok && this.user_name_ok && this.user_email_ok && this.password_ok && this.user_form.terms_accepted
        },

        team_name_ok(){
            const f = this.user_form
            return f && f.team_name.length
        },

        user_name_ok(){
            const f = this.user_form
            return f && f.name.length
        },

        user_email_ok(){
            const f = this.user_form
            return f && f.email.length && Utils.is_valid_email(f.email)
        },

        password_ok(){
            return this.password_strength == 2
        },

        password_strength(){
            if(!this.user_form){
                return 0
            }
            const pass = this.user_form.password
            if(pass.length < 4){ return 0 }
            else if(pass.length < 8){ return 1 }
            return 2
        }

    },

    watch: {
        current_slide_idx(){
            // Scroll to the top on slide transition
            Utils.scroll_top()
            switch(this.current_slide_idx){
                case 1:
                    this.$nextTick(()=>{
                        // force autoplay of the explainer
                        this.$explainer_video.play()
                    })
                    break

                case 3:
                    this.$nextTick(()=>{
                        // Focus the first non-prefilled input on the user form
                        // (do not focus the password field, some browsers like 
                        // Chrome may display a popover that hides the whole form)
                        if(!this.user_form.team_name.length){
                            $("#register_team_name").focus()
                        }
                        else if(!this.user_form.name.length){
                            $("#register_name").focus()
                        }
                    })
                    break
                default: break
            }
            // Pause the video when navigating away from it
            if(this.current_slide_idx != 1){
                this.$explainer_video.pause()
            }
        },

        customize_locations(){
            if(this.customize_locations && !this.all_locations.length){
                this.load_all_locations()
            }
        },
    },

    created(){
        this.load_invite()
    },

    mounted(){

        var self = this
        this.$nextTick(()=>{
            $('#setup_form_carousel').on('slide.bs.carousel', function (e) {
                if(e.direction === 'left'){
                    ++self.current_slide_idx

                }
                else if(e.direction === 'right'){
                    --self.current_slide_idx
                }
            })
            this.$explainer_video = document.getElementById("explainer-video")
        })
    },

    methods: {

        load_suggested_locations(){
            this.location_suggestion_loading = true
            StorageBackendService.get_suggested_locations(this.invite_key).then(res => {
                this.suggested_locations = res.body
                this.location_suggestion_failed = false
            }).catch(() => {
                // Error loading suggestion
                this.location_suggestion_failed = true
                // Switch to full table
                this.customize_locations = true
            }).finally(() => {
                this.location_suggestion_loading = false
            })
        },

        load_invite(){
            this.invite_key = Utils.parse_url_param('invite')
            if(!this.invite_key){
                // TODO
                this.invite_error = "No invite key in URL"
                return
            }

            this.invite_error = false
            this.invite = null
            this.invite_loading = true
            UserService.get_invite_by_key(this.invite_key).then(res => {
                this.invite = res.body
                this.invite_loading = false
                this.invite_loaded()

            }).catch(err => {
                this.invite_loading = false
                this.invite_error = err.body.message
            })
        },

        submit_form($evt){

            if(!this.user_form_ok){ return }

            // This is a special endpoint call, because we want the browser to trigger the 
            // password save dialog. Returns the result Promise of a fetch() call 
            // Source: https://developers.google.com/web/fundamentals/security/credential-management/save-forms
            this.form_loading = true

            // construct the payload
            const payload = new FormData($evt.target)
            UserService.team_invite_signup(payload)
            .then(fetch_result => {
                // Using fetch() to make this network call, both positive and negative
                // responses are handled here
                this.form_loading = false
                if(fetch_result.status == 200){
                    // OK
                    return Promise.resolve({
                        id: payload.get('email'),
                        name: payload.get('email'),
                        password: payload.get('password')
                    })
                }
                else{
                    // Error, parse json and reject
                    return Promise.reject(fetch_result.json())
                }
            }).then(creds => {
                if (window.PasswordCredential && navigator.credentials) {
                    // Instantiate PasswordCredential
                    return navigator.credentials.store(new window.PasswordCredential(creds));
                } else {
                    // Credential manager not supported in this browser
                    return Promise.resolve();
                }
            }).then(() => {
                this.form_loading = false
                this.form_error = false

                // Slide to the final step
                $('#setup_form_carousel').carousel('next')
                
                // Clear the user form 
                this.user_form.password = "*****"
                this.user_form = null

                // Count down the redirection timer
                var self = this
                let interval = setInterval(() => {
                    --self.redirect_timer_sec
                    if(self.redirect_timer_sec == 0){
                        clearInterval(interval)
                        // Redirect to login
                        self.$router.push('/login?email='+encodeURIComponent(this.invite.invited_email_address))
                    }
                }, 1000)
            })
            .catch(err_response => {
                this.form_loading = false
                // err_response is the JSON from UserService.team_invite_signup
                this.form_error = err_response.message
            })
        },

        invite_loaded(){

            this.load_suggested_locations()

            // init form
            const invite = this.invite
            this.user_form = {
                team_name: invite.invited_team_name || "",
                name: invite.invited_name || "",
                email: invite.invited_email_address,
                password: "",
                terms_accepted: false
            }
        },

        load_all_locations(){
            
            // Load storage locations, including their distance from the user
            const with_map_markers = (this.show_map === true)
            StorageBackendService.get_all_locations_with_distance(with_map_markers).then(res => {
                let locations = res.body
                // Add 'selected' attribute, pre-select the suggested locations
                locations.forEach(l => {
                    l.selected = !!this.suggested_locations.find(loc => loc.id == l.id)
                })
                this.all_locations = locations

                // ////////////////
                // Populate Filters

                // Collect the unique countries for the table filter
                let unique_countries = []
                this.all_locations.forEach(loc => {
                    if(!unique_countries.find(ul => ul.countrycode == loc.location.countrycode)){
                        unique_countries.push({
                            countrycode: loc.location.countrycode,
                            name: loc.location.country,
                            checked: false,
                            num: this.all_locations.filter(l => l.location.countrycode == loc.location.countrycode).length
                        })
                    }
                })
                this.location_filter.countries = Utils.sort_objects(unique_countries, 'name')

                // Collect all unique providers for the Cloud Provider table filter
                let cloud_providers = locations.map(loc => {
                    return {
                        id: loc.provider_id,
                        name: loc.provider_name,
                        logo: loc.provider_logo,
                        checked: false,
                        locations_num: 0
                    }
                })
                // Remove duplicates 
                let unique_providers = [...new Map(cloud_providers.map(cp => [cp['id'], cp])).values()]

                // Count the number of locations per cp
                unique_providers.forEach(provider => {
                    provider.locations_num = this.all_locations.filter(loc => loc.provider_id == provider.id).length
                })

                // Sort by name
                Utils.sort_objects(unique_providers, 'name')
                this.location_filter.providers = unique_providers
            })
        }

    }
}
</script>

<style scoped>


.wizard-nav i{
    margin-left: 0.5em;
    margin-right: .2em;
    transition: .2s ease-out all
}

.wizard-nav:hover i{
    margin-left: 0.7em;
    margin-right: 0em;
}

div.carousel-inner{
    /* Needed to prevent clipping the bottom of 
    location filter dropdowns when they are taller 
    than the carousel */
    overflow: visible;
}
.section-container#team-setup-container{
    margin-top: 0px !important;
    height: 100%;
    min-height: 100%;
}

#team-setup-container .container-overlap{
    height: 30%;
}

#team-setup-container .container-overlap + .container-md{
    margin-top: -10%;
    min-height: 80%;
}

.invite-box-container{
    max-width: 50%;
}

.row.user-form{
    margin: 0px 10%
}

label:not(.form-check-label){
    font-size: 1em;
    margin-bottom: 3px;
    padding-top: 1em
}

@media(max-width: 768px){
    #team-setup-container .container-overlap + .container-md{
        margin-top: -15em;
    }
    .invite-box-container{
        max-width: 100%;
        width: 100%;
    }
    .row.user-form{
        margin: inherit
    }
}

@media(max-width: 576px){
    #team-setup-container .container-overlap + .container-md{
        margin-top: 0;
        top: 1em;
        
    }
    .section-container#team-setup-container{
        height: auto;
        background-image: linear-gradient(135deg, #009688 0, #42A5F5 100%);
    }
    .welcome-header h2,.welcome-header h2 span{
        display: block !important;
        line-height: 1.3em;
        padding-bottom: 0px !important;
    }
    .welcome-header p{
        padding-top: 0px !important;
    }
    td,th{
        padding: .6em !important
    }
}

.cloud-provider-logo{
    max-width: 100px; 
    max-height: 40px;
    mix-blend-mode: color-burn;
}

.progress{
    height: 1em;
}

@media(max-width: 768px){
    /* Tablet */
}

@media(max-width: 576px){
    /* Phone */
    .cloud-provider-logo{
        max-width: 80px; 
        max-height: 30px;
        
    }
}

</style>