<template>
<section>
    <div v-if="deleted_groups">
        <div class="cardbox" v-for="(group, index) in deleted_groups" :key="index">

            <div class="cardbox-body text-center c-pointer" :class="{'text-bold': group.open}" @click="group.open = !group.open" :title="'Click to '+(group.open ? 'hide' : 'show')+' details'">
                <i class="float-right" :class="{'ion-chevron-down': !group.open, 'ion-chevron-up': group.open}"></i>

                {{ deleted_label ? deleted_label : "Deleted" }}
                <span v-if="group.deleted_by !== undefined" class="mr-2"> by
                    <user-box v-if="group.deleted_by !== undefined && user && user.team" :user-id="group.deleted_by" :team="user.team" :external-actor="modifiedBoxExternal"></user-box>
                </span>
                
                {{ group.deleted_at | timestamp_date }}, {{ group.deleted_at | timestamp_time }}

            </div>
            <div class="cardbox-body" v-if="group.open">

                <div v-if="!disableRestore && group.is_deleted_folder" class="fw text-center">
                    <button class="btn btn-secondary btn-gradient" :disabled="group.entities[0].restoring" @click="restore(group.entities[0])">
                        <i class="ion-arrow-return-right mr-2"></i>Restore folder<i v-if="group.entities[0].restoring" class="ion-load-c spin ml-1"></i>
                    </button>

                </div>

                <table class="table table-hover mb-0">
                    <thead>
                        <th class="nowrap" style="width: 1px"></th>
                        <th>Name</th>
                        <th class="nowrap">Type</th>
                        <th class="nowrap" style="width: 1px"></th>
                    </thead>
                    <tbody>
                        <tr v-for="file in group.entities" :key="file.id" >
                            <td class="text-center">
                                <img v-if="file.entity_type === 'FILE'" :src="file.extension | filetype_img_src" class="file-icon" />
                                <i v-if="file.entity_type === 'FOLDER'" class="ion-folder" style="font-size: 25px"></i>
                            </td>
                            <td>
                                <span class="text-bold filename">{{ file.name }}</span>
                                <div class="path"><small v-html="$options.filters.path(file.path)"></small></div>
                            </td>
                            <td>
                                <span v-if="file.entity_type === 'FILE'" class="text-upper">{{ file.extension }}</span>
                                <span v-if="file.entity_type === 'FOLDER'"><i>Directory</i></span>
                            </td>
                            <td v-if="!disableRestore && !group.is_deleted_folder">
                                <button v-if="!file.restoring" class="btn btn-secondary btn-gradient" @click="restore(file)">
                                    <i class="ion-arrow-return-right "></i><span class="ml-2">Restore</span>
                                </button>
                                <i v-else class="ion-load-c spin"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="!deleted_groups.length">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-12 col-lg-6">
                    <div class="cardbox text-white bg-gradient-warning b0">
                        <div class="cardbox-body text-center">
                            <div class="text-bold display-1"><i class="ion-qr-scanner"></i></div>
                            <p class="text-bold text-md">No <span style="text-transform: lowercase">{{ deleted_label ? deleted_label : "deleted" }} </span> files</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" v-if="deleted_groups === null">
        <div class="col-3"></div>
        <div class="col-12 col-lg-6">
            <div class="cardbox text-white bg-gradient-warning b0">
                <div class="cardbox-body text-center">
                    <div class="text-bold display-1"><div class="loader"></div></div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>

import { NamespaceService } from '@/services/namespace-service.js'
import { Utils } from '@/helpers/utils.js';

import userBox from '@/components/user-box.vue';

export default {
    props: ["user", "deleted_label", 'modified-box-external', 'restoreAllowed'],
    components: {
        userBox
    },

    data(){
        return {
            deleted_groups: null
        }
    },

    computed: {
        disableRestore(){ return this.restoreAllowed === false }
    },

    created(){
        this.reload()
    },

    methods: {

        reload(){

            this.deleted_groups = null
            NamespaceService.list_deleted().then(res => {
                if(!res.ok){
                    console.error("Failed to list deleted files: HTTP" + res.status, res.body)
                    return;
                }

				// Group deleted entities by their Delete Key
				let deleted_groups = {}
				res.body.forEach(entity => {
					if(entity.entity_type == NamespaceService.ENTITY_TYPE_FILE){
						// Parse extension of files
						entity.extension = Utils.get_file_extension(entity.name)
                    }
					if(!deleted_groups[entity.delete_key]){ deleted_groups[entity.delete_key] = [] }
					deleted_groups[entity.delete_key].push(entity)
				})

				// Make an array of the deleted_groups so we can sort them by time of delete (most recent comes first)
				let deleted_groups_arr = []
				for(var delete_key in deleted_groups){
					let group = deleted_groups[delete_key]
					deleted_groups_arr.push({
						deleted_at: group[0].deleted_at,
						delete_key: delete_key,
						deleted_by: group[0].deleted_by,
						entities: group
					})
				}

				deleted_groups_arr.sort( (a,b) => { return b.deleted_at - a.deleted_at } )
				// Within each group sort by the length of 'path', so folders come first

                deleted_groups_arr.forEach(group => {
                    group.open = false

					group.entities.sort( (a,b) => {
						// Check if they are in the same subfolder
						const path_diff = Utils.path(a.path).length - Utils.path(b.path).length
						if(path_diff != 0){
							// Different subfolder, separete by depth
							return path_diff
						}
						// They are in the same level. Rule 1: Folders come first
						const a_type = (a.entity_type === NamespaceService.ENTITY_TYPE_FILE) - 0
						const b_type = (b.entity_type === NamespaceService.ENTITY_TYPE_FILE) - 0
						const type_diff = a_type - b_type

						if(type_diff != 0){
							// Different types, folder come first
							return type_diff
						}
						// Same type, lexicographical sort
						return a.name.localeCompare(b.name)
                    })

                    group.is_deleted_folder = group.entities.length > 0 && group.entities[0].entity_type === NamespaceService.ENTITY_TYPE_FOLDER
                })
                if(deleted_groups_arr.length > 0){
                    deleted_groups_arr[0].open = true
                }

                this.deleted_groups = deleted_groups_arr

            }).catch(err => {
				console.error(err)
				Utils.show_error(Utils.make_error_message("Failed to load contents of Recycle Bin", err))
            })
        },

        restore: function(file){
            this.$set(file, "restoring", true)


            NamespaceService.undelete_file(file.id).then(() => {
				// Remove from files
				Utils.show_success("<b>"+file.name+"</b> restored to <b>"+Utils.path(file.path)+"</b>")
				file.restoring = false
				this.reload()

            }).catch(err => {
				console.error(err)
				file.restoring = false
				Utils.show_error(Utils.make_error_message("Error restoring "+file.name, err))
            })
		},

		range(i){
			let a = [];
			for(var j=0 ; j<i ; ++j){a.push(j)}
			return a
		}
    },

    filters: {

        path(file_path){
            return Utils.path(file_path)
		},
	},
}
</script>
