<template>
<section class="d-inline-block" v-if="data && data.length">
    
    <div class="pager noselect mb-2 d-block">

        <div class="text-right">
            <span v-if="is_filtered && !disable_filtered_label" class="text-right mr-2">
                <small>Showing <b>{{ data_page.length }} of {{ data.length }}</b> filtered {{ records_name ? records_name : 'records' }}.</small>
            </span>
            <small v-if="!disable_pagesize_selector">
                Page size:
                <select v-model="page_size" class="form-control form-control-sm d-inline-block p-1" style="width: 55px">
                    <option :value="5">5</option>
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                </select>
            </small>
        </div>
        
        <div class="text-right pager-button">
            <div class="d-inline-block p-1 m-1 c-pointer" :class="{'disabled': page <= 1}" @click="page > 1 ? page = page-1 : false"><i class="ion-chevron-left"></i></div>
            <div v-for="p in range(total_pages)" :key="p" @click="page = p" class="d-inline-block px-2 c-pointer" :class="{'bg-skyflok text-white active': (p == page)}">{{ p }}</div>
            <div class="d-inline-block p-1 m-1 c-pointer" :class="{'disabled': page >= total_pages}" @click="page < total_pages ? page = page+1 : false"><i class="ion-chevron-right"></i></div>
        </div>
        
    </div>

</section>
</template>
<script>
export default {
    props: ['is_filtered', 'data', 'update', 'initial_page_size', 'records_name', 'disable_pagesize_selector', 'disable_filtered_label'],
    data(){
        return {
            page_size: 10,
            page: 1,
        }
    },

    created(){
        if(this.initial_page_size){
            this.page_size = this.initial_page_size
        }
    },

    watch: {
        page_size(){
            // Reset page when the page size is changed (so it wouldn't be out of valid range)
            this.page = 1
        },

        data_page(){
            this.update(this.data_page)
        }
    },

    mounted(){
        this.update(this.data_page)
    },

    computed: {

        total_pages(){
            if(!this.data){ return null }
            return Math.ceil(this.data.length / this.page_size)
        },

        data_page(){
            if(!this.data){ return null }
            const start_offset = (this.page-1) * this.page_size
            const end_offset = start_offset + this.page_size
            return this.data.slice(start_offset, end_offset)
        }
    },

    methods: {
        range(n){
            let arr = []
            for(var i=0 ; i<n ; ++i){
                arr.push(i)
            }
            return i
        }
    }
}
</script>

<style scoped>
.pager-button div:not(.disabled):not(.active):hover{
    background: rgba(0, 128, 128, 0.2);
    color: #008080;
}
</style>