<template>
<div class="col-12 col-xl-6">
<div class="cardbox">
    <div class="pb-1" :class="{'bg-gradient-danger': !share.active, 'bg-gradient-warning': share.active && share.type == 1, 'bg-gradient-info': share.active && share.type == 0}"></div>
    <div class="cardbox-body">

        <div v-if="share.active" class="float-right action-btns">
            <!--
            <a :href="link" target="_BLANK" class="mr-1" title="Open this share in a new tab">
                <i class="ion-share mr-1"></i> Open Share
            </a>
            -->

            <button @click="revokeHandler(share)" class="btn btn-outline-warning mr-1" title="Disable this share immediately">
                <i class="ion-alert-circled mr-1"></i> Disable
            </button>

        </div>
        <div v-else class="float-right action-btns">
            <button @click="activateHandler(share)" :disabled="activatedisabled" :title="activatedisabled ? 'You reached the limit of active Shares. Upgrade your Team subscription to use more Shares!' : 'Activate this Share'" class="btn btn-outline-success mr-1">
                <i class="ion-minus-circled mr-1"></i> Activate
            </button>
            <button @click="deleteHandler(share)" class="btn btn-danger btn-gradient" title="Delete this share">
                <i class="ion-minus-circled mr-1"></i> Delete
            </button>
        </div>

        <p class="bl pl-3 mb-3" v-if="share.message" style="display:inline-block">Message: <i>{{ share.message }}</i></p>

        <!-- List files grouped by folder -->
        <template v-if="share.contents">
            <div v-for="group in share.contents" :key="group.folder" class="mb-3">
                <div v-if="group.folder" class="mb-2 text-muted text-sm">
                    <router-link :to="'files#'+group.folder.replace(/ > /g, '/').replace('Home', '')" class="text-muted" v-html="group.folder">
                    </router-link> &gt;
                </div>
                <shared-file v-for="file in group.files" :key="file.id" :entity="file"></shared-file>
            </div>
        </template>
        <i v-else class="ion-load-c spin"></i>

        <!-- Link -->
        <div v-if="share.active" class="text-center" style="white-space: nowrap">
            <!--<button class="btn btn-gradient btn-secondary mr-2" @click="to_clipboard('share_link-'+share.id)"><i class="ion-clipboard icon-lg mr-1"></i> Copy Share Link</button>-->
            <button  class="btn btn-gradient btn-secondary mr-2" @click="str_to_clipboard(link)"><i class="ion-clipboard icon-lg mr-1"></i> Copy Share Link</button>
            <button v-if="share.password" class="btn btn-gradient btn-secondary mr-1" @click="str_to_clipboard(share.password)"><i class="ion-clipboard icon-lg mr-1"></i> Copy Password</button>
            <span v-else><i class="text-muted text-sm mr-1">No password</i></span>
            <a :href="link" target="_BLANK" class="ml-1" title="Open this share in a new tab">
                Open<i class="ion-share ml-1"></i>
            </a>
        </div>



        <div class="row text-center mt-3">
            <!-- Bottom sheet - Active share -->
            <div class="col-3">
                <div class="text-muted">Expires</div>
                <div class="h5"><span v-if="share.expire">{{ share.expire | timestamp_rel }}</span><span v-else>Never</span></div>
            </div>
            <div class="col-3 bl created-col">
                <div class="text-muted">Opened</div>
                <div class="h5">{{ share.accessed_count }}x<!-- <button v-if="share.accessed_count > 0" class="btn btn-sm btn-secondary"><i class="ion-stats-bars"></i></button>--></div>
            </div>
            <div class="col bl">
                <modified-box v-if="user && user.team" :user-id="share.created_by" :timestamp="share.created" :team="user.team"></modified-box>
            </div>
        </div>


        <!--
        <pre v-if="share.idx > -1">{{ JSON.stringify(share, null, 2) }}</pre>
        -->

    </div>

</div>
</div>
</template>

<script>

import sharedFile from '@/components/shared-file.vue'
import modifiedBox from '@/components/modified-box.vue'

import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'

export default {
    props: ["share", "user", "revokeHandler", "deleteHandler", "activateHandler", "activatedisabled"],

    components: {
        sharedFile,
        modifiedBox
    },

    data(){
        return {
            link: ""
        }
    },

    computed: {
        is_mobile(){
            return Utils.is_mobile()
        },
    },

    created(){
        // The link must be generated as a data attribute, because the global
        // filter cannot be applied when using it as a function parameter
        this.link = this.$options.filters['share_link'](this.share.alphakey)
    },

    methods: {
        str_to_clipboard: function(str){
            Utils.to_clipboard(str)
        },
    }
}
</script>
