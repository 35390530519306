<template>
    <section class="section-container">

        <div class="container-overlap bg-gradient-info text-center">
            <div class="mb-3 text-center" v-if="user">
                <img class="wd-sm rounded-circle img-thumbnail fit-cover" style="height: 150px" :src="avatar_url(user)" alt="user">
                <a href="#" @click="change_image()" class="text-white text-sm" style="display: block; margin-top: -2em">Change</a>
            </div>
            <div class="text-white" v-if="user">
                <div class="h3">{{ user.name }}</div>
            </div>
        </div>

        <div class="container container-md">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-12 col-lg-8 cardbox">
                    <h5 class="cardbox-heading pb-0">Profile <a class="btn btn-sm btn-secondary float-right" @click="edit_mode=true"><i class="ion-edit text-grey mr-2"></i>Edit</a></h5>

                    <div class="cardbox-body">
                        <edit-user-form :user="user" :close-form="closeForm" :edit-mode="edit_mode" :browse-image="browse_image"></edit-user-form>
                    </div>
                    
                    <h5 class="cardbox-heading pb-0">
                        Two-Factor Authentication

                        <div class="float-right dropdown btn-group" v-if="mfa_status && mfa_status.has_2fa && mfa_content == 'status'">
                            <button class="btn btn-secondary btn-sm" type="button" data-toggle="dropdown" aria-expanded="false" style="font-size: 1rem">
                                <i class="ion-gear-b text-grey mr-2"></i>
                                Options
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" role="menu">
                                <a class="dropdown-item" href="#" @click.prevent="()=>{ mfa_content = 'show_qr' }"><i class="ion-lock-combination mr-2"></i> Set up a new phone or app</a>
                                <!--
                                <a class="dropdown-item disabled" href="#" :aria-disabled="true" :disabled="true" @click.prevent="()=>{ return false}"><i class="ion-chatbox-working mr-2"></i> Change to SMS</a>
                                -->
                                <a class="dropdown-item" href="#" @click.prevent="()=>{ mfa_content = 'recovery_codes' }"><i class="ion-help-buoy mr-2"></i> Show my backup codes</a>
                                <a class="dropdown-item" href="#" @click.prevent="()=>{ mfa_content = 'history' }"><i class="ion-calendar mr-2"></i> Event Log</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item text-danger" href="#" @click.prevent="()=>{ mfa_content = 'lost_phone' }"><i class="ion-alert-circled mr-2"></i> I lost my phone</a>
                                <a class="dropdown-item text-danger" href="#" @click.prevent="()=>{ mfa_content = 'delete' }"><i class="ion-trash-a mr-2"></i> Disable two factor auth</a>
                            </div>
                        </div>
                        <!-- Cancel the setup wizard -->
                        <button v-if="mfa_content=='show_qr'" class="btn btn-secondary btn-sm float-right" @click="mfa_content = 'status'" ><i class="ion-close-round text-grey mr-2"></i>Cancel</button>
                    </h5>
                

                    <div class="cardbox-body">
                        <mfa 
                            :user_email="user ? user.email_address : false"
                            :mfa_content="mfa_content"
                            @mfa_status_loaded="(status) => { mfa_status = status }"
                            @mfa_content_change="(new_content) => { mfa_content = new_content }"
                        />
                    </div>
                    
                </div>
            </div>
        </div>

        <!-- Sessions -->
        <div class="container container-lg">

            <h3 class="mb-3">Active Sessions <i class="ion-refresh text-muted" :class="{'spin': sessions_loading}" style="font-size: .6em; cursor:pointer" @click="load_sessions()"></i></h3>
            <!--<pre>{{ JSON.stringify(sessions, null, 2) }}</pre>-->
            <div class="row">
                <div class="col-sm-4" v-for="session in sessions" :key="session.id" :data-session-id="session.id" :data-device-id="session.device_id">
                <div class="cardbox" :class="{'text-success border-l-4': session.is_current}">
                    <div class="cardbox-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="wd-xxs">
                                <img :src="get_browser_logo_img(session.client_name)" width="60" />
                            </div>
                            <div class="ml-3">
                                <p class="lead mb-0" style="display:inline-block"><span style="text-transform: capitalize">{{ session.client_name }}</span></p>
                                <small v-if="session.is_current" class="ml-2">(this session)</small>
                                <div class="text-muted">
                                    <img v-if="session.geo_country" :src="get_flag_img(session.geo_country)"  class="mr-2"/>
                                    <span v-if="session.location_str">{{ session.location_str }}</span>
                                    <span v-else><i>Unknown location</i></span>
                                </div>
                                <div class="text-muted">Last used {{ session.last_used | timestamp_rel }}<i class="ion-calendar ml-1 text-muted" style="cursor: help" :title="session.issue_timestamp | timestamp"></i></div>
                                <!--<div class="text-muted">{{ session.ip }}</div>-->
                            </div>
                            <div class="ml-auto">
                                <a
                                    class="text-muted"
                                    href="#"
                                    @click="kill_session(session)"
                                    title="Kill this session">
                                    <i class="ion-close-round"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

        </div>

        <!--
        <div class="container container-lg">
            <h3>Event History <span class="badge badge-success text-sm">Preview</span></h3>
            <div class="row" v-if="events">

                <div v-for="event in events" :key="event.id" class="col-3">
                    <event-box :event="event"></event-box>
                </div>

                <div class="col-3">
                    <div class="cardbox">
                        <div class="cardbox-body">
                            <div class="fw d-flex align-items-center justify-content-center">
                                <button class="btn btn-secondary mr-1" :disabled="events_offset === 0" @click="events_offset -= events_pagesize; load_events()">&lt; Back</button>
                                <button class="btn btn-secondary ml-1" :disabled="(events.length == 0) || (events.length % events_pagesize !== 0)" @click="events_offset += events_pagesize; load_events()">Next &gt;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->
    </section>
</template>

<script>

import { AuthService } from '@/services/auth-service.js'
import { Utils } from '@/helpers/utils.js'
import editUserForm from '@/components/edit-user-form.vue'
//import eventBox from '@/components/event-box.vue'

import mfa from '@/components/mfa/main.vue'
 
export default {

    props: ["user"],

    components: {
        editUserForm,
        //eventBox,
        mfa
    },
    computed: { userid(){ return this.user && this.user.user_id } },
    watch: {
        userid(){ if(this.userid !== false){ this.load_events() } }
    },

    data() {
        return {

            sessions: [],
            sessions_loading: false,

            events: null,
            events_offset: 0,
            events_pagesize: 15,

            edit_mode: false,
            browse_image: false,

            mfa_status: null,
            mfa_content: 'status'
        }
    },

    created: function() {
        this.load_sessions()
    },

    methods: {

        change_image(){
            this.edit_mode=true
            
            this.$nextTick(() => {
                this.browse_image = true
            })
        },

        closeForm: function(){
            this.edit_mode = false
        },

        load_sessions: function(){
            this.sessions_loading = true
            AuthService.get_active_sessions().then(res => {

                let sessions = res.body
                sessions.forEach(session => {
                    session.location_str = this._get_session_location_string(session)
                }, this)
                this.sessions = sessions
                this.sessions_loading = false

                setTimeout(function() {
                    $('[data-toggle="popover"]').popover();
                }, 200);
            }, err => {
                console.error("Failed to get active sessions: HTTP"+err.status, err.body)
                this.sessions_loading = false
            })
        },

        load_events(){
            return 
            /*
            if(!this.user || !this.user.user_id){
                return
            }

            const params = {
                user_id: this.user.user_id,
                limit_from: this.events_offset,
                howmany: this.events_pagesize
            }

            AuditLogService.query_events(params).then(res => {
                const events = res.body

                // Load connecting entities
                const ns_events = events.filter(e => { return e.entity_type == 3 || e.entity_type == 4 })
                const ns_entity_ids = ns_events.map(e => { return e.entity_id })
                NamespaceService.get_files_by_id(ns_entity_ids, true).then(res => {
                    const ns_entities = res.body

                    ns_events.forEach(e => {
                        const ns_file = ns_entities.find(file => { return file.id === e.entity_id })
                        if(!ns_file){ console.log("File/Folder #" + e.entity_id + " has been deleted")}

                        e.file = ns_file

                    })
                    this.events = events
                })

            }).catch(err => {
                console.error(err)
                Utils.show_error("Error loading event log: ", err.body.message)
            })
            */
        },

        kill_session: function(session){
            AuthService.kill_session(session.id).then(res => {
                if(!res.ok){ console.error("Failed to kill session: HTTP"+res.status, res.body); return }
                // OK
                const idx = this.sessions.findIndex(s => { return s.id === session.id })
                this.sessions.splice(idx, 1)

                if(session.is_current){
                    AuthService.logout()
                }
                else{
                    Utils.show_success("Session killed successfully")
                }
            }).catch(err => {
                console.error("Failed to kill session: HTTP"+err.status, err.body);
                Utils.show_error("Failed to kill session")
            })
        },

        _get_session_location_string(session){
            let location_parts = []
            if(session.geo_city){
                location_parts.push(Utils.capitalize(session.geo_city))
            }
            if(session.geo_state && session.geo_country == "US"){
                location_parts.push(session.geo_state.toUpperCase())
            }
            if(session.geo_country){
                location_parts.push(session.geo_country)
            }

            if(location_parts.length > 0){
                return location_parts.join(", ")
            }
            return null
        },

        avatar_url: function(user) { return Utils.avatar_url(user) },

        get_flag_img(countrycode) {
            var images = require.context('../assets/img/flags', false, /\.png$/)
            return images('./' + countrycode + ".png")
        },

        get_browser_logo_img(browser_name) {
            var images = require.context('../assets/img/browser_logos', false, /\.png$/)
            const image_filename = browser_name.toLowerCase().split(" ").join("_")
            return images('./' + image_filename + ".png")
        } 
    },

    filters: {

        timestamp_rel: function(time){
            return Utils.timestamp_rel(time)
        },

        timezone: function(offset_min) {
            if (!offset_min) { return '' }

            const offset_hours = (offset_min / 60)
            return "GMT" + (offset_hours > 0 ? '+' : '') + offset_hours;
        },

        lang: function(lang_code) {
            return lang_code ? lang_code.toUpperCase() : '-'
        },

    }
}
</script>
<style lang="css" scoped>



.windows{
    color: #41a9f5
}

.android{
    color: #A4C639
}

.border-l-4{
    border-left: 4px solid
}
</style>
