<template>
<section>
    <div class="alert alert-info mb-3 text-bold">
        Select a team member to see which Spaces they are managers of. <br/>
        Click a Space to add/remove the selected person.
    </div>

    <div v-if="result" class="alert my-2 text-bold" :class="{'alert-success': result.success, 'alert-danger': result.error}" v-html="result.text"></div>

    <div class="row">
        <div class="col-5">
            <div class="mb-2"><b>Team Members</b></div>

            <button
                v-for="user in team" :key="user.user_id"
                class="btn d-block fw my-2 c-default"
                :class="{'btn-primary': selected_user_id === user.user_id, 'btn-outline-primary': selected_user_id !== user.user_id}"
                @mouseover="selected_user_id = user.user_id"
                @click.prevent="()=>{ return false }"
                >
                <b>{{ user.name }}</b> ({{spaces ? spaces.filter(sp => { return sp.members.indexOf(user.user_id) >= 0 }).length : 0}})
            </button>
        </div>
        <div class="col-1"></div>
        <div class="col-6" v-if="this.team && this.team.length > 0">
            <div class="mb-2">
                <b>Active Spaces</b>
                <div class="float-right">
                    <small>
                    Select <a href="#" @click="select_all_spaces()" :title="'Make ' +selected_user_name+ ' a manager in every active Space'">all</a> /
                    <a href="#" @click="select_none_spaces()" :title="'Remove ' +selected_user_name+ ' from the managers of every active Space'">none</a>
                    </small>
                </div>
            </div>

            <button
                v-for="space in active_spaces" :key="space.id"
                class="btn d-block fw my-2 text-bold"
                :class="{
                    'btn-success': my_space_ids.indexOf(space.id)>=0,
                    'btn-outline-success text-gray': my_space_ids.indexOf(space.id) < 0,
                }"
                @click="space_clicked(space)"
                :title="space.members.indexOf(selected_user_id) < 0 ? 'Make '+selected_user_name+' a manager of this space' : 'Remove '+selected_user_name+' from the managers of this space'"
                >
                {{ space.client_name }}<i v-if="space.loading" class="ml-2 ion-load-c spin"></i>
            </button>


            <div class="mt-4 mb-2"><b>Archived Spaces</b></div>
            <button
                v-for="space in archived_spaces" :key="space.id"
                class="btn d-block fw my-2 text-italic"
                :class="{
                    'btn-success': my_space_ids.indexOf(space.id)>=0,
                    'btn-outline-secondary text-gray': my_space_ids.indexOf(space.id) < 0,
                }"
                @click="space_clicked(space)"
                :title="space.members.indexOf(selected_user_id) < 0 ? 'Make '+selected_user_name+' a manager of this space' : 'Remove '+selected_user_name+' from the managers of this space'"
                >
                {{ space.client_name }} (archived)<i v-if="space.loading" class="ml-2 ion-load-c spin"></i>
            </button>

        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-secondary btn-gradient" @click="close_modal()">Done</button>
        </div>
    </div>
</section>
</template>
<script>
import { ClientSpacesService } from '@/services/client_spaces-service';
export default {
    props: ['spaces', 'team', 'close_modal'],

    data(){
        return {
            selected_user_id: null,
            result: null
        }
    },

    computed: {

        active_spaces(){
            if(!this.spaces){
                return []
            }

            return this.spaces.filter(sp => { return !sp.is_archived })
        },

        archived_spaces(){
            if(!this.spaces){
                return []
            }

            return this.spaces.filter(sp => { return sp.is_archived })
        },


        my_space_ids(){
            if(!this.spaces || !this.selected_user_id){
                return []
            }

            return this.spaces.filter(space => { return space.members.indexOf(this.selected_user_id) >= 0 }).map(space => { return space.id })
        },

        selected_user_name(){
            if(!this.selected_user_id){ return null }
            return this.team.find(m => { return m.user_id === this.selected_user_id }).name
        },

    },

    watch: {
        team(){
            this.set_initial_user()
        }
    },

    mounted(){
        this.set_initial_user()
    },

    methods: {

        set_initial_user(){
            if(this.team && this.team.length > 0){
                this.selected_user_id = this.team[0].user_id
            }
        },

        space_clicked(space){
            if(!this.selected_user_id || !space){ return }

            // If the selected user is already a manager -> remove him
            // otherwise add him
            const is_manager = space.members.indexOf(this.selected_user_id) >= 0
            const api_method = is_manager ? ClientSpacesService.remove_space_manager : ClientSpacesService.add_space_manager
            this.$set(space, "loading", true)
            api_method(space.id, this.selected_user_id).then(res => {
                space.loading = false
                // Show success message
                this.result = {
                    success: true,
                    text: "<b>"+this.selected_user_name+"</b> " + (is_manager ? 'removed from managing' : 'added as manager of') + " <b>"+space.client_name+"</b>"
                }

                if(is_manager){
                    // Remove from members
                    const idx = space.members.findIndex(m => { return m === this.selected_user_id })
                    if(idx >= 0){
                        space.members.splice(idx, 1)
                    }
                }
                else{
                    // Add to members
                    space.members.push(this.selected_user_id)
                }
            })
            .catch(err => {
                console.error("Error: ", err)

                this.result = {
                    error: true,
                    text: "Error: " + err.body.message
                }
                space.loading = false

            })
        },

        select_all_spaces(){
            if(!this.selected_user_id){ return }

            // Simulate clicking on every active space button where the
            // selected user is not a manager yet
            this.active_spaces.forEach(space => {
                if(space.members.indexOf(this.selected_user_id) < 0){
                    this.space_clicked(space)
                }
            })
        },

        select_none_spaces(){
            if(!this.selected_user_id){ return }

            // Simulate clicking on every active space button where the
            // selected user is a manager
            this.active_spaces.forEach(space => {
                if(space.members.indexOf(this.selected_user_id) >= 0){
                    this.space_clicked(space)
                }
            })
        }
    }

}
</script>