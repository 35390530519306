<template>
    <span v-if="user" class="user_box" :title="user.name">
        <img v-if="user.avatar_url && !no_avatar && avatar_left" class="thumb32 rounded-circle shadow-z2 mr-2" style="object-fit:cover" :src="user.avatar_url" />
        <span :class="{'text-muted text-italic': user.deleted === true}">{{ user.name }}</span>
        <img v-if="user.avatar_url && !no_avatar && !avatar_left" class="thumb32 rounded-circle shadow-z2 ml-2" style="object-fit:cover" :src="user.avatar_url" />
    </span>
</template>

<script>

import { Utils } from '@/helpers/utils.js'

export default {

    // 'externalActor' is a user object (name, avatar url) who is displayed when user ID is set to 0
    // This is the person who is not a Skyflok user, but their action is displayed, most probably a Client Space client
    props: ["team", "userId", "externalActor", 'no_avatar', 'avatar_left'],

    data: function(){
        return{
            user: null,
        }
    },

    watch: {
        userId: function(){
            this.init()
        },
        team: function(){
            this.init()
        },
    },

    created: function(){
        this.init()
    },

    methods: {
        init: function(){
            if(this.userId === undefined || this.team === undefined ){
                return;
            }
            if(this.userId === 0 && this.externalActor){
                this.user = this.externalActor
                return
            }

            let user = this.team.find(m => { return m.user_id ? (m.user_id == this.userId) : (m.id == this.userId) });
            if(user == undefined){
                console.error("[userbox] Member not found: #" + this.userId)
                user = { name:  "a deleted member", avatar_url: false, deleted: true }
            }
            else{
                user.avatar_url = Utils.avatar_url(user)
            }

            this.user = user
        }
    }
}
</script>

<style scoped>
.user_box img{
    object-fit: cover
}
.text-italic{
    font-style: italic
}
</style>
