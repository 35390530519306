<template>
<section>
    <div v-if="success" class="alert alert-success">{{success}}</div>
    <div v-if="error" class="alert alert-danger">{{error}}</div>

    <section v-if="!codes" class="fw text-center">
        Loading... <i class="ion-load-c spin ml-1"></i>
    </section>
    <section v-else>
        <div v-if="!space_id" class="alert alert-info">
            <p>If you lose your phone, the only way to login is using one of these backup code. Keep them as secure as your password! </p>
            It's highly recommended to store them in a trusted password manager like 
            <a href="https://lastpass.com" class="text-bold" target="_BLANK">LastPass</a>, 
            <a href="https://1password.com" class="text-bold" target="_BLANK">1Password</a> or
            <a href="https://keepersecurity.com" class="text-bold" target="_BLANK">Keeper</a>.
        </div>


        <div class="text-bold my-2 text-center">
            
            <span :class="{'text-warning': few_codes, 'text-success': !few_codes}">
                <i v-if="few_codes" class="ion-alert-circled mr-1"></i>
                {{ client_name ? client_name : 'You' }} {{ few_codes ? 'only ' : '' }}{{ client_name ? 'has' : 'have' }} {{codes.length}} backup codes
                <i v-if="!few_codes" class="ion-checkmark-circled ml-1"></i>
            </span>
            <span v-if="few_codes" class="ml-4">
                <button class="btn btn-gradient btn-secondary" @click="generate_additional_codes()" :disabled="generate_loading">
                    Generate more codes
                    <i v-if="generate_loading" class="ion-load-c spin ml-1"></i>
                </button>
            </span>
            
        </div>

        <div class="row codes">
            <div class="col-6 col-lg-4 text-center"  v-for="code in codes" :key="code">
                {{ code }}
            </div>
        </div>

        <div v-if="codes && codes.length" class="mt-2 row">
            <div class="col-6 text-right">
                <button class="btn btn-primary" @click="copy_codes()">
                    <i class="ion-clipboard mr-2"></i>Copy
                </button>
            </div>
            <div class="col-6 text-left">
                <button class="btn btn-primary" @click="download_codes()">
                    <i class="ion-arrow-down-a mr-2"></i>Save
                </button>
            </div>
        </div>

    </section>
</section>
</template>
<script>
import { AuthService } from '@/services/auth-service'
import { Utils } from '@/helpers/utils'
import { ClientSpacesService } from '@/services/client_spaces-service'
export default {
    props: {
        recovery_codes: Array, 
        user_email: String, 
        show_success: {type: Function}, 
        show_error: {type: Function}, 
        client_name: String, 
        space_id: Number
    },
    data(){
        return {
            codes: this.recovery_codes,
            generate_loading: false,

            error: null,
            success: null
        }
    },

    computed: {
        few_codes(){
            return this.codes && this.codes.length <= 3
        },

        codes_str(){
            let codes_str = "SkyFlok two factor auth backup codes" + (this.user_email ? ' for '+Utils.partially_redact_email(this.user_email) : '') + ": \n"
            
            this.codes.forEach(code => {
                codes_str += code + '\n'
            })
            return codes_str
        },

    },

    mounted(){
        if(!this.recovery_codes){
            this.load_codes()
        }
    },

    methods: {
        load_codes(){
            
            const apicall = this.space_id ? 
                ClientSpacesService.load_client_2fa_backup_codes(this.space_id) :
                AuthService.get_2fa_recovery_codes()

            apicall.then(res => {
                this.codes = res.body.codes
                this.error = false
            }).catch(err => {
                console.error(err)
                this.show_error ? this.show_error(err.body.message) : this.error = err.body.message
            })
        },

        generate_additional_codes(){
            this.generate_loading = true
            
            // TODO always use AuthService directly, it can handle generating backup codes for Clients directly
            //const apicall = AuthService.generate_2fa_recovery_codes()
            const apicall = this.space_id ? 
                ClientSpacesService.generate_client_2fa_backup_codes(this.space_id) :
                AuthService.generate_2fa_recovery_codes()
            
            apicall.then(res => {
                const new_codes = res.body.new_codes
                new_codes.forEach(code => { this.codes.push(code) })
                this.generate_loading = false
                const msg = "Backup codes generated."
                this.show_success ? this.show_success(msg) : this.success = msg
                this.error = false
            }).catch(err => {
                this.generate_loading = false
                this.show_error ? this.show_error(err.body.message) : this.error = err.body.message
            })
        },

        copy_codes(){
            if(!Utils.to_clipboard(this.codes_str)){
                const msg = "Failed to copy codes to clipboard, please select and manually copy them!"
                this.show_error ? this.show_error(msg) : this.error = msg
            }
        },
 
        download_codes(){
            const txt_blob = new Blob([this.codes_str], {type: 'text/plain'})
            const blob_url = URL.createObjectURL(txt_blob)

            Utils.force_download_file({name: "SkyFlok backup codes.txt"}, blob_url)
        }
    }
}
</script>
<style scoped>
.codes{
    line-height: 1.5em;
    font-size: 2em;
    font-family: monospace;
    font-weight: bold;
}

.alert.alert-info a{
    color: white;
    text-decoration: underline;
}
</style>