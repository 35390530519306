<template>
    <div v-if="user" class="d-flex justify-content-center align-items-center" :class="{'pull-right': pullRight === true}">
        <div class="text-center text-nowrap">
            <div class="">{{ timestamp | timestamp_rel }} <i class="ion-calendar ml-1 text-muted" style="cursor: help" :title="timestamp | timestamp"></i></div>
            <div :class="{'deleted text-muted text-nowrap': user.deleted }">by {{ user.name }}</div>
        </div>
        <div class="ml-2"  v-if="user.avatar_url">
            <img class="fit-cover thumb32 rounded-circle shadow-z2" :src="is_client_space_logo ? Utils.client_space_logo_src(user.avatar_url) : user.avatar_url" />
        </div>
    </div>
    <div v-else>
        <i class="ion-load-c spin"></i>
    </div>
</template>

<script>

import { Utils } from '@/helpers/utils.js'

export default {

    // 'externalActor' is a user object (name, avatar url) who is displayed when user ID is set to 0
    // This is the person who is not a Skyflok user, but their action is displayed, most probably a Client Space client
    props: ["team", "userId", "timestamp", "pullRight", "externalActor"],
    
    data: function(){
        return{
            user: null,
            Utils: Utils
        }
    },

    computed: {
        is_client_space_logo(){
            return this.user.avatar_url.search('client_space_logos') >= 0
        }
    },

    watch: {
        userId: function(){
            this.init()
        },
        team: function(){
            this.init()
        },
        timestamp: function(){
            this.init()
        },

        
    },

    created: function(){
        this.init()
    },

    methods: {
        init: function(){
            if(this.userId === undefined || this.team === undefined || this.timestamp === undefined){
                return;
            }
            
            if(this.userId === 0 && this.externalActor){
                this.user = this.externalActor
                return
            }
            let user = this.team.find(m => { const id = m.user_id || m.id; return id == this.userId });
            if(user == undefined){
                user = { name:  "a deleted member", avatar_url: false, deleted: true }
            }
            else{
                user.avatar_url = Utils.avatar_url(user)
            }
            
            this.user = user
        }
    },

    filters: {
        timestamp_rel: function(time){
            return Utils.timestamp_rel(time)
        }
    }
}
</script>

<style scoped>

.modified_box.pull-right{
    justify-content: flex-end;
}
.deleted{
    font-style: italic
}
</style>
