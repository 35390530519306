<template>
    <i
        class="ion-information-circled ml-1 info-icon c-help"
        data-toggle="popover"
        data-html="true"
        :class="custom_class"
        :data-content="content"
        :data-placement="placement || 'top'"
        :data-trigger="trigger || (is_mobile ? 'click' : 'hover')"
        ></i>
</template>
<script>
import { Utils } from '@/helpers/utils';
export default {
    props: [
        "custom_class",
        "content",
        "trigger",
        "placement"
    ],

    data(){
        return {
            is_mobile: Utils.is_mobile()
        }
    },

    mounted(){
        this.$nextTick(() => {
            $(".info-icon").popover();
        })
    },


}
</script>
