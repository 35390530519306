<template>
<div id="namespace-tree">
    <div v-if="error" class="alert alert-danger">{{ error }}</div>
    <div class="text-right">
        <div v-if="!submit_loading">
            <button class="btn btn-secondary mr-1" :class="{'btn-sm text-sm': is_mobile}" @click="$emit('close')">Cancel</button>
            <button class="btn btn-info text-bold" :class="{'btn-sm text-sm': is_mobile}"  @click="submit()" :disabled="!selected_folder">{{ action.startsWith('copy') ? 'Copy' : 'Move' }} to {{selected_folder ? selected_folder.name : '...'}}</button>
        </div>
        <i v-else class="ion-load-c spin"></i>
    </div>

    <div class="dd mt-2 mb-2">
        <!--
        <select class="form-control mb-3" v-if="!is_client_space_client && is_move_operation" v-model="selected_namespace">
            <option :value="'team_files'">Team Files</option>
            <option :value="'admin_folder'" v-if="is_user_admin">Admin Folder</option>
            <optgroup label="Client Spaces" v-if="my_client_spaces">
                <option 
                    v-for="space in my_client_spaces.filter(s => !s.is_archived)" 
                    :key="space.key" 
                    :value="space">{{ space.client_name }}</option>
            </optgroup>
        </select>
        -->

        <div v-if="namespace_loading" class="d-flex align-items-center justify-content-center gap-1">
            <span>Loading</span>
            <i class="ion-load-c spin"></i>
        </div>
        <namespace-tree-row 
            v-else-if="namespace"
            :children="namespace" 
            :clicked="tree_folder_selected"
        />
    </div>

    <div class="text-right">
        <div v-if="!submit_loading">
            <button class="btn btn-secondary mr-1" :class="{'btn-sm text-sm': is_mobile}" @click="$emit('close')">Cancel</button>
            <button class="btn btn-info text-bold" :class="{'btn-sm text-sm': is_mobile}" @click="submit()" :disabled="!selected_folder">{{ action.startsWith('copy') ? 'Copy' : 'Move' }} to {{selected_folder ? 'to '+selected_folder.name : '...'}}</button>
        </div>
        <i v-else class="ion-load-c spin"></i>
    </div>
    <div v-if="error" class="alert alert-danger mt-2">{{ error }}</div>
</div>
</template>
<script>

import { NamespaceService } from '@/services/namespace-service.js'
import { Utils } from '@/helpers/utils.js'

import namespaceTreeRow from "@/components/namespace-tree-row.vue";
import { ClientSpacesService } from '@/services/client_spaces-service';

export default {
    // folderSelected: callback that has to be called when the copy/move was carried out (param: true) or the action is failed/cancelled (param: false)
    // action: 'copy_file', 'move_file' or 'move_folder'
    // targetFile: The file that's being moved
    props: ["action", "targetFile", "is_user_admin", "is_client_space_client"],
    components: {
        namespaceTreeRow
    },
    data(){
        return {
            selected_namespace: false,
            my_client_spaces: null,

            error: false,
            namespace: null,
            ns_type: null,
            ns_key: null,

            
            selected_folder: null,
            namespace_loading: false,
            submit_loading: false
        }
    },

    computed: {
        is_mobile(){
            return Utils.is_mobile()
        },

        is_move_operation(){
            return this.action == "move_file" || this.action == "move_folder"
        }
    },

    watch: {
        selected_namespace(){
            if(!this.selected_namespace){
                return 
            }
            
            if(this.selected_namespace.client_name !== undefined){
                this.load_namespace(NamespaceService.NS_TYPE_CLIENT_SPACE, this.selected_namespace.key)
            }
            else if(this.selected_namespace === "team_files"){
                this.load_namespace(NamespaceService.NS_TYPE_DEFAULT)
            }
            else if(this.selected_namespace === "admin_folder"){
                this.load_namespace(NamespaceService.NS_TYPE_ADMIN_FOLDER)
            }
        },

        my_client_spaces(){
            if(!this.my_client_spaces){
                return
            }
            // When we are in a client space, 'my_client_spaces' is populated too late 
            if(!this.selected_namespace && NamespaceService.ns_type == NamespaceService.NS_TYPE_CLIENT_SPACE){
                this.selected_namespace = this.my_client_spaces.find(space => {
                    return space.key == NamespaceService.ns_key
                })
            }
        }
    },

    created(){

        if(!this.is_client_space_client){
            ClientSpacesService.list().then(res => {
                this.my_client_spaces = res.body
            }).catch(() => {
                console.error("Failed to load client spaces")
            })
        }
        
    },

    mounted(){
        // Load the current namespace contents
        this.load_namespace()

        // Set the selected namespace
        switch(NamespaceService.ns_type){
            case NamespaceService.NS_TYPE_DEFAULT:
                this.selected_namespace = 'team_files';
                break;
            case NamespaceService.NS_TYPE_ADMIN_FOLDER:
                this.selected_namespace = 'admin_folder';
                break;
            case NamespaceService.NS_TYPE_CLIENT_SPACE:
                this.selected_namespace = this.my_client_spaces && this.my_client_spaces.find(space => {
                    return space.key == NamespaceService.ns_key
                })
                break;
            default:
                console.error("Unknown ns type: "+ NamespaceService.ns_type)
        }
    },

    methods: {

        load_namespace(ns_type, ns_key){
            // Load the folder structure of the given namespace into this.namespace

            this.error = false
            this.namespace_loading = true
            const load_folders_only = true
            NamespaceService.get_all_namespace(load_folders_only, ns_type, ns_key).then(res => {

                let folders = res.body
                    .filter(en => en.entity_type === NamespaceService.ENTITY_TYPE_FOLDER)


                folders.forEach(f => {
                    f.color = Utils.string_to_color(f.name)
                })

                const moved_folder_id = (this.action === 'move_folder') ? this.targetFile.id : null
                
                this.ns_type = ns_type
                this.ns_key = ns_key

                this.namespace = [
                    {
                        name: "HOME",
                        id: -1, // Root is represented by '-1' when moving/copying into it via Namespace Service
                        parent_id: null,
                        children: this.flatToHierarchy(folders, moved_folder_id)
                    }
                ]

                this.namespace_loading = false

                // Clear the selected folder (when switching namespaces)
                this.selected_folder = null
            })
            .catch(err => {
                this.namespace_loading = false
                console.error(err)
                if(err.status && err.body){
                    this.error = "Failed to list folders: " + err.body
                }
            })
        },

        submit(){
            if(!this.selected_folder || this.targetFile == undefined){ return }
            this.submit_loading = true
            this.error = false
            var promise = null
            switch(this.action){
                case 'copy_file':
                    promise = NamespaceService.copy_file(this.targetFile.id, this.selected_folder.id)
                    // copy folder is not supported
                    break;
                case 'move_file':
                    if(this.is_client_space_client){
                        promise = NamespaceService.move_file(this.targetFile.id, this.selected_folder.id)
                    }
                    else{
                        promise = NamespaceService.move_file(this.targetFile.id, this.selected_folder.id, this.ns_type, this.ns_key)
                    }
                    break;
                case 'move_folder':
                    if(this.is_client_space_client){
                        promise = NamespaceService.move_folder(this.targetFile.id, this.selected_folder.id)
                    }
                    else{
                        promise = NamespaceService.move_folder(this.targetFile.id, this.selected_folder.id, this.ns_type, this.ns_key)
                    }
                    break;
                default:
                    this.error = "Unsupported action: '" + this.action + "'"
                    return;
            }
            promise.then(res => {

                // If the file/folder was moved to the root, then 'selected_folder.id' is -1 but 'targetFile.parent_id' should be set to null
                const new_parent_id = (this.selected_folder.id == -1) ? null : this.selected_folder.id
                this.selected_folder = null
                const result = {
                    action: this.action,
                    new_parent_id: new_parent_id
                }

                if(this.action == 'copy_file'){
                    // A new file was created, record its ID
                    result.new_file_id = res.body.id
                }

                this.$emit('ready', result)

            }).catch(err => {
                console.error(err)
                this.submit_loading = false
                if(err.body && err.body.message){
                    this.error = "Error: " + err.body.message
                }
                else{
                    this.error = "Error"
                }

            })

        },

        flatToHierarchy: function(flat, moved_folder_id) {
            /* Source: https://stackoverflow.com/questions/31383869/converting-flat-structure-to-hierarchical */

            var roots = [] // things without parent

            // make them accessible by guid on this map
            var all = {}

            flat.forEach(function(item) {
                all[item.id] = item
            })

            // connect childrens to its parent, and split roots apart
            Object.keys(all).forEach(function (id) {
                var item = all[id]

                if(item.id === moved_folder_id){
                    // do not add the moved folder to the tree (so it cannot be moved onto itself)
                    return
                }

                if (item.parent_id === null) {
                    // root
                    roots.push(item)
                } else if (item.parent_id in all) {
                    var p = all[item.parent_id]
                    if (!('children' in p)) {
                        p.children = []
                    }
                    p.children.push(item)
                    p.children.sort( (_a, _b) => {
                        let a = _a.name;
                        let b = _b.name;
                        if(typeof a == "string"){ a = a.toLowerCase(); b = b.toLowerCase(); }
                        if(a < b){ return -1; }
                        if(a > b){ return 1 }
                        return 0;
                    })
                }
            })

            roots.sort( (_a, _b) => {
                    let a = _a.name;
                    let b = _b.name;
                    if(typeof a == "string"){ a = a.toLowerCase(); b = b.toLowerCase(); }
                    if(a < b){ return -1; }
                    if(a > b){ return 1 }
                    return 0;
                })
            // done!
            return roots
        },


        tree_folder_selected: function(folder){
            if(this.selected_folder){
                // Unselect current
                this.$set(this.selected_folder, 'selected', false)
            }
            // Invert selected 
            if(!this.selected_folder || folder.id != this.selected_folder.id){
                this.$set(folder, 'selected', !folder.selected)
            }
            // Update selected_folder
            this.selected_folder = folder.selected ? folder : null
        },
    }
}
</script>
<style scoped>
    .dd{
        margin: 0px auto;
        font-size: inherit;
        line-height: inherit;
    }
</style>
