<template>
<section class="layout-container">
    <div class="layout-container layout-container-main" v-if="space">

        <aside class="sidebar-container">
            <div class="px-3">

                <div class="text-center py-3">
                    <div v-if="acting_user">
                        <img v-if="acting_user.avatar_url" :src=" is_skyflok_user ? acting_user.avatar_url : ClientSpacesService.is_skyflok_logo(space) ? Utils.client_space_logo_src(acting_user.avatar_url) : acting_user.avatar_url" class="avatar_url mr-2 mb-3" :class="{'initial128 round cover-fit': is_skyflok_user}" />

                        <h5 v-if="is_skyflok_user" class="text-lightgray text-bold mb-0">
                            {{ acting_user.name }}
                        </h5>
                        <div v-else class="c-pointer" @click="open_client_form()" title="Click to edit your info">
                            <h5 class="text-lightgray text-bold d-inline">
                                {{ acting_user.name }}
                            </h5>
                        </div>

                        <small class="text-muted" style="word-break: break-word">{{ is_skyflok_user ? acting_user.company_name : space.client_email}}</small>
                    </div>
                </div>
                
                <div class="mb-3 fw text-center text-white" v-if="!is_skyflok_user">
                    <button class="btn fw btn-outline-secondary" @click="open_client_form()" type="button">
                        My Settings <i class="ion-gear-a ml-2 text-muted"></i>
                    </button>
                </div>

                 <!-- 2FA status or CTA button -->
                <div class="mb-2" v-if="!is_skyflok_user">
                    
                    <button v-if="!mfa_status || mfa_enabled" @click="_open_modal('2fa-modal')" class="btn fw btn-outline-secondary btn-flat text-normal" style="text-transform:none">
                        Two-Factor Auth: 
                        <i v-if="!mfa_status" class="ion-load-c spin ml-2 "></i>
                        <span v-else class="ml-1 text-success text-bold">ON <i class="ion-locked ml-1"></i></span>
                    </button>
                    <button v-else class="btn fw btn-success" @click="_open_modal('2fa-modal')">
                        <i class="ion-locked mr-2"></i>Enable Two-Factor Auth
                    </button>
                </div>
                

                <!-- Subscribe for changes -->
                <div class="mb-2 fw text-center text-white">
                    <button
                        class="btn fw btn-outline-secondary btn-flat text-normal"
                        @click="change_watch()" style="text-transform: none"
                        :title="'Click to ' + (email_notifications ? 'stop receiving' : 'receive' ) + ' email notifications'"
                        :disabled="!space || change_watch_loading"
                    >
                        Email Notifications:
                        <i v-if="change_watch_loading" class="ion-load-c spin ml-1"></i>
                        <span class="ml-1 text-bold" v-else-if="!email_notifications">
                            OFF <i class="ion-android-notifications-none ml-1"></i>
                        </span>
                        <span class="ml-1 text-success text-bold" v-else-if="email_notifications">
                            ON <i class="ion-android-notifications ml-1"></i>
                        </span>
                    </button>
                </div>

               

                <!-- Trash -->
                <div class="mb-2" v-if="is_skyflok_user || !space.client_readonly">
                    <button class="btn fw" :class="{'btn-outline-secondary btn-flat': !show_trash, 'btn-primary': show_trash}" style="text-transform: none" @click="show_trash = !show_trash">{{ show_trash ? "Active Files" : "Recycle Bin" }} <i class="icon-lg ml-1" :class="{'ion-trash-a': !show_trash, 'ion-document-text': show_trash}"></i></button>
                </div>

                <!-- Activity --->
                <section>
                    <div class="sidebar-nav-heading px-0 pb-0">
                        LATEST EVENTS
                        <i class="ion-refresh ml-1 text-muted c-pointer" @click="load_recent_events()" :class="{'spin': recent_events_loading}"></i>
                        <!-- Filters: only downloads or uploads -->
                        <span v-if="false" class="float-right">
                            <span 
                                class=" c-pointer text-12" 
                                :class="{'text-success': recent_events_filter.downloads, 'text-muted': !recent_events_filter.downloads}" 
                                @click="recent_events_filter.downloads = !recent_events_filter.downloads"
                            >
                                <i class="ion-arrow-down-a"></i>
                            </span>

                            <span 
                                class="ml-2 c-pointer text-12" 
                                :class="{'text-info': recent_events_filter.uploads, 'text-muted': !recent_events_filter.uploads}" 
                                @click="recent_events_filter.uploads = !recent_events_filter.uploads"
                            >
                                <i class="ion-arrow-up-a"></i>
                            </span>
                        </span>

                    </div>
                    <event-box
                        v-for="event in recent_events_filtered"
                        :key="event.id"
                        :is_clickable="true"
                        :event="event"
                        :space="space"
                        :skyflok_team="acting_user.team"
                        @file_clicked="(file) => { sidebar_file_clicked(file) }">
                    </event-box>
                    <i v-if="recent_events !== null && recent_events.length === 0" class="d-block text-center text-muted text-sm py-2">No events</i>

                    <div class="fw text-right">
                        <button class="btn btn-flat btn-sm ion-chevron-left text-muted" :disabled="recent_events_pager.start_offset <= 0" @click="recent_events_page(false)"></button>
                        <button class="btn btn-flat btn-sm ion-chevron-right text-muted" :disabled="recent_events && recent_events.length === 0" @click="recent_events_page(true)"></button>
                    </div>
                </section>


                <!-- Data locations -->
                <div class="my-3" v-if="space.locations && skyflok_team" id="data-locations">
                    <locations :locations="space.locations" :disable_popover="is_narrow" :placement="is_narrow ? 'right' : 'left'" :team-name="skyflok_team.name"></locations>
                </div>


                <!-- Theme selector -->
                <div class="container color-selector">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-3"><button class="btn btn-sm btn-outline-secondary text-sm" @click="set_theme('theme-dark')">Dark</button></div>
                        <div class="col-3"><button class="btn btn-sm text-sm" @click="set_theme('theme-default')">Bright</button></div>
                    </div>
                </div>

                <div v-if="!is_skyflok_user" class="fw text-center mt-3">
                    <button class="btn btn-outline-danger btn-sm text-sm text-bold" @click="logout_client()" :disabled="logout_loading">
                        Log out
                        <i v-if="logout_loading" class="ion-load-c spin ml-2"></i>
                    </button>
                </div>
            </div>
        </aside>
        <div class="sidebar-layout-obfuscator"></div>


        <main class="main-container" :class="{'archived_space_bg': space.is_archived}">

            <header class="header-container">
            <global-alert :alert="alert"></global-alert>
            <nav class="text-center">
                <ul class="d-none d-lg-block">
                    <li>
                        <a :href="space.key | client_space_link" title="Space Home">
                            <img src="@/assets/img/skyflok_logo_black.png" width="130"/>
                        </a>
                    </li>
                </ul>

                <ul class="d-inline-block d-lg-none text-left">
                    <li><a class="sidebar-toggler menu-link menu-link-close" href="#"><span><em></em></span></a></li>
                </ul>
                <ul class="d-none d-xs-block text-left">
                    <li><a class="covermode-toggler menu-link menu-link-close" href="#"><span><em></em></span></a></li>
                </ul>

                <div class="d-inline-block d-sm-none" id="skyflok_center_logo">
                    <img src="@/assets/img/skyflok_logo_black.png" width="100"/>
                </div>

                <div class="d-none d-sm-inline-block">
                    <div v-if="skyflok_team" class="d-flex justify-content-between align-items-center">
                        <div :title="'Secure, private shared space between ' + skyflok_team.name + ' and ' + space.client_name" style="cursor: default">
                            <img class="d-none d-md-inline-block" v-if="skyflok_team.logo_url && skyflok_team.logo_url!=''" :src="skyflok_team.logo_url" style="max-width: 100px;max-height:48px"/>
                            <h5 class="d-inline">{{ skyflok_team.name }}</h5>

                            <i class="ion-code icon-lg mx-4"></i>

                            <img class="d-none d-md-inline-block" v-if="space.client_logo" :src="ClientSpacesService.is_skyflok_logo(space) ? Utils.client_space_logo_src(space.client_logo) : space.client_logo" style="max-width:60px; max-height:48px"/>
                            <h5 class="d-inline" :class="{'text-italic c-help': space.client_readonly}" :title="space.client_readonly ? space.client_name + ' can only browse and download files in this space, cannot upload or edit.' : null">
                                {{ space.client_name }}
                                <i v-if="space.client_readonly" class="ion-locked ml-1 text-sm text-gray"  data-toggle="popover" data-content="The Client can only browse and download files, but not allowed to upload, delete or change the folder structure." data-html="true" data-trigger="hover" data-placement="bottom"></i>
                            </h5>
                        </div>

                    </div>
                </div>

                <search-box
                    class="float-right mr-2"
                    :placeholder="'Search'"
                    :hideinput="true"
                    :redirect-when-clicked="false"
                    :custom-namespace="true">
                </search-box>

            </nav>
        </header>

            <div v-if="space.is_archived" class="fw text-center py-2 text-white text-bold bg-gradient-warning">
                <i class="ion-locked mr-2"></i>
                This Space is archived, you can browse it but cannot upload, download or change anything.
                <i class="ion-locked ml-2"></i>
            </div>

            <file-list-view
                v-if="!show_trash"
                style="margin-top: 0px"
                :user="acting_user"
                :share-allowed="false"
                :upload-allowed="!is_space_readonly"
                :download-allowed="!space.is_archived"
                :namespace-ops-allowed="!is_space_readonly"
                :modified-box-external="external_actor"
                :file_uploaded_callback="(file) => { on_file_uploaded(file) }"
                :file_download_started="(file) => { report_file_event('download_start', file) }"
                @file_download_succeeded="(file) => { report_file_event('download_finished', file); load_recent_events() }"
                :file_download_error="(file) => { report_file_event('download_error', file, error) }"
            ></file-list-view>

            <!-- Recycle Bin -->
            <div v-else class="container" style="padding-top: 100px; margin-top: 0px">

                <div class="row pt-3">
                    <div class="col-1"></div>
                    <div class="col-12 col-lg-10">
                        <div class="cardbox text-white bg-gradient-warning b0">
                            <div class="cardbox-body text-center text-bold" style="font-size: 1.3rem">
                                Deleted files and directories are stored here for 30 days.
                                <br/>
                                You can recover them during this period, afterwards they are deleted automatically.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-12 col-lg-8">
                        <deleted-groups
                            :user="acting_user"
                            :modified-box-external="external_actor"
                            :restore-allowed="!space.is_archived">
                        </deleted-groups>
                    </div>
                </div>
            </div>

        </main>

        <!-- Client Form Modal -->
        <div class="modal" id="client-form-modal" v-if="!is_skyflok_user">
            <div class="modal-dialog modal-lg" v-if="client_form">
                <div class="modal-content">
                    <div class="modal-header bg-gradient-info text-white">
                        <h5 class="mt-0 modal-title">Edit your information</h5>
                        <button class="close" type="button" @click="close_modal('client-form-modal')" aria-label="Close"><span>&times;</span></button>
                    </div>

                    <div class="modal-body">
                        <!-- Desktop form (see mobile form below) -->
                        <section class="d-none d-md-block">
                            <div v-if="client_form.error" class="alert alert-danger">{{ client_form.error }}</div>
                            <div v-if="client_form.success" class="alert alert-success">{{ client_form.success }}</div>
                            
                            <form v-on:submit.prevent="client_form_submit">
                                <table class="table form-table mb-0">
                                    <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td><input type="text" v-model="client_form.name" maxlength="255" class="form-control form-control-sm" /></td>
                                    </tr>
                                    <tr>
                                        <th class="nowrap">E-mail:</th>
                                        <td><input type="email" v-model.trim="client_form.email" maxlength="255" class="form-control form-control-sm" /></td>
                                    </tr>
                                    <tr v-show="!client_change_pass">
                                        <th>Password:</th>
                                        <td>
                                            <button class="btn btn-secondary btn-sm text-sm" @click="client_change_pass = true" type="button" >Change password</button>
                                        </td>
                                    </tr>
                                    <tr v-show="client_change_pass">
                                        <th>Current password</th>
                                        <td><input type="password" v-model="client_form.current_pass" class="form-control form-control-sm"/></td>
                                    </tr>
                                    <tr v-show="client_change_pass">
                                        <th>New password</th>
                                        <td>
                                            <div class="fw d-flex align-items-center">
                                                <input type="password" v-model="client_form.psw1" placeholder="New password" class="form-control form-control-sm mr-1" />
                                                <input type="password" v-model="client_form.psw2" placeholder="Repeat new password" class="form-control form-control-sm ml-1" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr >
                                        <th class="v-top">Logo:</th>
                                        <td style="width: 80%; padding-top: 0px">
                                            <div id="client_space_logos_carousel" class="mb-2">
                                                <div class="slide d-inline-block" v-for="logo in client_form.logos" :key="logo.path">
                                                    <img :title="logo.name+' (Click to '+(logo.selected ? 'un' : '')+'select)'" :src="Utils.client_space_logo_src(logo.path)" @click="select_logo(logo)" :class="{'selected': client_form.selected_logo === logo.path}" class="builtin-logo logo c-pointer m-1" width="84" height="84"/>
                                                </div>
                                            </div>

                                            <div v-if="client_form.custom_avatar_url !== null">
                                                <span class="text-sm mr-2">Custom logo:</span>
                                                <img :src="client_form.custom_avatar_url" class="avatar_url">
                                                <button type="button" class="btn btn-sm btn-secondary" @click="client_form.custom_avatar_url = null">Clear</button>
                                            </div>

                                            <div v-else>
                                                <span class="text-sm mr-2">Upload custom image:</span>
                                                <profile-image-selector :avatar_changed="(avatar_url) => { client_form.custom_avatar_url = avatar_url }"></profile-image-selector>
                                            </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td class="text-right" colspan="2">
                                                <button class="btn btn-secondary mr-2" @click.prevent="close_modal('client-form-modal')" type="button">Cancel</button>
                                                <button class="btn btn-info btn-gradient" type="submit" :disabled="client_form.loading">Save<i class="ion-load-c spin ml-2" v-if="client_form.loading"></i></button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </form>
                        </section>

                        <!-- Mobile form form (see desktop form above) -->
                        <section class="d-block d-xl-none mb-4">
                            <div v-if="client_form.error" class="alert alert-danger">{{ client_form.error }}</div>
                            <div v-if="client_form.success" class="alert alert-success">{{ client_form.success }}</div>
                            
                            <form v-on:submit.prevent="client_form_submit" class="text-sm">
                                <div class="mobile-form-field">
                                    <b>Name</b><br/>
                                    <input type="text" v-model="client_form.name" maxlength="255" class="form-control form-control-sm" />
                                </div>
                                <div class="mobile-form-field">
                                    <b>E-mail</b><br/>
                                    <input type="email" v-model.trim="client_form.email" maxlength="255" class="form-control form-control-sm" />
                                </div>
                                <div class="mobile-form-field">
                                    <b>Password</b><br/>
                                    <button class="btn btn-secondary btn-sm text-sm" type="button" @click="client_change_pass = true">Change password</button>
                                </div>
                                <div v-if="client_change_pass" class="mobile-form-field">
                                    <b>Current password</b><br/>
                                    <div class="d-flex align-items-center justify-content-center">
                                        
                                        <input
                                            v-if="client_form_show_current_pass"
                                            type="text"
                                            v-model="client_form.current_pass"
                                            class="form-control form-control-sm"
                                        />
                                        <input
                                            v-else
                                            type="password"
                                            v-model="client_form.current_pass"
                                            class="form-control form-control-sm"
                                        />

                                        <i
                                            class="ion-eye ml-2"
                                            :class="{'ion-eye': !client_form_show_current_pass, 'ion-eye-disabled': client_form_show_current_pass}"
                                            @click="client_form_show_current_pass = !client_form_show_current_pass"></i>
                                    </div>

                                </div>
                                <div class="mobile-form-field" v-if="client_change_pass">
                                    <b>New Password</b><br/>
                                    <section class="d-flex align-items-center">
                                    
                                        <input
                                            v-if="client_form_show_new_pass"
                                            type="text"
                                            v-model="client_form.psw1"
                                            class="form-control form-control-sm mr-1"
                                            :class="{
                                                'form-control-success': client_form.psw1 && client_form.psw2 && client_form.psw1 == client_form.psw2 && client_form.psw1.length >= 8,
                                                'form-control-danger': client_form.psw1 && client_form.psw2 && (client_form.psw1 !== client_form.psw2 || client_form.psw1.length < 8)
                                                }"
                                            />
                                        <input
                                            v-else
                                            type="password"
                                            v-model="client_form.psw1"
                                            class="form-control form-control-sm mr-1"
                                            :class="{
                                                'form-control-success': client_form.psw1 && client_form.psw2 && client_form.psw1 == client_form.psw2 && client_form.psw1.length >= 8,
                                                'form-control-danger': client_form.psw1 && client_form.psw2 && (client_form.psw1 !== client_form.psw2 || client_form.psw1.length < 8)
                                                }"
                                            />


                                        <input
                                            v-if="client_form_show_new_pass"
                                            type="text"
                                            v-model="client_form.psw2"
                                            class="form-control form-control-sm ml-1"
                                            :class="{
                                                'form-control-success': client_form.psw1 && client_form.psw2 && client_form.psw1 == client_form.psw2 && client_form.psw1.length >= 8,
                                                'form-control-danger': client_form.psw1 && client_form.psw2 && (client_form.psw1 !== client_form.psw2 || client_form.psw1.length < 8)
                                                }"
                                            />
                                        <input
                                            v-else
                                            type="password"
                                            v-model="client_form.psw2"
                                            class="form-control form-control-sm ml-1"
                                            :class="{
                                                'form-control-success': client_form.psw1 && client_form.psw2 && client_form.psw1 == client_form.psw2 && client_form.psw1.length >= 8,
                                                'form-control-danger': client_form.psw1 && client_form.psw2 && (client_form.psw1 !== client_form.psw2 || client_form.psw1.length < 8)
                                                }"
                                            />

                                        <i @click="client_form_show_new_pass = !client_form_show_new_pass" class="ion-eye ml-2" :class="{'ion-eye': !client_form_show_new_pass, 'ion-eye-disabled': client_form_show_new_pass}"></i>
                                    </section>
                                    <small v-if="client_form.psw1 && client_form.psw1.length < 8" class="text-danger">Minimum 8 characters required</small>
                                </div>
                                <div class="mobile-form-field">
                                    <b>Logo</b><br/>
                                    <div id="client_space_logos_carousel" class="mb-2">
                                        <div class="slide d-inline-block" v-for="logo in client_form.logos" :key="logo.path">
                                            <img
                                                :title="logo.name+' (Click to '+(logo.selected ? 'un' : '')+'select)'"
                                                :src="logo.path"
                                                @click="select_logo(logo)"
                                                type="button"
                                                :class="{'selected': client_form.selected_logo === logo.path}"
                                                class="builtin-logo logo c-pointer m-1"
                                                width="50"
                                                height="50"/>
                                        </div>
                                    </div>

                                    <div v-if="client_form.custom_avatar_url !== null">
                                        <span class="text-sm mr-2">Custom logo:</span>
                                        <img :src="client_form.custom_avatar_url" class="avatar_url">
                                        <button type="button" class="btn btn-sm btn-secondary" @click="client_form.custom_avatar_url = null">Clear</button>
                                    </div>

                                    <div v-else>
                                        <span class="text-sm mr-2" v-if="!client_form.custom_avatar_url">Or upload custom image:</span>
                                        <profile-image-selector :avatar_changed="(avatar_url) => { client_form.custom_avatar_url = avatar_url }"></profile-image-selector>
                                    </div>
                                </div>

                                <div class="fw d-flex align-items-center justify-content-center mt-3">
                                    <button class="fw btn btn-secondary mr-1" type="button" @click.prevent="close_modal('client-form-modal')">Cancel</button>
                                    <button class="fw btn btn-gradient btn-info ml-1" type="submit" :disabled="client_form.loading">Save<i class="ion-load-c spin" v-if="client_form.loading"></i></button>
                                </div>
                            </form>
                        </section>

                        <section class="fw text-center">
                            <button class="btn btn-outline-danger text-bold" @click.prevent="logout_client()" :disabled="logout_loading">
                                <i class="ion-power mr-2"></i>Log out from this Space
                                <i v-if="logout_loading" class="ion-load-c spin ml-1"></i>
                            </button>
                        </section>

                        <hr/>

                        <section class="text-center">
                            <small class="text-info">
                            Hello and welcome to SkyFlok!<br/>If you have any questions or requests about privacy, how your personal
                            information is handled or what happened to your uploaded files, don't hesitate to contact us
                            via email at <a href="mailto:support@skyflok.com">support@skyflok.com</a>.
                            <br/>Best regards from the SkyFlok Team!
                            </small>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <!-- Welcome -->
        <div class="modal" id="welcome-modal" v-if="!is_skyflok_user">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-gradient-primary text-white">
                        <h5 class="mt-0 modal-title">Welcome to SkyFlok!</h5>
                        <button class="close" type="button" @click="close_modal('welcome-modal')" aria-label="Close"><span>&times;</span></button>
                    </div>
                    <div class="modal-body" :class="{'px-4 mx-4': !is_mobile}">
                        <div class="fw text-center mb-4">
                            <h4>Hi <b>{{ space.client_name }}</b>, welcome to you new Client Space!</h4>
                        </div>
                        <p>
                            This is a secure, private shared folder between you and <strong>{{ skyflok_team.name }}</strong>.
                            <span v-if="!space.client_readonly">
                                You can share files with each other, organize them into folders, delete and restore them as you wish.
                            </span>
                            <span v-else>
                                You can see and download the files that {{ skyflok_team.name }} uploads here for you.
                            </span>
                            In the sidebar you can see the last few files uploads and downloads.
                        </p>
                        <p>
                            <span v-if="space.watch_client">
                                You will <b>receive an email</b> when new files has been uploaded. You can turn this feature off in the sidebar any time.
                            </span>
                            <span v-else>
                                It's recommended to turn on email notifications, so you know when there's something new for you here.
                                <div class="fw text-center mt-2">
                                    <button class="btn btn-default btn-bold" @click="change_watch()" :disabled="change_watch_loading">
                                        <i class="mr-2" :class="{'ion-android-notifications': !change_watch_loading, 'ion-load-c spin': change_watch_loading}"></i>
                                        Turn on email notifications 
                                    </button>
                                </div>
                            </span>
                        </p>

                        <p v-if="!mfa_enabled">
                            To maximize security, it is highly recommended to enable Two-Factor Authentication. 
                            <span class="d-block mt-2 fw text-center">
                                <button class="btn btn-success btn-bold" @click="()=>{ close_modal('welcome-modal'); _open_modal('2fa-modal') }">
                                    <i class="ion-locked mr-2"></i>
                                    Setup Two-Factor Authenticaion
                                </button>
                            </span>
                        </p>

                        <p class="mt-3">
                            If you have any questions or requests about privacy, how your personal
                            information is handled or what happened to your uploaded files, don't hesitate to contact us
                            via email at <a href="mailto:support@skyflok.com">support@skyflok.com</a>.
                            <br/>Best regards from the SkyFlok Team!
                        </p>

                        <p class="text-center">
                            <button class="btn btn-secondary" @click="close_modal('welcome-modal')">Close</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 2FA -->
        <div class="modal" id="2fa-modal" v-if="!is_skyflok_user">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-gradient-primary text-white">
                        <h5 class="mt-0 modal-title">Two-Factor Authentication</h5>
                        <button class="close" type="button" @click="close_modal('2fa-modal')" aria-label="Close"><span>&times;</span></button>
                    </div>
                    <div class="modal-body" :class="{'px-4 mx-4': !is_mobile}">
                        
                        <div v-if="mfa_alert" class="alert" :class="{'alert-danger': mfa_alert.type=='error', 'alert-success': mfa_alert.type=='success'}">
                            <span class="float-right clickable" style="margin-right: -.5em" data-dismiss="modal" @click.prevent="mfa_alert = null"><em class="ion-close-round"></em></span>
                            {{mfa_alert.msg}}
                        </div>

                        <h5 class="cardbox-heading pb-0">
                            &nbsp;
                            <div class="float-right dropdown btn-group" v-if="mfa_status && mfa_status.has_2fa && mfa_content == 'status'">
                                <button class="btn btn-secondary btn-sm" type="button" data-toggle="dropdown" aria-expanded="false" style="font-size: 1rem">
                                    <i class="ion-gear-b text-grey mr-2"></i>
                                    Settings
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" role="menu">
                                    <a class="dropdown-item" href="#" @click.prevent="()=>{ mfa_content = 'show_qr' }"><i class="ion-lock-combination mr-2"></i> Set up a new phone or app</a>
                                    <!--
                                    <a class="dropdown-item disabled" href="#" :aria-disabled="true" :disabled="true" @click.prevent="()=>{ return false}"><i class="ion-chatbox-working mr-2"></i> Change to SMS</a>
                                    -->
                                    <a class="dropdown-item" href="#" @click.prevent="()=>{ mfa_content = 'recovery_codes' }"><i class="ion-help-buoy mr-2"></i> Show my backup codes</a>
                                    <a class="dropdown-item" href="#" @click.prevent="()=>{ mfa_content = 'history' }"><i class="ion-calendar mr-2"></i> Event Log</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item text-danger" href="#" @click.prevent="()=>{ mfa_content = 'lost_phone' }"><i class="ion-alert-circled mr-2"></i> I lost my phone</a>
                                    <a class="dropdown-item text-danger" href="#" @click.prevent="()=>{ mfa_content = 'delete' }"><i class="ion-trash-a mr-2"></i> Disable two factor auth</a>
                                </div>
                            </div>
                            
                            <!-- Cancel the setup wizard -->
                            <button v-if="mfa_content=='show_qr'" class="btn btn-secondary btn-sm float-right" @click="mfa_content = 'status'" ><i class="ion-close-round text-grey mr-2"></i>Cancel</button>
                            
                        </h5>

                        <div class="cardbox-body">
                            <mfa 
                                :user_email="space ? space.client_email : false"
                                :mfa_content="mfa_content"
                                :is_client_space="true"
                                :show_success="(msg)=>{ mfa_alert = { type: 'success', msg: msg } }"
                                :show_error="(msg)=>{ mfa_alert = { type: 'error', msg: msg } }"
                                @mfa_status_loaded="(status) => { mfa_status = status }"
                                @mfa_content_change="(new_content) => { mfa_content = new_content }"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- Login -->
    <div class="layout-container" v-else>
        <div class="page-container bg-blue-grey-900">
            <div class="d-flex align-items-center align-items-center-ie bg-gradient-info">
            <div class="fw">
                <div class="container container-xs">
                <div class="cardbox cardbox-flat text-white form-validate text-color">

                    <div class="cardbox-heading text-center">
                        <img class="block-center img-fluid" src="@/assets/img/skyflok_logo_lg.png" style="max-width: 40%" alt="SkyFlok">
                    </div>

                    <div v-if="login.error" class="alert alert-danger mb-3" v-html="login.error"></div>

                    <section v-if="!disable_login">

                        <!-- Auto login SkyFlok users -->
                        <div v-if="login_form == 'autologin'">
                            <div>
                                <h4 class="fw text-center">
                                Loading Space... <i class="ion-load-c spin ml-2"></i>
                                </h4>
                            </div>
                        </div>

                        <!-- Client login with existing password  -->
                        <div v-else-if="login_form == 'login'" class="cardbox-body" style="margin-bottom: 200px">
                            <div class="mb-5">
                                <form @submit.prevent="login_client()">
                                    <div class="input-group">

                                        <input v-if="key" type="hidden" name="space_key" :value="key" style="display:none"/>

                                        <input
                                            class="form-control form-control-inverse"
                                            type="password"
                                            name="password"
                                            id="password_field"
                                            v-model="login.password"
                                            required="required"
                                            placeholder="Password"
                                            data-msg="Please type your password">

                                        <span class="input-group-btn">
                                            <button class="btn btn-secondary btn-gradient" type="submit" :disabled="!login.password || login.loading">
                                                Login
                                                <i class="icon-lg ml-2" :class="{'ion-ios-locked-outline': !login.loading, 'ion-load-c spin': login.loading}"></i>
                                            </button>
                                        </span>

                                    </div>
                                    <div class="form-group mt-2">
                                        <checkbox 
                                            :value="login.stay_logged_in" 
                                            label="Keep me logged in" 
                                            name="client_space_login_stay_logged_in" 
                                            @changed="newval => login.stay_logged_in = newval" />
                                    </div>

                                </form>

                                <div class="fw text-center mt-2">
                                    <a href="#" class="text-white text-sm" @click.prevent="login_form='forgotten-password'">Forgot your password?</a>
                                </div>
                            </div>

                            <div v-if="team_info" class="text-center mt-4 mb-4 mt-0">
                                <div style="font-size: 1.4em" class="mb-4 text-center">
                                    Log in to access your Client Space with<br/>
                                    <b>{{ team_info.name }}</b>
                                </div>

                                <login-logos
                                    :team_logo="team_info.logo_url"
                                    :client_logo="client_has_custom_logo ? client_logo : null">
                                </login-logos>

                            </div>
                        </div>

                        <!-- Login with email and password, space key not in URL -->
                        <div v-else-if="login_form == 'email_password'" class="cardbox-body" style="margin-bottom: 200px">
                            <div class="mb-5">
                                <form @submit.prevent="login_client()">

                                    <div class="cardbox-heading ">
                                        <div class="cardbox-title text-center">
                                            <h4>Log in to your Client Space</h4>
                                        </div>
                                    </div>

                                    <div class="cardbox-body">
                                        <div class="px-5">
                                            <div class="form-group">
                                                <input v-model="login.email" id="email" name="space_email" class="form-control form-control-inverse" type="email" placeholder="Email address">
                                            </div>
                                            <div class="form-group">
                                                <input v-model="login.password" id="password"  name="space_password" class="form-control form-control-inverse" type="password" placeholder="Password">
                                            </div>
                                            <div class="form-group">
                                                <checkbox 
                                                    :value="login.stay_logged_in" 
                                                    label="Keep me logged in" 
                                                    name="client_space_login_stay_logged_in" 
                                                    @changed="newval => login.stay_logged_in = newval" />
                                            </div>

                                            <button class="btn btn-lg btn-oval btn-gradient text-bold btn-secondary btn-block" type="submit" :disabled="login.loading || !login.email || !login.password">
                                                Login <i class="ion-load-c icon-lg spin ml-2" v-if="login.loading"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <!--
                                <div class="fw text-center mt-2 ">
                                    <a href="#" class="text-white text-sm" @click.prevent="login_form='forgotten-password-nospace'">Forgot your password?</a>
                                </div>
                                -->
                            </div>
                        </div>

                        <!-- Two Factor Auth -->
                        <div v-else-if="login_form == '2fa'" class="text-center">
                            <mfa-login 
                                :mfa_mode="mfa_mode" 
                                :user_email="null" 
                                :sms_phone_number="mfa_sms_phone_number"
                                :mfa_cooldown_seconds="mfa_cooldown"
                                @code_changed="(code) => { mfa_mode == 'backup' ? login.mfa_backup_code = code : login.mfa_code = code }"
                                @change_mfa_mode="(new_mode) => {  mfa_mode = new_mode }"
                            />

                            <button class="btn btn-lg btn-oval btn-gradient text-bold btn-secondary px-5 mt-4" @click.prevent="login_client()">
                                Login <i class="ion-load-c icon-lg spin ml-2" v-if="login.loading"></i>
                            </button>
                        </div>

                        <!-- Invite form -->
                        <div v-else-if="login_form == 'invite'" class="cardbox-body" style="margin-bottom: 100px">

                            <h3 v-if="invite_loading">Loading your invite...<i class="ion-load-c spin ml-2"></i></h3>
                            <section v-else-if="invite">
                                <login-logos
                                    class="mb-3"
                                    :team_logo="invite.team_logo_url"
                                    :client_logo="invite.client_logo">
                                </login-logos>


                                <h3>Hello, {{ invite.client_name }}!</h3>
                                <br/>
                                <p class="text-justified">
                                    You have been invited by <b>{{ invite.team_name }}</b> to this online shared folder called Client Space.
                                    <br/>In this folder, you can:
                                </p>
                                
                                <div class="">
                                    <span class="d-block pl-3 text-bold"><i class=" ion-checkmark mr-2"></i>Browse and download any file you want</span>
                                    <span class="d-block pl-3 " :class="{'text-muted': invite.client_readonly, 'text-bold': !invite.client_readonly}"><i class="mr-2" :class="{'ion-checkmark': !invite.client_readonly, 'ion-close-round': invite.client_readonly}"></i>Upload, move, delete or restore files and folders</span>
                                </div>

                                <p class="text-justified mt-4">
                                    Set your private password to accept the invitation and join the Space!
                                </p>

                                <div class="mt-2">
                                    <button class="btn btn-sm  text-sm" type="button" v-if="!invite_password_generated" @click="generate_password()">
                                        Generate strong password
                                        <i class="ion-locked ml-1"></i>
                                    </button>
                                    <span class="text-sm" v-else>
                                        Your password:
                                        <span class="bg-gray-dark px-1 mr-1 py-1 text-bold text-monospace" style="font-size: 1.2em" id="generated_psw">{{ invite_form.psw1 }}</span>
                                        <i class="ion-refresh c-pointer" @click="generate_password()"></i>
                                    </span>
                                </div>
                                
                                <form @submit.prevent="submit_invite_form" method="post">

                                    <div class="d-flex align-items-center justify-content-between py-2">
                                        <input
                                            v-if="invite_password_visible"
                                            type="text"
                                            name="password"
                                            placeholder="Password"
                                            class="form-control form-control-inverse mr-1"
                                            required="required"
                                            autocomplete="off"
                                            v-model="invite_form.psw1" />
                                        <input
                                            v-else
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            class="form-control form-control-inverse mr-1"
                                            required="required"
                                            autocomplete="off"
                                            v-model="invite_form.psw1" />
                                        

                                        <input
                                            v-if="invite_password_visible"
                                            type="text"
                                            required="required"
                                            name="password2"
                                            autocomplete="off"
                                            v-model="invite_form.psw2"
                                            placeholder="Repeat password"
                                            class="form-control form-control-inverse ml-1" />
                                        <input
                                            v-else
                                            type="password"
                                            required="required"
                                            name="password2"
                                            autocomplete="off"
                                            v-model="invite_form.psw2"
                                            placeholder="Repeat password"
                                            class="form-control form-control-inverse ml-1" />
                                        <a href="#" @click.prevent="invite_password_visible = !invite_password_visible" class="ml-2 text-white" title="Show/hide password"><i :class="{'ion-eye': !invite_password_visible, 'ion-eye-disabled': invite_password_visible}" class="ion-eye"></i></a>
                                    </div>
                                    
                                    <div v-if="invite_show_mfa" class="mb-3">
                                        <p class="text-12 mt-2">Two-Factor Authentication required</p>
                                        <mfa-login
                                            :mfa_mode="mfa_mode" 
                                            :user_email="null" 
                                            :sms_phone_number="mfa_sms_phone_number"
                                            :mfa_cooldown_seconds="mfa_cooldown"
                                            @code_changed="(code) => { mfa_mode == 'backup' ? invite_form.mfa_backup_code = code : invite_form.mfa_code = code }"
                                            @change_mfa_mode="(new_mode) => {  mfa_mode = new_mode }"
                                            
                                        />
                                    </div>
                                    <div v-else class="text-italic">
                                        Note: You can set up Two-Factor Authentication after joining the space. 
                                        <info-icon content="You can substantially increase security of your shared folder if you enable Two-Factor Authentication. Entering the folder will be secured both by your password and a one-time code that you receive on your phone."></info-icon>
                                    </div>

                                    <div class="form-group my-3">
                                        <checkbox 
                                            label="Notify me in email when a new file is uploaded (recommended)" 
                                            name="watch_client" 
                                            :value="invite_form.watch_client" 
                                            @changed="newval => invite_form.watch_client=newval" 
                                        />
                                    </div>

                                    <div class="form-group my-3">
                                        <checkbox 
                                            label="Keep me logged in" 
                                            name="stay_logged_in" 
                                            :value="invite_form.stay_logged_in" 
                                            @changed="newval => invite_form.stay_logged_in=newval" 
                                        />
                                    </div>

                                    <div v-if="invite_form.error" class="alert alert-danger" v-html="invite_form.error"></div>

                                    <button v-if="!invite_form.success" class="mt-2 btn btn-secondary text-bold text-primary fw" type="submit" :disabled="invite_form.loading">Join Client Space<i v-if="invite_form.loading" class="ion-load-c ml-2 spin"></i></button>
                                    <div v-else class="alert alert-success">Success, entering Space...</div>

                                </form>
                            </section>

                        </div>

                        <!-- Forgotten password form -->
                        <div v-else-if="login_form == 'forgotten-password'" class="cardbox-body" style="margin-bottom: 100px">
                            <div class="fw text-center mt-2">
                                <section v-if="!resend_invite.success">
                                    <p>Click the button below to reset your password and receive a new invite link to </p>
                                    <div class="text-bold redacted_email" v-if="team_info && team_info.redacted_client_email">{{ team_info.redacted_client_email }}</div>
                                    <button class="btn btn-lg btn-secondary text-bold text-primary text-12 mt-4" @click="forgotten_password()" :disabled="resend_invite.loading">
                                        Reset password<i v-if="resend_invite.loading" class="ion-load-c ml-2 spin"></i>
                                    </button>

                                    <div v-if="resend_invite.error" class="alert alert-danger mt-3" v-html="resend_invite.error"></div>

                                    <div class="mt-4 text-justify">
                                        <i class="ion-information-circled mr-2"></i>
                                        If you have Two-Factor Authentication enabled, you will have to provide the authentication code 
                                        (or backup code) as part of the password reset process.
                                    </div>

                                    <div class="mt-5">
                                        <a href="#" class="text-white text-sm" @click.prevent="login_form='login'"><i class="ion-arrow-left-c mr-2"></i>Back to login</a>
                                    </div>
                                </section>
                                <section v-else>
                                    <div class="alert alert-success">
                                        Your password has been reset and we sent a new invite email 
                                        <template  v-if="team_info && team_info.redacted_client_email">
                                            to <div class="my-2 text-bold redacted_email">{{ team_info.redacted_client_email }}</div>
                                        </template>
                                        <div>The invite expires in 24 hours.</div>
                                    </div>
                                    <div class="mt-4 text-12">Close this page now and click the invite link in the email!</div>
                                </section>

                                

                            </div>
                        </div>

                        <div v-else-if="login_form == 'forgotten-password-nospace'" class="cardbox-body" style="margin-bottom: 100px">
                            <div class="fw text-center mt-2">
                                TODO email + button form

                                <div class="mt-5">
                                    <a href="#" class="text-white text-sm" @click.prevent="login_form='email_password'">Back to login</a>
                                </div>

                            </div>
                        </div>

                    </section>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>

import { UserService } from '@/services/user-service.js'
import { NamespaceService } from '@/services/namespace-service.js'
import { ClientSpacesService } from '@/services/client_spaces-service.js'
import { TokenCache } from '@/helpers/token-cache.js'
import { bus, events } from '@/helpers/event_bus.js'
import { Utils } from '@/helpers/utils.js'

import mfaLogin from '@/components/mfa/login'
import fileListView from './filelist-view.vue'
import deletedGroups from '@/components/deleted_groups.vue'
import checkbox from '@/components/checkbox.vue'
import searchBox from '@/components/search-box.vue'
import globalAlert from '@/components/global-alert.vue'
import locations from '@/components/data-location-flags.vue'
import profileImageSelector from '@/components/profile-image-selector.vue'
import loginLogos from '@/components/client_space_login_logos.vue'
import eventBox from '@/components/client_space_app_event.vue'
import infoIcon from '@/components/info-icon.vue'

import mfa from '@/components/mfa/main'
import md5 from 'md5'

const window_width = window.innerWidth

// How frequently the space info is reloaded in milliseconds (e.g. arhived, readonly, etc)
// Set to false (or zero) to disable periodic refresh
// (This is the 'space' object, the file list is reloaded separately)
const SPACE_SYNC_INTERVAL_SEC = 5*60 // once per 5 minutes

export default {

    components: {
        checkbox,
        fileListView,
        deletedGroups,
        searchBox,
        globalAlert,
        locations,
        profileImageSelector,
        loginLogos,
        eventBox,
        infoIcon,
        mfa,
        mfaLogin
    },

    data(){
        return {

            key: Utils.parse_url_param('key'),

            mfa_status: null,
            mfa_content: 'status',
            mfa_alert: null,

            login: {
                email: null,
                password: null,
                mfa_code: null,
                mfa_backup_code: null,
                stay_logged_in: true,
                loading: false,
                error: false
            },
            login_form: null, // Which login form is displayed 
            mfa_mode: null,
            mfa_sms_phone_number: null,
            mfa_cooldown: 0,
            mfa_cooldown_interval: null,


            disable_login: false,
            logout_loading: false,
            user_auth: {
                type: null,
                email: null,
                password: null,
                token: null
            },

            is_skyflok_user: false,
            skyflok_team: null,
            client_logo: null,

            show_trash: false,
            change_watch_loading: false,

            acting_user: null,
            external_actor: null,

            space: null,

            client_form: null,
            client_change_pass: false,
            client_form_show_current_pass: false,
            client_form_show_new_pass: false,
            msg: null,

            check_interval_handler: null,
            alert: { },

            team_info: null,

            space_reload_interval_handler: null,

            invite_code: Utils.parse_url_param('invite'),
            invite_loading: false,
            invite: null,
            invite_password_generated: false,
            invite_password_visible: false,
            invite_show_mfa: false,
            invite_form: {
                psw1: null,
                psw2: null,
                watch_client: false,
                stay_logged_in: true,
                mfa_code: null,
                mfa_backup_code: null,
                error: false,
                loading: false
            },

            resend_invite:{
                loading: false,
                success: false,
                error: false,
            },

            recent_events: [],
            recent_events_loading: false,
            recent_events_filter: {
                uploads: true,
                downloads: true
            },
            recent_events_pager: {
                page_size: 6,
                start_offset: 0
            },
            all_events: null,

            Utils: Utils,
            ClientSpacesService: ClientSpacesService
        }
    },

    computed: {

        is_mobile(){
            return Utils.is_mobile()
        },
        is_space_readonly(){
            if(!this.space){ return true }
            // The namespace is read-only for everyone if the space is archived and readonly only for the client if it's 'space.client_readonly' is true
            return this.space.is_archived || (!this.is_skyflok_user && this.space.client_readonly)
        },

        client_has_custom_logo(){
            if(!this.client_logo){ return false }
            return this.client_logo.startsWith('http') || this.client_logo.startsWith("data:image/")
        },

        is_narrow(){
            return window_width <= 992
        },

        email_notifications(){
            if(!this.space){ return false }

            return this.is_skyflok_user ? this.space.watch_managers : this.space.watch_client
        },

        client_token_storage(){
            return this.login.stay_logged_in ? localStorage : sessionStorage
        },

        mfa_enabled(){
            return this.mfa_status && this.mfa_status.has_2fa
        },

        recent_events_filtered(){
            let events = this.recent_events
            if(!this.recent_events_filter.downloads){
                events = events.filter(e => e.event_type != 'file_download')
            }
            if(!this.recent_events_filter.uploads){
                events = events.filter(e => e.event_type != 'file_upload')
            }

            return events
        }
    },

    watch: {
        'client_form.selected_logo': function(){
            // When a logo is selected, clear the custom image
            if(this.client_form && this.client_form.selected_logo){
                this.client_form.custom_avatar_url = null
            }
        },
        'client_form.custom_avatar_url': function(){
            // When a custom image is selected, clear the built-in logo selection
            if(this.client_form && this.client_form.custom_avatar_url){
                this.client_form.selected_logo = null
            }
        },
        login_form(){
            // Clear error when login form changes
            this.login.error = false
        }
    },

    created(){

        bus.$on(events.ERROR, msg => { this.alert = { type: "danger", html: msg } })
        bus.$on(events.SUCCESS, msg => { this.alert = { type: "success", html: msg } })
        bus.$on(events.WARNING, msg => { this.alert = { type: "warning", html: msg } })

        if(!this.key || this.key.length == 0){
            if(!this.invite_code || this.invite_code.length == 0){
                // Not an invite and no key found: display error
                this.login_form = "email_password"
                return
            }
            else{
                // Invite code accept
                this.load_invite()
                return
            }
        }

        // Check if it's a client or skyflok user
        // They both store their tokens in the token cache, but the client also has a 'client-space-key=[key]' entry
        
        if(sessionStorage.getItem("client-space-key") == this.key || localStorage.getItem("client-space-key") === this.key){
            // Client of this Space with a cached, OK

            // Set the proper storage for TokenCache - we expect the token to be in the same storage where the key was
            TokenCache.storage = sessionStorage.getItem("client-space-key") == this.key ? sessionStorage : localStorage
            if(!TokenCache.is_stored()){
                // No token found here, redirect to login
                this.show_login_form()
                return
            }

            this.is_skyflok_user = false

            this.user_auth.type = ClientSpacesService.AUTH_TYPE_CLIENT
            this.user_auth.token = TokenCache.get()
            this.login_form = 'autologin'
            this.load_space(true)

        }
        else if(TokenCache.get()){
            // Logged in SkyFlok user
            
            this.is_skyflok_user = true
            this.login_form = 'autologin'

            // Check if token is still good, load user
            UserService.get_me().then(res => {

                // Set auth for subsequent calls
                this.user_auth.type = ClientSpacesService.AUTH_TYPE_SKYFLOK_USER;
                this.user_auth.token = TokenCache.get()

                // Start loading the space
                this.load_space(true)

                // Set space user
                this.acting_user = res.body
                this.acting_user.is_admin = this.acting_user.roles.indexOf("OA") >= 0
                this.acting_user.avatar_url = Utils.avatar_url(this.acting_user)

                // Load other team members (needed for file version history)
                // TODO only load members who are currently or had been
                // earlier Space Admins
                UserService.get_my_team().then(res => {
                    this.$set(this.acting_user, 'team', res.body)
                })

            }).catch(err => {
                console.error(err)
                this.show_login_form()
                this.login.error = "Failed to log you in automatically."
            })
        }
        else{
            // Not logged in or client of a different Client Space
            this.show_login_form()
            return
        }

    },

    destroyed(){
        if(this.space_reload_interval_handler){
            clearInterval(this.space_reload_interval_handler)
            this.space_reload_interval_handler = null
        }
    },

    mounted(){
        if(localStorage && localStorage["theme"]){
            this.set_theme(localStorage["theme"])
        }

        if(window.location.hostname.toLowerCase() === 'www.skyflok.com'){
            // Add Hotjar
            /*
            (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1028175,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            */
        }

    },



    methods: {

        show_login_form(){
            this.login_form = 'login'
            this.$nextTick(()=> {
                const psw_input = document.getElementById("password_field")
                psw_input ? psw_input.focus() : false
            })

            // Load team info so we can display the team name and logo on the Login form
            if(this.key){
                ClientSpacesService.get_team_info(this.key).then(res => {
                    this.team_info = res.body
                    this.client_logo = res.body.client_logo
                }).catch(err => {
                    console.error("Error loading team info of Space " + this.key)
                    if(err.status === 404){
                        // Space not found or archived
                        this.login.error = err.body.message
                        this.disable_login = true
                    }
                })
            }
        },

        login_client(){

            if(!this.login.password){
                this.login.error = "Fill your password!"
                return
            }

            if(!this.key && !this.login.email){
                this.login.error = "Fill your email address!"
                return
            }

            if(!this.key && !Utils.is_valid_email(this.login.email)){
                this.login.error = "This email address doesn't look valid. Please check and fix it."
                return
            }

            this.login.error = false
            this.login.loading = true

            // Hash password before submitting
            const password_hash = md5(this.login.password)
            ClientSpacesService.login_client(password_hash, this.key, this.login.email, this.login.mfa_code, this.login.mfa_backup_code)
            .then(res => {
                // OK, token received
                const response = res.body
                const token = response.token
                
                // If the client is logging in using email+pass, there's no Space Key in the URL.
                // The space key from the response always includes the correct space key
                this.key = response.space_key

                // Save the token 
                TokenCache.storage = this.login.stay_logged_in ? localStorage : sessionStorage
                TokenCache.store(token)
                TokenCache.storage.setItem("client-space-key", this.key)
                
                // Clear the login form
                this.login = {
                    email: null,
                    password: null,
                    mfa_code: null,
                    mfa_backup_code: null,
                    stay_logged_in: true,
                    loading: false,
                    error: false
                }

                // The following line causes a full page reload
                window.location.search = "?key=" + this.key
                this.load_space(true, true)

            }).catch(err => {
                this.login.loading = false
                const details = err.body
                if(err.status && err.status == 403){
                    // 2FA required
                    this.login_form = '2fa'
                    this.mfa_mode = details.mfa_mode
                    this.mfa_sms_phone_number = details.phone_number
                }
                else if(err.status && err.status == 429){
                    // Wrong 2FA code
                    this.mfa_cooldown = details.seconds_to_wait
                    this.login_btn_disabled = true
                    this.login_form = '2fa'
                    this.mfa_mode = details.mfa_mode
                    if(this.mfa_cooldown_interval){
                        clearInterval(this.mfa_cooldown_interval)
                    }

                    if(this.mfa_cooldown > 0){
                        this.login.error = "Wrong code! To assure security of your account, you have to wait until you can try again!"
                        var self = this
                        self.mfa_cooldown_interval = setInterval(()=>{
                            if(self.mfa_cooldown == 0){
                                self.login_btn_disabled = false
                                clearInterval(self.mfa_cooldown_interval)
                                return
                            }
                            self.mfa_cooldown -= 1;
                        }, 1000)
                    }
                }
                else{
                    // Login error
                    console.error("Client space login failed", err)
                    this.login.error = "Login failed"
                    if(err.body && err.body.message){
                        this.login.error += ": " + err.body.message
                    }
                }
                
            })
        },

        on_file_uploaded(file){

            // Report file uploads by SkyFlok Users to Client Space Service
            if(this.is_skyflok_user){
                const space_id = this.space.id
                const event = ClientSpacesService.SPACE_EVENT_FILE_UPLOADED
                const actor = ClientSpacesService.ACTOR_SPACE_MANAGER
                const file_id = file.id

                ClientSpacesService.report_space_event(this.user_auth, space_id, event, actor, file_id).then(() => {
                    // Nothing to do here, there's no UI change
                    //console.info("File upload reported to Client Space Service")
                }).catch(err => {
                    // Nothing to do here either
                    console.error("Error reporting new file upload: ", err)
                })
            }

            this.load_recent_events()
        },

        change_watch(){
            if(!this.space){
                return
            }

            const new_watch_value = !this.email_notifications
            this.change_watch_loading = true
            let error_handler = (err) => {
                console.error(err)
                this.change_watch_loading = false
                let msg = "Error turning notifications " + (new_watch_value ? "on" : "off")
                if(err.body && err.body.message){
                    msg += ": " + err.body.message
                }
                Utils.show_error(msg)
            }
            if(this.is_skyflok_user){
                // Change watch for managers
                ClientSpacesService.watch_managers(this.space.id, new_watch_value).then(() => {
                    this.change_watch_loading = false
                    this.space.watch_managers = new_watch_value
                }).catch(err => { error_handler(err) })
            }
            else{
                // Change watch for client
                ClientSpacesService.watch_client(this.user_auth, this.space.key, new_watch_value).then(() => {
                    this.change_watch_loading = false
                    this.space.watch_client = new_watch_value

                }).catch(err => { error_handler(err) })
            }


        },

        open_client_form(){

            this.client_form = {
                name: this.space.client_name,
                email: this.space.client_email,

                logos: Utils.client_space_logos.map(logo => { return { path: Utils.client_space_logo_base_path + logo, name: logo.replace(".png", '').replace("_", ' ') } }),

                selected_logo: null,
                custom_avatar_url: null,

                change_pass: false,
                current_pass: null,
                psw1: null,
                psw2: null,


                error: false,
                success: false,
                loading: false
            }
            // Figure out if the client logo is one of the built-in ones, or custom
            const builtin_logo = Utils.client_space_logos.find(logo => { return this.space.client_logo ===  Utils.client_space_logo_base_path + logo })
            if(!builtin_logo){
                this.client_form.custom_avatar_url = this.space.client_logo
            }
            else{
                this.client_form.selected_logo = this.space.client_logo
            }

            this._open_modal("client-form-modal", ()=>{
                this.client_form = null
            })
        },

        client_form_submit(){
            const form = this.client_form
            if(form.name.length == 0){
                form.error = "Please fill in the name!"
                return
            }
            if(!Utils.is_valid_email(form.email)){
                form.error = "The e-mail address doesn't look okay!"
                return
            }
            if(this.client_change_pass){
                if(this.client_form.psw1 != this.client_form.psw2){
                    form.error = "The two passwords must match!"
                    return
                }
                if(!this.client_form.psw1 || this.client_form.psw1.length < 8){
                    form.error = "The new password must be 8+ characters long!"
                    return
                }
            }

            let client_info = {
                name: this.client_form.name,
                email: this.client_form.email,
                logo: this.client_form.selected_logo ? this.client_form.selected_logo : this.client_form.custom_avatar_url,
            }

            if(this.client_change_pass){

                const plaintext_pass_format = /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/gm
                if(plaintext_pass_format.exec(this.client_form.current_pass)){
                    client_info['current_pass'] = this.client_form.current_pass
                }
                else{
                    client_info['current_pass'] = md5(this.client_form.current_pass)
                }

                client_info['new_pass'] = md5(this.client_form.psw1)
            }

            this.client_form.loading = true
            ClientSpacesService.client_info_edit(this.space.key, this.user_auth, client_info).then(() => {
                this.client_form.loading = false

                this.space.client_name = client_info.name
                this.space.client_email = client_info.email
                this.space.client_logo = client_info.logo

                this.acting_user.name = client_info.name
                this.acting_user.email = client_info.email
                this.acting_user.avatar_url = client_info.logo

                this.close_modal('client-form-modal')
                if(this.is_mobile){
                    // Close form modal and sidebar
                    this._close_sidebar()
                }
                Utils.show_success("Info updated successfully")
            }).catch(err => {
                console.error(err)
                this.client_form.loading = false
                this.client_form.error = "Error: " + err.body.message
            })
        },

        select_logo(logo){
            if(this.client_form.selected_logo === logo.path){
                // unselect the selected logo
                this.client_form.selected_logo = null
            }
            else{
                // Select the built-in logo, clear the custom one
                this.client_form.selected_logo = logo.path
            }
        },

        load_invite(){
            this.login_form = 'invite'

            this.invite_loading = true
            ClientSpacesService.load_valid_invite(this.invite_code).then(res => {
                this.invite = res.body
                this.invite_loading = false
            }).catch(err => {
                this.invite_loading = false
                if(err.status && err.status === 404){
                    this.login.error = "<b>Error!</b> This invite either does not exist or has been used already. Please talk to the person who invited you."
                }
            })
        },

        generate_password(){
            const psw = Utils.random_string(8)
            this.invite_form.psw1 = psw
            this.invite_form.psw2 = psw
            this.invite_password_generated = true
        },

        submit_invite_form(){
            if(this.invite_form.psw1 != this.invite_form.psw2){
                this.invite_form.error = "The two passwords do not match!"
                return
            }
            if(!this.invite_form.psw1 || this.invite_form.psw1.length < 8){
                this.invite_form.error = "The password must be at least 8 characters!"
                return
            }

            // OK
            this.invite_form.error = false

            // Hash password
            const password_hash = md5(this.invite_form.psw1)

            this.invite_form.loading = true
            ClientSpacesService.client_join_space(this.invite_code, password_hash, this.invite_form.watch_client, this.invite_form.mfa_code, this.invite_form.mfa_backup_code).then(res => {
                this.invite_form.loading = false
                this.invite_form.success = true

                const result = res.body
                
                this.key = result.key
                const token = result.token

                // Store received token in localStorage
                //this.set_client_token(token)
                TokenCache.storage = this.invite_form.stay_logged_in ? localStorage : sessionStorage
                TokenCache.store(token)
                TokenCache.storage.setItem("client-space-key", this.key)

                this.invite_form.psw1 = null
                this.invite_form.psw2 = null
                this.invite_form = {}

                // Redirect to the Space
                localStorage["client_space_welcome"] = "1"
                window.location.search = "?key=" + this.key

            }).catch(err => {
                this.invite_form.loading = false
                const details = err.body
                if(err.status && err.status == 403){
                    // 2FA required
                    this.invite_show_mfa = true
                    this.mfa_mode = details.mfa_mode
                    this.mfa_sms_phone_number = details.phone_number
                }
                else if(err.status && err.status == 429){
                    // Wrong 2FA code
                    this.invite_show_mfa = true
                    this.mfa_cooldown = details.seconds_to_wait ? details.seconds_to_wait : 0
                    //this.mfa_mode = details.mfa_mode
                    if(this.mfa_cooldown_interval){
                        clearInterval(this.mfa_cooldown_interval)
                    }

                    if(this.mfa_cooldown > 0){
                        this.invite_form.error = "Wrong code! To assure security of your account, you have to wait until you can try again!"
                        var self = this
                        self.mfa_cooldown_interval = setInterval(()=>{
                            if(self.mfa_cooldown == 0){
                                self.login_btn_disabled = false
                                clearInterval(self.mfa_cooldown_interval)
                                return
                            }
                            self.mfa_cooldown -= 1;
                        }, 1000)
                    }
                }
                else{
                    // Error
                    console.error(err)
                    
                    
                    this.invite_form.error = "Error! "
                    if(err.body && err.body.message){
                        this.invite_form.error += " " + err.body.message
                    }
                }

            })

        },

        forgotten_password(){
            // Reset space password and send a new invite
            this.resend_invite.loading = true
            this.resend_invite.error = false
            ClientSpacesService.reset_password(this.key).then(() => {
                this.resend_invite.loading = false
                this.resend_invite.success = true

            }).catch(err => {
                console.error(err)
                this.resend_invite.loading = false
                this.resend_invite.error = err.body ? err.body.message : "Error"
            })
        },

        load_space(with_team_info, do_initialize){
            if(do_initialize === undefined){
                do_initialize = true
            }
            if(with_team_info === undefined){
                with_team_info = false
            }

            this.login.error = null
            const with_storage_locations = do_initialize
            this.login_loading = true
            ClientSpacesService.get_space(this.user_auth, this.key, with_team_info, with_storage_locations)
            .then(res => {
                this.login_loading = false
                let space = res.body

                this.on_successful_login(space, do_initialize)

            }).catch(err => {
                this.login_loading = false

                // Stop reloading the Space
                if(this.space_reload_interval_handler){
                    clearInterval(this.space_reload_interval_handler)
                    this.space_reload_interval_handler = null
                }
                
                console.log("Error loading space: ", err)
                if(err.status){
                    // It's an HTTP error

                    if(err.status === 401){
                        // Auto-login failed
                        console.log("Auto-login failed")
                        if(this.user_auth.type === ClientSpacesService.AUTH_TYPE_SKYFLOK_USER){
                            // The logged in user doesn't have access to this space!
                            this.show_login_form()
                            this.$nextTick(() => {
                                this.login.error = "Autologin as SkyFlok team member failed! You can log in below as the Client"
                            })
                        }
                        else if(this.user_auth.type === ClientSpacesService.AUTH_TYPE_CLIENT){
                            // Expired client token
                            this.show_login_form()
                            this.$nextTick(() => {
                                this.login.error = "Session expired, please log in again!"
                            })
                            // Clear the cached token
                            if(this.key){
                                this.clear_client_token()
                            }
                        }
                        return
                    }
                    else if(err.status === 404){
                        this.login.error = "Space not found! Please make sure that you copied the complete Space link to the address bar and try again!"
                    }
                    else if(err.status === 410){
                        this.login.error = "This Space has been archived. If you think it's a mistake, please contact the person who gave you access!"
                        this.space_is_archived()
                        this.clear_client_token()
                    }
                    else if(err.status === 403){
                        this.show_login_form()
                        this.$nextTick(() => {
                            this.login.error = err.body.message
                        })
                    }
                    else{
                        this.show_login_form()
                        this.$nextTick(() => {
                            this.login.error = "Failed to log you in"
                            if(err.body && err.body.message){
                                this.login.error += ": " + err.body.message
                            }
                        })
                    }

                }
            })
        },

        on_successful_login(space, do_initialize){
            // Set team info if it present
            if(space.team_info){
                this.skyflok_team = space.team_info
            }

            // Make sure to set this before setting 'this.space', so
            // FileListView starts using NamespaceService with the correct settings
            NamespaceService.ns_type = NamespaceService.NS_TYPE_CLIENT_SPACE
            NamespaceService.ns_key = space.key

            space.client_logo = space.client_logo ? space.client_logo : ('https://www.gravatar.com/avatar/' + md5(space.client_email) + '?s=200&d=identicon')


            if(!this.is_skyflok_user){
                // Set acting user and external actor
                this.acting_user = {
                    name: space.client_name,
                    avatar_url: space.client_logo,
                    team: this.skyflok_team.team,
                    team_settings: this.skyflok_team,
                    is_client_space_client: true,
                    is_admin: false
                }

                this.external_actor = this.acting_user
            }
            else{
                // Set the client as the external actor in the modified boxes
                this.external_actor = {
                    name: space.client_name,
                    avatar_url: space.client_logo,
                    team: this.skyflok_team.team
                }
            }

            // Publish Space
            this.space = space

            // Init UI
            if(do_initialize){
                this.$nextTick(()=>{
                    this.init_space_ui()
                })
            }

            if(!this.is_skyflok_user && localStorage["client_space_welcome"] == "1"){
                // Show welcome modal on first login
                
                localStorage.removeItem("client_space_welcome")
                this.$nextTick(() => {
                    this._open_modal("welcome-modal")
                })
            }

            // Load the event log
            this.load_recent_events()

            // Start refreshing space periodically
            var self = this
            if(SPACE_SYNC_INTERVAL_SEC > 0 && !this.space_reload_interval_handler){
                this.space_reload_interval_handler = setInterval(function(){
                    self.load_space(false, false)
                }, SPACE_SYNC_INTERVAL_SEC * 1000)
            }

        },

        init_space_ui(){
            var layoutContainer = $('.layout-container-main');
            var $body = $('body');
            // Handler to toggle sidebar visibility on mobile
            $('.sidebar-toggler').on('click', function(e) {
                e.preventDefault();
                layoutContainer.toggleClass('sidebar-visible');
                // toggle icon state
                $(this).parent().toggleClass('active');
            });
            // Close sidebar when click on backdrop
            $('.sidebar-layout-obfuscator').on('click', function(e) {
                e.preventDefault();
                $body.removeClass('sidebar-cover-visible'); // for use with cover mode
                layoutContainer.removeClass('sidebar-visible'); // for use on mobiles
                // restore icon
                $('.sidebar-toggler').parent().removeClass('active');
            });

            // escape key closes sidebar on desktops
            $(document).keyup(function(e) {
                if (e.keyCode === 27) {
                    $body.removeClass('sidebar-cover-visible');
                }
            });

            // Handler to toggle sidebar visibility on desktop
            $('.covermode-toggler').click(function(e) {
                e.preventDefault();
                $body.addClass('sidebar-cover-visible');
            });

            $('.sidebar-close').click(function(e) {
                e.preventDefault();
                $body.removeClass('sidebar-cover-visible');
            });

            // remove desktop offcanvas when app changes to mobile
            // so when it returns, the sidebar is shown again
            window.addEventListener('resize', function() {
                if (window.innerWidth < 768) {
                    $body.removeClass('sidebar-cover-visible');
                }
            });
        },

        _close_sidebar(){

            $('body').removeClass('sidebar-cover-visible'); // for use with cover mode
            $('.layout-container-main').removeClass('sidebar-visible'); // for use on mobiles
            // restore icon
            $('.sidebar-toggler').parent().removeClass('active');
        },

        load_recent_events(){
            this.recent_events_loading = true
            const start = this.recent_events_pager.start_offset
            const howmany = this.recent_events_pager.page_size
            const desc = true

            ClientSpacesService.query_file_uploads_downloads(this.user_auth, this.key, desc, howmany, start).then(res => {
                const events = res.body
                this.recent_events_loading = false
                this.recent_events = events
            }).catch(err => {
                console.error("File events query error ", err)
                this.recent_events_loading = false
                Utils.show_error("Error loading events")
            })
        },

        recent_events_page(is_forward){
            if(is_forward){
                this.recent_events_pager.start_offset += this.recent_events_pager.page_size
            }
            else{
                if(this.recent_events_pager.start_offset <= 0){ return }
                this.recent_events_pager.start_offset -= this.recent_events_pager.page_size
                this.recent_events_pager.start_offset = Math.max(0, this.recent_events_pager.start_offset)
            }

            this.load_recent_events()
        },

        sidebar_file_clicked(file){
            // The user clicked a file in the sidebar (in recent events)
            if(!file || !file.path){ return }
            
            // The file path includes the filename at the end, which we need to get rid of
            const parentpath = '/'+file.path.split('/').slice(0, -1).join('/')
            bus.$emit(events.FILELIST_CHANGE_FOLDER_TO_ID, file.parent_id, parentpath)
            
            this.$nextTick(()=>{
                bus.$emit(events.HIGHLIGHT_FILE, file.id)
            })
        },

        space_is_archived(){
            // Called when the space turns out to be archived.
            if(!this.is_skyflok_user){
                this.space = null
                this.login.error = "This Space has been archived. If you think it's a mistake, please contact the SkyFlok Team Member who gave you access!"
                if(this.space_reload_interval_handler){
                    clearInterval(this.space_reload_interval_handler)
                    this.space_reload_interval_handler = null
                }
            }
            else if(this.space){
                this.space.is_archived = true
            }
        },

        space_is_active(){
            if(this.is_skyflok_user && this.space){
                this.space.is_archived = false
            }
        },

        logout_client(){
            this.logout_loading = true
            // Called when the Client clicks the logout button.
            // Invalidate token
            ClientSpacesService.logout_client(this.user_auth).catch(() => {
                throw "Error invalidating client token " + this.user_auth.token
            })
            // Clear all from both sessionStorage and localstorage
            // (namespace is cached in localStorage, token might be in either one)
            sessionStorage.clear()
            localStorage.clear()
            // reload the page
            location.reload()
        },

        _show_message(text, type){
            if(type === undefined){ type = "success" }
            this.msg = {
                type: type,
                msg: text
            }
        },
        _range(num){
            var arr = [];
            for(var i=0 ; i<num ; ++i){ arr.push(i) }
            return arr;
        },
        close_modal(el_id){
            $("#"+el_id).modal("hide")
        },

        set_theme(theme){
            document.body.className = theme
            if(localStorage){ localStorage["theme"] = theme }
        },

        report_file_event(evt, file, error){
            if(error){
                console.error(error)
            }
        },

        get_client_token(){
            return this.client_token_storage.getItem("client_space-" + this.key)
        },

        set_client_token(token){
            this.client_token_storage["client_space-" + this.key] = token
        },

        clear_client_token(){
            this.client_token_storage.removeItem("client_space-" + this.key)
        },

        _open_modal(id, onclose){
            $("#"+id).modal('show')
            if(onclose){
                $("#"+id).on('hidden.bs.modal', function (e) {
                    onclose(e)
                })
            }
        }
    }
}
</script>

<style scoped>

    .redacted_email{
        word-break: break-word;
        font-size: 1.2em

    }

    .top-padding-70{
        padding-top: 70px
    }

    #files_search{
        margin-right: 10px
    }

    aside.sidebar-container{
        position: fixed;
        right: 0px;
        left: auto;
        z-index: 40;
    }
    main.main-container{
        margin-left: 0px;
        margin-right: 260px;
    }

    .avatar_url{
        max-height: 100px;
        max-width: 100px
    }

    @media(max-width: 992px){
        /* Remove the top padding when there's no header */
        .header-container{
            width: 100%
        }
        main.main-container{
            margin-left: 0px;
            margin-right: 0px;
        }
        aside.sidebar-container{
            right: auto;
            left: 0px;
        }
        .avatar_url{
            max-height: 80px;
            max-width: 80px
        }
    }

    @media(max-width: 767px){
        .avatar_url{
            max-height: 60px;
            max-width: 60px
        }
    }

    @media(max-width: 330px){
        .skyflok_center_logo{ display: none }
    }

    .brand-header{
        color: inherit;
        background-color: inherit;
    }

    .sidebar-nav-heading{
        padding: 1.2rem 1rem;
        font-size: .8rem;
        font-weight: bold;
        letter-spacing: .045rem
    }
    .archived_space_bg{
        background: #F8F9F9 !important;
    }
    .form-table tr:first-child td,
    .form-table tr:first-child th{
        border-top: none
    }
    .form-table th{
        text-align: right
    }

    img.builtin-logo{
        border: 2px solid transparent;
    }
    img.selected{
        border: 2px solid darkgray !important;
        box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.15)
    }

    #data-locations{ opacity: .75; transition: opacity linear .2s }
    .sidebar-container:hover #data-locations{ opacity: 1 }

    .sidebar-container .dropdown-menu{
        min-width: auto !important
    }

    .mobile-form-field{
        padding-bottom: 1em
    }


</style>
