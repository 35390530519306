<template>
<section>
    <div v-if="admin_folder_disabled" class="container pt-5">
        <upgrade-warning-box :feature="'Admin Folder'"></upgrade-warning-box>
    </div>

    <file-list-view
        v-else-if="user && user.is_admin"
        :user="user"
        :share-allowed="false"
        :is_admin_folder="true"
    ></file-list-view>

    <div v-else class="container pt-5">
        <admin-warning-box></admin-warning-box>
    </div>

</section>
</template>
<script>

import { NamespaceService } from '@/services/namespace-service.js'

import fileListView from './filelist-view.vue'
import upgradeWarningBox from '@/components/upgrade_warning_box.vue'
import adminWarningBox from '@/components/admin_feature_warning_box.vue'

export default {
    props: ['user'],
    components: {
        upgradeWarningBox,
        adminWarningBox,
        fileListView
    },
    created(){
        NamespaceService.ns_type = NamespaceService.NS_TYPE_ADMIN_FOLDER
    },
    
    computed: {
        admin_folder_disabled(){
            // Assume shares are enabled until team settings are loaded
            return this.user && this.user.team_settings ? this.user.team_settings.config.admin_folder_enabled === false : false
        },
    }

}
</script>

