const email_domain_typos = [
    {
        correct: "gmail.com",
        typos: ["gmail.cm", "gmail.con", "gmail.co", "gmal.com", "g-mail.com", "gmaill.com", "gmai.com",
                "gmail.co.uk", "googlemail.co.uk", "gmail.com.au", "gmail.ca", "googlemaile.com",
                "googlmail.com", "gmail.fr", "gmail.com.mx", "gmail.nl", "googlmail.com", "gmail.cpm",
                "gamil.com", "gmail.cim", "gmaul.com", "gnail.com", "gmaik.com", "gmail.be"]
    },
    {
        correct: "hotmail.com",
        typos: ["hotmail.cm", "hoymail.com", "homail.com", "hotmai.com",
                "hotmail.co", "hotmal.com", "hotmil.com", "hotmain.com", "hatmail.com",
                "hotmial.co.uk", "hotmall.co.uk", "hotmaiil.co.uk", "hotmaik.com",
                "hptmail.com"]
    },
    {
        correct: "yahoo.com",
        typos: ["yahoo.cm", "yahoo.co", "yaho.com", "yaoo.com", "yahooo.com",
                "yhaoo.com", "ayhoo.com", "dyahoo.com", "ahoo.com", "yhoo.com", "yahool.com", "yhoo.com",
                "yahoomail.com", "tyahoo.com", "yahool.com", "yahoo.net", "yshoo.com", "myshoo.com",
                "yahho.com", "yaqhoo.com", "ayahoo.com", "yah00.com", "yahho.com", "yohoo.com",
                "olyahoo.com", "yahaoo.com", "yahioo.com", "yahooc.com", "yahopo.com", "yajoo.com",
                "yhool.com", "yyahoo.com", "yayoo.com", "ysahoo.com", "ywhoo.com", "yaghoo.com",
                "hahoo.com", "qyahoo.com", "yahoo0.com", "yanoo.com", "yaho0o.com", "yahoom.com",
                "youhoo.com", "ytahoo.com", "69yahoo.com", "yahoo.org", "yahoop.com", "yhahoo.com",
                "yzhoo.com", "iyahoo.com", "y7ahoo.com", "yaboo.com", "yajhoo.com", "yaool.com",
                "ydhoo.com", "51yahoo.com", "yafoo.com", "yahgoo.com", "yahoord.com", "yhaooo.com",
                "yhoo.net", "yuhoo.com"]
    },
    {
        correct: "aol.com",
        typos: ["aol.cm", "a0l.com", "aol.co"]
    }
]

export { email_domain_typos }