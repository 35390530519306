<template>
<section>
    <div v-if="events_loading" class="fw text-center m-2">
        Loading... <i class="ion-load-c spin ml-1"></i>
    </div>
    <div v-else-if="events && !events.length" class="fw text-center">
        <b>No events</b>
    </div>
    <div v-else>
        <table class="table mb-0">
            <thead>
                <tr>
                    <th class="width-0">Timestamp</th>
                    <th>Event</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="event in events_page" :key="event.id">
                    <td class="text-sm width-0 nowrap">
                        {{ event.timestamp | timestamp_time }} {{ event.timestamp | timestamp_date }}
                    </td>
                    <td>
                        <span v-if="event.event_type == 1">Two-Factor Authentication enabled with {{ event.extra1 | mfa_kind }}</span>
                        <span v-if="event.event_type == 2">Two-Factor Authentication disabled</span>
                        <span v-if="event.event_type == 3">Additional backup codes generated</span>
                        <span v-if="event.event_type == 4">Successful login with {{ event.extra1 | mfa_kind }}</span>
                        <span v-if="event.event_type == 5">Failed login with {{ event.extra1 | mfa_kind }} <info-icon v-if="event.extra2" :content="'IP address: ' + event.extra2"></info-icon></span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="text-right">
            <div class="d-inline-block p-1 m-1 c-pointer" :class="{'disabled text-muted': page <= 1}" @click="page > 1 ? page = page-1 : false"><i class="ion-chevron-left"></i></div>
            <div v-for="p in range(total_pages)" :key="p" @click="() => { page = p }" class="d-inline-block px-2 c-pointer" :class="{'bg-primary text-white': (p == page)}">{{ p }}</div>
            <div class="d-inline-block p-1 m-1 c-pointer" :class="{'disabled text-muted': page >= total_pages}" @click="page < total_pages ? page = page+1 : false"><i class="ion-chevron-right"></i></div>
        </div>
    </div>
</section>
</template>
<script>
import { AuditLogService } from '@/services/auditlog-service'
import { Utils } from '@/helpers/utils'

import infoIcon from '@/components/info-icon'
import { ClientSpacesService } from '@/services/client_spaces-service'

export default {
    props: ['is_client_space', 'show_success', 'show_error'],

    components: {
        infoIcon
    },

    data(){
        return {
            events: null,
            events_loading: false,
            page: 1,
            page_size: 25,
        }
    },

    created(){
        this.load_history()
    },

    computed: {
        events_page(){
            if(!this.events){
                return null
            }

            const start_offset = (this.page-1) * this.page_size
            const end_offset = start_offset + this.page_size
            return this.events.slice(start_offset, end_offset)
        },

        total_pages(){
            if(!this.events){ return null }
            return Math.ceil(this.events.length / this.page_size)
        },
    },

    methods: {

        load_history(is_client){
            is_client = is_client === undefined ? false : is_client
            let request = null
            
            if(is_client){
                // When a Client is present, use ClientSpaceService
                request = ClientSpacesService.get_client_2fa_events()
            }
            else{
                // When a team member is present, use AuditLogService
                const query_params = {
                    entity_type: AuditLogService.ENTITY_TYPE_MFA
                }
                request = AuditLogService.query_events(query_params)
            }
            
            this.events_loading = true
            request.then(res => {
                this.events_loading = false
                this.events = res.body
            }).catch(err => {
                if(this.is_client_space && !is_client && err.status && err.status >= 400 && err.status < 500){
                    // Looks like a client is present, reload using ClientSpaceService
                    this.load_history(true)
                }
                else{
                    console.error(err)
                    let msg = "Error loading events"
                    if(err.body && err.body.message){
                        msg += ": " + err.body.message
                    }
                    this.show_error ? this.show_error(msg) : Utils.show_error(msg)
                }
            })
        },

        range(n){
            let arr = []
            for(var i=0 ; i<n ; ++i){
                arr.push(i)
            }
            return i
        }
    },

    filters: {
        mfa_kind(code){
            switch(code-0){
                case 1: return "code generator mobile app"
                case 2: return "SMS code"
                case 3: return "backup code"
                default: console.error("Unexpected MFA kind code: " + code); return code
            }
        }
    }
}
</script>

<style scoped>
table.table thead th {
    text-transform: uppercase;
    font-weight: bold;
}
</style>