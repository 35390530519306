<template>
<div class="Password__strength-meter">
    <div class="Password__strength-meter--fill" :data-score="password_strength"></div>
</div>
</template>
<script>
export default {
    props: {
        password_strength: {
            type: Number,
            default: 1
        }
    } 
}
</script>
<style scoped>
/* 
Password strength meter CSS from: 
https://github.com/apertureless/vue-password-strength-meter/blob/develop/src/components/PasswordStrengthMeter.vue 
To change the number of strength levels, edit:
- width, left and right of :before and :after
- add more --fill classes
- width and background color of the --fill classes
*/
.Password__strength-meter {
    position: relative;
    height: 3px;
    background: #DDD;
    margin: 10px auto 20px;
    border-radius: 3px;
}
.Password__strength-meter:before, .Password__strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #FFF;
    border-style: solid;
    border-width: 0 5px 0 5px;
    position: absolute;
    width: 33%;
    z-index: 10;
}
.Password__strength-meter:before {
    left: 33%;
}
.Password__strength-meter:after {
    right: 33%;
}
.Password__strength-meter--fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
}
.Password__strength-meter--fill[data-score='0'] {
    background: darkred;
    width: 33%;
}
.Password__strength-meter--fill[data-score='1'] {
    background: orange;
    width: 66%;
}
.Password__strength-meter--fill[data-score='2'] {
    background: green;
    width: 100%;
}
</style>