<template>
<div class="layout-container">
	<div class="page-container">
	<div class="d-flex align-items-center align-items-center-ie">
		<div class="fw container container-sm">
			<div class="cardbox text-bold">
				<div class="pb-1" :class="[status_ribbon_class]"></div>
                <div class="p-4">
					<router-view
						@changeStatus="(new_status) => status_changed(new_status)" 
					/>
                </div>  
                <div class="d-flex align-items-center justify-content-center fw mb-2 text-gray text-sm">
                    Made in Scandinavia
                    <img src="@/assets/img/flags/DK.png" height="20" class="ml-2" alt="Danish flag"/>
                </div>
                <div class="pt-1" :class="[status_ribbon_class]"></div>
			</div>
		</div>
	</div>
	</div>
</div>
</template>
<script>

export default {

	data(){
		return {
			status_ribbon_class: 'bg-gradient-info'
		}
	},

	methods: {
		status_changed(new_status){
			switch(new_status){
				case 'SUCCESS': 
					this.status_ribbon_class = 'bg-gradient-success'
					break;
				case 'ERROR': 
					this.status_ribbon_class = 'bg-gradient-danger'
					break;
				case 'LOADING': 
					this.status_ribbon_class = 'progress-bar-info progress-bar-striped'
					break;
				default:
					this.status_ribbon_class = 'bg-gradient-info'
					break;
				
			}
		}
	}

}
</script>