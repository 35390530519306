
// Private variables that we don't want to export
const token_key = "token"

// Use the window.localStorage API to permanently store the token
const TokenCache = {

    storage: localStorage,

    get(){
        return TokenCache.storage.getItem(token_key, null)
    },

    is_stored(){
        return TokenCache.storage.getItem(token_key, null) !== null
    },

    store(token){
        TokenCache.storage.setItem(token_key, token)
        return true
    },

    clear(){
        TokenCache.storage.removeItem(token_key)
        return true
    }
}

export { TokenCache }