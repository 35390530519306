<template>
    <div class="cardbox text-white b0" :class="cardbox_class" @click="clicked ? clicked() : false">
        <div class="cardbox-body text-center">
            <div class="text-bold display-1" v-if="icon">
                <i :class="[icon]"></i>
            </div>
            <p class="text-bold text-md" v-if="text">
                {{ text }}
            </p>
        </div>
    </div>
</template>
<script>

export default {
    props: ['clicked', 'bgClass', 'icon', 'text'],
    computed: {
        cardbox_class(){
            let classObj = {
                'c-pointer': !!this.clicked
            }
            classObj[this.bgClass] = true
            return classObj
        }
    }
}
</script>
