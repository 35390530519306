<template>
    <div class="layout-container">
        <div class="page-container bg-blue-grey-900">
            <div class="d-flex align-items-center align-items-center-ie bg-gradient-primary">
                <div class="fw container container-xs text-center">

                    <div v-if="google_user">
                        <div class="d-flex align-items-center justify-content-center mt-5">
                            <img v-if="google_user.image_url" :src="google_user.image_url" class="initial64 rounded-circle shadow-z2" >
                            <div class="ml-3">
                                <h4>{{ google_user.name }}</h4>
                                <div>{{ google_user.email }}</div>
                            </div>
                        </div>
                        <h4 class="mt-5">Logging you in... <i class="ion-load-c spin"></i></h4>
                    </div>

                    <section v-else>
                    
                        <div class="mb-3">
                            <img src="@/assets/img/skyflok_logo_lg.png" style="max-height: 40px"/>
                        </div>

                        <div class="cardbox-heading mb-5">
                            <div class="cardbox-title text-center">
                                <h4>{{ show_mfa ? "Two-Factor Authentication" : "Sign in to your SkyFlok Team" }}</h4>
                            </div>
                        </div>

                        <section v-show="!show_mfa && !email_prefilled">
                            <div class="c-pointer" @click.prevent="google_sign_in()">
                                <img src="@/assets/img/federated_login/google/btn_google_signin_light_normal_web@2x.png" style="max-height: 3em" />
                            </div>

                            <div class="d-flex align-items-center my-3">
                                <hr class="fw ml-5 mr-3 my-0" />
                                <span> OR </span>
                                <hr class="fw mr-5 ml-3 my-0" />
                            </div>
                        </section>
                        
                        <div class="cardbox-body">

                            <div v-if="confirm_result" class="alert" :class="{'alert-success': confirm_result.ok, 'alert-danger': !confirm_result.ok}">
                                <!-- reg confirmation result -->
                                {{ confirm_result.msg }}
                            </div>

                            <div v-if="error_msg" class="alert alert-danger">
                                <!-- login error -->
                                {{ error_msg }}
                            </div>

                            <form
                                @submit.prevent="login"
                                class="cardbox cardbox-flat text-white form-validate text-color"
                                id="user-login"
                                action
                                name="loginForm"
                                novalidate
                            >
                                <div v-if="!show_mfa" class="px-5">

                                    <div class="form-group">
                                        <input 
                                            v-model="form.email" 
                                            id="email" 
                                            class="form-control form-control-inverse" 
                                            type="email" 
                                            required 
                                            placeholder="Email address"
                                            autocomplete="email" 
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input 
                                            v-model="form.pass" 
                                            id="password" 
                                            class="form-control form-control-inverse" 
                                            type="password" 
                                            required 
                                            placeholder="Password" 
                                            autocomplete="current-password"
                                        />
                                    </div>
                                    
                                </div>
                                <section v-else class="pb-4">
                                    <mfa-login 
                                        :mfa_mode="mfa_mode" 
                                        :user_email="form.email" 
                                        :sms_phone_number="mfa_sms_phone_number"
                                        :mfa_cooldown_seconds="mfa_cooldown"
                                        @code_changed="(code) => { mfa_mode == 'backup' ? form.mfa_backup_code = code : form.mfa_code = code }"
                                        @change_mfa_mode="(new_mode) => {  mfa_mode = new_mode }"
                                    />
                                    
                                </section>

                                <div class="text-center my-4">
                                    <button v-if="!login_loading" :disabled="login_btn_disabled" class="btn btn-lg px-5 text-bold" type="submit">
                                        Login to my Team
                                    </button>
                                    <div v-else>
                                        <i class="ion-load-c icon-lg spin"></i>
                                    </div>
                                </div>
                            </form>
                            <!--
                            <div class="text-center"><small><a class="text-inherit" href="/recover-password">Forgot password?</a></small></div>
                            -->
                        </div>

                        <section v-show="!show_mfa && !email_prefilled">
                            
                            <h5 class="cardbox-footer text-center b0 px-5 mt-5">
                                <p>Are you a Client of a SkyFlok Team?</p>
                                <a href="https://spaces.skyflok.com" class="btn btn-info px-4 btn-oval text-bold ">Client Space Login <i class="ion-arrow-right-c ml-1"/></a>
                            </h5>

                            <div class="cardbox-footer text-center b0 mt-5">
                                <span class="mr-1">No account yet?</span>
                                <router-link to="/register_trial" class="text-inherit text-bold">Create your SkyFlok team now!</router-link>
                            </div>
                        </section>

                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus, events } from "../helpers/event_bus.js";
import { Utils } from "../helpers/utils.js";
import { AuthService } from "../services/auth-service.js";
import { TokenCache } from "../helpers/token-cache.js";

import platform from "platform";
import mfaLogin from '@/components/mfa/login'

export default {

    components: {
        mfaLogin
    },

    data() {
        return {
            google_user: null,
            
            form: {
                email: null,
                pass: null,
                mfa_code: null,
                mfa_backup_code: null
            },
            email_prefilled: false,
            login_btn_disabled: false,
            error_msg: null,
            login_loading: false,
            confirm_result: null,

            show_mfa: false,
            mfa_mode: null,
            mfa_sms_phone_number: null,
            mfa_cooldown: 0,
            mfa_cooldown_interval: null
        };
    },

  created: function() {
    // Pre-fill email if it's in the url
    const email = Utils.parse_url_param("email", false, true);
    if (email) {
        this.email_prefilled = true
        this.form.email = email;
    }

    const confirm = Utils.parse_url_param("confirm");
    if (confirm) {
      //const user_id = Utils.parse_url_param("user_id");
      const confirm_key = Utils.parse_url_param("confirm_key");

      if (!confirm_key) {
        // Error!
        this.confirm_result = {
          ok: false,
          msg:
            "The confirmation URL is incomplete, please try to copy it from your email and paste it to the address bar."
        };
        return;
      }

      if (confirm === "primary") {
        // Confirm the user's primary email address
        AuthService.confirm_primary_email(confirm_key)
          .then(res => {
            if (!res.ok) {
              this.confirm_result = {
                ok: false,
                msg:
                  "We could not confirm your registration, please try to copy the URL address from your email and paste it to the address bar."
              };
              return;
            }

            this.confirm_result = {
              ok: true,
              msg:
                "Your registration has been confirmed successfully, please log in!"
            };
            setTimeout(function() {
              document.getElementById("email").focus();
            }, 500);
          })
          .catch(err_resp => {
            this.confirm_result = {
              ok: false,
              msg: "Internal Error"
            };
            if (err_resp.status) {
              if (err_resp.status === 404) {
                this.confirm_result.msg =
                  "Your account is already activated, please log in.";
              } else {
                this.confirm_result.msg =
                  "Error activating your account! Please copy and paste the full URL from the email!";
              }
            }
          });
      }
    }
  },

    watch: {
        'form.mfa_code'(){ this.login_btn_state() },
        'form.mfa_backup_code'(){ this.login_btn_state() },
        'show_mfa'(){ 
            this.login_btn_state() 

            if(this.show_mfa){
                this.$nextTick(()=>{
                    document.getElementById("mfa_code").focus()
                })
            }
        },

    },

    methods: {

        login() {

            this.error_msg = false;

            let credentials = null
            if(this.google_user && this.google_user.id_token){
                credentials = {
                    google_id_token: this.google_user.id_token
                }
            }
            else if(this.form && this.form.email && this.form.pass){
                credentials = {
                    email: this.form.email,
                    password: this.form.pass
                }
            }

            if(!credentials) {
                // nothing to log in with
                return
            }

            // Add backup or normal MFA code if present
            if(this.form.mfa_backup_code){
                credentials["mfa_backup_code"] = this.form.mfa_backup_code
            }
            else if(this.form.mfa_code){
                credentials["mfa_code"] = this.form.mfa_code
            }
            

            this.login_loading = true;
            AuthService.login(
                credentials,
                Utils.get_device_id(),
                platform.os ? platform.os.family : 'unknown',
                platform.name,
                platform.version
            )
            .then(res => {
                // Success, the token is the response body (not json)
                const token = res.body;
                const redirect_url = Utils.parse_url_param("return_to")
                if (redirect_url) {
                    // Full redirect to the requested URL
                    TokenCache.store(token);
                    window.location.href = redirect_url;
                } else {
                    // Emit login event and proceed normally
                    bus.$emit(events.LOGIN_SUCCESSFUL, token);
                }
            })
            .catch(err_resp => {
                this.login_loading = false;
                const details = err_resp.body
                if(err_resp.status && err_resp.status == 403){
                    // 2FA required
                    this.show_mfa = true
                    this.mfa_mode = details.mfa_mode
                    this.mfa_sms_phone_number = details.phone_number
                }
                else if(err_resp.status && err_resp.status == 429){
                    // Wrong MFA code
                    this.mfa_cooldown = details.seconds_to_wait
                    this.login_btn_disabled = true
                    this.show_mfa = true
                    this.mfa_mode = details.mfa_mode
                    if(this.mfa_cooldown_interval){
                        clearInterval(this.mfa_cooldown_interval)
                    }

                    if(this.mfa_cooldown > 0){
                        this.error_msg = "Wrong code! To assure security of your account, you have to wait until you can try again!"
                        var self = this
                        self.mfa_cooldown_interval = setInterval(()=>{
                            if(self.mfa_cooldown == 0){
                                self.login_btn_disabled = false
                                clearInterval(self.mfa_cooldown_interval)
                                return
                            }
                            self.mfa_cooldown -= 1;
                        }, 1000)
                    }
                }
                else{
                    console.error(err_resp)
                    this.google_user = null
                    if (err_resp.body && err_resp.body.message) {
                        this.error_msg = "Login failed: " + err_resp.body.message;
                    } else {
                        this.error_msg = "Login failed";
                    }
                    // Error
                    bus.$emit(events.LOGIN_FAILED);
                }
            });
        },

        login_btn_state(){
            if(this.show_mfa){
                switch(this.mfa_mode){
                    case AuthService.MFA_MODE_APP:
                        this.login_btn_disabled = !(this.form.mfa_code && this.form.mfa_code.length == 6)
                        break;
                    case 'backup':
                        this.login_btn_disabled = !(this.form.mfa_backup_code && this.form.mfa_backup_code.length == 8)
                        break;
                    default: break

                }
            }
        },

        async google_sign_in(){

            if(!window.gapi){
                // Google Platform.JS should not be loaded, load on demand and wait until its done
                await Utils.loadScript(Utils.GOOGLE_PLATFORM_JS)
            }
            
            // Load and initialize Google API Platform authentication module
            window.gapi.load('auth2', async () => {
                window.gapi.auth2.init({
                    client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
                })
                var auth2 = window.gapi.auth2.getAuthInstance();
                const gUser = await auth2.signIn()
                this.login_google(gUser)
            })
        },

        login_google(googleUser){
            if(!googleUser){
                console.warning("Google user signed out")
                this.google_user = null;
                return
            }

            var profile = googleUser.getBasicProfile();
            this.google_user = {
                'name': profile.getName(),
                'email': profile.getEmail(),
                'image_url': profile.getImageUrl(),
                'id_token': googleUser.getAuthResponse().id_token
            }
            // At this point we can remove the google platformjs scipt
            Utils.unloadScript(Utils.GOOGLE_PLATFORM_JS)
            if(window.gapi){
                window.gapi = null
            }
            
            // Perform login
            this.login()
        }
  }
};
</script>

<style lang="css" scoped>
hr{
    border-color: rgba(255, 255, 255, 0.7)
}
</style>