import { bus, events } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'


// This is not exported
const base_url = process.env.VUE_APP_share_service_url

const ShareService = {

    SHARE_TYPE_FILE: 0,
    SHARE_TYPE_FOLDER: 1,

    SHARE_APP_PATH: "share",

    get_active_shares: function(){
        return bus.$http.get(base_url + "/shares", { headers: Utils.get_auth_header() })
    },

    create_file_share: function(file_ids, share_name, message, expire_days, is_psw_protected, download_limit){
        if(!Array.isArray(file_ids)){ file_ids = [ file_ids ] }

        const payload = {
            files: file_ids,
            type: ShareService.SHARE_TYPE_FILE,
            name: share_name,
            message: message,
            expire_days: expire_days,
            password_protected: is_psw_protected,
            download_limit: download_limit
        }

        return bus.$http.post(base_url + "/share", payload, { headers: Utils.get_auth_header() })
    },

    create_folder_share: function(folder_id, share_name, message, expire_days, is_psw_protected, download_limit){
        const payload = {
            folder: folder_id,
            type: ShareService.SHARE_TYPE_FOLDER,
            name: share_name,
            message: message,
            expire_days: expire_days,
            password_protected: is_psw_protected
        }

        return bus.$http.post(base_url + "/share", payload, { headers: Utils.get_auth_header() })
    },

    get_shares_num: function(ids){
        if(!Array.isArray(ids)){ ids = [ ids ] }
        const url_params = {
            fileids: ids.join(',')
        }
        return bus.$http.get(base_url + "/share/active_for_files", { params: url_params, headers: Utils.get_auth_header() })
    },

    get_shares_of_file: function(file_id){
        const url_params = {
            file_id: file_id
        }
        return bus.$http.get(base_url + "/share/list_active_shares_for_file", { params: url_params, headers: Utils.get_auth_header() })
    },

    add_file_to_share: function(share_id, ids){
        if(!Array.isArray(ids)){ ids = [ ids ] }

        const payload = {
            files: ids
        }

        return bus.$http.post(base_url + "share/file?shareid="+share_id, payload, { headers: Utils.get_auth_header() })
    },

    revoke_share: function(share_id){
        const url_params = {
            share_id: share_id
        }
        return bus.$http.get(base_url + "/share/revoke", { params: url_params, headers: Utils.get_auth_header() })
    },

    activate_revoked_share: function(share_id){
        const url_params = {
            share_id: share_id
        }
        return bus.$http.get(base_url + "/share/activate_revoked", { params: url_params, headers: Utils.get_auth_header() })
    },


    delete_share: function(share_id){
        return bus.$http.delete(base_url + "/share?shareid="+share_id, { headers: Utils.get_auth_header() })
    },

    get_share_by_key: function(share_key, password, with_locations){
        let url_params = {
            key: share_key,
            now: new Date().getTime(),

        }
        const headers = {
            "Authorization": "Password " + password
        }
        if(with_locations != undefined){
            url_params.with_locations = with_locations ? 1 : 0
        }
        return bus.$http.get(base_url + "/share/by_key", { params: url_params, headers: headers })
    },

    download_file_by_key: function(file_id, share_key, password){
        let url_params = {
            key: share_key,
            file_id: file_id,
            now: new Date().getTime()
        }
        const headers = {
            "Authorization": "Password " + password
        }
        return bus.$http.get(base_url + "/share/by_key", { params: url_params, headers: headers })
    },

    get_subfolder_by_key(share_key, password, subfolder_id){
        let url_params = {
            key: share_key,
            folder_id: subfolder_id,
            now: new Date().getTime()
        }
        const headers = {
            "Authorization": "Password " + password
        }
        return bus.$http.get(base_url + "/share/by_key", { params: url_params, headers: headers })
    }

}


export { ShareService }


/* **************************************************************************************** */
// Install HTTP interceptor for requests going to FileVersion to get the latest version with
// packet links. The file IDs received by Share App are obfuscated by Share Service,
// and only Share Service knows what was the original ID, and proxy the request to FileVersion.
/* **************************************************************************************** */

import Vue from 'vue'
import VueResource from 'vue-resource';
Vue.use(VueResource)

const share_key = Utils.parse_url_param('key')

const BASE_PATH = process.env.VUE_APP_BASE_PATH + '/'
const full_path = window.location.pathname
const relative_path = full_path.slice(BASE_PATH.length, full_path.length)

if(relative_path === ShareService.SHARE_APP_PATH && share_key && share_key.length > 0){
    // Share App

    const requests_to_redirect = [

        {
            /* Get download links to the latest version of the file */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "GET",
            service: "file_version",
            path: "download_latest"
        },
    ]

    Vue.http.interceptors.push( (request, next) => {
        const request_url = new URL(request.url)
        // Check if the request is one of the ones we want to redirect
        requests_to_redirect.forEach(rule => {
            const is_same_service = rule.base_url.toUpperCase() === request_url.origin.toUpperCase()
            const is_same_method = rule.method.toUpperCase() === request.method.toUpperCase()
            const is_same_endpoint = '/' + rule.path.toUpperCase() === request_url.pathname.toUpperCase()

            if(is_same_service && is_same_endpoint && is_same_method){
                if(rule.block){
                    request.url = false
                }
                else{
                    if(rule.service === 'file_version' && rule.path === 'download_latest'){
                        // Use ShareService endpoint instead
                        request.url = base_url + "/share/by_key?key="+share_key
                        // Add password
                        const share_psw = localStorage.getItem("share-" + share_key)
                        if(share_psw){
                            request.headers.set('Authorization', 'Password ' + localStorage["share-" + share_key])
                        }
                    }
                }
            }
        })
        if(!request.url){ return false }
        // return response callback
        next(response =>{
            if(response.status === 410){
                bus.$emit(events.SHARE_ARCHIVED, share_key)
            }
        });


    })
}