import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'

// This is not exported
const base_url = process.env.VUE_APP_billing_service_url

const BillingService = {

    get_skyflok_options(){
        return bus.$http.get(base_url + "/skyflok_pricing", { headers: Utils.get_auth_header() })
    }

}

export { BillingService }