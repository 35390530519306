<template>
<div v-if="user && !user.is_admin" class="container pt-5">
    <admin-warning-box></admin-warning-box>
</div>
<section v-else-if="user" class="section-container">
<div class="container container-lg">
    <div class="row">
        <div class="col-12">

            <div v-if="user.team_settings" class="mt-2 alert alert-info text-center">
                <h5>Join link to {{ user.team_settings.name }}:</h5>
                <div class="ml-2 text-bold" style="font-family: monospace; font-size: 1.5em"><a :href="user.team_settings | join_team_link" class="text-white" target="_BLANK">{{ user.team_settings | join_team_link }} <i class="ion-share text-white"></i></a></div>
                <i>New members can join if <span v-if="user.team_settings.email_domain">they have a <b>@{{ user.team_settings.email_domain }}</b> email address or </span>they are <router-link :to="'/team-settings'" class="text-white"><u>invited</u></router-link> by their email address. </i>
            </div>

            <div class="cardbox">
                <div class="cardbox-body">

                    <table class="table table-hover mb-0" v-if="user && team">
                        <thead>
                            <th></th>
                            <th>Name</th>
                            <th class="d-none d-sm-table-cell">Email address</th>
                            <!--<th>Status</th>-->
                            <th>Roles</th>
                            <th class="d-none d-sm-table-cell">Registered</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr v-for="member in team" :key="member.id" class="member-row">
                                <td style="width: 1px">
                                    <img class="thumb40 rounded fit-cover shadow-z3" :src="member.avatar_url" alt="user">
                                </td>
                                <td :class="{'text-bold': member.user_id === user.user_id}">{{ member.name }}<span v-if="member.user_id === user.user_id"> (me)</span><div v-if="member.job_title"><small class="text-muted">{{ member.job_title }}</small></div></td>

                                <!-- Email confirmed -->
                                <td class="d-none d-sm-table-cell" :class="{'text-muted text-it': !member.confirmed}">
                                    {{ member.email_address }}
                                    <div v-if="!member.confirmed">
                                        <small class="text-muted">
                                            <span class="mr-1">Not confirmed</span>
                                            <a href="#" v-if="!member.re_sending_email" @click="resend_email(member)">Re-send email</a>
                                            <i v-if="member.re_sending_email === resend_status.in_progress" class="ion-load-c spin"></i>
                                            <span v-if="member.re_sending_email > resend_status.in_progress" :class="{'text-success': member.re_sending_email == resend_status.success, 'text-danger': member.re_sending_email == resend_status.failed}">{{ member.re_sending_email === resend_status.success ? 'Email sent' : member.re_sending_email_err }}</span>
                                        </small>
                                    </div>
                                </td>

                                <!-- Suspended -->
                                <!--
                                <td style="min-width: 146px">
                                    <div class="view_status nowrap" :class="{'text-success': !member.is_suspended, 'text-danger': member.is_suspended}">
                                        {{ member.is_suspended ? 'Suspended' : 'Active' }}
                                        <i :class="{'ion-checkmark-round': !member.is_suspended, 'ion-alert-circled': member.is_suspended}" class="ml-1"></i>
                                    </div>
                                    <div class="edit_status">
                                        <button v-if="!member.is_suspended" class="btn btn-warning" @click="suspend_user(member, true)" :disabled="member.suspend_loading">
                                            <i class="ion-alert-circled mr-1 icon-lg"></i> Suspend
                                            <i v-if="member.suspend_loading" class="ion-load-c spin ml-1"></i>
                                        </button>
                                        <button v-else class="btn btn-success" @click="suspend_user(member, false)" :disabled="member.suspend_loading">
                                            <i class="ion-checkmark-round mr-1 icon-lg"></i> Activate
                                            <i v-if="member.suspend_loading" class="ion-load-c spin ml-1"></i>
                                        </button>
                                    </div>
                                    <div v-if="member.suspend_error" class="text-danger text-sm">{{member.suspend_error}}</div>
                                </td>
                                -->

                                <!-- Roles -->
                                <td>
                                    <div v-if="member.roles" class="text-sm">
                                        <span>
                                            <div v-for="role in member.roles" :key="role.role" :class="{'text-muted text-it': !role.has}" class="nowrap">
                                                {{ role.role | rolename }}
                                                <i v-if="role.has" :class="{'view_status': role.role !='EM'}" class="ion-checkmark-round ml-1"></i>

                                                <span class="edit_status">
                                                    <span v-if="role.has && role.role != 'EM'" :title="'Remove role'" >
                                                        <i :class="{'ion-load-c spin': role.loading, 'text-danger ion-minus-circled pointer': !role.loading}" class="icon-lg  ml-1 " @click="change_role(member, role)"></i>
                                                    </span>
                                                    <span v-if="!role.has" title="Add role" >
                                                        <i :class="{'ion-load-c spin': role.loading, 'text-success ion-plus-circled pointer': !role.loading}" class="icon-lg ml-1" @click="change_role(member, role)" :disabled="true"></i>
                                                    </span>
                                                </span>
                                            </div>
                                            <div v-if="member.change_role_error" class="text-danger text-sm">{{member.change_role_error}}</div>
                                        </span>

                                    </div>
                                    <i v-else class="ion-load-c spin"></i>
                                </td>
                                <td  class="d-none d-sm-table-cell">{{ member.created | timestamp_rel }} <i class="ion-calendar ml-1 text-muted" style="cursor: help" :title="member.created | timestamp"></i></td>
                                <td>
                                    <button class="btn btn-secondary" type="button" @click="view_member = member" data-toggle="modal" data-target="#member-details">
                                        Edit <i class="ion-edit ml-2"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="member-details">
    <div class="modal-dialog modal-lg" v-if="view_member">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title"><img class="thumb40 rounded fit-cover mr-2" style="border: 2px solid #ebebeb" :src="view_member | avatar_url" alt="user"><b>{{view_member.name}}</b></h5>
                <button class="close" type="button" @click="closeModal()" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">

                <edit-user-form :user="view_member" :close-form="closeModal" :edit-mode="true" :browse-image="false" :is-admin="user && user.is_admin"></edit-user-form>
                <hr>
                <!--
                <div class="d-flex justify-content-center align-items-center mt-2">
                    <small class="ml-3 text-warning">When you suspend someone, they are logged out and immediately lose access to all files and the SkyFlok applications. Their user .</small>
                    <button class="btn btn-warning btn-gradient" type="button" @click="suspend_user(view_member)">Suspend {{ view_member.name }}</button>

                </div>
                <hr>
                -->


                <div class="d-flex justify-content-center align-items-center mt-2">
                    <button class="btn btn-danger btn-gradient" type="button" @click="delete_user(view_member)" :disabled="view_member.delete_loading">Delete {{ view_member.name }}<i v-if="view_member.delete_loading" class="ion-load-c spin ml-2"></i></button>
                    <small v-if="!view_member.delete_error" class="ml-3 text-danger">
                        When you delete someone, they are logged out and immediately lose access to all files and the SkyFlok applications.
                        However, the files they already downloaded stay on their devices.
                    </small>
                    <div v-else class="ml-3 text-danger">{{ view_member.delete_error }}</div>
                </div>

                <!--
                <div class="d-flex mt-3" style="width: 60%; margin: 0px auto; justify-content: space-between">
                    <div>
                        <button class="btn btn-warning"><i class="ion-alert-circled mr-1 icon-lg"></i> Suspend</button>
                    </div>
                    <div>
                        <button class="btn btn-danger"><i class="ion-minus-circled mr-1 icon-lg"></i> Delete</button>
                    </div>
                    <div>
                        <button class="btn btn-success"><i class="ion-star mr-1 icon-lg"></i> Make Admin</button>
                    </div>
                </div>
                -->
            </div>
        </div>
    </div>
</div>
</section>
</template>

<script>

import { AuthService } from '@/services/auth-service.js'
import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'

import editUserForm from '@/components/edit-user-form.vue'
import adminWarningBox from '@/components/admin_feature_warning_box.vue'

export default {
    props: ["user"],
    components: {
        editUserForm,
        adminWarningBox
    },

    data() {
        return {
            team: null,
            initialized: false,
            view_member: null,
            search_str: "",

            resend_status: {
                in_progress: 1,
                success: 2,
                failed: 3
            }
        }
    },

    watch: {
        user: function(){
            if(this.user && this.user.team){ this.init() }
        },
    },


    created: function(){
        bus.$emit(events.RELOAD_TEAM)
        if(this.user && this.user.team){ this.init() }
    },



    methods: {

        closeModal: function(){
            $("#member-details").modal("hide")
            this.view_member = null;
        },

        init: function(){
            this.team = this.user.team
            let user_ids = []
            this.team.forEach(member => {
                // TODO remove this when it's returned by the backend
                if(member.confirmed === undefined){
                    this.$set(member, 'confirmed', true)
                }

                user_ids.push(member.user_id)
            }, this);

            if(user_ids.length > 0){
                AuthService.get_user_roles_batch(user_ids).then(res => {

                    res.body.forEach(u => {
                        let member = this.team.find(m => { return m.user_id === u.user_id })
                        if(member){
                            this.$set(member, 'roles', [])

                            member.roles.push({
                                role: AuthService.ROLE_TEAM_MEMBER,
                                has: (u.roles.indexOf(AuthService.ROLE_TEAM_MEMBER) >= 0)
                            })
                            member.roles.push({
                                role: AuthService.ROLE_TEAM_ADMIN,
                                has: (u.roles.indexOf(AuthService.ROLE_TEAM_ADMIN) >= 0)
                            })
                        }
                    })
                })
            }
        },

        suspend_user: function(member, new_value){
            this.$set(member, 'suspend_error', null)

            if(new_value && member.is_suspended){ return }
            if(!new_value && !member.is_suspended){ return }

            if(member.user_id == this.user.user_id){
                member.suspend_error = "Cannot suspend yourself"
                return;
            }

            this.$set(member, 'suspend_loading', true)
            setTimeout(function(){
                if(Math.random() < 0.6){
                    // Success:
                    member.suspend_loading = false
                    member.is_suspended = new_value
                    member.suspend_error = null
                }
                else{
                    // Error:
                    member.suspend_loading = false
                    member.suspend_error = "Failed to "+(new_value ? 'suspend' : 'activate')+" user"
                }
            }, 500)
            /*
            AuthService.suspend_user(member.user_id, new_value).then(res => {
                member.suspend_loading = false
                member.is_suspended = new_value
                member.suspend_error = null
            }).catch(err => {
                member.suspend_loading = false
                member.suspend_error = "Failed to "+(new_value ? 'suspend' : 'activate')+" user"
            })
            */
        },

        delete_user: function(member){
            if(member.user_id === this.user.user_id){
                if(!confirm('You are about to delete yourself. Are you sure?')){
                    return
                }
            }
            else{
                if(!confirm('You are about to delete a user from your team who will lose access immediately. Are you sure you want this?')){
                    return
                }
            }

            this.$set(member, 'delete_loading', true)
            this.$set(member, 'delete_error', false)

            AuthService.delete_user(member.user_id).then(() => {
                Utils.show_success(member.name + " deleted successfully")
                const idx = this.user.team.findIndex(m => { return m.user_id === member.user_id })
                if(idx < 0){return;}
                this.team.splice(idx, 1)
                this.closeModal()
                bus.$emit(events.RELOAD_TEAM)

            }).catch(err => {
                console.error(err)
                member.delete_loading = false
                member.delete_error = "Error deleting user: " + err.body.message
            })


        },

        change_role: function(member, role){
            this.$set(member, 'change_role_error', null)

            if(role.role === 'EM'){
                member.change_role_error = "'Member' role cannot be removed"
                return
            }
            if(member.user_id === this.user.user_id && role.role === 'OA' && role.has == true){
                // removing own admin role
                if(!confirm('You are about to remove your own Admin role. Are you sure you want this?')){
                    return
                }
                // Well okay then ¯\_(ツ)_/¯
            }

            this.$set(role, 'loading', true)

            let service_method = null
            if(role.has){
                // revoke
                service_method = AuthService.revoke_role(member.user_id, role.role)
            }
            else{
                // grant
                service_method = AuthService.grant_role(member.user_id, role.role)
            }

            service_method.then(() => {
                // Success
                role.loading = false
                member.change_role_error = null
                role.has = !role.has

                // Change 'is_admin' if it has been added or removed
                if(role.role === 'OA'){
                    bus.$emit(events.TEAM_MEMBER_PROFILE_CHANGED, { id: member.user_id, is_admin: role.has })
                }
            }).catch(err => {
                console.error(err)
                // Error:
                role.loading = false
                member.change_role_error = "Failed to "+(role.has ? 'remove' : 'add') + " " + this.$options.filters.rolename(role.role) + " role"
            })
            /*
            var self = this
            setTimeout(function(){
                if(Math.random() < 0.6){
                    // Success:
                    role.loading = false
                    member.change_role_error = null
                    role.has = !role.has
                }
                else{
                    // Error:
                    role.loading = false
                    member.change_role_error = "Failed to "+(role.has ? 'remove' : 'add') + " " + self.$options.filters.rolename(role.role) + " role"
                }
            }, 500)
            */

        },

        resend_email: function(member){
            this.$set(member, 're_sending_email', 1)
            AuthService.resend_confirmation_email(member.user_id, member.name, this.team ? this.team.name : null).then(() => {
                member.re_sending_email = this.resend_status.success
            }).catch(err => {
                member.re_sending_email = this.resend_status.failed
                this.$set(member, 're_sending_email_err', err.body.message)
            })
        }

    },

    filters: {
        roles_arr: function(roles){
            let arr = []
            roles.forEach(r => {
                if(r.has){
                    switch(r.role){
                        case AuthService.ROLE_TEAM_MEMBER:
                            arr.push("Member")
                            break;
                        case AuthService.ROLE_TEAM_ADMIN:
                            arr.push("Admin")
                            break;
                        default: break
                    }
                }
            })

            return arr.join(", ")
        },

        rolename: function(role_code){
            switch(role_code){
                case AuthService.ROLE_TEAM_MEMBER:
                    return "Member"
                case AuthService.ROLE_TEAM_ADMIN:
                    return "Admin"
                default: return ""
            }
        }
    }

}
</script>

<style scoped>
    .text-it{ font-style: italic }

    thead th{
        border-top: none
    }

    .settings-table th{
        text-align: right
    }
    .settings-table tr:first-child th,
    .settings-table tr:first-child td{
        border-top: none
    }


    .member-row .view_status,
    .member-row:hover .edit_status{
        display: initial;
    }

    .member-row:hover .view_status,
    .member-row .edit_status{
        display: none;
    }

    .pointer{ cursor: pointer }

</style>
