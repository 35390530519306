<template>
    <section class="section-container">
        <div class="container-overlap bg-gradient-success text-center">
            <div class="mb-3"></div>
            <div class="text-white">
                <div class="text-lg">
                    <i class="ion-bug"></i>
                </div>
                <!--<div class="h3">Bug Report</div>-->
                <div class="h3">Sorry for your inconvenience, please tell us what happened!</div>

            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-12 col-lg-8">
                    <div class="cardbox">
                        <div class="cardbox-body">
                            <div v-if="form_sent">
                                <h2 class="text-center mt-4 mb-4">Your report has been sent successfully!</h2>
                            </div>

                            <form v-else v-on:submit.prevent="submit_form" class="mt-3" action="">
                                <div class="form-group">
                                    <label>What went wrong?</label>
                                    <div class="input-group">
                                        <textarea class="form-control fw" id="bugreport_text" style="height: 14em" v-model="form.text"></textarea>
                                    </div>
                                    <div class="text-right">
                                        <small>Please describe the problem with as much detail as you can!</small>
                                    </div>
                                </div>
                                <div class="form-group">

                                    <div class="flex" v-if="user && user.team_settings">
                                        <label class="mr-2">Reported by:</label>
                                        <img :src="user.avatar_url" class="shadow-z2 thumb32 rounded fit-cover">
                                        <span>{{ user.name }}, {{ user.team_settings.name }}</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <checkbox 
                                        :value="form.response_requested" 
                                        label="I would like to be contacted about this issue" 
                                        @changed="newval => {form.response_requested = newval}" 
                                    />
                                    
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-success" :disabled="form_loading" type="submit">Send bug report!<i v-if="form_loading" class="ion-load-c spin ml-2"></i></button>
                                    <div class="text-danger" v-if="error">{{ error }}</div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { StorageBackendService } from '@/services/storage_backend-service.js'
import checkbox from '@/components/checkbox.vue'

export default {
    props: ['user'],

    components: {
        checkbox
    },

    data: function(){
        return {
            form: {
                text: "",
                response_requested: false
            },
            form_loading: false,
            form_sent: false,
            error: false
        }
    },

    mounted: function(){
        setTimeout(function(){
            document.getElementById("bugreport_text").focus()
        }, 200)
    },

    methods: {
        submit_form: function(){

            if(this.form.text == ""){
                this.error = "Please write what happened!"
                document.getElementById("bugreport_text").focus()
                return
            }

            this.form_loading = true
            StorageBackendService.send_bugreport(this.form.text, this.form.response_requested)
                .then(res => {
                    this.form_loading = false
                    this.error = false

                    if(!res.ok){ console.error("Failed to send bugreport: HTTP "+res.status, res.body); return; }
                    this.form_sent = true
                })
                .catch(err => {
                    this.form_loading = false
                    this.form_sent = false
                    this.error = "Error saving bug report, please try again later."
                    console.error(err)
                })
        }
    }
}
</script>

<style scoped>
 .custom-control-indicator{ border: 1px solid green }
</style>
