<template>
<div class="layout-container" style="overflow: overlay" :class="{'bg-gradient-danger': error !== false, 'bg-gradient-secondary': error === false}">
    <div class="page-container">
        <div class="container-fluid">
            <div class="row">

                <file-preview
                    class="col-12 text-center" v-if="blob_url"
                    :blob_url="blob_url"
                    :file_name="file_name"
                    :mime_type="mime_type"
                    :viewer_name="viewer_name">
                </file-preview>
                <div v-else>
                    No file
                </div>

            </div>
        </div>
    </div>
</div>
</template>
<script>

import filePreview from './file-preview.vue'
import { Utils } from '@/helpers/utils.js'

export default {
    components: { filePreview },

    data(){
        return {
            blob_url: null,
            viewer_name: null,
            file_name: null,
            mime_type: null,

            error: false
        }
    },

    created(){
        this.blob_url = Utils.parse_url_param('url', false, true)
        this.viewer_name = Utils.parse_url_param('viewer', false, false)
        this.file_name = Utils.parse_url_param('name', false, true)
        this.mime_type = Utils.parse_url_param('type', false, true)
    }

}
</script>

