<template>
    <section class="text-center">
        <div v-if="mfa_cooldown_seconds && mfa_cooldown_seconds > 0">
            {{ mfa_cooldown_seconds }} seconds to go
        </div>
        <section v-if="mfa_mode == MFA_MODE_APP">
            <div class="my-3 text-center d-flex align-items-center justify-content-center">
                <i class="ion-iphone mr-2 text-md"></i>
                <div class="mr-2">Authentication Code:</div>
                <input v-model="code" id="mfa_code" class="form-control form-control-inverse" style="width: 160px" type="number" min="0" max="999999" maxlength="6" required placeholder="123456">
            </div>
            <div class="mt-2">
                <i class="ion-information-circled mr-1"></i>
                Copy the 6-digit code from your Authenticator mobile app for "{{ user_email ? 'SkyFlok, '+user_email : "SkyFlok" }}"
            </div>
        </section>
        <section v-else-if="mfa_mode == MFA_MODE_SMS">
            <div class="m-2 text-12">
                Copy the code that you received in SMS to <span class="nowrap">{{ sms_phone_number }}</span>
            </div>
            <div class="my-3 text-center d-flex align-items-center justify-content-center">
                <i class="ion-chatbox-working mr-2 text-md"></i>
                <div class="mr-2">SMS Code:</div>
                <input v-model="code" id="mfa_code" class="form-control form-control-inverse" style="width: 160px" type="number" maxlength="6" required placeholder="123456" autocomplete="one-time-code">
            </div>
        </section>
        <section v-else-if="mfa_mode == 'backup'">
            <div class="my-3 text-center d-flex align-items-center justify-content-center">
                <div class="mr-2">Backup Code:</div>
                <input v-model="code" id="mfa_code" class="form-control form-control-inverse" style="width: 160px" type="text" required placeholder="aa11bb22">
            </div>
            <div class="fw text-center mt-3" >
                <a href="#" class="text-white" @click.prevent="()=>{ $emit('change_mfa_mode', original_mfa_mode) }">
                    <i class="ion-arrow-left-c mr-2"></i> Back to 
                    <span v-if="original_mfa_mode==MFA_MODE_APP">authentication code</span>
                    <span v-else-if="original_mfa_mode==MFA_MODE_SMS">SMS code</span>
                </a>
            </div>
        </section>

        <div v-if="mfa_mode != 'backup'" class="mt-4">
            <a href="#" @click.prevent="$emit('change_mfa_mode', 'backup')" class="text-white text-underline">Use a backup code instead</a>
        </div>
    </section>
</template>

<script>
import { AuthService } from '@/services/auth-service'

export default {
    props: ["mfa_mode", "user_email", "sms_phone_number", "mfa_cooldown_seconds"],
    data(){
        return {
            code: "",
            MFA_MODE_APP: AuthService.MFA_MODE_APP,
            MFA_MODE_SMS: AuthService.MFA_MODE_SMS,
            original_mfa_mode: null
        }
    },

    watch: {
        code(){
            this.$emit('code_changed', this.code)
            
        },

        
    },

    created(){
        this.original_mfa_mode = this.mfa_mode
    }
}
</script>
