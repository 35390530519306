<template>
<div class="layout-container">
	<div class="page-container">
        <div class="d-flex align-items-center align-items-center-ie">
            <div class="fw container container-sm">
                <div class="cardbox text-bold">
                    <div class="pb-1 bg-gradient-info"></div>
                    <div class="p-4">

                    <div class="cardbox-heading">
                        <div class="cardbox-title text-center"><a href="https://skyflok.com" target="_blank"><img src="@/assets/img/skyflok_logo_black.png" style="max-height: 40px" class="mr-2"></a></div>
                    </div>

                    <div class="cardbox-body" v-if="!is_success">

                        <div v-if="error" class="alert alert-danger mt-4" v-html="error"></div>

                        <div v-if="team_info">
                            <h2 class="text-center mb-4">
                                {{ is_pending_registration ? 'Welcome to' : 'Join'}} <b>{{ team_info.name }}</b>
                                <img v-if="team_info.logo_url" :src="team_info.logo_url" style="max-height: 50px" class="ml-1" :alt="team_info.name" />
                            </h2>

                            <form v-if="form" @submit.prevent="submit_form">
                                <label for="u_name">Your Name</label>
                                <input class="form-control" id="u_name" v-model="form.name" type="text" autocomplete="name" maxlength="255"/>

                                <label for="u_email">Email Address</label>
                                <div v-if="form.email_readonly">
                                    <i class="ion-email mr-2"></i><span class="text-bold">{{ form.email }}</span> <span class="badge text-success ml-2" style="background: white">Confirmed <i class="ion-checkmark-circled text-success ml-1"></i></span>
                                </div>
                                <span v-else>
                                    <input class="form-control" id="u_email" v-model="form.email" type="email" :disabled="form.email_readonly" autocomplete="username email" />
                                    <small v-if="email_suggested">Did you mean <a href="#" class="text-underline  text-bold" @click.prevent="form.email = email_suggested">{{ email_suggested }}</a>?</small>
                                </span>
                                
                                <div v-if="is_pending_registration && form.has_password && !show_password_section" class="mt-4">
                                    <div>You already have a password, but you can change it now, if you want.</div>
                                    <div class="fw text-center mt-2">
                                        <button class="btn btn-secondary" @click="show_password_section=true" type="button">Change my password</button>
                                    </div>
                                </div>
                                
                                <section v-if="!form.has_password || show_password_section">
                                    <label for="u_password">Password</label>
                                    <input class="form-control" id="u_password" v-model="form.password" type="password" autocomplete="new-password" placeholder="At least 8 characters" />
                                </section>

                                
                                <div class="form-check d-flex align-items-start justify-content-center flex-column gap-4">
                                    <input class="form-check-input" type="checkbox" v-model="form.terms_accepted" id="checkbox-terms-accepted" />
                                    <label class="form-check-label pl-2" for="checkbox-terms-accepted">I understand that <b>{{ team_info.name }}</b> accepted the
                                        <a href="https://www.skyflok.com/legal" target="_BLANK" rel="noopener">Terms of Service and Privacy Policy</a>
                                        of SkyFlok, which applies to how my personal data is handled.
                                    </label>
                                </div>

                                <div class="text-center mt-4">

                                    <button type="submit" :disabled="form_loading" class="btn btn-lg text-bold bg-skyflok text-white text-12" style="width: 50%">
                                        
                                        <span v-if="is_pending_registration">Activate your account</span>
                                        <span v-else>Join {{ team_info.name }}</span>

                                        <i v-if="form_loading" class="ion-load-c ml-2 spin"></i>
                                    </button>
                                </div>
                            </form>
                            <!--<pre>{{ JSON.stringify(form, null, 2) }}</pre>-->
                        </div>
                        <div v-else-if="!error" class="text-center">
                            <i class="ion-load-c spin mr-2"></i>Loading, hang on..
                        </div>
                    </div>

                    <!-- Success -->
                    <div class="cardbox-body pt-0 text-center" v-else>
                        <section v-if="!redirect_to_login">
                        <img 
                            src="@/assets/img/illustrations/mail_sent-success.svg" 
                            class="width-25p my-4" :class="{'fw': Utils.is_mobile()}" 
                        />
                        <div class="text-14 px-5">
                            <div class="text-success mb-2">Success!</div>
                            Please activate your new SkyFlok account with the link we have sent in email.
                        </div>
                        </section>
                        <div v-else class="text-14 text-success">Success!<br/>Redirecting to login...</div>
                    </div>

                    </div>
                    <reg-footer></reg-footer>
                    <div class="pb-1 bg-gradient-info"></div>
                </div>
            </div>
        </div>
	</div>
</div>
</template>
<script>

import { Utils } from '@/helpers/utils.js'
import { UserService } from '@/services/user-service.js'
import { AuthService } from '@/services/auth-service.js'

import regFooter from './reg_wizard_footer.vue'
import { email_domain_typos } from '@/assets/static/email_domain_typos.js'

export default {

    components: { regFooter },
    
    data(){
        return {
            Utils: Utils,

            is_success: false,
            error: null,
            team_info: null,
            join_key: null,
            
            is_pending_registration: false,
            show_password_section: false,

            password_generated: false,

            form: null,
            input_elements: null,
            form_loading: false,
            email_suggested: null,
            redirect_to_login: false
        }
    },

    watch: {
        'form.email': function(){
            let suggestion_found = false
            if(this.form.email.length == 0){ return }
            for(var i=0 ; i<email_domain_typos.length ; ++i){
                const record = email_domain_typos[i]

                const typo_found = record.typos
                    .filter(typo => { return this.form.email.endsWith('@'+typo) })

                if(typo_found.length > 0){
                    this.email_suggested = this.form.email.replace(typo_found[0], record.correct)
                    suggestion_found = true
                    break
                }
            }

            if(!suggestion_found){
                this.email_suggested = null
            }
        },

        team_info(){
            if(!this.team_info){ return }
            
            this.$nextTick(()=>{
                // When the Team Info is loaded and the form is displayed, collect refs to input fields 
                this.input_elements = {
                    name: document.getElementById("u_name"),
                    email: document.getElementById("u_email"),
                    password: document.getElementById("u_password"),
                }
                // ...and focus the first one 
                this.input_elements.name.focus()
            })
        },

        show_password_section(){
            if(this.show_password_section){
                this.$nextTick(()=>{
                    this.input_elements.password.focus()
                })
            }
        }
    },

    computed: {

        form_submit_enabled(){
            if(!this.form){ return false }
            return this.form.name.length > 0 &&
                Utils.is_valid_email(this.form.email) &&
                this.form.password.length >= 8 &&
                this.form.terms_accepted === true
        }
    },

    created(){
        
        const join_key = Utils.parse_url_param('key', false)
        const challenge = Utils.parse_url_param('invite', false)
        
        if(!join_key && !challenge) {
            this.error = "Something is missing from the URL!<br/>Please try to copy-paste the whole URL to the address bar manually."; 
            return; 
        }

        if(join_key){
            // Simple join form, load only the basic team info (name, logo)
            this.join_key = join_key
            this.get_team_info()
        }
        else if(challenge){
            // Existing user (created by a Reseller) confirming their email address, 
            // about to choose password and/or accept terms. 
            this.challenge = challenge
            this.get_pending_registration()
        }
    },


    methods: {

        submit_form(){

            this.error = null

			// Mandatory: name, email, password
			if(!this.form.name || this.form.name == ''){
                this.error = "Please fill in your name!"
                this.input_elements.name.focus()
				return false
            }
            if(!this.form.email_readonly){

                if(this.form.email.length == 0){
                    this.error = "E-mail is empty!"
                    this.input_elements.email.focus()
                    return false
                }
                if(!Utils.is_valid_email(this.form.email)){
                    this.error = "Your e-mail address doesn't look okay, please check!"
                    this.input_elements.email.focus()
                }
            }

            if(!this.form.has_password){

                if(this.form.password.length == 0){
                    this.error = "Password is empty!"
                    this.input_elements.password.focus()
                    return false
                }
                if(this.form.password.length < 8){
                    this.error = "Password too short!"
                    this.input_elements.password.focus()
                    return false;
                }
            }

            if(this.form.terms_accepted !== true){
                this.error = "You have to accept the Terms and Conditions!"
				return false;
            }

            this.form_loading = true
            this.error = null

            if(this.is_pending_registration){
                // This is an existing user who is accepting the terms and/or setting password now. Call AuthService
                let payload = {
                    'name': this.form.name,
                    'challenge': this.challenge,
                    'terms_accepted': this.form.terms_accepted,
                    'newsletter': this.form.newsletter
                }
                // Add password if the user set it now
                if(!this.form.has_password){
                    payload['password'] = this.form.password
                }
                AuthService.confirm_invited_registration(payload).then(() => {
                    this.redirect_to_login = true
                    this.form_loading = false
                    this.is_success = true

                    var self = this;
                    setTimeout(()=>{
                        self.$router.push('/login?email='+window.encodeURIComponent(this.form.email))
                    }, 2000)
                }).catch(err => {
                    console.error(err)

                    this.form_loading = false
                    this.error = "Failed to activate your account!"
                })
            }
            else{

                // Non-user just opened the join link, call UserService
                UserService.register_to_team(this.join_key, this.form).then(() => {
                    this.is_success = true
                    this.form_loading = false

                }).catch(err => {
                    console.error(err);
                    this.form_loading = false
                    this.error = "We couldn't create your user account. Please talk to the person who invited you"
                })
            }
			
        },

        get_team_info(){

            UserService.get_team_info(this.join_key).then(res => {
                this.team_info = res.data

                this.form = {
                    name: Utils.parse_url_param('name', ""),
                    email: Utils.parse_url_param('email', ""),
                    password: "",
                    terms_accepted: false,
                    newsletter: false
                }

            }).catch(err => {
                this.error = "Wrong join key!"
                console.error(err)
            })
        },

        get_pending_registration(){
            if(!this.challenge){
                console.error("get_pending_registration called without challenge")
                return
            }
            // Load team and user info
            AuthService.get_pending_registration(this.challenge).then(res => {
                const info = res.body 
                this.is_pending_registration = true
                
                // We received all the info about the team too
                if(info.team_info && info.team_info.name){
                    this.team_info = info.team_info
                }
                else{
                    console.error("No team info received!", info)
                }

                // publish form
                this.form = {
                    name: info.user_name,
                    email: info.user_email,
                    email_readonly: true,
                    password: "",
                    has_password: info.has_password,
                    terms_accepted: info.terms_accepted,
                    terms_accepted_readonly: info.terms_accepted,
                    newsletter: false,
                    user_roles: info.roles
                }

            }).catch(err => {
                console.log(err)
                if(err && err.status && err.status === 404){
                    this.error = "Invite not found! This might be because your account is already active."
                }
                else{
                    this.error = "There was an error loading your invite! Our team has been notified about the error, please try again later or send us an email at support@skyflok.com"
                }
            })
        }
    }
}
</script>
<style scoped>
label:not(.custom-control){
    font-size: 1em;
    margin-bottom: 3px;
    padding-top: 1em
}
</style>
