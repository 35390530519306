<template>
    <div v-if="!qr_url">
        Setting up... <i class="ion-load-c spin ml-2"></i>
    </div>
    <show-verify-qr v-else-if="!verified" :url="qr_url" @verified="()=>{ verified = true }"/>
    <div v-else>
        <mfa-recovery-codes :recovery_codes="backup_codes" :is_setup="true" :user_email="user_email" class="my-3"/>
        <div class="fw text-center-mt-4">
            <button class="btn btn-success" @click="()=>{ $emit('completed') }">Finish setup</button>
        </div>
    </div>
</template>
<script>

import showVerifyQr from './show_verify_qrcode.vue'
import mfaRecoveryCodes from './recovery_codes.vue'
import { AuthService } from '@/services/auth-service'


export default {
    props: ["user_email", 'show_error'],

    components: {
        showVerifyQr,
        mfaRecoveryCodes
    },

    data(){
        return {
            qr_url: null,
            verified: false,
            backup_codes: null,
        }
    },

    mounted(){
        this.setup_2fa_app()
    },

    methods: {

        setup_2fa_app(){
            const setup_2fa_config = {
                kind: AuthService.MFA_MODE_APP
            }

            AuthService.setup_2fa(setup_2fa_config).then(res => {
                this.qr_url = res.body.url
                this.backup_codes = res.body.recovery_codes
                
            }).catch(err => {
                console.error(err)
                this.show_error(err.body.message)
            })
        }
    }
}
</script>