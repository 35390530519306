<template>
    <section v-if="locations && locations.length > 0">
        <div
            v-if="!disable_popover"
            class="d-inline-block text-center fw c-help"
            data-toggle="popover"
            data-html="true"
            data-title="Data Location"
            data-trigger="hover"
            :data-placement="placement || 'right'"
            :data-content="popover_content">

            <img 
                v-for="location in locations_sorted" :key="location.id" 
                :src="Utils.flag_img_src(location.countrycode)" 
                class="mx-1"
                :class="{'thumb32 rounded-circle rounded-circle img-thumbnail': circles}" />

        </div>

        <div v-else @click="open_modal()" class="fw text-center c-pointer">
            <img
                v-for="location in locations_sorted"
                :key="location.id"
                :src="Utils.flag_img_src(location.countrycode)"
                class="mx-1"
                :class="{'thumb32 rounded-circle rounded-circle img-thumbnail': circles}"
                />

            <div class="modal" id="data-locations-modal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header bg-gradient-info text-white">
                            <h5 class="mt-0 modal-title text-bold">Data location</h5>
                            <button class="close" type="button" @click="close_modal('data-locations-modal')" aria-label="Close"><span>&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <locations-content :teamName="teamName" :textStart="textStart" :locations="locations"></locations-content>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>
</template>
<script>
import locationsContent from './locations_popover_content.vue'
import { Utils } from '@/helpers/utils';
export default {
    props: ["locations", "teamName", "placement", "circles", "textStart", "disable_popover"],
    components: {
        locationsContent
    },

    data(){
        return {
            popover_content: '',
            Utils: Utils
        }
    },

    computed: {
        locations_sorted(){
            if(!this.locations) return []
            return Utils.sort_objects(this.locations, 'name')
        }
    },

    mounted(){
        if(!this.locations){ return }
        
        this.popover_content = "<div id='data-location-flags-container'>"
        if(this.textStart){
            this.popover_content += this.textStart
        }
        else{
            this.popover_content += this.teamName ? "Every file in this shared folder is encrypted and distributed in the following locations, selected by the administrators of "+this.teamName+":" : "<b>Your files are distributed in "+this.locations.length+" different locations:</b><br/>"
        }

        this.popover_content += "<ul style='list-style-type: none'>"
        this.locations_sorted.forEach(loc => {
            this.popover_content += "<li><img src='"+Utils.flag_img_src(loc.countrycode)+"' class='mr-1' />"+loc.name+", "+loc.country+"</li>"
        })
        this.popover_content += "</ul>"

        this.popover_content += this.teamName ? "SkyFlok's unique patented technology allows you to choose where your data is stored. Keep it close to you or spread it across the globe, it’s up to you.<br/><br/>Visit <b>skyflok.com</b> to learn more and try SkyFlok for free!" : "<i class='text-sm'>These locations were selected by your Team's Administrators</i>"
        this.popover_content += "</div>"

        if(this.disable_popover !== false){
            this.$nextTick(() => {
                $('[data-toggle="popover"]').popover();
                $('[data-toggle="popover"]').on('inserted.bs.popover', function(){
                    document.querySelector(".popover").style.maxWidth = "400px"
                })
            })
        }
    },

    methods: {

        open_modal(){
            document.body.appendChild($("#data-locations-modal")[0])
            $("#data-locations-modal").modal('show')
        },

        close_modal(){
            $("#data-locations-modal").modal('hide')
        },
    }
}
</script>
<style scoped>
#popover-content{
    display: none
}

</style>
