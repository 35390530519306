<template>
<section class="section-container">
<div v-if="client_spaces_disabled" class="container">
    <upgrade-warning-box :feature="'Client Spaces'"></upgrade-warning-box>
</div>
<div v-else class="container-fluid">

    <!-- Description -->
    <div class="row" v-if="false">
        <div class="col-2"></div>
        <div class="col-12 col-lg-8">
            <div class="cardbox text-white bg-gradient-success b0">
                <div class="cardbox-body text-center text-12">
                    Client Spaces are <b>secure, confidential shared folders</b> between you and your clients, where
                    both of you can upload and download files.
                    It's a simple but very <b>secure alternative of emailing files to each other</b>, and you don't even need to
                    make your clients Team Members.
                </div>
            </div>
        </div>
    </div>

    <div v-if="user && spaces" class="fw">

        <h3 class="fw d-flex justify-content-between" >
            <div>
                <span>Active Spaces</span>
                <small>
                    ({{active_spaces_num}}<span v-if="active_spaces_limit && active_spaces_num >= active_spaces_limit"> out of {{ active_spaces_limit }}<i v-if="active_spaces.length >= active_spaces_limit" class="ml-1 icon ion-alert-circled text-warning" title="You reached the limit of active Client Spaces. Upgrade your Team to use more Spaces!"></i></span>)
                    <a href="#" @click.prevent="load_spaces()" class="ml-1 text-inherit"><i class="ion-refresh" :class="{'spin': spaces_loading}"></i></a>
                </small>
            </div>

            <div class="d-flex align-items-center justify-content-center gap-3">
                <!-- View selector (table/grid) -->
                <div>
                    <button class="btn btn-pill-left" :class="{'btn-secondary': view_mode != 'grid', 'btn-gradient btn-secondary': view_mode == 'grid'}" @click="view_mode = 'grid'">
                        Grid <i class="ion-grid ml-1" />
                    </button>
                    <button class="btn btn-pill-right"  :class="{'btn-secondary': view_mode != 'table', 'btn-gradient btn-secondary': view_mode == 'table'}" @click="view_mode = 'table'">
                        Table <i class="ion-navicon ml-1" />
                    </button>
                </div>

                <button v-if="user.is_admin" class="btn btn-info btn-gradient ion-person-stalker" @click="show_edit_managers_modal=true; open_dialog('edit_managers', null)">
                    <span class="d-none d-sm-inline-block ml-2">Edit Managers</span>
                </button>

                <!-- Create Space button -->
                <button class="btn btn-primary btn-gradient ion-plus-round" @click="create_space()" :disabled="active_spaces_limit && active_spaces_num >= active_spaces_limit" :title="(active_spaces_limit && active_spaces.length >= active_spaces_limit) ? 'Upgrade your Team for more active Client Spaces!' : ''">
                    <span class="d-none d-sm-inline-block ml-2">Create Space</span>
                </button>
            </div>
        </h3>

        <div class="row mt-3" v-if="active_spaces_num > 0 && view_mode === 'grid'">
            <client-space-box
                v-for="space in active_spaces"
                :key="space.id"
                :space="space"
                :user="user"
                @edit="() => { edit_space(space) }"
                :watch_clicked="() => { change_watch(space) }"
                :reload_spaces_callback="() => { load_spaces() }"
                @open_dialog="(dialog_id) => { open_dialog(dialog_id, space) }"
                >
            </client-space-box>

            <div class="col-12 col-sm-6 col-md-4 col-xl-3 text-center">
                <div class="p-5 add_space_btn text-gray-light c-pointer" @click="create_space()">
                    <i class="ion-plus-round icon-3x"></i>
                    <h5 class="mt-2 text-bold">Add Space</h5>
                </div>
            </div>
        </div>

        <div class="row mt-3" v-if="active_spaces_num > 0 && view_mode === 'table'">
            <div class="cardbox fw mx-3">

                <!-- Expired invites warning -->
                <div class="cardbox-body" v-if="active_spaces_with_expired_invite.length > 0 && !dismiss_expired_invites_alert">
                    <div class="fw text-center">
                        <div class="alert alert-warning d-inline-block text-bold">
                            There {{ active_spaces_with_expired_invite.length === 1 ? 'is' : 'are' }} {{ active_spaces_with_expired_invite.length }} space{{ active_spaces_with_expired_invite.length === 1 ? '' : 's' }} with expired invite!
                            <button class="btn btn-sm btn-secondary ml-2 text-sm" @click.prevent="show_expired_invites()">Show</button>
                            <a href="#" class="ml-2 text-normal text-white text-sm" @click.prevent="dismiss_expired_invites_alert = true"><i class="ion-close-round"></i></a>
                        </div>
                    </div>
                </div>

                <div class="cardbox-body">

                    <!-- Filters -->
                    <div class="filters mb-2" v-if="show_filters">

                        <div class="filter">
                            <label class="filter_name" for="filter_client_name">Client Name</label>
                            <input type="text" class="form-control form-control-sm" id="filter_client_name" v-model="table_filter.client_name" style="width: 150px"/>
                        </div>

                        <div class="filter">
                            <label class="filter_name" for="filter_email">E-mail address</label>
                            <input type="text" class="form-control form-control-sm" id="filter_email" v-model="table_filter.email" style="width: 150px"/>
                        </div>

                        <!-- TODO add managers filter -->

                        <div class="filter">
                            <label class="filter_name" for="">Client Joined</label>
                            <checkbox label="Yes" :value="table_filter.joined_yes" @changed="newval => table_filter.joined_yes=newval"/>
                            <checkbox label="No" :value="table_filter.joined_no" @changed="newval => table_filter.joined_no=newval"/>
                        </div>

                        <div class="filter">
                            <label class="filter_name" for="">Invite Sent</label>
                            <checkbox label="Yes" :value="table_filter.invite_sent_yes" @changed="newval => table_filter.invite_sent_yes=newval"/>
                            <checkbox label="No" :value="table_filter.invite_sent_no" @changed="newval => table_filter.invite_sent_no=newval"/>
                        </div>

                        <div class="filter">
                            <label class="filter_name" for="">Invite Expired</label>
                            <checkbox label="Yes" :value="table_filter.invite_expired_yes" @changed="newval => table_filter.invite_expired_yes=newval"/>
                            <checkbox label="No" :value="table_filter.invite_expired_no" @changed="newval => table_filter.invite_expired_no=newval"/>
                        </div>

                        <div class="filter">
                            <label class="filter_name" for="">Client Role</label>
                            <checkbox label="Full Control" :value="table_filter.role_full_control" @changed="newval => table_filter.role_full_control=newval"/>
                            <checkbox label="Download Only" :value="table_filter.role_readonly" @changed="newval => table_filter.role_readonly=newval"/>
                        </div>

                        <div class="filter">
                            <label class="filter_name" for="">Two-Factor Auth</label>
                            <checkbox label="Yes" :value="table_filter.mfa_enabled_yes" @changed="newval => table_filter.mfa_enabled_yes=newval"/>
                            <checkbox label="No" :value="table_filter.mfa_enabled_no" @changed="newval => table_filter.mfa_enabled_no=newval"/>
                        </div>


                        <div class="filter">
                            <label class="filter_name" for="">Files</label>
                            <checkbox label="Empty" :value="table_filter.files_empty" @changed="newval => table_filter.files_empty=newval"/>
                            <checkbox label="Not Empty" :value="table_filter.files_not_empty" @changed="newval => table_filter.files_not_empty=newval"/>
                        </div>

                    </div>

                    <div v-if="is_search" class="text-dark">
                        <i class="ion-funnel mr-1"></i><small>Showing <b>{{ active_spaces_filtered.length }} of {{ active_spaces.length }}</b> spaces</small>
                    </div>

                    <!-- Multiselect actions -->
                    <div v-if="checked_spaces && checked_spaces.length > 0" class="card card-info text-white d-inline-block p-3">
                            <div class="mb-2">
                                <b>{{ checked_spaces.length }} space{{ checked_spaces.length === 1 ? '' : 's' }} selected </b>
                                <a title="Clear selection" class="ion-close-round ml-2 text-white" href="#" @click="()=>{ active_spaces.forEach(s => { s.checked = false }); table_check_all=false }"></a>
                            </div>

                            <button
                                class="btn btn-secondary text-bold mr-2"
                                id="resend_invite_batch_btn"
                                data-toggle="popover" data-animation="false" data-trigger="hover" data-html="true" data-placement="top"
                                :disabled="resend_invite_batch_loading || checked_spaces_no_password.length === 0"
                                :data-content="'Send an invite email to the selected spaces where the client has not joined yet ('+(checked_spaces_no_password.length)+' out of '+checked_spaces.length+' selected spaces)'"
                                @click="resend_invite_batch(checked_spaces_no_password)">

                                Re-send Invite
                                <small class="">({{(checked_spaces_no_password.length)}}/{{checked_spaces.length}})</small>
                                <i class="hover-available ion-information-circled ml-1"></i>
                                <i class="ion-load-c spin ml-1" v-if="resend_invite_batch_loading"></i>
                            </button>

                            <!-- TODO bulk actions
                            <button class="btn btn-secondary text-bold mr-2"  title="Coming soon!">Set Client Role</button>
                            <button class="btn btn-secondary text-bold mr-2"  title="Coming soon!">Set Managers</button>
                            <button class="btn btn-secondary text-bold mr-2"  title="Coming soon!">Archive</button>
                            -->

                            <button
                                class="btn btn-secondary text-bold mr-2"
                                @click="multiselect_csv_export(checked_spaces)"
                                :disabled="multiselect_csv_export_loading">
                                Export to CSV
                                <i class="ion-load-c spin ml-1" v-if="multiselect_csv_export_loading"></i>
                            </button>


                    </div>

                    <table class="table table-hover" id="spaces_table" :class="{'any_checked': checked_spaces && checked_spaces.length > 0 }">
                        <thead>
                            <!-- Multiselect -->
                            <th
                                class="d-none d-sm-table-cell"
                                @click="table_check_all = !table_check_all"
                                title="Select or deselect all displayed spaces">
                                <checkbox :value="table_check_all" @changed="newval => table_check_all = newval" />
                                
                            </th>

                            <th class="width-0 d-none d-sm-table-cell"></th>
                            <th class="c-pointer nowrap" @click="sort_by('client_name')">Client Name<i v-if="table_sort.field=='client_name'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i></th>
                            <th class="c-pointer nowrap" @click="sort_by('client_email')">E-mail Address<i v-if="table_sort.field=='client_email'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i></th>
                            <th class="d-none d-md-table-cell c-pointer text-center nowrap" @click="sort_by('watch_managers')">
                                Watch<info-icon content="Whether Space Managers are subscribed to email notification of new file uploads by the Client"></info-icon><i v-if="table_sort.field=='watch_managers'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i>
                            </th>
                            <th class="text-center d-none d-sm-table-cell c-pointer text-center nowrap" @click="sort_by('joined')">Joined<i v-if="table_sort.field=='joined'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i></th>
                            <th class="text-center d-none d-md-table-cell c-pointer text-center nowrap" @click="sort_by('client_role')">
                                Role<info-icon content="What the client is allowed to do in the Space.<br/><span class='text-primary'>Download</span>: Download files but no uploading or deleting anything<br/><span class='text-success'>Full</span>: Upload, download, delete, etc"></info-icon>
                                <i v-if="table_sort.field=='client_role'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"/>
                            </th>
                            <th class="text-center d-none d-lg-table-cell c-pointer nowrap" @click="sort_by('mfa_enabled')">
                                2FA<info-icon content="Whether the Client enabled Two-Factor Authentication"></info-icon>
                                <i v-if="table_sort.field=='mfa_enabled'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"/>
                            </th>
                            <th class="text-center d-none d-lg-table-cell c-pointer text-center nowrap" @click="sort_by('files_num')">Files<i v-if="table_sort.field=='files_num'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i></th>
                            <th class="text-center d-none d-md-table-cell c-pointer nowrap" @click="sort_by('managers_num')">Managers<i v-if="table_sort.field=='managers_num'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i></th>
                            <th class="d-none d-lg-table-cell c-pointer nowrap" @click="sort_by('created')">Created<i v-if="table_sort.field=='created'" :class="{'ion-arrow-up-c': !table_sort.desc, 'ion-arrow-down-c': table_sort.desc}" class="ml-1"></i></th>
                            <th class="width-0"></th>
                            <th class="width-0"></th>
                        </thead>
                        <tbody>
                            <tr
                                v-for="space in active_spaces_filtered_sorted" :key="space.id"
                                @click="() => { /* Multiselect return false;  space.checked = !space.checked */ 0}"
                                :class="{'checked': space.checked}">
                                <!-- Multiselect -->
                                <td class="d-none d-sm-table-cell c-pointer pr-0 mr-0">
                                    <checkbox :value="space.checked" @changed="newval => table_check_all = space.checked" />
                                </td>

                                <td class="d-none d-sm-table-cell pl-0 v-middle" style="height: 48px">
                                    <!-- Logo image: max 48x48, keep image proportions -->
                                    <img 
                                        v-if="space.client_logo" 
                                        class="rounded fit-contain shadow-z1"
                                        style="max-width: 48px; max-height: 48px;"  
                                        :src="ClientSpacesService.is_skyflok_logo(space) ? Utils.client_space_logo_src(space.client_logo) : space.client_logo"
                                    />
                                    <!-- 3-letter initials: fixed 48x48 -->
                                    <div v-else class="shadow-z1 thumb48 rounded bg-gradient-secondary d-inline-block">
                                        <div class="d-flex align-items-center justify-content-center fh fw text-white text-bold">
                                            {{ space.client_name.split(' ').map(frag => frag.length > 0 ? frag[0].toUpperCase() : '').splice(0,3).join('') }}
                                        </div>
                                    </div>
                                </td>
                                <td class="break-word">
                                    <span v-html="highlight_search(space.client_name, table_filter.client_name)"></span>
                                </td>
                                <td class="break-word">
                                    <span v-html="highlight_search(space.client_email, table_filter.email)"></span>
                                </td>
                                <td class="d-none d-md-table-cell text-center">
                                    <button
                                        class="btn btn-secondary btn-flat btn-flat-icon c-pointer"
                                        @click="change_watch(space)"
                                        type="button"
                                        data-toggle="popover" data-animation="false" data-trigger="hover" data-html="true" data-placement="top" :data-content="'Click to turn ' + (space.watch_managers ? 'off' : 'on') + ' email notifications<br/>when the client uploads a file'"
                                        >
                                        <i :class="{'ion-android-notifications': space.watch_managers, 'ion-android-notifications-none text-gray-light': !space.watch_managers}"></i>
                                    </button>
                                </td>
                                <td class="d-none d-sm-table-cell text-center">
                                    <span v-if="space.password_set" class="text-success">
                                        <i class="ion-checkmark-circled mr-1"></i> Yes
                                    </span>
                                    <span v-else-if="!space.invite_code">
                                        Invite not sent<br/>
                                        <a href="#" @click.stop="resend_client_invite(space)" class="btn btn-sm text-sm btn-primary text-bold mt-1" :disabled="space.invite_sending">
                                            Send invite
                                            <i class="ion-load-c spin ml-1" v-if="space.invite_sending"></i>
                                        </a>
                                    </span>
                                    <span v-else-if="space.invite_code && space.is_invite_expired" class="text-danger">
                                        <i class="ion-alert-circled mr-1"></i>Expired!<br/>
                                        <a href="#" @click.stop="resend_client_invite(space)" class="btn btn-sm text-sm btn-primary text-bold mt-1" :disabled="space.invite_sending">
                                            Send invite
                                            <i class="ion-load-c spin ml-1" v-if="space.invite_sending"></i>
                                        </a>
                                    </span>
                                    <span v-else-if="space.invite_code && !space.is_invite_expired">
                                        Invite sent <small><i class="ion-information-circled ml-1 c-help" data-container="body" data-toggle="popover" data-placement="top" data-html="true" data-trigger="hover" :data-content="space.will_expire_text"></i></small><br/>
                                        <small>
                                            <a href="#" @click.stop="resend_client_invite(space)" :disabled="space.invite_sending">
                                                Re-send invite?
                                                <i class="ion-load-c spin ml-1" v-if="space.invite_sending"></i>
                                            </a>
                                        </small>
                                    </span>
                                </td>
                                <td class="d-none d-md-table-cell text-center">
                                    <span v-if="space.client_readonly" class="text-primary">Download</span>
                                    <span v-else class="text-success">Full</span>
                                </td>
                                <td class="d-none d-lg-table-cell text-center nowrap">
                                    <span v-if="space.mfa_enabled" class="text-success">
                                        <i class="ion-locked mr-1"></i> Yes
                                    </span>
                                    <span v-else class="text-muted">No</span>
                                </td>
                                <td class="d-none d-lg-table-cell text-center">
                                    <i v-if="!space.ns_stats" class="ion-load-c spin"></i>
                                    <i v-else-if="space.ns_stats.active_files == 0">Empty</i>
                                    <span v-else>
                                        {{ space.ns_stats.active_files }}
                                    </span>
                                </td>
                                <td class="d-none d-md-table-cell">
                                    <span v-if="space.members.length > 1">{{ space.members.length }} managers</span>
                                    <user-box v-else-if="user.team" :avatar_left="true" class="nowrap" :team="user.team" :userId="space.members[0]"></user-box>
                                    <!--
                                    <span v-else-if="user.team">
                                        <img :src='user.team.find(member => { return member.user_id === space.members[0] }) | avatar_url' class='thumb32 mr-2 rounded fit-cover'/>
                                        {{ user.team.find(member => { return member.user_id === space.members[0] }).name }}
                                    </span>
                                    -->
                                </td>
                                <td class="d-none d-lg-table-cell">
                                    <timestamp :timestamp="space.created"></timestamp>
                                    <!--{{ space.created | timestamp_time_date }}-->
                                </td>
                                <td>
                                    <a :href="space.key | client_space_link" class="btn btn-sm text-sm btn-info ml-2 text-bold" target="_BLANK" :title="'Open the Space of '+space.client_name" @click="() => {return false; space.checked = !space.checked}">
                                        <span class="d-none d-md-inline-block mr-1">Open</span>
                                        <i class="ion-share"></i>
                                    </a>
                                </td>
                                <td>
                                    <div class="dropdown" @click="() => { return false; space.checked = !space.checked}">
                                        <button class="btn btn-secondary btn-flat btn-flat-icon" data-toggle="dropdown" :id="'dropdown_'+space.id" type="button" aria-expanded="false"><em class="ion-android-more-horizontal"></em></button>
                                        <div class="dropdown-menu dropdown-scale dropdown-menu-right" role="menu">
                                            <a class="dropdown-item" href="#" @click.prevent="edit_space(space)" v-if="!space.is_archived"><i class="ion-edit mr-2"></i> Edit Space</a>
                                            <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="open_dialog('reset_password', space)" v-if="!space.is_archived"><i class="ion-refresh mr-2"></i> Reset Client password</a>
                                            <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="open_dialog('mfa_backup_codes', space)" v-if="space.mfa_enabled && !space.is_archived"><i class="ion-help-buoy mr-2"></i> 2FA Backup Codes</a>
                                            <div class="dropdown-divider" role="separator" v-if="!space.is_archived"></div>
                                            <!--<a class="dropdown-item text-warning" href="#" @click.prevent="disableclicked(space)">Disable client access...</a>-->
                                            <a class="dropdown-item text-danger" href="#" @click.prevent="open_dialog('archive_space', space)" v-if="!space.is_archived"><i class="ion-trash-a mr-2"></i> Archive or Delete...</a>
                                            <a v-else-if="activatedisabled !== true" class="dropdown-item text-success" href="#" @click.prevent="activateclicked(space)"><i class="ion-wand mr-2"></i> Activate Space</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>

        <!-- Big "create space" action button -->
        <div v-if="active_spaces_num === 0" class="row mb-3">
            <div class="col-4"></div>
            <div class="col-4">
                <div class="cardbox text-white bg-gradient-success b0 c-pointer" @click="create_space()">
                    <div class="cardbox-body text-center">
                        <div class="text-bold display-1"><i class="ion-plus-round"></i></div>
                        <p class="text-bold text-md">Create Client Space</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Archive spaces -->
        <div v-if="archived_spaces && archived_spaces.length > 0">
            <h3>Archived Spaces <small>({{archived_spaces.length}})</small> <button class="btn btn-secondary" @click="show_archived = !show_archived">{{ show_archived ? 'Hide' : 'Show' }}</button></h3>
            <div class="row" id="archived_spaces_container" :class="{'d-none': !show_archived}">
                <client-space-box
                    v-for="space in archived_spaces"
                    :key="space.id"
                    :space="space"
                    :user="user"
                    :activatedisabled="active_spaces_limit && active_spaces.length >= active_spaces_limit"
                    :activateclicked="(space) => { activate_space(space) }"
                />
            </div>
        </div>

    </div>

    <!-- Loader -->
    <div v-else class="row">
        <div class="col-3">&nbsp;</div>
        <div class="col-6">
            <div>
                <div class="cardbox text-white bg-gradient-success b0">
                    <div class="cardbox-body text-center">
                        <div class="text-bold display-1"><div class="loader"></div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="client_space_form">
    <div class="modal-dialog modal-lg" v-if="show_space_form">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">
                    <div v-if="space_to_edit && space_to_edit.id">
                        Edit Client Space of <b>{{space_to_edit.client_name}}</b>
                    </div>
                    <div v-else>
                        Create new Client Space
                    </div>
                </h5>
                <button class="close" type="button" @click="close_modal('client_space_form')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <div v-if="space_to_edit === null && !hide_form_switcher"  class="d-none d-md-block mb-2 text-center ">
                    <a v-if="!batch_create_mode" href="#" @click.prevent="batch_create_mode = true">
                        <span class="badge badge-info mr-2">NEW</span>Creating many Spaces? Try the new batch mode!
                    </a>
                    <a v-else href="#" @click.prevent="batch_create_mode = false">
                        Create a single Space
                    </a>
                </div>

                <client-space-form
                    ref="space_form"
                    v-if="user && user.team"
                    :space="space_to_edit"
                    :team="user.team"
                    :user="user"
                    :batch_mode="batch_create_mode"
                    :hide_form_switch="() => { hide_form_switcher = true }"
                    :reload_spaces_callback="() => { load_spaces() }"
                    :closeDialog="() => { close_modal('client_space_form') }" />

            </div>
        </div>
    </div>
</div>

<div class="modal" id="archive_space_modal">
    <div class="modal-dialog modal-lg" v-if="space_to_archive">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">Archive Client Space of <b>{{ space_to_archive.client_name }}</b></h5>
                <button class="close" type="button" @click="close_modal('archive_space_modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <archive-space-modal
                    :space="space_to_archive"
                    @archived="(space_id) => { on_space_updated(space_id)}"
                />

            </div>
        </div>
    </div>
</div>

<div class="modal" id="disable_space_modal">
    <div class="modal-dialog modal-lg" v-if="space_to_disable">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">Disable access of <b>{{space_to_disable.client_name}}</b></h5>
                <button class="close" type="button" @click="close_modal('disable_space_modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                Disable access
            </div>
        </div>
    </div>
</div>

<div class="modal" id="mfa_backup_codes_modal">
    <div class="modal-dialog modal-lg" v-if="mfa_backup_codes_space">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">
                    Two-Factor Authentication Backup Codes
                </h5>
                <button class="close" type="button" @click="close_modal('mfa_backup_codes_modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <mfa-backup-codes 
                    :space_id="mfa_backup_codes_space.id" 
                    :client_name="mfa_backup_codes_space.client_name" 
                    :user_email="mfa_backup_codes_space.client_email"
                />
            </div>
        </div>
    </div>
</div>

<div class="modal" id="reset_password_modal">
    <div class="modal-dialog modal-lg" v-if="password_reset_space">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">
                    Reset password of <b>{{ password_reset_space.client_name }}</b>
                </h5>
                <button class="close" type="button" @click="close_modal('reset_password_modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body text-black">
                <space-psw-reset-modal
                    :space="password_reset_space"
                    :space_updated="(updated_space) => { password_reset_space.invite_expires = updated_space.invite_expires; password_reset_space.password_set = updated_space.password_set; password_reset_space.invite_code = updated_space.invite_code; init_space(password_reset_space) }"
                    :close_modal="() => { close_modal('reset_password_modal'); }"
                    >
                </space-psw-reset-modal>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="edit_managers_modal">
    <div class="modal-dialog modal-lg" v-if="show_edit_managers_modal">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title text-bold">
                    Edit Managers
                </h5>
                <button class="close" type="button" @click="close_modal('edit_managers_modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body text-black">
                <edit-managers-modal
                    :team="user ? user.team : []"
                    :spaces="spaces"
                    :close_modal="()=>{ close_modal('edit_managers_modal') }">
                </edit-managers-modal>
            </div>
        </div>
    </div>
</div>


</section>
</template>

<script>

import { ClientSpacesService } from '@/services/client_spaces-service.js'
import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'
import Vue from 'vue'

import clientSpaceBox from '@/components/client-space-box.1.vue'
import clientSpaceForm from '@/components/modals/client-space/space-form.vue'
import upgradeWarningBox from '@/components/upgrade_warning_box.vue'
//import spaceLinkPswFragment from '@/components/client-space-link-psw.vue'
import spacePswResetModal from '@/components/modals/client-space/psw-reset.vue'
import archiveSpaceModal from '@/components/modals/client-space/archive-space.vue'
import editManagersModal from '@/components/modals/client-space/edit-managers.vue'
import userBox from '@/components/user-box.vue'
import timestamp from '@/components/timestamp.vue'
import infoIcon from '@/components/info-icon.vue'
import checkbox from '@/components/checkbox'
import mfaBackupCodes from '@/components/mfa/recovery_codes'

export default {
    props: ["user", 'features'],

    components: {
        upgradeWarningBox,
        clientSpaceBox,
        userBox,
        clientSpaceForm,
        /*spaceLinkPswFragment,*/
        spacePswResetModal,
        archiveSpaceModal,
        editManagersModal,
        timestamp,
        infoIcon,
        mfaBackupCodes,
        checkbox
    },

    data(){
        return {

            spaces: null,
            spaces_loading: false,

            show_archived: false,

            space_to_edit: null,
            show_space_form: false,

            space_to_archive: null,
            archive_loading: false,
            archive_error: false,

            resend_invite_batch_loading: false,

            batch_create_mode: false,
            hide_form_switcher: false,

            view_mode: 'grid',
            table_check_all: false,
            table_filter: {
                client_name: null,
                email: null,
                joined_yes: true,
                joined_no: true,
                invite_expired_yes: true,
                invite_expired_no: true,
                invite_sent_yes: true,
                invite_sent_no: true,
                role_full_control: true,
                role_readonly: true,
                mfa_enabled_yes: true,
                mfa_enabled_no: true,
                files_empty: true,
                files_not_empty: true
            },
            table_sort: {
                field: 'client_name',
                desc: false
            },
            show_filters: true,

            dismiss_expired_invites_alert: false,

            password_reset_space: null,
            space_to_disable: null,
            mfa_backup_codes_space: null,
            show_edit_managers_modal: false,

            Utils: Utils,
            ClientSpacesService: ClientSpacesService
        }
    },

    computed: {

        client_spaces_disabled(){
            // Assume client spaces are enabled until team settings are loaded
            return this.user && this.user.team_settings ? this.user.team_settings.config.client_spaces_enabled === false : false
        },
        active_spaces_limit(){
            var limit = this.user && this.user.team_settings && this.user.team_settings.config.active_spaces_limit
            return (!limit || limit == 0) ? false : limit
        },
        active_spaces(){
            return this.spaces && this.spaces.filter(s => { return !s.is_archived })
        },
        archived_spaces(){
            return this.spaces && this.spaces.filter(s => { return s.is_archived })
        },
        active_spaces_num(){
            return this.active_spaces ? this.active_spaces.length : 0
        },
        active_spaces_with_expired_invite(){
            return this.active_spaces ? this.active_spaces.filter(sp => { return !sp.password_set && sp.is_invite_expired }) : []
        },

        active_spaces_filtered(){
            if(this.is_search){
                // Search every team, not just the current page
                let spaces = this.active_spaces
                const filters = this.table_filter
                if(filters.client_name){
                    spaces = spaces.filter(space => { return space.client_name.toLowerCase().search(filters.client_name.toLowerCase()) >= 0 })
                }
                if(filters.email){
                    spaces = spaces.filter(space => { return space.client_email.toLowerCase().search(filters.email.toLowerCase()) >= 0 })
                }
                if(!filters.joined_yes){
                    spaces = spaces.filter(space => { return !space.password_set })
                }
                if(!filters.joined_no){
                    spaces = spaces.filter(space => { return space.password_set })
                }
                if(!filters.invite_expired_yes){
                    spaces = spaces.filter(space => { return !space.is_invite_expired })
                }
                if(!filters.invite_expired_no){
                    spaces = spaces.filter(space => { return space.is_invite_expired })
                }
                if(!filters.invite_sent_yes){
                    spaces = spaces.filter(space => { return !space.password_set && !space.invite_code })
                }
                if(!filters.invite_sent_no){
                    spaces = spaces.filter(space => { return !space.password_set && space.invite_code })
                }
                if(!filters.role_full_control){
                    spaces = spaces.filter(space => { return space.client_readonly })
                }
                if(!filters.role_readonly){
                    spaces = spaces.filter(space => { return !space.client_readonly })
                }
                if(!filters.mfa_enabled_yes){
                    spaces = spaces.filter(space => { return !space.mfa_enabled })
                }
                if(!filters.mfa_enabled_no){
                    spaces = spaces.filter(space => { return space.mfa_enabled })
                }
                if(!filters.files_empty){
                    spaces = spaces.filter(space => { return space.ns_stats ? space.ns_stats.active_files > 0 : false })
                }
                if(!filters.files_not_empty){
                    spaces = spaces.filter(space => { return space.ns_stats ? space.ns_stats.active_files == 0 : false })
                }

                return spaces
            }
            else{
                // When search is not active, return the current page
                return this.active_spaces
            }
        },

        active_spaces_filtered_sorted(){
            let spaces = this.active_spaces_filtered

            spaces.sort( (a,b) => {
                switch(this.table_sort.field){
                    case 'client_name':
                        return a.client_name.toLowerCase() > b.client_name.toLowerCase() ? 1 : -1;
                    case 'client_email':
                        return a.client_email.toLowerCase() > b.client_email.toLowerCase() ? 1 : -1;
                    case 'watch_managers':
                        return a.watch_managers > b.watch_managers ? 1 : -1;
                    case 'joined':
                        // Assign a number to both 'a' and 'b' based on the state and sort by that
                        return this._get_joined_val(a) > this._get_joined_val(b) ? 1 : -1
                    case 'client_role':
                        return a.client_readonly > b.client_readonly ? 1 : -1
                    case 'mfa_enabled':
                        return a.mfa_enabled > b.mfa_enabled ? 1 : -1
                    case 'files_num':
                        if(!a.ns_stats || !b.ns_stats){ return 0 }
                        return a.ns_stats.active_files > b.ns_stats.active_files ? 1 : -1
                    case 'managers_num':
                        if(!a.members || !b.members){ return 0 }
                        return a.members.length > b.members.length ? 1 : -1
                    case 'created':
                        return a.created > b.created ? 1 : -1

                    default:
                        return 0
                }
            })

            if(this.table_sort.desc){
                // reverse if desc
                spaces.reverse()
            }

            return spaces
        },

        is_search(){
            const f = this.table_filter
            return f.client_name || f.email || !f.joined_yes || !f.joined_no || !f.invite_expired_yes || !f.invite_expired_no || !f.invite_sent_yes || !f.invite_sent_no || !f.role_full_control || !f.role_readonly || !f.files_empty || !f.files_not_empty|| !f.mfa_enabled_yes || !f.mfa_enabled_no
        },

        checked_spaces(){
            if(!this.active_spaces){
                return null
            }
            return this.active_spaces.filter(space => { return space.checked })
        },

        checked_spaces_no_password(){
            if(!this.checked_spaces){ return [] }
            return this.checked_spaces.filter(space => { return !space.password_set })
        },

        is_mobile(){
            return Utils.is_mobile()
        },
    },

    watch: {
        active_spaces_num(){
            const view = Utils.parse_url_param('view')
            if(view){
                // Set the hardcoded view
                this.view_mode = view
                localStorage['spaces_view'] = view
            }
            else{
                const user_choice = localStorage['spaces_view']
                if(user_choice !== undefined){
                    this.view_mode = user_choice
                }
                else{
                    // Set view based on spaces num
                    this.view_mode = (this.active_spaces_num > 30) ? 'table' : 'grid'
                }
            }

        },

        table_check_all(){
            // Set every space to checked or unchecked
            this.active_spaces_filtered.forEach(sp => {
                sp.checked = this.table_check_all
            })

        },

        view_mode(){
            localStorage['spaces_view'] = this.view_mode
            this.$nextTick(() => { $('[data-toggle="popover"]').popover(); })
        },

        checked_spaces(){
            if(this.checked_spaces.length > 0){
                this.$nextTick(()=>{
                    $('[data-toggle="popover"]').popover()
                })
            }
        },

        table_filter: {
            handler(val){
                localStorage['spaces_filters'] = JSON.stringify(val)
            },
            deep: true
        },

        table_sort: {
            handler(val){
                localStorage['spaces_sort'] = JSON.stringify(val)
            },
            deep: true
        }
    },

    created(){
        if(this.features && this.features.client_spaces){
            this.load_spaces()
        }

        // Hide the filters on mobile by default
        this.show_filters = !this.is_mobile

        const unwatch_space_key = Utils.parse_url_param("unwatch")
        if(unwatch_space_key){
            // TODO
        }

        // Reload spaces when the page regains focus
        var self = this
        window.onfocus = () => {
            self.load_spaces()
        }
    },

    mounted(){
        const view = Utils.parse_url_param('view')
        if(view){ this.view_mode = view}

        // Restore previous table sort and filters
        if(localStorage['spaces_sort']){
            
            // If there are new key in this.table_sort, merge them into the cached 
            let cached_sort = JSON.parse(localStorage['spaces_sort'])
            let new_keys_found = false
            for(const [key, value] of Object.entries(this.table_sort)){
                if(cached_sort[key] === undefined){
                    cached_sort[key] = this.table_sort[key]
                    new_keys_found = true
                }
            }
            this.table_sort = cached_sort

            if(new_keys_found){
                // Update the stored dictionary
                localStorage['spaces_sort'] = JSON.stringify(this.table_sort)
            }
            
        }
        if(localStorage['spaces_filters']){

            // If there are new key in this.table_sort, merge them into the cached 
            let cached_filters = JSON.parse(localStorage['spaces_filters'])
            let new_keys_found = false
            for(const [key, value] of Object.entries(this.table_filter)){
                if(cached_filters[key] === undefined){
                    cached_filters[key] = this.table_filter[key]
                    new_keys_found = true
                }
            }
            this.table_filter = cached_filters

            if(new_keys_found){
                // Update the stored dictionary
                localStorage['spaces_filters'] = JSON.stringify(this.table_filter)
            }

        }
    },

    methods: {

        show_expired_invites(){
            this.table_filter = {
                client_name: null,
                email: null,
                joined_yes: false,
                joined_no: true,
                invite_expired_yes: true,
                invite_expired_no: false,
                invite_sent_yes: true,
                invite_sent_no: true,
                role_full_control: true,
                role_readonly: true,
                mfa_enabled_yes: true,
                mfa_enabled_no: true,
                files_empty: true,
                files_not_empty: true
            }
        },

        init_space(space){

            if(space.checked === undefined){
                space.checked = false
            }
            if(space.invite_expires){
                this.$set(space, 'is_invite_expired', (Utils.timestamp_ms() >= space.invite_expires*1000))
                if(!space.is_invite_expired){
                    this.$set(space, 'will_expire_text', "Invite will expire " + Utils.timestamp_rel(space.invite_expires).toLowerCase())
                }
            }
        },

        load_spaces(){

            this.spaces_loading = true
            const with_mfa_status = true
            ClientSpacesService.list(with_mfa_status).then(res => {
                const spaces = res.body

                spaces.forEach(space => {

                    if(this.spaces){
                        // Carry over selection
                        const existing_space = this.spaces.find(s => { return s.id === space.id })
                        if(existing_space){
                            space.checked = existing_space.checked
                        }
                    }
                    this.init_space(space)
                })

                this.spaces = spaces
                this.spaces_loading = false

                bus.$emit(events.SPACES_LOADED, spaces)

                // Load stats (number of files)
                this.load_files_num()

                this.$nextTick(() => {
                    $('[data-toggle="popover"]').popover();
                })
            })
            .catch(err => {
                this.spaces_loading = false
                console.error("Load client spaces error: ",  err)
                Utils.show_error("Internal error loading Spaces, please try again later!")
            })
        },

        open_dialog(dialog_name, space){
            let modal_id = dialog_name + '_modal'
            switch(dialog_name){
                case 'archive_space':
                    this.space_to_archive = space
                    break
                case 'disable_space':
                    this.space_to_disable = space
                    break
                case 'reset_password':
                    this.password_reset_space = space
                    break
                case 'space_link_password':
                    this.show_password_of_space = space
                    break;
                case 'edit_managers':
                    break;
                case 'mfa_backup_codes':
                    this.mfa_backup_codes_space = space
                    break;


                default:
                    console.warn("Unknown dialog to open: '"+dialog_name+"'")
                    return
            }

            this._open_modal(modal_id)
            this.$nextTick(() => {
                $('[data-toggle="popover"]').popover();
            })
        },

        /*
        show_link_psw(space){
            // Open the dialog with space link and password
            this.show_password_of_space = space
            this._open_modal("space_link_password")
        },
        */

        create_space(){
            // Open the Create Space form
            this.space_to_edit = null
            this.show_space_form = true

            this._open_modal("client_space_form")
        },

        edit_space(space, edit_managers_only){
            // Open the Edit Space form
            this.space_to_edit = Vue.util.extend({}, space)
            this.space_to_edit.edit_managers_only = edit_managers_only
            this.show_space_form = true
            this._open_modal("client_space_form")
        },

        activate_space(space){
            const new_archive_state = false
            ClientSpacesService.archive(space.id, new_archive_state).then(() => {
                space.is_archived = false
                space.delete_at = null
                Utils.show_success("Activated Space of "+space.client_name)
            }).catch(err => {
                console.error(err)
                Utils.show_error("Error activating space: " + err.body.message)
            })
        },

        load_files_num(){
            if(!this.spaces || this.spaces.length == 0){
                return
            }

            // Loads the number of active files in each Space
            ClientSpacesService.get_files_count().then(res => {
                const stats = res.body
                this.spaces.forEach(space => {
                    const stat = stats.find(s => { return s.space_id === space.id })
                    if(stat){
                        this.$set(space, 'ns_stats', stat)
                    }
                })
            }).catch(err => {
                console.error(err)
            })
        },

        resend_invite_batch(spaces){
            if(!spaces || spaces.length == 0){
                return
            }

            this.resend_invite_batch_loading = true
            const ids = spaces.map(s => { return s.id })

            ClientSpacesService.resend_invite_batch(ids).then(res => {
                this.resend_invite_batch_loading = false
                const emails_scheduled = res.body.emails_scheduled
                Utils.show_success("Started re-sending invite to "+emails_scheduled+" client"+(emails_scheduled === 1 ? '' : 's'))

                this.hide_multiselect()

                // Wait as many seconds as there were emails scheduled (the backend queue is processing 1/second)
                var self = this
                setTimeout(() => {
                    self.load_spaces()
                }, emails_scheduled*1000)

            }).catch(err => {
                console.error("Error re-sending invite to "+ids.length+" spaces: ", err)
                this.resend_invite_batch_loading = false
                let str = "Failed to re-send invite to "+ids.length+" spaces"
                if(err.body && err.body.message){
                    str += ": " + err.body.message
                }
                Utils.show_error(str)
            })
        },

        hide_multiselect(){
            // Hide the popover (if present) and uncheck all spaces
            $('#resend_invite_batch_btn').popover('dispose')
            // Uncheck all spaces
            this.table_check_all = false
            this.checked_spaces.forEach(s => { s.checked = false })
        },

        resend_client_invite(space){

            this.$set(space, 'invite_sending', true)
            ClientSpacesService.resend_invite(space.id).then(() => {
                // New invite sent!
                space.invite_sending = false

                // Reload spaces
                this.load_spaces()

                Utils.show_success("Invite sent to <b>" + space.client_name +"</b>")
                this.$nextTick(() => { $('[data-toggle="popover"]').popover(); })

            }).catch(err => {
                console.error("Error sending new invite for space: ", err)
                space.invite_sending = false
                Utils.show_error("Error sending invite: " + err.body.message)
            })
        },

        on_space_updated(space_id){
            // A space has been successfully archived
            // Close the modal and set `is_archived`

            this.close_modal('archive_space_modal')
            const space = this.spaces.find(s => s.id == space_id)
            if(space){
                space.is_archived = true
            }

        },

        close_modal(id){
            $('#'+id).modal('hide')
            this.$nextTick(()=>{
                this.show_space_form = false
                this.hide_form_switcher = false
                this.show_edit_managers_modal = false
                this.mfa_backup_codes_modal = null
                this.space_to_archive = null
            })
        },

        _open_modal(id){
            $("#"+id).modal({
                backdrop: 'static'
            })

            var self = this
            $("#"+id).on('hidden.bs.modal', function(){
                self.space_to_edit = null
                self.show_space_form = false
                self.batch_create_mode = false
                self.hide_form_switcher = false
            })
        },

        highlight_search(string, search_str){
            if(!this.is_search){
                const is_long = string.length > 50
                return is_long ? string.substring(0, 50)+'...' : string
            }
            return string.replace(new RegExp(search_str, "gi"), match => {
                return '<span class="bg-warning">' + match + '</span>';
            });
        },

        change_watch(space){

            const new_watch = !space.watch_managers
            ClientSpacesService.watch_managers(space.id, new_watch).then(() => {
                // Change the local state
                space.watch_managers = new_watch

                const confirm_message = space.watch_managers ?
                    "Space Managers will receive an email when <b>"+space.client_name+"</b> uploads a new file <button class='btn btn-secondary btn-sm ml-2' id='client_spaces_turn_on_all_notifications'>Turn on for all</button>" :
                    "Turned off email notifications of <b>"+space.client_name+"</b> for all Space Managers <button class='btn btn-secondary btn-sm ml-2' id='client_spaces_turn_off_all_notifications'>Turn off for all</button>"

                Utils.show_success(confirm_message)
                var self = this
                this.$nextTick(()=>{
                    const all_on_btn = document.getElementById("client_spaces_turn_on_all_notifications")
                    if(all_on_btn){
                        all_on_btn.onclick = function(){
                            self.change_watch_all(true)
                        }
                    }
                    const all_off_btn = document.getElementById("client_spaces_turn_off_all_notifications")
                    if(all_off_btn){
                        all_off_btn.onclick = function(){
                            self.change_watch_all(false)
                        }
                    }
                })

            }).catch(err => {
                console.error(err)
                Utils.show_error("Error: " + err.body.message)
            })

        },

        change_watch_all(new_value){

            ClientSpacesService.watch_managers(ClientSpacesService.CHANGE_WATCH_ALL_SPACES, new_value).then(() => {
                // Change the local state
                this.spaces.forEach(sp => {
                    sp.watch_managers = new_value
                })

                const confirm_message = new_value ?
                    "Notifications turned on for all spaces" :
                    "Notifications turned off for all spaces"

                Utils.show_success(confirm_message)

            }).catch(err => {
                console.error(err)
                Utils.show_error("Error: " + err.body.message)
            })
        },

        sort_by(field){
            if(this.table_sort.field === field){
                // switch direction
                this.table_sort.desc = !this.table_sort.desc
            }
            else{
                this.table_sort = {
                    field: field,
                    desc: false
                }
            }
        },

        _get_joined_val(space){
            // Returns a consistent number that can be used to sort spaces by the 'client joined' column
            if(space.password_set){ return 1 }
            if(!space.invite_code){ return 2 }
            if(space.is_invite_expired){ return 3 }
            if(!space.is_invite_expired){ return 4 }
            return 5
        },

        _get_joined_val_str(joined_val_num){
            /* Converts the 'joined' integer value to a string that goes into the exported CSV */
            switch(joined_val_num){
                case 1: return "Client joined"
                case 2: return "No invite"
                case 3: return "Invite expired"
                case 4: return "Pending invite"
                default: return ""
            }
        },

        _get_managers_names(space){
            let names = []
            space.members.forEach(member_id => {
                let user = this.user.team.find(m => { return m.user_id === member_id })
                if(user){
                    names.push(user.name)
                }
            })
            return names.join(" and ")
        },

        multiselect_csv_export(spaces){
            if(!spaces || spaces.length === 0){
                return
            }

            this.multiselect_csv_export_loading = true

            let csv_contents = "Client Name,Client E-mail,Watching,Joined,Role,Managers\r\n"
            spaces.forEach(sp => {
                csv_contents += sp.client_name+","+sp.client_email+","+(sp.watch_managers ? 'Yes' : 'No')+","
                csv_contents += this._get_joined_val_str(this._get_joined_val(sp)) + ","
                csv_contents += (sp.client_readonly ? "Download only" : "Full control") + ","
                csv_contents += this._get_managers_names(sp) + '\r\n'
            })

            const blob = new Blob([csv_contents], { type: "text/csv" })
            const blob_url = window.URL.createObjectURL(blob)

            Utils.force_download_file({name: "skyflok_clients_export.csv"}, blob_url)
            this.multiselect_csv_export_loading = false
            this.hide_multiselect()

            setTimeout(function(){ if(URL && URL.revokeObjectURL){ URL.revokeObjectURL(blob_url) } }, 10E3) // 10s
        }

    }

}
</script>

<style scoped>

    .add_space{
        text-align: center;
        cursor: pointer;
        height: 100%;
    }

    .add_space_btn:hover{
        background: #ebebeb
    }

    #spaces_table thead th{
        border-top: none
    }

    #spaces_table td .custom-checkbox{
        opacity: 0;
        transition: opacity .2s linear;
    }

    #spaces_table tr:hover td .custom-checkbox,
    #spaces_table.any_checked .custom-checkbox{
        opacity: 1
    }

    #spaces_table .custom-control.custom-checkbox{
        width: 24px
    }
    #spaces_table .custom-control-input:not(:checked)~.custom-control-indicator{
        background-color: #ddd
    }

    #spaces_table tr.checked td{
        background: #efefef
    }

    .filter{
        display: inline-block;
        margin-right: 1em;
        margin-top: .5em;
        margin-bottom: .5em;
        vertical-align: top;
    }

    .filters label.filter_name{
        display: block;
        text-align: left;
        text-transform: uppercase;
        font-size: small;
        font-weight: bold;
        margin-bottom: .5em;
    }


</style>
