<template>
    <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
            <div class="cardbox text-white bg-gradient-warning b0 mt-5">
                <div class="cardbox-body text-center">
                    <div class="display-3"><i class="ion-wand"></i></div>
                    <p class="text-bold text-md">Upgrade your Team to use {{ feature }}!</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["feature"]
}
</script>
