<template>
<section class="section-container">
    <div v-if="shares_disabled" class="container">
        <upgrade-warning-box :feature="'Shares'"></upgrade-warning-box>
    </div>
<div v-else class="container container-lg pt-4">

    <!-- Description -->
    <!--
    <div class="row">
        <div class="col-1">&nbsp;</div>
        <div class="col-10">
            <div class="cardbox text-white bg-gradient-info b0">
                <div class="cardbox-body text-center" style="font-size: 1.3em">
                    Shares are currently in <i><b>Beta</b></i> stage, please expect more features and changes on this page.<br/>
                    We never compromise on security, your shared files enjoy full protection.
                </div>
            </div>
        </div>
    </div>
    -->

    <div v-if="shares" >
        <h3>
            Active Shares
            <small>
                ({{active_shares.length}}<span v-if="active_shares_limit && active_shares.length >= active_shares_limit"> out of {{ active_shares_limit }}<i v-if="active_shares.length >= active_shares_limit" class="ml-1 icon ion-alert-circled text-warning" title="You reached the limit of active Shares. Upgrade your Team to use more Shares!"></i></span>)
                <a href="#" @click.prevent="load_shares()" class="ml-1 text-inherit"><i class="ion-refresh" :class="{'spin': shares_loading}"></i></a>
            </small>
            </h3>
        <div class="row" v-if="active_shares && active_shares.length > 0">
            <share-box
                v-for="share in active_shares" :key="share.id"
                :share="share"
                :user="user"
                :revokeHandler="revoke_share">
            </share-box>
        </div>
        <div v-else class="row mb-3">
            <div class="col-3"></div>
            <div class="col-12 col-lg-6">
				<div class="cardbox text-white bg-gradient-info b0">
					<div class="cardbox-body text-center">
						<div class="text-bold display-1"><i class="ion-link"></i></div>
						<p class="text-bold text-md">No active shares</p>
					</div>

				</div>
			</div>
        </div>


        <!-- Hide expired shares when there's none -->
        <div v-if="expired_shares && expired_shares.length > 0">
            <h3 title="These shares are either expired or manually disabled">Inactive Shares <small>({{expired_shares.length}})</small> <button class="btn btn-secondary" @click="show_expired=!show_expired">{{ show_expired ? 'Hide' : 'Show' }}</button></h3>
            <div class="row" id="expired_shares_container" :class="{'d-none': !show_expired}">
                <share-box
                    v-for="share in expired_shares" :key="share.id"
                    :share="share"
                    :user="user"
                    :deleteHandler="delete_share"
                    :activateHandler="activate_share"
                    :activatedisabled="active_shares_limit && active_shares.length >= active_shares_limit">
                </share-box>
            </div>
        </div>
    </div>

    <!-- Loader -->
    <div v-else class="row">
        <div class="col-3"></div>
        <div class="col-12 col-lg-6">
            <hero-box :bg-class="'bg-gradient-info'" :icon="'loader d-block'"></hero-box>
        </div>
    </div>


</div>
</section>
</template>

<script>

import { ShareService } from '@/services/share-service.js'
import { NamespaceService } from '@/services/namespace-service.js'
import { Utils } from '@/helpers/utils.js'

import shareBox from '@/components/share-box.vue'
import upgradeWarningBox from '@/components/upgrade_warning_box.vue'
import heroBox from '@/components/hero-box.vue'

export default {

    props: ["user"],

    components: {
        shareBox,
        upgradeWarningBox,
        heroBox
    },

    data: function() {
        return {
            shares: null,
            shares_loading: false,
            show_expired: false
        }
    },

    computed: {

        active_shares: function(){
            return this.shares.filter( s => { return s.contents.length > 0 && s.active } )
        },

        expired_shares: function(){
            return this.shares.filter( s => { return s.contents.length > 0 && !s.active } )
        },
        
        shares_disabled(){
            // Assume shares are enabled until team settings are loaded
            return this.user && this.user.team_settings ? this.user.team_settings.config.share_enabled === false : false
        },

        active_shares_limit(){
            var limit = this.user && this.user.team_settings && this.user.team_settings.config.active_shares_limit
            return (!limit || limit == 0) ? false : limit
        },
    },


    created(){
        this.load_shares()
    },

    mounted(){
        // Reload shares when the page regains focus
        var self = this
        window.onfocus = () => {
            self.load_shares()
        }
    },

    methods: {

        load_shares(){
            // Use default namespace
            NamespaceService.ns_type = NamespaceService.NS_TYPE_DEFAULT

            this.shares_loading = true
            ShareService.get_active_shares().then(res => {

                const shares = res.body

                let all_entity_ids = []
                // Collect all unique entity IDs from all shares
                shares.forEach(share => {
                    if(share.contents){
                        if(Array.isArray(share.contents)){
                            share.contents.forEach(entity_id => {
                                if(all_entity_ids.indexOf(entity_id) < 0){ all_entity_ids.push(entity_id) }
                            })
                        }
                        else{
                            if(all_entity_ids.indexOf(share.contents) < 0){ all_entity_ids.push(share.contents) }
                        }
                    }
                })
                // Sort shares to show the most recent ones first
                shares.sort( (a,b) => { return b.created - a.created })

                // Load all file records from Namespace
                if(all_entity_ids.length > 0){
                    NamespaceService.get_files_by_id(all_entity_ids, true).then(res => {
                        const shared_files = res.body
                        // Patch the files into every file share
                        shares.forEach(share => {

                                let files = {}
                                share.contents.forEach( file_id => {
                                    var file = shared_files.filter(f => { return f.is_deleted === 0 }).find( file => { return file.id === file_id } )
                                    if(file){
                                        // Group by parent folder
                                        const path = Utils.path(file.path)
                                        if(!files[path]){ files[path] = [] }
                                        files[path].push(file)
                                    }
                                }, this)

                                let contents = []
                                for(var folder in files){
                                    contents.push({
                                        folder: folder,
                                        files: files[folder]
                                    })
                                }

                                // Add contents into the share object
                                share.contents = contents
                            }, this)


                            // Ready to display
                            this.shares_loading = false
                            this.shares = shares

                    }).catch(err_resp => {
                        console.error("Error getting details of shared files: ", err_resp)
                    })

                }
                else{
                    this.shares_loading = false
                    this.shares = shares
                }

            }).catch(err => {
                this.shares_loading = false
                console.error(err)

                if(err.status == 404){
                    // The team has no shares
                    this.shares = []
                    return
                }

                Utils.show_error("Error loading shares.")
            })
        },

        revoke_share: function(share){
            ShareService.revoke_share(share.id).then(() => {
                share.active = false
                Utils.show_success("Share disabled successfully")
            })
            .catch(err => {
                let str = "Error disabling share"
                if(err.message){ str += ": " + err.message }
                Utils.show_error(str)
            })
        },

        activate_share(share){
            ShareService.activate_revoked_share(share.id).then(() => {
                share.active = true
                Utils.show_success("Share activated successfully")
            })
            .catch(err => {
                let str = "Error activating share"
                if(err.message){ str += ": " + err.message }
                Utils.show_error(str)
            })
        },

        delete_share: function(share){
            ShareService.delete_share(share.id).then(() => {
                const idx = this.shares.findIndex(s => { return s.id === share.id})
                if(idx < 0){ return }
                this.shares.splice(idx, 1)
                Utils.show_success("Share deleted successfully")
            })
            .catch(err => {
                let str = "Error deleting share"
                if(err.message){ str += ": " + err.message }
                Utils.show_error(str)
            })
        }
    },

}
</script>