<template>
<div class="share_form">
    <div v-if="error" class="alert alert-danger">{{ error }}</div>
    <div v-if="success" class="alert alert-success">{{ success }}</div>

    <!-- Step 1: Share Form -->
    <div v-if="!new_share">

        <div class="row">
            <div class="text-center fw">
                <sharedFile v-for="entity in entities" :key="entity.id" :entity="entity"></sharedFile>
            </div>
        </div>

        <div v-if="invalid_share" class="alert alert-danger">{{ invalid_share }}</div>
        <div v-else class="row mt-4">
            <div class="col-1"></div>
            <div class="col-12 col-lg-10">
                <form @submit.prevent="submit_share">

                    <div class="form-group" v-if="!share_expire_days_to" :class="{'text-gray': !share_expire_checkbox}">
                        <div class="form-check d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" v-model="share_expire_checkbox" :value="true" id="share_expires"/>
                            <label class="form-check-label" for="share_expires">
                                <section clasS=" d-flex align-items-center gap-2">
                                    <span>Share expires</span>
                                    <input type="number" :min="1" :max="share_expire_days_to ? Math.min(365, share_expire_days_to) : 365" v-model="form.expire_days"  class="form-control form-control-sm"  :class="{'text-muted': !share_expire_checkbox}" :disabled="!share_expire_checkbox" style="display: inline; width: 50px"/>
                                    <span>days from today <i v-if="form.expire_days">(on {{ expire_date }})</i></span>
                                </section>
                            </label>
                        </div>
                    </div>

                    <div class="form-group" v-if="password_protection_enabled" :class="{'text-muted': !form.enable_password}">
                        <div v-if="teamSettings.is_passwordless_share_allowed" class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.enable_password" id="share_enable_password"/>
                            <label class="form-check-label" for="share_enable_password">Password protection</label>
                        </div>

                        <span v-else>
                            Password protection: <span class="text-success" style="cursor: help" title="This is a team setting, only Team Adminstrators can change it.">Yes<i class="ion-locked ml-1"></i></span>
                        </span>
                    </div>

                    <div class="d-flex align-items-center gap-2" v-if="!is_folder_share" :class="{'text-gray': !download_limit_checkbox}">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="download_limit_checkbox" :value="true" id="share_max_downloads"/>
                            <label class="form-check-label" for="share_max_downloads">Maximum file downloads: </label>
                        </div>
                        <input type="number" min="1" max="100" v-model="form.download_limit"  class="form-control form-control-sm"  :class="{'text-muted': !download_limit_checkbox}" :disabled="!download_limit_checkbox" style="display: inline; width: 50px"/>
                        <i class="ion-help-circled" data-toggle="popover" data-placement="top" data-content="Limit how many times the shared file can be downloaded. When the download limit is reached, the share is disabled automatically." data-html="true" data-trigger="hover"></i>
                    </div>

                    <div class="form-group mt-4">
                        <label class="control-label" for="message-content">Message above the shared {{ is_folder_share ? "folder" : "files" }}:</label>
                        <textarea class="form-control ml-1" v-model="form.message" placeholder="Optional"></textarea>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button class="btn btn-secondary mr-2" :class="{'btn-sm text-sm': is_mobile}" type="button" @click="close()" aria-label="Close">Cancel</button>
                        <button class="btn btn-info btn-gradient" :class="{'btn-sm text-sm': is_mobile}" type="submit" :disabled="form.loading || disable_create">Create Share<i v-if="form.loading" class="ion-load-c spin ml-2"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Step 2: display new share link & pass -->
    <div v-else class="row">
        <div class="col-1"></div>
        <div class="col-12 col-lg-10">
            <div>
                <label for="new_share_link">Share Link:</label>
                <div class="input-group mt-0">
                    <input type="text" class="form-control" id="new_share_link" :value="new_share.link">
                    <button v-if="!is_mobile" class="btn btn-gradient btn-secondary ml-1" :class="{'btn-sm text-sm': is_mobile}" @click="to_clipboard('new_share_link')"><i class="ion-clipboard icon-lg"></i><span class="d-none d-md-inline-block ml-1">Copy</span></button>
                    <a class="btn btn-primary ml-1" :class="{'btn-sm text-sm': is_mobile}" :href="new_share.link" target="_BLANK"><i class="ion-share icon-lg mr-1"></i> Open</a>
                </div>
            </div>
            <div class="mt-2" v-if="new_share.password_protected">
                <label for="new_share_pass">Password:</label>
                <div class="input-group" style="width: 300px">
                    <input type="text" class="form-control" id="new_share_pass" :value="new_share.password">
                    <button v-if="!is_mobile" class="btn btn-gradient btn-secondary" :class="{'btn-sm text-sm': is_mobile}" @click="to_clipboard('new_share_pass')"><i class="ion-clipboard icon-lg"></i><span class="d-none d-md-inline-block ml-1">Copy</span></button>
                </div>
                <div class="text-center" v-if="false">
                    <div class="alert alert-info mt-2 d-inline-block">You need to send both the link and the password to the recipients.<br/>It's a lot more secure to use two different channels, like email and text message!</div>
                </div>
            </div>

            <div class="text-center mt-3">
                <button class="btn btn-outline btn-secondary" :class="{'btn-sm text-sm': is_mobile}" @click="close ? close() : false">Close</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import { ShareService } from '@/services/share-service.js'
import { Utils } from '@/helpers/utils.js'
import sharedFile from './shared-file.vue'
import { NamespaceService } from '@/services/namespace-service';

export default {
    props: ["entities", "teamSettings", 'close', 'shareCreated'],
    components: {
        sharedFile
    },

    data(){
        return {
            invalid_share: false,

            form: null,
            error: false,
            disable_create: false,
            success: false,

            share_expire_checkbox: true,
            download_limit_checkbox: false,

            new_share: null
        }
    },

    computed: {
        expire_date(){
            const expire_date = new Date(Utils.timestamp_ms() + (this.form.expire_days * 3600*24*1000))
            return expire_date.toLocaleDateString("en-US", { weekday: 'long',  day: 'numeric', month: 'long' })
        },

        is_folder_share(){
            return this.entities[0].entity_type === NamespaceService.ENTITY_TYPE_FOLDER
        },
        share_max_size_bytes(){
            var limit = this.teamSettings && this.teamSettings.config.share_max_size
            return (!limit || limit == 0) ? false : limit * 1024*1024*1024
        },
        share_max_files(){
            var limit = this.teamSettings && this.teamSettings.config.share_max_files
            return (!limit || limit == 0) ? false : limit
        },
        share_expire_days_from(){
            var limit = this.teamSettings && this.teamSettings.config.share_expire_days_from
            return (!limit || limit == 0) ? false : limit
        },
        share_expire_days_to(){
            var limit = this.teamSettings && this.teamSettings.config.share_expire_days_to
            return (!limit || limit == 0) ? false : limit
        },
        password_protection_enabled(){
            return this.teamSettings && this.teamSettings.config.share_password_protection_enabled
        },
        is_mobile(){
            return Utils.is_mobile()
        },

    },

    watch: {
        entities(){
            this.init()
        }
    },

    created(){
        this.init()

    },

    mounted() {
        this.$nextTick(() => {
            $('[data-toggle="popover"]').popover();
        })
    },

    methods: {

        init(){

            this.invalid_share = false

            if(this.entities.length == 0){
                this.invalid_share = "Empty share."
                return
            }

            // Make sure entities are mixed files & folders
            this.entities.forEach(en => {
                if(en.entity_type !== this.entities[0].entity_type){
                    this.invalid_share = "Cannot share files and folders mixed!"
                    return
                }
            })

            // Folder share cannot contain anything else than a single folder
            if(this.entities.length > 1 && this.entities[0].entity_type === NamespaceService.ENTITY_TYPE_FOLDER){
                this.invalid_share = "Folder Shares cannot contain more than a single folder!"
                return
            }

            // Check number of files
            if(this.share_max_files && !this.is_folder_share && this.entities.length > this.share_max_files){
                this.invalid_share = "Maximum " + this.share_max_files + " files are allowed in a share. Upgrade your Team to enable larger Shares!"
                return
            }

            // Check max size
            if(this.share_max_size_bytes){
                var total_size = 0
                this.entities.filter(en => { return en.entity_type === NamespaceService.ENTITY_TYPE_FILE }).forEach(en => {
                    total_size += en.size
                })

                if(total_size > this.share_max_size_bytes){
                    this.invalid_share = "Share size limit exceeded. Upgrade your Team to enable larger Shares! Limit: " + Utils.format_bytes(this.share_max_size_bytes)
                    return
                }
            }

            this.disable_create = false
            this.error = false
            this.success = false
            this.new_share = null


            this.form = {
                expire_days: this.share_expire_days_to ? Math.min(30, this.share_expire_days_to) : 30,
                message: null,
                loading: false,
                enable_password: true,
                download_limit: 1,
            }
        },

        submit_share(){
            this.form.loading = true
            let id = null;
            let create_share_func = null;
            if(this.is_folder_share){
                id = this.entities[0].id
                create_share_func = ShareService.create_folder_share
            }
            else{
                // File share
                id = []
                this.entities.forEach(e => { id.push(e.id) })
                create_share_func = ShareService.create_file_share
            }
            // Remove expiration if the checkbox is unchecked
            const share_expire_days = this.share_expire_checkbox ? this.form.expire_days : null
            // Remove download limit if checkbox is unchecked
            const download_limit = this.download_limit_checkbox ? this.form.download_limit : null

            create_share_func(
                id,
                this.form.name,
                this.form.message,
                share_expire_days,
                this.form.enable_password,
                download_limit).then(res => {
                    this.form.loading = false
                    this.error = false

                    this.success = "Share created"

                    let share  = res.body
                    share.link = this.$options.filters.share_link(share.alphakey)
                    this.new_share = share

                    // TODO increase share counter
                    this.shareCreated(share)

                }).catch(err => {
                    console.error(err)
                    this.form.loading = false
                    this.error = "Error creating share: " + err.body.message
                })
        },

        to_clipboard: function(input_id){
            const $el = document.getElementById(input_id)
            $el.select();
            const flash_time_ms = 150
            if(document.execCommand && document.execCommand("copy")){
                setTimeout(function(){
                    $el.blur()
                    setTimeout(function(){
                        $el.select()
                        setTimeout(function(){ $el.blur() }, flash_time_ms)
                    }, flash_time_ms)
                }, flash_time_ms)
            }
        },
    }
}

</script>
