import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'

// This is not exported
const base_url = process.env.VUE_APP_fileversion_service_url

const FileVersionService = {

    DETAILS_FULL: "full",
    DETAILS_BASIC: "basic",

    create_version: function(file_id, file_size, file_hash, ancestor_id, generations_arr){
        
        const payload = {
			file_id: file_id,
			size: file_size,
            hash: file_hash,
            ancestor_id: ancestor_id,
            generations: generations_arr
        }

        return bus.$http.post(base_url + "/file_version", payload, { headers: Utils.get_auth_header() })
    },

    download_version: function(file_version_id){
        const url_params = {
            id: file_version_id
        }
        return bus.$http.get(base_url + "/download_version", { params: url_params, headers: Utils.get_auth_header() })
    },

    download_latest(file_id){
        const url_params = {
            file_id: file_id
        }
        return bus.$http.get(base_url + "/download_latest", { params: url_params, headers: Utils.get_auth_header() })
    },

    get_latest_info_with_count(file_ids_arr){
        if(!Array.isArray(file_ids_arr)){
            // Make it an array if a single ID is added
            file_ids_arr = [ file_ids_arr ]
        }
        
       const payload = {
           file_ids: file_ids_arr,
            with_count: true
       }
       return bus.$http.post(base_url + "/latest", payload, { headers: Utils.get_auth_header() })
    },

    // Returns every FileVersion in order of timestamp for the given file(s)
    // You can pass either a single File ID or an array of IDs
    // Response type can be FileVersionService.DETAILS_BASIC (default) or FileVersionService.DETAILS_FULL
    get_file_history: function(file_id){
        const url_params = {
            file_id: file_id
        }
        return bus.$http.get(base_url + "/history", { params: url_params, headers: Utils.get_auth_header() })
    },

    // Reverts the file to the given FileVersion ID
    revert_file: function(file_id, previous_version_id){

        const payload = {
            file_id: file_id,
            version_id: previous_version_id
        }

        return bus.$http.put(base_url + "/revert_file", payload, { headers: Utils.get_auth_header() })
    },

    // Returns the total number of bytes used by the current company 
    // (latest and previous versions of live and deleted files) 
    get_company_storage_use: function(){
        return bus.$http.get(base_url + "/info/total_storage", { headers: Utils.get_auth_header() })
    },

    get_storage_history(from, to){
        let url_params = {}
        if(from){ url_params["from"] = from }
        if(to){ url_params["to"] = to }
        return bus.$http.get(base_url + "/info/storage_history", { params: url_params, headers: Utils.get_auth_header() })
    }
    
}

export { FileVersionService }