<template>
<div :class="get_class()" class="alert top-alert">
    <div v-html="html"></div>
    <i class="ion-close-round" title="close" @click="visible = false"></i>
</div>
</template>
<script>

// How long the alert is visible if not set to stay indefinitely
const ALERT_VISIBLE_MS = 5000

export default {
    props: ["alert"],

    data: function(){
        return {
            visible: false,
            html: ""
        }
    },

    watch: {
        alert: function(){

            if(this.alert && this.alert.html){
                this.show()
            }
        }
    },

    created: function(){
        if(this.alert && this.alert.html){
            this.show()
        }
    },

    methods: {

        show(){
            this.html = this.alert.html


            this.$nextTick(() => {
                this.visible = true;

                if(!this.alert.keep){
                    // Hide and clear
                    var self = this
                    setTimeout(function(){
                        self.visible = false
                        // Set the timeout length same as the .top-alert animation
                        setTimeout(function(){ self.html = "" }, 200)
                    }, ALERT_VISIBLE_MS)
                }
            })
        },

        get_class(){
            let c = { 'visible': this.visible }
            const type = this.alert.type || "success"
            c["alert-" + type] = true
            if(this.html.length > 100){
                c['wide'] = true
            }
            return c
        }
    },

}
</script>
<style scoped>
    .top-alert{
        position: fixed;
        top: -200%;
        min-height: 60px;
        width: 50%;
        left: 25%;
        text-align: center;
        border-radius: 0px;
        transition: 200ms top linear;

        display: flex;
        justify-content: space-between;
        z-index: 9999
    }
    .top-alert.wide{
        width: 80%;
        left: 10%
    }
    .top-alert i{
        cursor: pointer;
    }

    .top-alert.visible{
        top: 0px
    }

    @media(max-width: 992px){
        .top-alert{
            left: 5%;
            width: 90%
        }
    }

</style>
