import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'

// This is not exported
const base_url = process.env.VUE_APP_storage_backend_monitor_service_url

const StorageBackendMonitorService = {

    report_packet_transfers(data_arr){
        return bus.$http.post(base_url + "/measurement/batch", data_arr, { headers: Utils.get_auth_header() })
    },

    report_failed_transfer(error_report){
        return bus.$http.post(base_url + "/failure", error_report, { headers: Utils.get_auth_header() })
    }

}

export { StorageBackendMonitorService }