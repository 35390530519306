<template>
    <section class="section-container" id="install_apps">
        <div class="container pt-5">
            <div class="row">

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="cardbox b">
                        <div class="p-2">
                            <div class="cardbox-body bg-gradient-primary text-center text-white rounded">
                                <em class="ion-social-windows icon-5x"></em>
                                <div class="text-bold text-lg">Windows</div>
                            </div>
                        </div>
                        <div class="cardbox-body">
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Runs in the background</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Sync only the selected folders</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Password and/or PIN code</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Accidental overwrite protection</span>
                            </p>
                        </div>
                        <div class="cardbox-body text-center">
                            <a class="btn btn-outline-primary" href="#" @click="coming_soon()"><i class="ion-social-windows icon-lg mr-2"></i>Coming soon!</a>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="cardbox b">
                        <div class="p-2">
                            <div class="cardbox-body bg-gradient-secondary text-center text-white rounded">
                                <em class="ion-social-apple-outline icon-5x"></em>
                                <div class="text-bold text-lg">macOS</div>
                            </div>
                        </div>
                        <div class="cardbox-body">
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Runs in the background</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Sync only the selected folders</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Password and/or PIN code</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Accidental overwrite protection</span>
                            </p>
                        </div>
                        <div class="cardbox-body text-center">
                            <a class="btn btn-outline-secondary btn-gradient" href="#" @click="coming_soon()"><i class="ion-social-apple-outline icon-lg mr-2"></i> Coming soon!</a>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="cardbox b">
                        <div class="p-2">
                            <div class="cardbox-body bg-gradient-danger text-center text-white rounded">
                                <em class="ion-social-tux icon-5x"></em>
                                <div class="text-bold text-lg">Linux</div>
                            </div>
                        </div>
                        <div class="cardbox-body">
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Runs in the background</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Sync only the selected folders</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Password and/or PIN code</span>
                            </p>
                            <p class="mb-3">
                                <em class="ion-checkmark-round mx-3"></em>
                                <span>Accidental overwrite protection</span>
                            </p>
                        </div>
                        <div class="cardbox-body text-center">
                            <a class="btn btn-outline-danger btn-gradient" href="#" @click="coming_soon()"><i class="ion-social-tux icon-lg mr-2"></i> Coming soon!</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>

import { bus, events } from '@/helpers/event_bus.js'

export default {
    methods: {
        coming_soon: function(){
            bus.$emit(events.SHOW_ALERT, { message: "Coming soon!", type: "info" })
        }
    }
}
</script>
