<template>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="model" :name="name" :id="`checkbox-${divid}`" />
        <label class="form-check-label" :for="`checkbox-${divid}`">{{label}}</label>
    </div>
</template>
<script>
import { Utils } from '@/helpers/utils';

export default {
    props: ['value', 'label', 'name'],
    data(){
        return {
            model: this.value
        }
    },
    computed: {
        divid: () => Utils.random_string(5)
    },
    watch: {
        value(){ this.model = this.value },
        model(){ this.$emit('changed', this.model) }
    }
}
</script>