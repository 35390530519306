<template>
    <section class="fw">
        You can reset the password and send a new invite to <b>{{ space.client_name }}</b>.

        <p class="mt-3 mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            The current invite (if any) will be invalid immediately
        </p>
        <p class="mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            A new invite is sent to <b>{{ space.client_email }}</b>
        </p>
        <p class="mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            The new link can be used in the next 24 hours
        </p>

        <div class="mt-3 text-center">
            <button class="btn btn-primary text-bold" @click="reset_space_password()" :disabled="loading">
                Reset password and send new invite
                <i class="ion-load-c spin ml-1" v-if="loading"></i>
            </button>
            <div v-if="error" class="mt-2 text-danger" v-html="error"></div>
        </div>

    </section>
</template>

<script>

import { ClientSpacesService } from '@/services/client_spaces-service.js'
import { Utils } from '@/helpers/utils.js'

export default {
    props: ['space', 'space_updated', 'close_modal'],

    data(){
        return {
            loading: false,
            error: false
        }
    },

    methods: {
        reset_space_password(){
            if(!this.space){
                return
            }

            this.loading = true
            this.error = false
            ClientSpacesService.reset_password(this.space.key).then(res => {
                const updated_space = res.body

                // update the space
                if(this.space_updated){
                    this.space_updated(updated_space)
                }

                this.loading = false
                Utils.show_success("Password reset of <b>"+this.space.client_name+"</b> successful, new invite sent.")

                if(this.close_modal){
                    this.close_modal()
                }

            }).catch(err => {
                console.error("Failed to reset password of space " + this.space.client_name, err)
                this.loading = false
                this.error = err.body ? err.body.message : "Failed to reset password!"
            })
        },
    }
}
</script>

<style scoped>

@media(max-width: 576px){

    i.ion-checkmark{
        margin-left: 0px !important
    }
}
</style>
