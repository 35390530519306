<template>
<section>

    <section class="d-none d-xl-block">
        <!-- Desktop form -->
        <form v-on:submit.prevent="submit_form" v-if="form">
            <table class="table client_space_form settings-table mb-0">
                <tr v-if="form_error">
                    <td colspan="2">
                        <div class="alert alert-danger" v-html="form_error"></div>
                    </td>
                </tr>

                <!-- Batch mode -->
                <tr v-if="batch_mode && !raw_csv">
                    <th class="">
                        Outlook contacts<br/>CSV file
                        <!--
                        <i class="ion-information-circled icon-lg ml-1" data-container="body" data-toggle="popover" data-placement="bottom" data-html="true" data-trigger="hover" data-content="You can export your Outlook contacts as <b>Comma Separated Values</b> following this tutorial:<br/><a href='https://support.office.com/en-us/article/export-contacts-from-outlook-10f09abd-643c-4495-bb80-543714eca73f' target_='_BLANK'>Export contacts from Outlook</a>"></i>
                        -->
                    </th>
                    <td>
                        <input v-if="!raw_csv" type="file" class="form-control form-control-sm" @change="read_csv">
                        <button v-else class="btn btn-sm btn-secondary" @click="raw_csv = null; parsed_contacts = null" type="button">Load a new CSV</button>
                        <div class="mt-2 text-sm d-flex align-items-center justify-content-between">

                            <a href='https://support.office.com/en-us/article/export-contacts-from-outlook-10f09abd-643c-4495-bb80-543714eca73f' target='_BLANK'><i class="ion-share mr-1"></i>How to export contacts from Outlook</a>
                            <div>
                                <a href="assets/static/skyflok_client_space_import_simple.csv" class="" title="Download a simple example CSV file" download="skyflok_client_space_import_simple.csv">Example csv 1</a>
                                <a href="assets/static/outlook_contacts_exported.csv" class="ml-1"  title="Download an example for Outlook exported contacts" download="outlook_contacts_exported.csv">Example csv 2</a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="batch_mode">
                    <th>Clients<span v-if="parsed_contacts"> ({{ parsed_contacts.length }})</span></th>
                    <td>
                        <div v-if="!parsed_contacts" class="muted text-sm text-italic">You will see the list of imported clients here after loading a CSV file</div>
                        <div v-else>

                            <div v-if="parsed_contacts.length != valid_batch_clients.length" class="m-2 text-warning text-sm">
                                <i class="ion-alert-circled mr-1"></i>
                                There is a problem with {{ parsed_contacts.length - valid_batch_clients.length }} out of {{ parsed_contacts.length }} contacts. We cannot create a Space for them unless you fix the problems manually.
                            </div>
                            <div style="max-height: 300px; overflow-y: auto">
                                <table class="table table-sm table-bordered">
                                    <thead>
                                        <th style="width: 1px" class="text-center"></th>
                                        <th style="width: 1px">
                                            <div class="c-pointer" @click="parsed_contacts[0].send_email = !parsed_contacts[0].send_email; parsed_contacts.map(c => { c.send_email = parsed_contacts[0].send_email })" data-container="body" data-toggle="popover" data-placement="top" data-html="true" data-trigger="hover" data-content="Send an invite email to the client.<br/><br/>Click to check/uncheck all"><i class="ion-email"></i></div>
                                        </th>
                                        <th class="text-center">Client Name</th>
                                        <th class="text-center">E-mail address</th>
                                        <th style=""></th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contact, index) in parsed_contacts" :key="index">
                                            <td class="nowrap">
                                                {{ index+1 }}.
                                                <i v-if="contact.name == '' || !validate_email(contact.email)" class="ion-alert-circled text-warning" title="There is a problem with this contact, please fix if you want to create a Space for him/her!"></i>
                                            </td>
                                            <td>
                                                <input type="checkbox" class="form-control form-control-sm" v-model="contact.send_email"/>
                                            </td>
                                            <td :class="{'table-danger': contact.name == ''}">
                                                <input type="text" v-model="contact.name" class="form-control form-control-sm" >
                                            </td>
                                            <td :class="{'table-danger': !validate_email(contact.email)}">
                                                <input type="email" v-model="contact.email" class="form-control form-control-sm" >
                                            </td>
                                            <td class="text-center">
                                                <a href="#" class=" text-danger" @click="parsed_contacts.splice(index, 1)" title="Remove this row"><i class="ion-close-round"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="text-center">
                                                <a href="#" class="text-sm" @click.prevent="parsed_contacts.push({name: '', email: '', send_email: true})"><i class="ion-plus-round mr-1"></i>Add a client manually</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>

                <!-- Single Space Mode -->
                <tr v-if="!batch_mode && (form.id === null || (space && !space.edit_managers_only))">
                    <th>Client name *</th>
                    <td><input type="text" class="form-control form-control-sm" id="form-client_name" v-model="form.client_name" required maxlength="255" /></td>
                </tr>
                <tr v-if="!batch_mode && (form.id === null || (space && !space.edit_managers_only))">
                    <th>Client e-mail *</th>
                    <td><input type="email" class="form-control form-control-sm" id="form-client_email" v-model="form.client_email" required maxlength="255" /></td>
                </tr>

                <tr v-if="form.id === null || (space && !space.edit_managers_only)">
                    <th class="v-top">Client logo<div v-if="batch_mode"><small>(applies to all spaces)</small></div></th>
                    <td style="width: 80%; padding-top: 0px">
                        <div id="client_space_logos_carousel">
                            <div class="slide d-inline-block" v-for="logo in logos" :key="logo.path">
                                <img
                                    :title="logo.name+' (Click to '+(logo.selected ? 'un' : '')+'select)'"
                                    :src="logo.path"
                                    @click="select_logo(logo)"
                                    :class="{'selected': logo.selected}"
                                    class="logo c-pointer m-1"
                                    width="84"
                                    height="84"/>
                            </div>
                        </div>

                        <div v-if="space !== null && custom_logo_url" class="mt-2">
                            <span class="text-sm mr-2">Custom logo:</span>
                            <img :src="custom_logo_url" class="shadow-z5 thumb64 rounded fit-cover">
                            <button type="button" class="btn btn-sm btn-secondary" @click="custom_logo_url = null">Clear</button>
                        </div>

                        <div v-else class="mt-2">
                            <span v-if="!custom_logo_url" class="text-sm mr-2">Upload custom logo:</span>
                            <profile-image-selector
                                class="d-inline-block"
                                :avatar_changed="(avatar_url) => { custom_logo_url = avatar_url; logos.forEach(logo => { logo.selected = false }) }"
                                :max_dimension_px="128">
                            </profile-image-selector>
                        </div>
                    </td>
                </tr>
                <tr v-if="form && form.client_readonly !== undefined">
                    <th class="v-top">Client role<div v-if="batch_mode"><small>(applies to all spaces)</small></div></th>
                    <td>
                        <button class="btn mb-2" :class="{'btn-success': !form.client_readonly, 'btn-secondary': form.client_readonly}" type="button" @click="form.client_readonly=false">
                            <b>Upload, download and delete</b><br/>
                            The client can upload, download &amp; delete files<br/>and change the folder structure.
                        </button>
                        <button class="btn" :class="{'btn-primary': form.client_readonly, 'btn-secondary': !form.client_readonly}" type="button" @click="form.client_readonly=true">
                            <b>Download only</b><br/>
                            The client cannot change anything <br/>or upload new files, just browse and download.
                        </button>
                    </td>
                </tr>
                <tr>
                    <th class="v-top nowrap">
                        Space Managers <i class="ion-information-circled icon-lg ml-1"  data-container="body" data-toggle="popover" data-placement="top" data-html="true" data-trigger="hover" data-content="Team Members who are allowed to upload files to this Space.<br/><br/>Note that Team Administrators can see and open every Space, but even they cannot upload/download if they are not Space Managers."></i>
                        <div v-if="batch_mode"><small>(applies to all spaces)</small></div>
                        <div class="text-normal" :class="{'text-danger': members.filter(m => { return m.selected }).length === 0 }"><i class="text-sm">{{ members.filter(m => { return m.selected }).length }} selected</i></div>
                        <div>
                            <small><a href="#" @click.stop="members.filter(m=>{ return m.selected }).length < members.length ? members.map(m => { m.selected = true }) : members.map(m => { m.selected = false })">Select {{ members.filter(m=>{ return m.selected }).length < members.length ? 'all' : 'none' }}</a></small>
                        </div>
                    </th>
                    <td>
                        <div
                            v-for="member in members"
                            :key="member.user_id"
                            class="float-left member-box m-1"
                            :class="{'bg-info text-white active text-bold': member.selected}"
                            @click="member.selected = !member.selected">
                            <user-box
                                :team="team"
                                :user-id="member.user_id"
                                :no-prefix="true"
                                class='m-1 ml-2 mr-2'
                                :title="'Click to '+(member.selected ? 'remove' : 'add')+' ' + member.name">
                            </user-box>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>Notifications</th>
                    <td>
                        <button class="btn text-bold mr-1" @click="form.watch_managers = true" :class="{'btn-success': form.watch_managers, 'btn-secondary': !form.watch_managers}" type="button">Yes <i class="ion-android-notifications ml-2"></i></button>
                        <button class="btn text-bold" @click="form.watch_managers = false" :class="{'btn-secondary btn-gradient': !form.watch_managers, 'btn-secondary': form.watch_managers}" type="button">No <i class="ion-android-notifications-off ml-2"></i></button>
                        <br/>
                        <small>Space Managers get an email notification when the Client uploads new files</small>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="text-right">
                        <button class="btn btn-secondary mr-2" type="button" @click.prevent="closeDialog()">Cancel</button>
                        <button class="btn btn-info btn-gradient" type="submit" :disabled="form_loading || !is_submit_allowed">
                            <span v-if="form.id !== null">Update Space</span>
                            <span v-else-if="!batch_mode">Create Space & Send Invite <i class="ion-paper-airplane ml-1"></i></span>
                            <span v-else>Create {{ valid_batch_clients.length }} Space{{ valid_batch_clients.length === 1 ? '' : 's' }} & Send Invitation{{ valid_batch_clients.length === 1 ? '' : 's' }}</span>
                            <i v-if="form_loading" class="ml-2 ion-load-c spin"></i>
                        </button>
                        <div v-if="form.id == null">
                            <small><a class="c-help" href="#" @click.prevent="() => { return false }" data-container="body" data-toggle="popover" data-placement="top" data-html="true" data-trigger="hover" data-content="When you click the Create button below: <ul><li>The Space is created, you can start using it immediately.</li><li>We send an email to your Client with an invitation link. The link expires in 24 hours.</li><li>When your Client clicks the link, they can set their own password and you get an email notification.</li><li>If the invitation expires without the Client accepting it, you get an email notification and you can send a new invite.</li></ul>">What happens when I click create? <i class="ion-information-circled"></i></a></small>
                        </div>
                    </td>
                </tr>
            </table>

            <!--
            <pre>{{form | json}}</pre>
            -->

        </form>
    </section>
    <section class="d-block d-xl-none">
        <!-- Mobile form (no batch mode on mobile) -->
        <div v-if="form_error" class="alert alert-danger" v-html="form_error"></div>
        <form v-on:submit.prevent="submit_form" v-if="form">
            <div class="mobile-form-field">
                <div class="text-bold">Client Name</div>
                <input type="text" class="form-control form-control-sm" id="form-client_name" v-model="form.client_name" required maxlength="255" />
            </div>

            <div class="mobile-form-field">
                <div class="text-bold">Client E-mail</div>
                <input type="email" class="form-control form-control-sm" id="form-client_email" v-model="form.client_email" required maxlength="255" />
            </div>

            <div class="mobile-form-field" v-if="form.id === null || (space && !space.edit_managers_only)">
                <div class="text-bold">Logo</div>
                <div id="client_space_logos_carousel" class="mb-2">
                    <div class="slide d-inline-block" v-for="logo in logos" :key="logo.path">
                        <img
                            :title="logo.name+' (Click to '+(logo.selected ? 'un' : '')+'select)'"
                            :src="logo.path"
                            @click="select_logo(logo)"
                            :class="{'selected': logo.selected }"
                            class="builtin-logo logo c-pointer m-1"
                            width="50"
                            height="50"/>
                    </div>
                </div>

                <div v-if="space !== null && custom_logo_url">
                    <span class="text-sm mr-2">Custom logo:</span>
                    <img :src="custom_logo_url" class="shadow-z5 thumb64 rounded fit-cover">
                    <button type="button" class="btn btn-sm btn-secondary" @click="custom_logo_url = null">Clear</button>
                </div>

                <div v-else>
                    <span class="text-sm mr-2" v-if="!custom_logo_url">Or upload custom image:</span>
                    <profile-image-selector
                        class="d-inline-block"
                        :max_dimension_px="128"
                        :avatar_changed="(avatar_url) => { custom_logo_url = avatar_url; logos.forEach(logo => { logo.selected = false }) }">
                    </profile-image-selector>
                </div>
            </div>

            <div class="mobile-form-field" >

                <div class="text-bold">Client role<span v-if="batch_mode"><small>(applies to all spaces)</small></span></div>

                <button class="btn fw mb-2 btn-sm text-sm client-role-button" :class="{'btn-success': !form.client_readonly, 'btn-secondary': form.client_readonly}" type="button" @click="form.client_readonly=false">
                    <b>Upload, download and delete</b>
                    <div class="break-word d-block">The client can upload, download &amp; delete files and change the folder structure.</div>
                </button>
                <button class="btn fw btn-sm text-sm client-role-button" :class="{'btn-primary': form.client_readonly, 'btn-secondary': !form.client_readonly}" type="button" @click="form.client_readonly=true">
                    <b>Download only</b><br/>
                    The client cannot change anything  or upload new files, just browse and download.
                </button>
            </div>

            <div class="mobile-form-field d-block">
                <div class="text-bold">
                    Space Managers
                    <span class="text-normal ml-2" :class="{'text-danger': members.filter(m => { return m.selected }).length === 0 }"><i class="text-sm">{{ members.filter(m => { return m.selected }).length }} selected</i></span>
                </div>
                <div class="fw">
                    <div
                        v-for="member in members"
                        :key="member.user_id"
                        class="member-box m-1 d-inline-block"
                        :class="{'bg-info text-white active text-bold': member.selected}"
                        @click="member.selected = !member.selected">
                        <user-box
                            :team="team"
                            :user-id="member.user_id"
                            :no-prefix="true"
                            class='m-1 ml-2 mr-2'
                            :title="'Click to '+(member.selected ? 'remove' : 'add')+' ' + member.name">
                        </user-box>
                    </div>
                </div>
            </div>

            <div class="mobile-form-field">
                <div class="text-bold">Notifications</div>
                <div class="fw">
                    <span class="d-block text-sm mb-2">Get email notifications when the Client uploads new files</span>
                    <button class="btn text-bold btn-sm text-sm mr-1" @click="form.watch_managers = true" :class="{'btn-success': form.watch_managers, 'btn-secondary': !form.watch_managers}" type="button">Yes <i class="ion-android-notifications ml-2"></i></button>
                    <button class="btn text-bold btn-sm text-sm" @click="form.watch_managers = false" :class="{'btn-secondary btn-gradient': !form.watch_managers, 'btn-secondary': form.watch_managers}" type="button">No <i class="ion-android-notifications-off ml-2"></i></button>
                </div>
            </div>

            <div class="fw d-flex align-items-center justify-content-center mt-3">
                <button class="btn btn-secondary mr-1" type="button" @click.prevent="closeDialog()">Cancel</button>
                <button class="btn btn-info btn-gradient ml-1" type="submit" :disabled="form_loading || !is_submit_allowed">
                    <span v-if="form.id !== null">Update Space<i v-if="form_loading" class="ml-1 ion-load-c spin"></i></span>
                    <span v-else-if="!batch_mode">Create Space<i v-if="form_loading" class="ml-1 ion-load-c spin"></i></span>
                </button>
            </div>

        </form>


    </section>

    <!-- Result of a single-Space operation (create or update) -->
    <div v-if="form === null && new_space !== null">
        <div class="alert alert-success">
            <p><b>Invitation sent to {{ new_space.client_email }}</b></p>
            You will receive an email when <b>{{ new_space.client_name }}</b> joins the Space or when the invitation expires.
        </div>

        <div class="fw text-center">
            <button class="btn btn-secondary mr-1" @click="()=>{ closeDialog() }">Close</button>
            <a class="btn btn-primary ml-1" :href="new_space.key | client_space_link" @click="() => { closeDialog() }" target="_BLANK">Open Space <i class="ion-share ml-1"></i></a>
        </div>

    </div>

    <!-- Result of a batch create operation -->
    <div v-if="form === null && batch_result !== null">
        <h5 class="text-center mb-3">
            <b>{{ batch_result.filter(client => { return client.space_saved }).length }} out of {{ parsed_contacts.length }}</b> Client Spaces created successfully.
        </h5>

        <div style="max-height: 300px; overflow-y: auto">
            <table class="table table-sm ">
                <thead>
                    <th class="width-0"></th>
                    <th class="">Client Name</th>
                    <th class="">E-mail address</th>
                    <th class="text-center nowrap width-0">Space created</th>
                    <th class="text-center nowrap width-0">Invite sent</th>
                </thead>
                <tbody>
                    <tr v-for="(space, index) in batch_result" :key="index">
                        <td>{{index+1}}.</td>
                        <td>
                            {{ space.client_name }}
                        </td>
                        <td>
                            {{ space.client_email }}
                        </td>
                        <td class="text-center">
                            <i class="text-success ion-checkmark-circled" v-if="space.space_saved"></i>
                            <i class="text-warning ion-alert-circled" v-else></i>
                        </td>
                        <td class="text-center">
                            <i class="text-success ion-checkmark-circled" v-if="space.email_sent"></i>
                            <i class="ion-minus-circled text-muted" v-else></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="text-center mt-4">
            <button class="btn btn-secondary" @click="closeDialog()">Close this window</button>
        </div>
    </div>

</section>
</template>

<script>
import { Utils } from '@/helpers/utils.js';
import { ClientSpacesService } from '@/services/client_spaces-service.js';
import userBox from '@/components/user-box.vue';
import profileImageSelector from '@/components/profile-image-selector.vue'
import Vue from 'vue';

export default {
	props: ['space', 'team', 'user', 'closeDialog', 'batch_mode', 'hide_form_switch', 'reload_spaces_callback'],
	components: { userBox, profileImageSelector },
	data() {
		return {
			form: null,
			form_loading: false,
            form_error: false,

            custom_logo_url: null,

			logos: [],
            members: [],

            raw_csv: null,
            parsed_contacts: null,
            batch_result: null,

			new_space: null,
            Utils: Utils
		};
	},

	created() {
		// Fill up logos
		this.logos = [];
		for (var i = 0; i < Utils.client_space_logos.length; ++i) {
			this.logos.push({
				path: Utils.client_space_logo_src(Utils.client_space_logo_base_path + Utils.client_space_logos[i]) ,
				name: Utils.client_space_logos[i].replace('.png', '').replace('_', ' '),
                filename: Utils.client_space_logos[i],
				selected: false
			});
		}
    },

	mounted() {
		this.init_form();
        this.$nextTick(function(){
            $("#form-client_name").focus()
        })
    },

	computed: {
		space_id() {
			return this.space ? this.space.id : null;
        },

        is_submit_allowed(){
            const space_members_selected = this.members && (this.members.filter(m => { return m.selected }).length > 0)

            const clients_ok = this.batch_mode ?
                (this.valid_batch_clients.length > 0)
                : (this.form.client_name && this.form.client_name.length > 0 && Utils.is_valid_email(this.form.client_email))

            return space_members_selected && clients_ok
        },

        valid_batch_clients(){
            if(!this.parsed_contacts){
                return []
            }

            return this.parsed_contacts.filter(c => {
                return c.name && c.name.length > 0 && Utils.is_valid_email(c.email)
            })
        }

	},

	watch: {
		space_id() {
			this.init_form();
        },

        batch_mode(){
            // Activate popover
            this.$nextTick( ()=>{ $('[data-toggle="popover"]').popover() })
        },

        raw_csv(){
            if(!this.raw_csv){
                return null
            }

            const csv_array = Utils.csv_to_array(this.raw_csv)
            if(!csv_array || csv_array.length < 2){
                this.form_error = "Error reading CSV!"
                return null
            }

            // The CSV Array looks like this:
            // [Line 0]: "Title", "First Name", "Middle Name", "Last Name", ..., "E-mail Address", ...
            // [Line 1]: "", "John", "", "Smith", ..., "john@smith.com", ...
            // ...
            // To get the name, we concatenate the following: Title, First name, Middle Name, Last Name
            const headings_row = csv_array[0]
            for(var i=0 ; i<headings_row.length ; ++i){ headings_row[i] = headings_row[i].toLowerCase() }

            const clientname_index = headings_row.indexOf("client name")
            const name_index = headings_row.indexOf("name")
            const title_index = headings_row.indexOf("title")
            const firstname_index = headings_row.indexOf("first name")
            const middlename_index = headings_row.indexOf("middle name")
            const lastname_index = headings_row.indexOf("last name")

            const email_index = headings_row.indexOf("e-mail address")

            let parsed_contacts = []

            // Construct the parsed contacts from the "body" rows of the CSV
            for(var i=1 ; i<csv_array.length ; ++i){
                const row = csv_array[i]
                if(!row || row.length == 0){
                    continue
                }
                // Parse name
                let name_parts = []

                if(clientname_index >= 0 && row[clientname_index] && row[clientname_index] !== ""){ name_parts.push(row[clientname_index]) }
                else if(name_index >= 0 && row[name_index] && row[name_index] !== ""){ name_parts.push(row[name_index]) }
                else{
                    if(title_index >= 0 && row[title_index] && row[title_index] !== ""){ name_parts.push(row[title_index]) }
                    if(firstname_index >= 0 && row[firstname_index] && row[firstname_index] !== ''){ name_parts.push(row[firstname_index]) }
                    if(middlename_index >= 0 && row[middlename_index] && row[middlename_index] !== ''){ name_parts.push(row[middlename_index]) }
                    if(lastname_index >= 0 && row[lastname_index] && row[lastname_index] !== ''){ name_parts.push(row[lastname_index]) }
                }

                const name = name_parts.length > 0 ? name_parts.join(' ') : null

                // Parse email
                const email = (email_index >= 0 && row[email_index] && row[email_index] !== '') ? row[email_index] : null

                if(name !== null || email !== null){
                    parsed_contacts.push({
                        name: name,
                        email: email,
                        send_email: true
                    })
                }
            }

            // Remove duplicates by email address
            this.parsed_contacts = []
            parsed_contacts.forEach(contact => {
                // Check if this email address is not yet in this.parsed_contacts
                const existing_item = this.parsed_contacts.find(pc => { return pc.email === contact.email })
                if(!existing_item){
                    this.parsed_contacts.push(contact)
                }
            })

            // Activate popover
            this.$nextTick( ()=>{ $('[data-toggle="popover"]').popover() })
        }

	},

    methods: {

        init_form() {
            this.form = {};

            this.form_loading = false;
            this.form_error = false;

            if (this.space === null) {
                // New space
                this.form = {
                    id: null,

                    client_name: null,
                    client_logo: null,
                    client_email: null,

                    client_readonly: false,

                    is_starred: false,
                    is_archived: false,
                    members: [],

                    watch_managers: false
                };
            } else {
                // Edit existing Space, clone 'space' prop to local variable 'form'
                this.form = Vue.util.extend({}, this.space)
            }

            // Reset logo
            this.logos.forEach(l => l.selected = false);
            if(ClientSpacesService.is_skyflok_logo(this.form)){
                // Make built-in logo selected or populate the custom logo url
                // The path in this.form.client_logo may be different than the built-in logos path, compare only on the file name
                const client_logo_name = this.form.client_logo.substr(this.form.client_logo.lastIndexOf('/')+1)
                console.log(client_logo_name)
                const logo = this.logos.find(l => l.filename == client_logo_name)
                if(logo){ logo.selected = true }
            }
            else{
                this.custom_logo_url = this.form.client_logo
            }
            

            // Load members
            this.members = [];
            this.members = this.user.team.map(m => {
                // Make a copy and add the 'selected' attribute
                m = Vue.util.extend({}, m)
                m.selected = false
                return m
            })
            

            // If this is an existing space, also select all the members of the space
            if (this.form.members) {
                this.form.members.forEach(user_id => {
                    let m = this.members.find(m => { return user_id === m.user_id; });
                    if (m) {
                        m.selected = true;
                    }
                });
            }

            // Pre-select the logged in user for a new space and the first logo
            if (this.space === null) {
                const me = this.members.find(m => { return m.user_id === this.user.user_id; });
                if (me) {
                    me.selected = true;
                }
                this.logos[0].selected = true
            }

            // Activate popover
            this.$nextTick( ()=>{ $('[data-toggle="popover"]').popover() })
        },

        submit_form() {
            if(!this.batch_mode){
                if(!this.form.client_name || this.form.client_name.length === 0) {
                    this.form_error = 'Please fill in the <b>Client name</b>!';
                    document.getElementById('form-client_name').focus();
                    document.getElementById('form-client_name').classList.add('form-control-danger');
                    return;
                }
                if (!this.form.client_email || this.form.client_email.length === 0) {
                    this.form_error = 'Please fill in the <b>Client e-mail address</b>!';
                    document.getElementById('form-client_email').focus();
                    document.getElementById('form-client_email').classList.add('form-control-danger');
                    return;
                }
                if (!Utils.is_valid_email(this.form.client_email)) {
                    this.form_error = "The client e-mail address doesn't seem to be valid!";
                    document.getElementById('form-client_email').focus();
                    document.getElementById('form-client_email').classList.add('form-control-danger');
                    return;
                }
            }
            else{
                if(this.valid_batch_clients.length === 0){
                    this.form_error = "At least one client data (name and email address) must be valid."
                    return
                }
            }


            // OK

            this.form.members = [];
            this.members.filter(m => { return m.selected; }).forEach(m => {
                this.form.members.push(m.user_id);
            });

            // If a custom logo URL is set but no built-in logo is selected, set the form field
            const selected_logo = this.logos.find(l => { return l.selected })
            if(selected_logo){
                // A logo is selected
                this.form.client_logo = selected_logo.path
            }
            else if(this.custom_logo_url){
                // A custom logo URL is set
                this.form.client_logo = this.custom_logo_url
            }
            else{
                // No logo
                this.form.client_logo = null
            }

            this.form_error = false;
            this.form_loading = true;

            let promise = null

            if(this.form.id){
                // Update a Space
                promise = ClientSpacesService.update(this.form)
            }
            else if(!this.batch_mode){
                promise = ClientSpacesService.create(this.form)
            }
            // Handle batch mode completely separately (below)

            if(promise){
                promise.then(res => {

                    const new_space = res.body;

                    this.form_loading = false;
                    this.hide_form_switch()
                    if(this.reload_spaces_callback){
                        this.reload_spaces_callback()
                    }


                    new_space.link = this.$options.filters['client_space_link'](new_space.key);

                    if (this.form.id) {
                        // Edited successfully
                        Utils.show_success('Space of <b>' + this.form.client_name + '</b> updated successfully!')

                        this.closeDialog();
                    } else {
                        // New space created
                        this.form = null;
                        this.new_space = new_space;
                    }
                })
                .catch(err => {
                    this.form_loading = false;
                    this.hide_form_switch()
                    this.form_error = 'Error saving Client Space, please try again later!';
                });
            }

            if(this.batch_mode){

                ClientSpacesService.create_batch(this.form, this.valid_batch_clients).then(res => {

                    this.form_loading = false
                    this.form = null
                    this.batch_result = res.body

                    this.hide_form_switch()

                    if(this.reload_spaces_callback){
                        this.reload_spaces_callback()
                    }


                }).catch(err => {
                    console.error("Batch create error: ", err)
                    this.form_loading = false
                    this.hide_form_switch()
                    this.form_error = "Error creating Spaces: " + err.body.message
                })

            }

        },

        select_logo(logo) {
            if (logo.selected) {
                // Unselect the existing one, fall back to the custom url
                logo.selected = false;
            } else {
                // Unselect everyone, select the given logo
                this.logos.forEach(l => {
                    l.selected = (l.path === logo.path)
                });

                this.custom_logo_url = null
            }
        },

        read_csv($evt){
            var files = $evt.target.files || $evt.dataTransfer.files;
            if (!files || !files.length || files.length < 1){ return; }
            const csv_file = files[0]
            const extension = Utils.get_file_extension(csv_file.name)

            if(extension.toLowerCase() !== "csv"){
                // Not a CSV
                $evt.target.value = ""
                this.raw_csv = null
                this.form_error = "Please select a CSV file!"
                return
            }

            // Image was selected
            var self = this
            var reader = new FileReader()
            reader.onloadend = function(evt){
                self.raw_csv = reader.result;
            }
            reader.readAsText(csv_file, 'ISO-8859-1')
        },

        validate_email(email){
            return Utils.is_valid_email(email)
        },

    }
};
</script>

<style scoped>

table.client_space_form th {
  text-align: right;
  vertical-align: top;
}
/*
table.client_space_form tr:first-child td,
table.client_space_form tr:first-child th,
table.client_space_form tr:last-child td,
table.client_space_form tr:last-child th {
  border: none;
}
*/

.logo {
  border: 2px solid transparent;
  cursor: pointer;
}
.selected {
  border: 2px solid darkgray !important;
  box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.15);
}

.member-box {
    padding: 5px 2px;
    cursor: pointer;
    border-radius: 2px;
}

.member-box:hover {
    background: rgba(0, 0, 0, .1)
}

.mobile-form-field{
    padding-bottom: 1em;
    margin-left: 1em
}

.mobile-form-field div.text-bold:first-child{
    display: inline-block;
    padding-bottom: .5em;
    margin-left: -1em
}


.client-role-button{
    white-space: normal
}

th.v-top {
  vertical-align: top;
}
</style>
