import { TokenCache } from './token-cache.js'
import Vue from 'vue'



let bus = new Vue({
    computed: {
        auth_header: function(){
            return TokenCache.is_stored() ? { "Authorization": "Bearer " + TokenCache.get() } : null
        }
    }
})


// Has to be strings to work properly :(
const events = {
    LOGIN_SUCCESSFUL: "LOGIN_SUCCESSFUL",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT: "LOGOUT",

    USED_STORAGE_CHANGED: "USED_STORAGE_CHANGED",
    USER_PROFILE_CHANGED: "USER_PROFILE_CHANGED",
    TEAM_MEMBER_PROFILE_CHANGED: "TEAM_MEMBER_PROFILE_CHANGED",
    RELOAD_TEAM: "RELOAD_TEAM",
    RELOAD_TEAM_SETTINGS: "RELOAD_TEAM_SETTINGS",
    RELOAD_BUCKETS: "RELOAD_BUCKETS",
    TEAM_LOADED: "TEAM_LOADED",
    SPACES_LOADED: "SPACES_LOADED",

    SHOW_ALERT: "SHOW_ALERT",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",

    HIGHLIGHT_FILE: "HIGHLIGHT_FILE",
    REMOVE_FROM_FILELIST: "REMOVE_FROM_FILELIST",
    FILE_FOLDER_DRAG_START: "FILE_FOLDER_DRAG_START",
    FILE_FOLDER_DRAG_END: "FILE_FOLDER_DRAG_END",
    FILELIST_CHANGE_FOLDER_TO: "FILELIST_CHANGE_FOLDER_TO",
    FILELIST_CHANGE_FOLDER_TO_ID: "FILELIST_CHANGE_FOLDER_TO_ID",
    NAVIGATE_TO: "NAVIGATE_TO",
    RELOAD_2FA_STATUS: "RELOAD_2FA_STATUS",

    SHARE_ARCHIVED: "SHARE_ARCHIVED",
}

export { bus, events }