import { bus } from '../helpers/event_bus.js'

// This is not exported
const base_url = process.env.VUE_APP_config_service_url

const ConfigService = {

    get_config(plan_key){
        return bus.$http.get(base_url + "/plans/"+plan_key)
    }

}

export { ConfigService }