<template>
    <div>

        <span v-if="!avatar_url">
            <input type="file" class="form-control form-control-sm" id="new_profile_photo_input" @change="image_changed" />
        </span>

        <span v-else>
            <img :src="avatar_url" class="fit-cover img-thumbnail" :class="[imgclass]" style="max-width: 80%"/>
            <button type="button" class="btn btn-sm btn-secondary" @click="avatar_url = null">Clear</button>
        </span>

    </div>
</template>
<script>

export default {

    //props: ["imgclass", "avatar_changed", "max_dimension_px"],
    props: {
        imgclass: { type: String, default: "" },
        avatar_changed: { type: Function, default: ()=>{} },
        max_dimension_px: { type: Number, default: 400 }
    },


    data(){
        return {
            avatar_url: null
        }
    },

    watch: {
        avatar_url(){
            if(this.avatar_changed){
                this.avatar_changed(this.avatar_url)
            }
        }
    },

    methods: {

        imageToDataUri(img, width, height) {

            // create an off-screen canvas
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            // set its dimension to target size
            canvas.width = width;
            canvas.height = height;

            // draw source image into the off-screen canvas:
            ctx.drawImage(img, 0, 0, width, height);

            // encode image to data-uri with base64 version of compressed image
            const result = canvas.toDataURL();
            canvas = null;
            return result
        },

        resizeImage(img, picture) {

            var width = img.width;
            var height = img.height;
            var self = this;

            const max_dimension_px = this.max_dimension_px 
                                        ? this.max_dimension_px 
                                        : 400
            

            if (width > max_dimension_px || height > max_dimension_px)
            {
                var scale = max_dimension_px / Math.max(width, height);
                width = scale * width;
                height = scale * height;

                self.avatar_url = self.imageToDataUri(img, width, height);
            }
            else if (picture.type == "image/gif")
            {
                self.avatar_url = self.imageToDataUri(img, width, height);
            }
            else
            {
                self.avatar_url = img.src;
            }

        },


        image_changed($evt){
            var files = $evt.target.files || $evt.dataTransfer.files;
            if (!files || !files.length || files.length < 1){ return; }
            const picture = files[0]

            if(picture.type.split("/")[0] != "image"){
                // Not an image
                $evt.target.value = ""
                this.avatar_url = null
                return
            }

            // Image was selected
            var self = this
            var reader = new FileReader()
            reader.onloadend = () => {

                var img = new Image;

                img.onload = function () { self.resizeImage(img, picture) };
                img.src = reader.result;

            }
            reader.readAsDataURL(picture)
        },

    }
}
</script>
