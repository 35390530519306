<template>
<div>
    <div v-if="error" class="alert alert-danger">{{ error }}</div>
    <div v-if="success" class="alert alert-success">{{ success }}</div>
    <form v-on:submit.prevent="submit_form">
        <table class="table mb-0 settings-table" :class="{'': !editMode}">
            <tbody v-if="user && form">
                <tr>
                    <th class="width-50p">Name: </th>
                    <td>
                        <div v-if="!editMode">{{ user.name }}</div>
                        <input v-else type="text" id="user_name_input" v-model="form.name" class="form-control form-control-sm" />
                    </td>
                </tr>
                <tr>
                    <th>Job title: </th>
                    <td>
                        <div v-if="!editMode">{{ user.job_title || '-' }}</div>
                        <input v-else type="text" v-model="form.job_title" class="form-control form-control-sm" placeholder="E.g. CTO, software engineer, marketing trainee" />
                    </td>
                </tr>
                <tr v-if="editMode">
                    <th>New profile picture:</th>
                    <td>
                        <profile-image-selector :avatar_changed="(avatar_url) => { form.avatar_url = avatar_url }" :imgclass="'wd-sm rounded-circlewd-sm rounded-circle'"></profile-image-selector>
                    </td>
                </tr>
                <tr>
                    <th>E-mail address: </th>
                    <td>
                        {{ user.email_address }}
                        <div><small v-if="editMode"><i>{{ isAdmin ? "Cannot change the email address" : "You can't change your email address" }}</i></small></div>
                    </td>
                </tr>
                <tr v-if="!editMode || !psw_change">
                    <th style="vertical-align: middle">Password: </th>
                    <td>
                        <button v-if="editMode" @click="psw_change=true" class="btn btn-secondary" type="button" >{{ isAdmin === true ? "Change password of " + user.name : "I want to change my password" }}</button>
                        <div v-else>
                            <i class="ion-asterisk text-muted mr-1 text-sm" v-for="i in [1,2,3,4,5]" :key="i"></i>
                        </div>
                    </td>
                </tr>
                <tr v-if="editMode && psw_change && !isAdmin">
                    <th>Current password:</th>
                    <td><input type="password" id="current_password_input" class="form-control form-control-sm" v-model="form.current_psw" /></td>
                </tr>
                <tr v-if="editMode && psw_change">
                    <th>New password: </th>
                    <td>
                        <input type="password" class="form-control form-control-sm" v-model="form.new_password" />
                        <small style="font-size: 75%">At least 8 characters, add numbers and symbols for more safety</small>
                    </td>
                </tr>
                <tr v-if="editMode && psw_change">
                    <th>Repeat new password:</th>
                    <td>
                        <input type="password" class="form-control form-control-sm" v-model="form.new_password_2" />
                    </td>
                </tr>
                 <tr v-if="!editMode">
                    <th>Registered at:</th>
                    <td>{{ user.created | timestamp }} <small>({{ user.created | timestamp_rel }})</small></td>
                 </tr>

                <tr v-if="editMode">
                    <td colspan="2" class="text-center" style="border-top: none">
                        <div>
                            <button class="btn btn-secondary mr-2" type="button" @click="closeForm()">Cancel</button>
                            <button class="btn btn-info btn-gradient" type="submit" :disabled="form_loading">Save<i v-if="form_loading" class="ml-2 ion-load-c spin"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </form>

        <div v-if="editMode && user.team_settings">
            <div class="text-info text-center mt-1">
                <small>
                <i class="ion-information-circled text-info mr-1"></i>
                    Do you have questions or requests about privacy, how your personal information is handled or what happened to
                    files you uploaded? Would you like to get a copy of all your personal data? <br/>
                    We are happy to help! Contact us at <a href="mailto:SkyFlok Support<support@skyflok.com>">support@skyflok.com</a>!
                </small>
            </div>
            <hr/>
            <div style="width: 90%; margin: 0px auto">
                <small>
                Deleting your account is a permanent operation that cannot be reversed. <br/>
                Your SkyFlok account and all your personal information is deleted and you won't be able to log in any more. You lose access to every file too.<br/>
                Your past actions are kept in the system until the SkyFlok Team of {{ user.team_settings.name }} exists, but your name and photo are replaced by '<i>a deleted member</i>'
                </small>
            </div>
            <div class="text-center mt-2">
                <button class="btn btn-danger btn-gradient" @click="delete_my_account()" :disabled="delete_loading">I understand, delete my SkyFlok account<i v-if="delete_loading" class="ion-load-c spin ml-2"></i></button>
                <div v-if="delete_error" class="text-danger">{{ delete_error }}</div>
            </div>
        </div>
</div>
</template>

<script>

import { AuthService } from '@/services/auth-service.js'
import { UserService } from '@/services/user-service.js'
import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'

import profileImageSelector from '@/components/profile-image-selector.vue'

export default {
    props: ["user", "editMode", "closeForm", "browseImage", "isAdmin"],

    components: {
        profileImageSelector
    },

    data: function(){
        return {
            is_initialited: false,
            form: {},
            form_loading: false,
            error: false,
            success: false,
            psw_change: false,

            delete_loading: false,
            delete_error: false
        }
    },

    watch: {
        browseImage: function(){
            if(this.browseImage){
                setTimeout(function(){
                    document.getElementById("new_profile_photo_input").click()
                }, 200)
            }
        },
        user: function(){
            this.init_form()
        },
        editMode: function(){
            this.init_form()
        }
    },

    created: function(){
        this.is_initialited = false

        if(this.user){
            this.init_form()
        }
    },

    mounted: function(){
        setTimeout(function(){
            if(this.browseImage === true){
                document.getElementById("new_profile_photo_input").click()
            }
        }, 200)
    },

    methods: {

        init_form: function(){
            this.is_initialited = true
            this.form = {
                name: this.user.name,
                job_title: this.user.job_title,
                avatar_url: null,


                current_psw: null,
                new_password: null,
                new_password_2: null
            }
            this.psw_change = false
            this.error = false
            this.success = false
        },



        submit_form: function(){
            if(this.form.current_psw){
                if(this.form.current_psw.length < 8){
                    this.error = "Please fill in the current password!"
                    return
                }
                if(!this.form.new_password || this.form.new_password.length < 8){
                    this.error = "The new password must be at least 8 characters long!"
                    return
                }
                if(this.form.new_password != this.form.new_password_2){
                    this.error = "The passwords don't match!"
                    return
                }
            }

            this.error = false
            this.success = false

            let promises = []
            let user_update_ok = false
            this.form_loading = true
            let password_change_ok = null
            var self = this

            if(this.isAdmin === true){
                // Change own profile & password
                promises.push(UserService.edit_user(this.user.user_id, this.form.name, this.form.job_title, this.form.avatar_url)
                    .then(function(){
                        this.edit_mode = false
                        user_update_ok = true
                        Utils.show_success("Profile updated successfully")

                        let profile_change_details = {
                            id: self.user.user_id,
                            name: self.form.name,
                            job_title: self.form.job_title
                        }
                        if(self.form.avatar_url){
                            profile_change_details["avatar_url"] = self.form.avatar_url
                        }
                        bus.$emit(events.TEAM_MEMBER_PROFILE_CHANGED, profile_change_details)

                    })
                    .catch(err => {
                        console.error(err)
                        Utils.show_error("Error updating profile: " + err.body.message)
                    })
                )

                if(this.form.new_password){
                    // Password change
                    promises.push(AuthService.change_password_admin(this.user.user_id, this.form.new_password)
                        .then(function(){
                            password_change_ok = true
                            Utils.show_success("Password changed successfully")

                        }).catch(err => {
                            console.error(err)
                            Utils.show_error("Failed to change password: " + err.body.message)
                            password_change_ok = false
                        })
                    )
                }
            }
            else{
                // Change own profile & password
                promises.push(UserService.edit_me(this.form.name, this.form.job_title, this.form.avatar_url)
                    .then(() => {

                        user_update_ok = true
                        Utils.show_success("Profile updated successfully")

                        bus.$emit(events.USER_PROFILE_CHANGED, {
                            name: self.form.name,
                            avatar_url: self.form.avatar_url,
                            job_title: self.form.job_title
                        })

                    })
                    .catch(err => {
                        console.error(err)
                        Utils.show_error("Error updating profile" + err.body.message)
                    })
                )

                if(this.form.current_psw) {
                    // Password change
                    promises.push(AuthService.change_password(this.form.current_psw, this.form.new_password)
                        .then(function(){
                            password_change_ok = true
                            Utils.show_success("Password changed successfully")
                        }).catch(err => {
                            console.error(err)
                            Utils.show_error("Failed to change password: " + err.body.message)
                            password_change_ok = false
                        })
                    )
                }
            }

            Promise.all(promises).then(function(){
                self.form_loading = false
                if(!user_update_ok || password_change_ok === false){
                    // error should be shown by now
                }
                else{
                    self.error = false
                    self.closeForm()
                }
            })
        },

        delete_my_account(){
            if(!confirm("Are you sure you want to delete your own account? You will be logged out immediately and cannot log in any more!")){
                return;
            }

            // Well okay then
            this.delete_loading = true
            AuthService.delete_user(this.user.user_id).then(() => {
                this.delete_loading = false

                bus.$emit(events.LOGOUT)
                window.location.reload(true)

            }).catch(err => {
                this.delete_loading = false
                this.delete_error = "Error deleting account: " + err.body.message
            })
        }
    }
}
</script>

<style scoped>
    .settings-table th{
        text-align: right;
        vertical-align: middle;
    }
</style>
