<template>
<div class="cardbox b" style="box-shadow: none">
    <div class="cardbox-body" v-if="invite">
        <div class="fw pb-3 text-center text-bold text-14 color-skyflok">
            <strong v-if="is_free_trial">Free Trial</strong>
            <strong v-else-if="invite.invited_team_name">{{ invite.invited_team_name }}</strong>
            <strong v-else>Your SkyFlok Team</strong>
        </div>
        <p class="mb-3" v-if="invite.config.time_limit_days"><em class="ion-checkmark-round mx-3"></em><span>{{invite.config.time_limit_days}} Days</span></p>
        <p class="mb-3"><em class="ion-checkmark-round mx-3"></em><span>{{invite.config.initial_storage_gb || 'Unlimited'}} GB Storage</span></p>
        <p class="mb-3"><em class="ion-checkmark-round mx-3"></em><span>{{invite.config.initial_users || 'Unlimited'}} Team Members</span></p>
        <p class="mb-3"><em class="ion-checkmark-round mx-3"></em><span>{{invite.config.active_spaces_limit || 'Unlimited'}} Client Space{{invite.config.active_spaces_limit==1 ?'':'s'}}</span> <info-icon content="A secure folder where you and your clients can share files with each other. GDPR-compatible alternative to sending files in email."/></p>
        <p class="mb-3"><em class="ion-checkmark-round mx-3"></em><span>Data Location Selection <info-icon content="You can select where your files are stored physically" /></span></p>
    </div>
    <div v-else class="cardbox-body text-center" >
        <i class="ion-load-c spin color-skyflok text-md"></i>
    </div>
</div>
</template>
<script>

import infoIcon from './info-icon'

export default {
    props: {
        invite: {
            type: Object
        }
    },

    components: {
        infoIcon
    },

    computed: {
        is_free_trial(){
            return this.invite.key === 'skyflok-trial'
        },
        
    }
}
</script>