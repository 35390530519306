<template>
    <div class="nowrap d-inline-block">
        <section v-if="!is_narrow_screen">
            <i v-if="loading" class="ion-load-c spin text-muted mr-2"></i>
            <span @mouseover="search_focused = true" @mouseout="search_focused = false" @focus="search_focused = true" @blur="search_focused = false" >
                <input type="text" v-model="search_term" name="skyflok-search" id="skyflok-search-input" class="form-control" :class="{'wide': wide_search, 'hidden': hideinput}" :placeholder="placeholder || 'Search Team Files'"/>
                <i class="ion-close-round c-pointer" v-if="search_term != ''" style="margin-left: -20px;" @click="search_term = ''; search_focused = false"></i>
                <button id="searchbutton" @click="($evt) => $evt.target.parentNode.children[0].focus()" class="ml-2 btn btn-secondary btn-gradient ion-search"></button>
            </span>

            <div class="list-group mb-4 search_results shadow-z5" v-show="search_term != '' && results">
                <a class="list-group-item" href="#" v-for="result in results" :key="result.id"
                    @click.prevent="search_result_clicked(result)"
                    :title="(result.entity_type == FOLDER_TYPE ? ('Navigate to ' + result.path) : ('Show '+result.name))">
                    <div style="width: 40px;" class="text-center">
                        <i v-if="result.entity_type == FOLDER_TYPE" class="ion-folder"></i>
                        <img v-if="result.entity_type == FILE_TYPE" class="file-icon" :src="result.extension | filetype_img_src">
                    </div>
                    <div class="ml-2 text-left">
                        <div class="badge mr-2 bg-primary">{{ result.path | path }}</div>
                        <div class=""><strong>{{ result.name }}</strong></div>
                    </div>
                </a>
            </div>
        </section>
        <section v-else>
            <button class="btn btn-secondary btn-gradient" @click="open_search_dialog()"><i class="ion-search"></i></button>

            <!-- Search modal -->
            <div class="modal" id="search-modal">
                <div class="modal-dialog modal-lg cardbox mt-5" style="overflow: auto; box-shadow: none">
                    <div class="pb-1 bg-gradient-info"></div>
                    <div class="modal-content" style="border: none">
                        <div class="modal-body">
                            <button class="close" type="button" aria-label="Close" data-dismiss="modal"><span>&times;</span></button>
                                <div>
                                    <div class="d-flex align-items-center">
                                        <b class="text-12">{{ placeholder || 'Search Team Files' }}</b>
                                        <i v-if="loading" class="ion-load-c spin ml-2"></i>
                                        <span v-else-if="search_term != ''" class="badge badge-info ml-2">{{ results.length }} result{{ results.length === 1 ? '' : 's' }}</span>
                                    </div>
                                    <input
                                        type="text"
                                        v-model="search_term"
                                        name="skyflok-search"
                                        id="skyflok-search-input"
                                        class="form-control form-control-sm"
                                        placeholder="Search for file or folder name"
                                        />
                                </div>
                                <div class="list-group search_results" style="position: relative" v-show="search_term != '' && results">
                                    <a class="list-group-item" href="#" v-for="result in results" :key="result.id"
                                        @click.prevent="search_result_clicked(result)"
                                        :title="(result.entity_type == FOLDER_TYPE ? ('Navigate to ' + result.path) : ('Show '+result.name))">
                                        <div style="width: 40px;" class="text-center">
                                            <i v-if="result.entity_type == FOLDER_TYPE" class="ion-folder"></i>
                                            <img v-if="result.entity_type == FILE_TYPE" class="file-icon" :src="result.extension | filetype_img_src">
                                        </div>
                                        <div class="ml-2 text-left">
                                            <div class="badge mr-2 bg-primary">{{ result.path | path }}</div>
                                            <div class=""><strong>{{ result.name }}</strong></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    </div>


</template>
<script>

import { NamespaceService } from '@/services/namespace-service.js'
import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'

export default {
    props: ["placeholder", 'customNamespace', 'redirectWhenClicked', "hideinput"],
    data(){
        return {
            loading: false,
            search_term: "",
            search_focused: false,
            results: [],

            FOLDER_TYPE: NamespaceService.ENTITY_TYPE_FOLDER,
            FILE_TYPE: NamespaceService.ENTITY_TYPE_FILE,


        }
    },

    computed: {
        wide_search(){
            return this.search_focused || this.search_term.length > 0
        },

        is_narrow_screen(){
            return window.innerWidth <= 992
        }
    },

    watch: {
        search_term: function(){
            if(this.search_term == ''){
                // Clean
                this.results = [];
                return;
            }

            this.search()
        }
    },

    mounted(){
        // Move the modal directly to the Body, so it's above the backdrop (only on mobile screens)
        const modal = document.getElementById("search-modal")
        if(modal){
            document.body.appendChild(modal)
        }

    },

    methods: {

        search(){
            this.loading = true

            let cached_namespace = null
            if(!this.customNamespace){
                // cache the working namespace
                cached_namespace = NamespaceService.ns_type
                NamespaceService.ns_type = NamespaceService.NS_TYPE_DEFAULT
            }

            NamespaceService.search(this.search_term).then(res => {

                var results = []
                res.body.forEach(file => {
                    file.extension = Utils.get_file_extension(file.name)
                    results.push(file)
                })

                results.sort((a,b) => { return a.path.length - b.path.length })

                this.results = results
                this.loading = false

                document.getElementById("skyflok-search-input").focus()

            })
            .catch(err => {
                console.error(err)
                this.loading = false
            })

            if(cached_namespace){
                // Set the working namespace back to the original immediately after the search request
                NamespaceService.ns_type = cached_namespace
            }
        },

        search_result_clicked(el){

            // If we are already on Files, just change the hash
            // otherwise use Vue Router to change the page without full reload
            const location_hash = (el.entity_type === NamespaceService.ENTITY_TYPE_FOLDER) ? el.path : el.path.substring(0, el.path.lastIndexOf('/'))

            if(this.redirectWhenClicked === false || this.$route.path === '/files'){
                bus.$emit(events.FILELIST_CHANGE_FOLDER_TO, location_hash)

                setTimeout(function(){
                    // Highlight the file
                    bus.$emit(events.HIGHLIGHT_FILE, el.id)
                }, 500)
            }
            else{
                // otherwise, navigate to /files
                this.$router.push("/files")
                setTimeout(function(){
                    // Set the hash after the page switch
                    window.location.hash = location_hash

                    setTimeout(function(){
                        // Highlight the file
                        bus.$emit(events.HIGHLIGHT_FILE, el.id)
                    }, 500)

                }, 500)
            }

            this.search_term = ""
            this.results = null

            if(this.is_narrow_screen){
                $("#search-modal").modal('hide')
            }
        },

        open_search_dialog(){

            $("#search-modal").modal({

            })

            this.$nextTick(() => {
                document.getElementById("skyflok-search-input").focus()
            })

            var self = this
            $("#search-modal").on('hidden.bs.modal', function(){
                self.search_term = ""
            })
        }
    }
}
</script>

<style scoped>



.search_results{
    position: absolute;
    background: white;
    max-height: 400px;
    max-width: 420px;
    overflow: auto;
}

.theme-dark .search_results{
    background: rgb(53, 68, 76)
}

.search_results .list-group-item{
    display: -webkit-box;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.5em;
    padding: .8em
}


i,
input{
    vertical-align: middle;
}

input{
    display: inline-block;
    width: 160px;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    transition: .2s width ease-out
}


input.wide,
input:not(.hidden):hover,
input:focus{
    display:inline-block;
    width: 300px
}

input:not(.wide):not(:focus).hidden{
    display:none;
}

.ion-folder{
    font-size: 32px
}

#search-modal{
    line-height: 2em;
    z-index: 9999
}
#search-modal input,
#search-modal .search_results{
    width: 100%;
    max-width: 100%;
}
#search-modal .search_results .list-group-item{
    border-left: 0px;
    border-right: 0px;
    background-color: transparent
}


    /* Tablets and phones */
    @media(max-width: 992px){

        .search_results .list-group-item{
            padding: .5em;

        }

        .ion-folder{
            font-size: 26px
        }
    }

    /* Phones */
    @media(max-width: 576px){


    }

</style>
