import { bus, events } from './event_bus.js'
import { TokenCache } from './token-cache.js'
import { FileDownloader } from './file-downloader.js'

import md5 from 'md5'
import mimeTypes from 'mime-types'
//import platform from "platform"

const Utils = {
    _cached_images: [],

    device_id_key: "device_id",

    flag_images: require.context('../assets/img/flags', false, /\.png$/),
    cloud_provider_images: require.context('../assets/img/cloud_logos/350X150', false),
    client_space_images: require.context('../assets/img/client_space_logos', false, /\.png$/),
    asset_videos: require.context('../assets/videos', false),

    material_colors: [
        "red", "pink", "purple", "deep-purple", "indigo", "blue", "cyan",
        "teal", "green", "deep-orange", "brown", "blue-grey"
    ],

    APP_COLORS: {
        'gray-darker':            '#263238',
        'gray-dark':              '#455A64',
        'gray':                   '#607D8B',
        'gray-light':             '#90A4AE',
        'gray-lighter':           '#ECEFF1',

        'primary':                '#448AFF',
        'success':                '#4CAF50',
        'info':                   '#03A9F4',
        'warning':                '#FFB300',
        'danger':                 '#F44336'
    },

    all_colors: [
        "red", "pink", "purple", "deep-purple", "indigo", "blue", "light-blue", "cyan",
        "teal", "green", "light-green", "lime", "yellow", "amber", "orange", "deep-orange",
        "brown", "blue-grey", "grey"
    ],

    client_space_logo_base_path: "client_space_logos/",
    client_space_logos: [
        "Accounting.png","Insurance.png",
        "Notary.png","Startup.png",
        "Architecture.png","Journalist.png",
        "Photographer.png","University.png",
        "Doctor.png","Law_firm.png",
        "Researcher.png","Writer.png",
        "Film_Producer.png","Media_Designer.png",
        "SaaS.png"
    ],
    
    GOOGLE_PLATFORM_JS: "https://apis.google.com/js/platform.js",
    

    show_error(html, sticky){ bus.$emit(events.ERROR, html, sticky) },
    show_warning(html, sticky){ bus.$emit(events.WARNING, html, sticky) },
    show_success(html, sticky){ bus.$emit(events.SUCCESS, html, sticky) },

    make_error_message(message, err){

        if(err && err.status && err.body && err.body.message){
            message += ": <b>"+err.body.message+"</b>"
        }
        else{
            message += "."
        }
        return message
    },

    random_between: function(min, max){
        return Math.floor(Math.random() * max) + min
    },

    random_string: function(len) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < len; i++){
            text += possible.charAt(Utils.random_between(0, possible.length));
        }
        return text;
    },

    unique_array(array_with_duplicates){
        return [...new Set(array_with_duplicates)]
    },

    avatar_url: function(user){
        if(!user || !user.email_address){ return '' }
        return user.avatar_url ? user.avatar_url : 'https://www.gravatar.com/avatar/' + md5(user.email_address) + '?s=200&d=identicon'
    },

    is_valid_email(str){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return str ? re.test(String(str).toLowerCase()) : false;

    },

    capitalize: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    string_to_color: function(string, colors_arr){
        if(colors_arr === undefined){ colors_arr = Utils.material_colors }

        // Hash the string, convert hash to int, return color at int%colors_arr.length
        const hash = md5(string)
        let intval = 0;
        for (var i = 0; i < hash.length; i++) {
            intval += hash.charCodeAt(i);
        }
        return colors_arr[intval % colors_arr.length]
    },

    app_color_by_name(name) {
        return Utils.APP_COLORS[name]
    },

    path: function(orig_path_string){
        let path_segments = orig_path_string.split("/")
        // Add 'Home' to the beginning
        path_segments.unshift('Home')
        //path_segments.unshift('<i class="icon ion-home icon-lg" title="Home folder"></i>')
        // Remove last item (which is the file/folder name itself)
        path_segments.pop()

        return path_segments.join(" > ")
    },

    timestamp_ms: function(){
        //if(performance.now !== undefined) { return performance.now() }
        return new Date().getTime();
    },

    timestamp_sec: function() {
        return Math.round(Utils.timestamp_ms() / 1000)
    },

    timestamp_rel: function(timestamp){

        if(!Number.isInteger(timestamp)){ return timestamp }

        const current = Utils.timestamp_sec();

        var msPerMinute = 60 ;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - timestamp;
        const is_future = elapsed < 0
        elapsed = Math.abs(elapsed)

        let time_str = ""
        if (elapsed < msPerMinute) {
            return 'just now';
        }
        else if (elapsed < msPerHour) {
            const val = Math.floor(elapsed/msPerMinute)
            time_str = val + ' minute'+(val > 1 ? 's' : '')
        }

        else if (elapsed < msPerDay ) {
            const val = Math.floor(elapsed/msPerHour)
            time_str = val + ' hour'+(val > 1 ? 's' : '')
        }

        else if (elapsed < msPerMonth) {
            const val = Math.floor(elapsed/msPerDay)
            time_str = val + ' day'+(val > 1 ? 's' : '')
        }

        else if (elapsed < msPerYear) {
            const val = Math.floor(elapsed/msPerMonth)
            time_str = val + ' month'+(val > 1 ? 's' : '')
        }
        else {
            const val = Math.floor(elapsed/msPerYear)
            time_str = val + ' year'+(val > 1 ? 's' : '')
        }

        if(time_str === "1 day"){
            return is_future ? 'Tomorrow' : 'Yesterday'
        }

        return is_future ? 'In ' + time_str : time_str + ' ago'

    },

    monthNames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ],

    monthNamesShort: [
        "Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ],

    timestamp_date: function(time){
        time = (time - 0)*1000
        var d = new Date(time)
        if(!d){ return time }
        return Utils.monthNamesShort[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
    },

    timestamp_time(time){
        time = (time - 0)*1000
        var d = new Date(time)
        let hours = d.getHours()
        hours = hours < 10 ? ('0'+hours) : hours
        let minutes = d.getMinutes()
        minutes = minutes < 10 ? ('0'+minutes) : minutes
        return hours+':'+minutes
    },

    to_clipboard: function(str){
        let area = document.createElement("textarea")
        area.style.position = "absolute"
        area.style.top = "-999px"
        area.innerHTML = str

        document.body.appendChild(area)
        area.select()
        let success = false
        if(document.execCommand && document.execCommand("copy")){
            Utils.show_success("Copied to clipboard.")
            success = true
        }
        document.body.removeChild(area)

        return success
    },

    timestamp: function(time){
        time = (time - 0)*1000
        var d = new Date(time)
        if(!d){ return time }
        return d.toLocaleTimeString() + ", " + d.toLocaleDateString()
    },

    format_bytes: function (bytes, do_round) {
        if(bytes === undefined || bytes === null || Number.isNaN(bytes)){ return bytes }
        if (do_round === undefined){ do_round = false }

        const kB = 1024, MB = kB*kB, GB = kB*MB, TB = kB*GB
        let result = 0.0;
        let unit = "";
        let frac_digits = 0;

        if(bytes >= TB){ result = bytes / TB; unit = "TB"; frac_digits = 1 }
        else if(bytes >= GB) { result = bytes / GB; unit = "GB"; frac_digits = 0 }
        else if(bytes >= MB) { result = bytes / MB; unit = "MB"; frac_digits = 0 }
        else if(bytes >= kB){ result = bytes / kB; unit = "kB" }
        else { result = bytes; unit = "bytes" }

        return (do_round ? Math.round(result) : ( (bytes < kB) ? result : result.toFixed(frac_digits))) + " " + unit
    },

    get_file_viewer: function(file, is_mobile_browser, browser_name){
        if(!file.extension){return null}

        //if(!file.mime_type){
        file.mime_type = Utils.get_mime_type(file.extension)
        //}

        let viewer = { 'name': false, 'label': false, 'icon_class': false }
        const type = file.mime_type && file.mime_type.split("/")[0]
        // Extensions of script types which are texts but has 'application/...' mime type
        const script_extensions = ["sh", "bat", "json", "js", "token"]
        if(type == "image"){
            viewer.label = "Image Viewer"; viewer.name = "image"; viewer.icon_class = "ion-image"; viewer.verb = "View"; viewer.btn_class="btn-success";
        }
        else if(type == "video"){
            viewer.label = "Video Player"; viewer.name = "video"; viewer.icon_class = "ion-play"; viewer.verb = "Play";  viewer.btn_class="btn-info";
        }
        else if(type == "audio"){
            viewer.label = "Audio Player"; viewer.name = "audio"; viewer.icon_class = "ion-headphone"; viewer.verb = "Play";  viewer.btn_class="btn-info";
        }
        else if(type == "text" || script_extensions.indexOf(file.extension.toLowerCase()) >= 0){
            viewer.label = "Text Viewer"; viewer.name = "text"; viewer.icon_class = "ion-document-text"; viewer.verb = "Read";  viewer.btn_class="btn-secondary";
        }
        else if(file.mime_type == "application/pdf"){
            if(!is_mobile_browser || (is_mobile_browser && browser_name === 'safari')){
                // Desktop browsers and mobile Safari can open PDFs
                viewer.label = "PDF Viewer";
                viewer.name = "pdf";
                viewer.icon_class = "ion-document-text";
                viewer.verb = "View PDF";
                viewer.btn_class="btn-danger";
            }
            else{
                // Other mobile browsers cannot
                return null
            }
        }
        else{
            return null
        }

        return viewer
    },

    get_mime_type: function(extension){
        return mimeTypes.lookup(extension) || "application/octet-stream"
    },

    get_device_id: function(){
        let device_id = localStorage.getItem(Utils.device_id_key, null)
        if(device_id === null){
            // First time, generate and save
            device_id = md5(Utils.random_string(10))
            localStorage.setItem(Utils.device_id_key, device_id)
        }
        return device_id
    },

    get_file_extension(filename){
        const last_dot_idx = filename.lastIndexOf(".")
        return last_dot_idx < 0 ? "" : filename.substr(last_dot_idx+1, filename.length)
    },

    filetype_img_src(extension){
        const default_img = "assets/filetype_icons/Colorful/file_unknown.png"
        if(!extension){
            return default_img
        }
        const supported_extensions = ["3gp","7u","ae","ai","apk","asf","avi","bak","bmp","cdr","css","csv","divx","dll","doc","docx","dw","dwg","eps","exe","flv","fw","gif","gz","html","ico","iso","jar","jpg","js","mov","mp3","mp4","mpeg","pdf","php","png","ppt","pptx","ps","psd","rar","svg","swf","sys","tar","tiff","txt","wav","zip"]
        if(supported_extensions.indexOf(extension.toLowerCase()) >= 0){
            return "assets/filetype_icons/Colorful/file_"+extension.toLowerCase()+".png"
        }
        const jpeg_additional_extensions = ["jpeg","jpe","jif","jfif","jfi"]
        if(jpeg_additional_extensions.indexOf(extension.toLowerCase()) >= 0){
            return "assets/filetype_icons/Colorful/file_jpg.png"
        }
        return default_img
    },

    cloud_provider_logo_src(filename) {
        return Utils.cloud_provider_images('./' + filename)
    },

    flag_img_src (countrycode) {
        return Utils.flag_images('./' + countrycode + ".png")
    },

    client_space_logo_src(client_logo) {
        // client_logo is the relative file path like 'assets/img/client_space_logos/Writer.png'
        // Cut off everything before 'client_space_logos/'
        
        const start = client_logo.search(Utils.client_space_logo_base_path)
        if(start < 0) return
        
        return Utils.client_space_images('./' + (client_logo.substr(start + Utils.client_space_logo_base_path.length)) )
    },

    asset_video_url(video_filename) {
        return Utils.asset_videos('./' + video_filename)
    },

    force_download_file(file, file_url, callback, set_download, set_blank){
        // Construct a new link and click it
        var a = document.createElement("a");
        a.href = file_url
        if(set_download !== false){
            a.download = file.name
        }
        if(set_blank){
            //a.target = '_BLANK'
        }
        a.style.display = "none"

        // Add it to the DOM (Chrome is okay without it, but Firefox is not)
        document.body.appendChild(a)

        a.click()
        setTimeout(function(){ document.body.removeChild(a) }, 1000)
        if(callback){
            callback(file)
        }
    },

    download_or_open_file: function(file, version_id, view_file_ready_callback, download_complete_callback, cancelled, error_callback){

        if(file.loading){
            console.error("File already loading")
            return false
        }

        (new FileDownloader()).downloadFile(file, version_id).then(res => {
            file.loading = false
            if('cancel' in file){
                delete file.cancel
            }
            if(res.download_url === undefined && file.open_when_ready){
                // This callback is called only if the file was set to be opened, not downloaded
                view_file_ready_callback({
                    name: file.name,
                    viewer: file.viewer,
                    mime_type: res.mime_type,
                    blob_url: res.blob_url
                })
                file.open_when_ready = false
            }
            else{
                
                if(!res.saved_to_disk){
                    // Save blob locally
                    Utils.force_download_file(file, res.download_url || res.blob_url, download_complete_callback, true, true)
                }
            }
        })
        .catch(err => {
            // Catch errors
            file.loading = false
            if('cancel' in file){
                delete file.cancel
                cancelled()
            }

            if(!err || !('cancelled' in err)){
                Utils.show_error("Error downloading "+file.name + ", please try again later!" )
                console.error("Download error: ", err)
                // TODO report error
                if(error_callback){
                    error_callback(err)
                }
            }
        })
    },


    /**
     * Sorts an array of objects by an attribute or sub-attribute.
     * Usage: Utils.sort(locations, 'distance', true) or Utils.sort(locations, 'provider.id', false)
     * @param {Array} array The array of objects to sort
     * @param {String} sort_attr Which object attribute (e.g. 'id' or 'provider.id') to sort by
     * @param {Boolean} is_asc Sort order, default ascending
     * @returns {Array} A sorted copy of the array
     */
    sort_objects(array, sort_attr, is_asc){
        is_asc = (is_asc === undefined) ? true : is_asc
        if(!array){return array}
        return array.sort( (_a, _b) => {
            let a = _a[sort_attr];
            let b = _b[sort_attr];
            

            if(sort_attr.length >= 3 && sort_attr.indexOf('.') >= 0){
                // If listed by a sub-attribute
                const dot_idx = sort_attr.indexOf('.')
                const outer_key = sort_attr.slice(0, dot_idx)
                const inner_key = sort_attr.slice(dot_idx+1)

                if(outer_key.length && inner_key.length && outer_key in _a && _a[outer_key] && outer_key in _b && _b[outer_key] && inner_key in _a[outer_key] && inner_key in _b[outer_key]){
                    const tmp = _a[outer_key]
                    a = tmp[inner_key]

                    const tmp2 = _b[outer_key]
                    b = tmp2[inner_key]
                }
            }
            
            if(typeof a == "string"){ a = a.toLowerCase(); b = b.toLowerCase(); }
            const lg = is_asc ? -1 : 1;
            if(a < b){ return lg; }
            if(a > b){ return -1*lg }
            return 0;
        })
    },

    sort_by_name(_a, _b){
        let a = _a.name;
        let b = _b.name;
        if(typeof a == "string"){ a = a.toLowerCase(); b = b.toLowerCase(); }
        if(a < b){ return -1; }
        if(a > b){ return 1 }
        return 0;
    },


    parse_url_param(param_name, default_value, urldecode){
        if(urldecode === undefined){ urldecode = true }
        if(default_value === undefined){ default_value = false }
        const url = new URL(window.location);
        const params_arr = (url.search.substring(1)).split('&')
        for(var i=0 ; i<params_arr.length ; ++i){
            let params = params_arr[i];
            if(!params || params.length == 0){ return; }
            const pair = params.split('=');
            if(pair.length > 0 && pair[0] === param_name){
                if(pair.length > 1){ return urldecode ? decodeURIComponent(pair[1]) : pair[1] }
                return true
            }
        }
        return default_value;
    },

    get_auth_header(){ return TokenCache.is_stored() ? { "Authorization": "Bearer " + TokenCache.get() } : {} },

    // Return array of string values, or NULL if CSV string not well formed.
    csv_to_array(text) {
        try{
            let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
            for (l of text) {
                if ('"' === l) {
                    if (s && l === p) row[i] += l;
                    s = !s;
                } else if ((',' === l || ';' === l) && s) l = row[++i] = '';
                else if ('\n' === l && s) {
                    if ('\r' === p) row[i] = row[i].slice(0, -1);
                    row = ret[++r] = [l = '']; i = 0;
                } else row[i] += l;
                p = l;
            }
            return ret;
        } catch (e){
            console.error("Error parsing CSV: ", e)
        }
    },

    is_mobile(){
        return screen.width < 768; 
        //return platform.os.family && ['android', 'ios', 'windows phone'].indexOf(platform.os.family.toLowerCase()) >= 0
    },

    partially_redact_email(email_address){
        
        /*
        Hides most of the email address, leaving enough characters readable that the owner can identify it.
        For example:
        marcell@chocolate-cloud.cc -> ma*****@ch*************.cc
        mac@one.com -> m**@o**.com
        */

        if(!Utils.is_valid_email(email_address)){
            return false
        }

        const email_parts = email_address.split('@')
        if(email_parts.length != 2){
            return false
        }

        let front = email_parts[0]
        let domain = email_parts[1]

        let domain_parts = domain.split('.')
        if(domain_parts.length < 2){
            return false
        }

        // Keep the logarithm of the front length (but at least 1)
        let characters_to_keep = Math.max(1, Math.round(Math.log2(front.length)))
        // Keep this many characters of the front and replace the rest with stars
        //front = front[:characters_to_keep] + len(front[characters_to_keep:])*'*'
        front = _redact_after_idx(front, characters_to_keep)
        
        
        // Keep the TLD intact, redact the part of the domain before it
        const tld = domain_parts.pop()
        domain_parts = domain_parts.join('')
        characters_to_keep = Math.max(1, Math.round(Math.log2(domain_parts.length)))
        //domain_to_redact = domain_to_redact[:characters_to_keep] + len(domain_to_redact[characters_to_keep:])*'*'
        domain_parts = _redact_after_idx(domain_parts, characters_to_keep)
        domain = domain_parts + '.' + tld

        return front + '@' + domain
    },

    scroll_top(){
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
    },

    round_up_100(num){
        return Math.round(num/100) * 100
    },

    loadScript(src){
        return new Promise(function (resolve, reject) {
            let shouldAppend = false;
            let el = document.querySelector('script[src="' + src + '"]');
            if (!el) {
              el = document.createElement('script');
              el.type = 'text/javascript';
              el.async = true;
              el.src = src;
              shouldAppend = true;
            }
            else if (el.hasAttribute('data-loaded')) {
              resolve(el);
              return;
            }
    
            el.addEventListener('error', reject);
            el.addEventListener('abort', reject);
            el.addEventListener('load', function loadScriptHandler() {
              el.setAttribute('data-loaded', true);
              resolve(el);
            });
    
            if (shouldAppend) document.head.appendChild(el);
          });
    },

    unloadScript(src){ // eslint-disable-line no-param-reassign
        return new Promise(function (resolve, reject) {
          const el = document.querySelector('script[src="' + src + '"]');
  
          if (!el) {
            reject();
            return;
          }
          document.head.removeChild(el);
          resolve();
        });
      },

    avg(arr) {
       return arr && arr.length ? arr.reduce((a, b) => a + b, 0) / arr.length : 0
    },

    add_gps_noise(coords){
        const noise_max = 0.5
        return {
            lat: coords.lat + (Math.random() * (2*noise_max)) - noise_max,
            lng: coords.lng + (Math.random() * (2*noise_max)) - noise_max,
        }
    },

    highlight_search(string, search_str){
        if(!search_str){
            return string
        }
        return string.replace(new RegExp(search_str, "gi"), match => {
            return '<span class="bg-warning">' + match + '</span>';
        });
    },
}

//Utils.cache_space_logos()

function _redact_after_idx(str, idx){ 
    let i = 0;
    return str.split('').map(ch => {
        ++i;
        return i > idx ? '*' : ch
    }).join('')  } 

export { Utils }