<template>
<section>
    <div class="cardbox-heading">
        <div class="cardbox-title text-center">
            <a href="https://skyflok.com" target="_blank">
                <img src="@/assets/img/skyflok_logo_green.png" style="max-height: 60px" class="mr-2">
            </a>
        </div>
    </div>

    <div v-if="!hide_form" class="cardbord-body text-center">
        <div class="text-14">Try SkyFlok free for {{ team_config ? team_config.time_limit_days : '30' }} days!</div>
        <div class="text-sm"><span>No credit card required</span></div>
    </div>

    <div v-if="error" class="alert alert-danger mt-2" v-html="error"></div>

    <div v-else-if="success" class="fw text-center my-3">
        <div class="text-success text-14">
            Success!<br/>Check your email for the invite!
        </div>
        <div class="mt-5">
            <img src="@/assets/img/illustrations/mail_sent-success.svg" height="150" >
        </div>
    </div>

    <div v-if="!hide_form" class="cardbox-body row pb-0">
        
        <div class="col-12 col-md-6">

            <form @submit.prevent="submit()">

            <label for="register_name pt-0 mt-0" style="padding-top: 0px">Name</label>
            <input type="text" class="form-control" id="register_name" name="name" maxlength="255" placeholder="Your name (optional)" v-model="form.name">
            
            <label for="register_email" class="mt-3">Email Address</label>
            <input type="email" class="form-control" id="register_email" name="email" v-model="form.email" placeholder="Your email address">
            <small v-if="email_suggested" class="d-block">Did you mean <a href="#" @click="form.email=email_suggested">{{email_suggested}}</a>?</small>
            
            <div class="mt-5">
                <button 
                    class="btn btn-block btn-lg text-bold bg-skyflok text-white text-12" 
                    type="submit"
                    :disabled="!form_ok || loading">
                    Sign Up <i v-if="loading" class="ion-load-c spin ml-2"></i>
                </button>
            </div>

            </form>
        </div>

        <div class="col-12 col-md-6">
            <div class="d-block d-md-none pt-3">&nbsp;</div>
            <div class="mt-4" :class="{'mx-3': !Utils.is_mobile()}">
                <invite-config-box
                    :invite="invite"
                />
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { UserService } from '@/services/user-service.js';
import { ConfigService } from '@/services/config-service.js';
import { Utils } from '@/helpers/utils.js';
import { email_domain_typos } from '@/assets/static/email_domain_typos.js'

import inviteConfigBox from './invite-config-box.vue'

// Global status
const STATUS = {
    DEFAULT: 1,
    SUCCESS: 2,
    ERROR: 0,
}

const SKYFLOK_TRIAL_KEY = "skyflok-trial"

export default {

    components: { 
        inviteConfigBox
    },

	data() {
        return {
            form_status: STATUS.DEFAULT,
            Utils: Utils,

            team_config: null,

            form: {
                name: "",
                email: "",
            },

            email_suggested: false,
            hide_form: false,

            loading: false,
            error: false,
            success: false
        }
    },

    computed: {

        invite(){
            // Construct the 'invite' object expected by inviteConfigBox 
            if(!this.team_config){
                return null
            }
            return {
                key: SKYFLOK_TRIAL_KEY,
                config: this.team_config
            }
        },

        email_ok(){
            return Utils.is_valid_email(this.form.email)
        },

        form_ok(){
            return this.email_ok
        },

    },

    watch: {
        // Suggest fixes of commonly mistyped email domains
        'form.email': function(){
            let suggestion_found = false
            if(this.form.email.length == 0){ return }
            for(var i=0 ; i<email_domain_typos.length ; ++i){
                const record = email_domain_typos[i]
                const typo_found = record.typos
                    .filter(typo => { return this.form.email.endsWith('@'+typo) })

                if(typo_found.length > 0){
                    this.email_suggested = this.form.email.replace(typo_found[0], record.correct)
                    suggestion_found = true
                    break
                }
            }

            if(!suggestion_found){
                this.email_suggested = null
            }
        },

        form_status(){
            // Ask the container to change the top and bottom strip color according to the current status
            switch(this.form_status){
                case STATUS.SUCCESS: this.$emit('changeStatus',  'SUCCESS'); return
                case STATUS.ERROR: this.$emit('changeStatus', 'ERROR'); return 
                default: this.$emit('changeStatus', 'bg-gradient-info'); return
            }
        },

        loading(){
            if(this.loading){
                this.$emit('changeStatus', 'LOADING')
            }
        }
    },

    mounted() {

        // Focus the first input field
        this.$nextTick(_=>{
            document.querySelector('input').focus()
        })

        // Load free trial config
        ConfigService.get_config('skyflok-trial').then(res => {
            this.team_config = res.body
        }).catch(_ => {
            this.form_status = STATUS.ERROR
            this.loading = false
            this.hide_form = true
            this.error = "Internal error, please try again later!"
        })
    },


    methods: {

        submit(){
            if(!this.form_ok){ return }

            this.error = false
            this.loading = true;

            UserService.trial_invite_signup(this.form).then(_ => {
                this.loading = false;
                this.success = true
                this.form_status = STATUS.SUCCESS
                this.hide_form = true
                
            }).catch(err_resp => {
                console.error(err_resp)
                this.loading = false;
                this.form_status = STATUS.ERROR
                this.error = "Signup failed"
                if(err_resp.body && err_resp.body.message){
                    this.error += ": " + err_resp.body.message
                }
            })
        }
    }
};
</script>

<style lang="css" scoped>
label:not(.custom-control){
    font-size: .9em;
    margin-bottom: 3px;
    padding-top: 1em
}
</style>
