<template>
<span class="nowrap">
    {{ timestamp | timestamp_rel }}<i class="ion-calendar text-muted ml-2" :title="timestamp | timestamp"></i>
</span>
</template>
<script>
export default {
    props: ['timestamp']
}
</script>
