import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'

// This is not exported
const base_url = process.env.VUE_APP_user_service_url

const UserService = {

    get_user: function(user_id){
        return bus.$http.get(base_url + "/users/" + user_id , { headers: Utils.get_auth_header() })
    },

    get_me: function(){
        return bus.$http.get(base_url + "/users/me", { headers: Utils.get_auth_header() })
    },

    edit_user: function(user_id, name, job_title, avatar_url){
        if(!user_id){ return false }

        let payload = { }
        if(name != null){ payload.name = name }
        if(job_title != null){ payload.job_title = job_title }
        if(avatar_url != null){ payload.avatar_url = avatar_url }

        return bus.$http.put(base_url + "/users/"+user_id, payload, { headers: Utils.get_auth_header() })
    },

    edit_me: function(name, job_title, avatar_url){
        let payload = { }
        if(name != null){ payload.name = name }
        if(job_title != null){ payload.job_title = job_title }
        if(avatar_url != null){ payload.avatar_url = avatar_url }

        return bus.$http.put(base_url + "/users/me", payload, { headers: Utils.get_auth_header() })
    },

    get_my_team: function(mark_admins){
        if(mark_admins === undefined){
            mark_admins = false
        }
        const url_params = {
            mark_admins: mark_admins ? 1 : 0
        }
        return bus.$http.get(base_url + "/users", { params: url_params, headers: Utils.get_auth_header() })
    },

    get_company_settings: function(){
        return bus.$http.get(base_url + "/companies/me", { headers: Utils.get_auth_header() })
    },

    add_user_email: function(email){
        const payload = {
            user_email: email
        }
        return bus.$http.post(base_url + "/companies/user_email", payload, { headers: Utils.get_auth_header() })
    },

    delete_user_email: function(email){
        const url_params = {
            user_email: email
        }
        return bus.$http.delete(base_url + "/companies/user_email", { params: url_params, headers: Utils.get_auth_header() } )
    },

    update_team_settings: function(payload){
        return bus.$http.put(base_url + "/companies/me", payload, { headers: Utils.get_auth_header() })
    },

    get_team_info(join_key){
        return bus.$http.get(base_url + "/company_info?key=" + join_key)
    },

    trial_invite_signup(payload){
        return bus.$http.post(base_url + "/invite/new", payload)
    },

    get_invite_by_key(invite_key){
		return bus.$http.get(base_url + "/invite/"+invite_key)
    },

    team_invite_signup(formData){
        // This is a special endpoint call, because we want the browser to trigger the 
        // password save dialog. Returns the result Promise of a fetch() call 
        // Source: https://developers.google.com/web/fundamentals/security/credential-management/save-forms
        
        return fetch(base_url+'/invite/team_signup', {
            method: 'POST',
            body: formData,
            credentials: 'include'
        })
    },

    /* Deprecated, use team_invite_signup instead */
	register_company(invite_key, form){
        if(invite_key){
            form['invite_key'] = invite_key
        }
		return bus.$http.post(base_url + "/register_team", form)
    },

    register_to_team: function(join_key, reg_form){
        const payload = {
            "join_key": join_key,
            "name": reg_form.name,
            "password": reg_form.password,
            "email_address": reg_form.email,
            "terms_accepted": reg_form.terms_accepted,
            "newsletter": reg_form.newsletter
        }

		return bus.$http.post(base_url + "/register_to_team", payload)
    }

}

export { UserService }