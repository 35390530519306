import { bus } from '../helpers/event_bus.js'


const MobileDownload = {
    BASE_URL: "https://www.sky-flok.com",
    _ENDPOINT_URL: "/get_url.php",

    get_url(fileData, filename){
        var payload = new FormData()
        payload.append("userFile", fileData, filename)

        return bus.$http.post(MobileDownload.BASE_URL + MobileDownload._ENDPOINT_URL, payload)
    }
}

export { MobileDownload }