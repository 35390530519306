<template>
<section>
    Scan this QR code with your authenticator mobile app:
    <div class="fw text-center mt-2">
        <div v-if="!qr_ready" style="width: 200px; height: 200px; margin: 0px auto; background: #ebebeb" class="d-flex align-items-center justify-content-center">
            <i class="ion-load-c spin"></i>
        </div>
        <canvas v-else id="qr_code" width="200" height="200" style="background: #ebebeb"></canvas>
    </div>
    <div class="mt-2 fw text-center" v-if="!success">
        <form @submit.prevent="verify()">
            Input the 6-digit code that your app is showing:
            <div class="input-group col-12 col-lg-6 offset-lg-3 mt-2" >
                <input type="number" :id="input_id" class="form-control" v-model="mfa_code_verify" placeholder="123456">
                <button type="submit" class="btn btn-primary btn-gradient ml-2" :disabled="!mfa_code_verify || mfa_code_verify.length != 6 || loading">
                    Verify
                    <i v-if="loading" class="ion-load-c spin ml-1"></i>
                </button>
            </div>
            <div v-if="error" class="text-danger">{{ error }}</div>
        </form>
    </div>
    <div v-else class="fw text-center text-success text-bold">
        Setup successful!
    </div>
 
    <div class="alert alert-secondary text-left mt-3">
        You can use any mobile application that can generate time-based one time passwords (TOTP), such as:
        <div class="row mt-3 auth_app_logos">
            <div class="col-4 col-lg-2 text-center">
                <img src="@/assets/img/authenticator_logos/google.png" class="auth_app_logo" >
                <small>Google Authenticator</small>
            </div>
            <div class="col-4 col-lg-2 text-center" >
                <img src="@/assets/img/authenticator_logos/authy.png" class="auth_app_logo" >
                <small>Twilio Authy</small>
            </div>
            <div class="col-4 col-lg-2 text-center" >
                <img src="@/assets/img/authenticator_logos/1pass.png" class="auth_app_logo" >
                <small>1Password</small>
            </div>
            <div class="col-4 col-lg-2 text-center" >
                <img src="@/assets/img/authenticator_logos/lastpass.png" class="auth_app_logo" >
                <small>LastPass Authenticator</small>
            </div>
            <div class="col-4 col-lg-2 text-center" >
                <img src="@/assets/img/authenticator_logos/microsoft.png" class="auth_app_logo" >
                <small>Microsoft Authenticator</small>
            </div>
            <div class="col-4 col-lg-2 text-center" >
                <img src="@/assets/img/authenticator_logos/freeotp.png" class="auth_app_logo" >
                <small>FreeOTP Authenticator</small>
            </div>
        </div>
        
    </div>
</section>
</template>
<script>


import QRious from 'qrious'
import { AuthService } from '@/services/auth-service'
//import { Utils } from '@/helpers/utils'

export default {
    props: ["url", 'show_error'],

    data(){
        return {
            mfa_code_verify: null,
            qr_ready: !!this.url,
            loading: false,
            success: null,
            error: null,

            id: Math.floor(Math.random() * 100)
        }
    },

    computed: {
        input_id(){
            return 'mfa-verifyqr-' + this.id
        }
    },

    mounted(){

        if(this.url){
            this.show_qr(this.url)
        }
        else{
            AuthService.get_2fa_url().then(res => {
                this.show_qr(res.body.url)
            }).catch(err => {
                console.error(err)
                this.show_error(err.body.message)
            })
        }

        // Focus the input field
        this.focus_input()
    },

    methods: {
        
        verify(){
            
            if(!this.mfa_code_verify || this.mfa_code_verify.length != 6){
                this.error = "The code must be exactly 6 digits!"
                return 
            }
            this.error = false
            this.loading = true
            AuthService.verify_2fa_code(this.mfa_code_verify).then(() => {
                this.loading = false
                this.success = true

                this.$emit('verified')
                
            }).catch(err => {
                this.loading = false
                this.success = false
                this.error = err.body.message
                this.focus_input()
            })
        },

        show_qr(url){
            this.qr_ready = true
            this.$nextTick(()=>{
                new QRious({
                    element: document.getElementById('qr_code'),
                    value: url,
                    size: 200
                })
            })
        },

        focus_input(){
            this.$nextTick(()=>{
                const input = document.getElementById(this.input_id)
                input ? input.focus() : false;
            })
        }
    }
}
</script>
<style scoped>
.auth_app_logos img{
    max-width: 40px;
    max-height: 40px;
}

.auth_app_logos small{
    display:block;
    line-height: 1.2em;
    margin-top: .4em
}
</style>