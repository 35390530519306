<template>
<div class="col-12 col-md-6 col-lg-6 col-xl-3">
<div class="cardbox mx-2 mb-4" :class="{'text-muted': space.is_archived}">

    <div class="pb-1" :class="[color]"></div>

    <div class="cardbox-body pb-0 pb-2">

        <div class="d-flex align-items-center justify-content-between">
            <div class="text-center">
                
                <button
                    class="btn btn-secondary btn-flat btn-flat-icon d-block c-pointer"
                    @click="watch_clicked()"
                    type="button"
                    data-toggle="popover" data-animation="false" data-trigger="hover" data-placement="top" :data-content="watch_toggle_popover_text" >
                    <i :class="{
                        'ion-android-notifications': space.watch_managers, 
                        'ion-android-notifications-none text-muted': !space.watch_managers
                        }"/>
                </button>
                
            </div>


            <div class="dropdown">
                <button class="btn btn-secondary btn-flat btn-sm btn-flat-icon" type="button" data-toggle="dropdown" aria-expanded="false">
                    <i class="ion-more text-gray-light"></i>
                </button>
                <div class="dropdown-menu " role="menu">
                    <a class="dropdown-item" href="#"  @click.prevent="$emit('edit')" v-if="!space.is_archived"><i class="ion-edit mr-2"></i> Edit Space</a>
                    <a class="dropdown-item" href="#" @click.prevent="$emit('open_dialog', 'reset_password')" v-if="!space.is_archived"><i class="ion-refresh mr-2"></i> Reset Client Password</a>
                    <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="$emit('open_dialog', 'mfa_backup_codes')" v-if="space.mfa_enabled && !space.is_archived"><i class="ion-help-buoy mr-2"></i> 2FA Backup Codes</a>
                    <div class="dropdown-divider" role="separator" v-if="!space.is_archived"></div>
                    <!--<a class="dropdown-item text-warning" href="#" @click.prevent="disableclicked(space)">Disable client access...</a>-->
                    <a class="dropdown-item text-danger" href="#" @click.prevent="$emit('open_dialog', 'archive_space')" v-if="!space.is_archived"><i class="ion-trash-a mr-2"></i> Archive or Delete...</a>
                    <a v-else-if="activatedisabled !== true" class="dropdown-item text-success" href="#" @click.prevent="activateclicked(space)"><i class="ion-wand mr-2"></i> Activate Space</a>
                </div>
            </div>
        </div>
    </div>

    <div class="cardbox-body pt-0 text-center">
        <a :href="space.key | client_space_link" style="color: inherit; text-decoration: none" target="_BLANK">
            <div class=" d-table-cell v-middle" style="height: 128px;">
                <!-- Logo image: max 128x128, keep image proportions -->
                <img 
                    v-if="space.client_logo" 
                    class="shadow-z3 rounded fit-contain d-inline-block"
                    style="max-width: 128px; max-height: 128px;" 
                    :src="ClientSpacesService.is_skyflok_logo(space) ? Utils.client_space_logo_src(space.client_logo) : space.client_logo" 
                />

                <!-- 3-letter initials: fixed 128x128 -->
                <div v-else class="shadow-z3 thumb128 rounded bg-gradient-secondary d-inline-block">
                    <div class="d-flex align-items-center justify-content-center fh fw text-white text-bold">
                        <h1 class="p-0 m-0">{{ space.client_name.split(' ').map(frag => { return frag.length > 0 ? frag[0].toUpperCase() : '' }).splice(0,3).join('') }}</h1>
                    </div>
                </div>
            </div>

            <div class="h3 text-bold mt-3" :title="space.client_name.length > 15 ? space.client_name : ''">
                {{ space.client_name.substr(0, 15) }}{{ space.client_name.length > 15 ? '&hellip;' : '' }}
            </div>
            <div 
                class="text-sm" 
                :class="{
                    'text-gray text-italic': client_state == 'INVITE_PENDING', 
                    'text-danger': client_state == 'INVITE_EXPIRED'
                }"
                :data-toggle="client_popover_text != '' ? 'popover' : ''" 
                :data-content="client_popover_text"
                data-animation="false" data-trigger="hover" data-placement="top" 
            >
                <span>{{space.client_email}}</span>
                <i 
                    v-if="client_state != ''" 
                    class=" ml-1" 
                    :class="{
                        'ion-clock': client_state == 'INVITE_PENDING', 
                        'ion-alert-circled': client_state == 'INVITE_EXPIRED', 
                        'ion-checkmark-circled text-success': client_state == 'JOINED'
                        }"
                />
            </div>
        </a>
        
        <div class="alert alert-warning alert-sm text-center mb-0" v-if="space.delete_at" >
            <small>This Space will be automatically deleted {{ space.delete_at | timestamp_rel }}</small>
        </div>
    </div>

    <div class="cardbox-body pt-0 text-center">
        <div class="image-list d-flex align-items-center justify-content-center nowrap">
            <img v-for="member in space_managers.splice(0,3)" :key="member.id" class="rounded-circle thumb48 fit-cover shadow-z2" style="border: 2px solid white" :src="member.avatar_url" :alt="member.name">
            <div v-if="space.members.length > 3" class="rounded-circle thumb48 shadow-z2 bg-gray-lighter d-inline-block text-center d-flex align-items-center justify-content-center"  style="border: 2px solid white; margin-left: -8px" ><span>+{{ space.members.length-3 }}</span></div>
        </div>
        <small class="nowrap">Managers</small>
    </div>

    <div class="cardbox-body pt-0">
        <a target="_BLANK" v-if="!space.is_archived" :href="space.key | client_space_link" title="Open this Space in a new tab" :class="[ 'btn btn-block text-white', color ]">
            <b>Open Space <i class="ion-share ml-1"></i></b>
        </a>
        <a v-else :href="space.key | client_space_link" target="_BLANK" title="Browse this Space in a new tab" class="btn btn-block bg-gradient-secondary text-white">
            <b>Browse Space <i class="ion-share ml-1"></i></b>
        </a>
    </div>

</div>
</div>
</template>

<script>
import { Utils } from '@/helpers/utils.js'
import { ClientSpacesService } from '@/services/client_spaces-service.js'

export default {
    props: [
        "user", "space",
        "activatedisabled",
        "activateclicked",
        "watch_clicked",
        "reload_spaces_callback"
    ],
    data(){
        return {
            members: null,
            color: null,
            activate_loading: false,
            activate_error: false,

            space_size: null,

            watching: Math.round(Math.random()),
            Utils: Utils,
            ClientSpacesService: ClientSpacesService,
        }
    },

    computed: {

        space_managers(){
            if(!this.user || !this.user.team || !this.space){ return [] }
            return this.user.team.filter(member => this.space.members.indexOf(member.user_id) >= 0 )
        },
        /*
        space_managers_html(){
            // Generates the list of Space Managers for the HTML popover
            const managers = this.space_managers
            if(!managers.length){ return "" }
            let content = ""
            managers.forEach(user => {
                content += "<div class='my-2'><img src='"+Utils.avatar_url(user)+"' class='thumb40 mr-2 rounded-circle fit-cover'/>"+user.name + "</div>"
            })
            return content
        },
        */

        active_files_num(){
            return this.space.ns_stats ? this.space.ns_stats.active_files : null
        },

        client_state(){
            if(this.space.invite_code){
                return this.space.is_invite_expired ? "INVITE_EXPIRED" : "INVITE_PENDING"
            }
            else if(this.space.password_set) return "JOINED"
            return ""
        },

        client_popover_text(){
            switch(this.client_state){
                case "INVITE_EXPIRED": return "Invite expired!"
                case "INVITE_PENDING": return "Invite sent, waiting for them to join"
                case "JOINED": return "Joined"
                default: return ""
            }
        },

        watch_toggle_popover_text(){
            return `Email notifications of new file uploads ${this.space.watch_managers ? 'enabled' : 'disabled'}`
        }

    },

    

    mounted(){
        this.$nextTick(() => {
            $('[data-toggle="popover"]').popover();
        })
    },

    created(){

        // Set color based on name (if not archived, then just gray gradient)
        this.color = this.space.is_archived ? (this.space.delete_at ? 'bg-gradient-danger' : 'bg-gradient-secondary') : "bg-" + Utils.string_to_color(this.space.client_name) + "-400"
    },

    methods: {

        activate_space(id){
            this.activate_loading = true

            ClientSpacesService.archive(id, false).then(() => {
                this.activate_loading = false
                this.activate_error = false
                if(this.reload_spaces_callback){
                    this.reload_spaces_callback()
                }
                Utils.show_success("Space activated successfully!")

            }).catch(err => {
                this.activate_loading = false
                Utils.show_error("Error activating space: " + err.body.message)
            })
        },

        get_space_link(alphakey){
            return this.$options.filters.client_space_link(alphakey)
        },

        

    }
}
</script>
<style scoped>
.soft-border{
    border: 2px solid #ebebeb
}

@media(max-width: 576px){
    .thumb128{
        width: 96px !important;
        height: 96px !important;
    }
}
</style>
