<template>
<div class="fw text-center">
    <div v-if="client_logo && team_logo" class="nowrap">
        <img :src="team_logo" class="thumb80 shadow-z5">
        <i class="ion-code icon-lg mx-4"></i>
        <img :src="client_logo" class="thumb80 shadow-z5">
    </div>

    <div v-else-if="team_logo">
        <img v-if="team_logo" :src="team_logo">
    </div>

    <div v-else-if="client_logo">
        <img v-if="client_logo" :src="client_logo">
    </div>
</div>
</template>

<script type="text/javascript">
export default {
    props: ['team_logo', 'client_logo'],
}
</script>

<style scoped>
    img{
        max-height: 100px;
        max-width: 300px;
        object-fit: contain;
        border: 2px solid white;
        background: white
    }
</style>