<template>
<section class="section-container">
<div v-if="user && user.team_settings && !user.team_settings.is_free_trial" class="fw text-center text-14 pt-5">
    <!-- Not free trial -->
    <p class="text-success mb-4"><i class="text-success ion-checkmark-circled mr-2"></i> Your team has an active SkyFlok subscription. </p>
    Contact us at <a href="mailto:support@skyflok.com" style="color:initial" class="text-initial text-underline">support@skyflok.com</a> if you have a question or you want to change it.
</div>
<div v-else class="container">
    <div v-if="loading" class="fw text-center text-14 pt-5">
        Loading subscription options... <i class="ion-load-c spin ml-2"></i>
    </div>
    <div v-else-if="!available_plans.length" class="fw text-center text-14 pt-5 text-danger">
        No plans found.
    </div>
    <div v-else class="row pt-5">
        <div v-if="available_plans.length == 2" class="offset-1 d-none d-md-block"></div>
        <div v-for="plan in available_plans" :key="plan._key" class="col-12 col-md-6 col-lg-5">
            <div class="cardbox b plan-box">
                <div class="cardbox-body px-5">

                    <div class="text-center color-skyflok text-14">{{plan.name}}</div>
                    
                    <div class="text-center my-4 text-bold">
                        <div class="mt-2 text-14">{{plan.pricing.monthly_price}} / month</div>
                        <small class="text-gray" v-if="plan.pricing.annual_savings">{{plan.pricing.annual_savings}} with annual billing</small>
                        <small v-else>&nbsp;</small>
                    </div>
                
                    <p v-for="(feature, idx) in plan.features" :key="idx">
                        <i class="ion-checkmark-round mr-3"/>{{feature}}
                    </p>
                    

                    <p class="text-center mt-4">
                        <button class="btn btn-gradient btn-skyflok btn-block text-bold text-12" :class="plan_btn_class(plan)" @click="plan_selected(plan)">
                            Select
                        </button>
                    </p>

                </div>
            </div>
        </div>
        
    </div>

    <div v-if="!loading" class="row mt-5">
        <div class="offset-2 d-none d-md-block"></div>
        <div class="col-12 col-lg-8">

            <div class="cardbox">
                <div class="cardbox-body">

                    <div class="text-center color-skyflok text-14">Need more? No problem!</div>
                    
                    <div class="text-center">
                        <div class="mt-2">
                            Contact us on
                            <a href="mailto:upgrade@skyflok.com" style="color:initial" class="text-initial text-underline">upgrade@skyflok.com</a>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="instructions_modal">
    <div class="modal-dialog" v-if="show_instructions">
        <div class="modal-content">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">Checkout Form Instructions</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body px-4">
                
                <p class="pt-3">
                    Please use our webshop to start a SkyFlok subscription.
                    <br/>On the Checkout Form:
                </p>

                <ol class="mx-3">
                    <li class="mb-2">Select <strong>Yearly</strong> or <strong>Monthly</strong> billing</li>
                    <li class="mb-2">Click the <span class="badge badge-dark" style="font-size: 100%;">Add to cart</span> button</li>
                    <li class="mb-2">Use your email address <span class="badge badge-dark" style="font-size: 100%;">{{user ? user.email_address : ''}}</span> under <strong>Billing Details</strong></li>
                </ol>

                <p class="mt-4 px-5">
                    <a class="btn btn-gradient btn-block" :class="plan_btn_class(selected_plan)" :href="selected_plan.pricing.checkout_url" target="_blank" @click="close_modal()">
                        Open Checkout Form <i class="ion-arrow-right-c ml-1"></i>
                    </a>
                </p>

            </div>
        </div>
    </div>
</div>

</section>
</template>
<script>

import { Utils } from '@/helpers/utils';
import { BillingService } from '@/services/billing-service'

export default {

    props: {
        user: {
            type: Object,
            default: () => { return {} }
        }
    },

    data() {
        return {
            loading: false,
            available_plans: [],
            selected_plan: null,
            show_instructions: false,
        };
    },

    mounted(){
        this.load_plans()
    },


  methods: {

        load_plans(){
            this.loading = true
            BillingService.get_skyflok_options().then(res => {
                this.available_plans = res.body
                this.loading = false
            }).catch(err => {
                console.error(err)
                this.loading = false
                Utils.show_error("Error loading SkyFlok Subscriptions! Please try again later.")
            })
        },

        plan_selected(which){
            this.show_instructions = true
            this.selected_plan = which
            $("#instructions_modal").modal()
        },

        plan_btn_class(plan){
            switch(plan._key){
                case 'fair-sky': return 'btn-primary'
                case 'small-sky': return 'btn-info'
                default: return ""
            }
        },

        close_modal(){
            $('#instructions_modal').modal('hide')
            this.show_instructions = false
        }
  }
};
</script>

<style scoped>
.cardbox.plan-box{
    border: 2px solid transparent;
    top: 4em;
    position: unset;
    margin-left: 2em;
    margin-right: 2em;
}

@media(max-width: 1200px){
    .cardbox.plan-box{
        margin-left: 1em;
        margin-right: 1em;
    }
}
.cardbox.plan-box:hover{
    border: 2px solid #008080;
}
</style>