import { FileVersionService } from '@/services/fileversion-service.js'
import { MobileDownload } from '@/services/external.js'
import { StorageBackendMonitorService } from '@/services/storagebackendmonitor-service.js'
import { Utils } from '@/helpers/utils.js'
import AES from "@/assets/js/aes.js"

// Define the worker location as a relative path, this will be used only in runtime
const worker_path = 'assets/js/workers/download_generation_worker.js'

const FILESTREAM_MIN_SIZE_MB = 500

// Downloads a single file
function FileDownloader() {

    this.ns_file = null
    this.version = null
    this.config = null

    this.download_worker = null
    this.download_file_reject = null
    this.cancelled = false

    // Returns a Promise that resolves when the file finished downloading
    this.downloadFile = (ns_file, version_id, version_obj) => {
        if(this.ns_file){ throw "This FileDownloader instance is already downloading a file!" }

        // Start the loading bar
        ns_file.loading = 0.5

        // Hook up the cancel function
        ns_file.cancel = () => { this.cancelDownload() }

        // Cache file and version
        this.ns_file = ns_file
        this.version = version_obj // TODO make sure the given version object includes the download links!
        this.mime_type = Utils.get_mime_type(Utils.get_file_extension(this.ns_file.name))

        return new Promise(async (resolve, reject) => {
            try{
                const before = performance.now()
                // Cache reject function so we can reject the promise when the user wants to cancel download
                this.download_file_reject = reject


                // Try to acquire a FileSystemWritableFileStream and iteratively append to a local file,
                // if the downloading file size is over the limit and the user is not trying to view it in the browser
                let filestream  = null
                // TODO: When a non-current file version is being downloaded, the size-based decision is made with the current version's size
                if(this.ns_file.size > FILESTREAM_MIN_SIZE_MB*1024*1024 && !this.ns_file.preview){
                    try{
                        // Trigger the file save dialog, point it to the Downloads folder and pass the file name
                        // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream
                        const newHandle = await window.showSaveFilePicker({
                            startIn: "downloads", 
                            suggestedName: this.ns_file.name
                        });
                        // create a FileSystemWritableFileStream to write to
                        filestream = await newHandle.createWritable();

                    } catch (error) {
                        if(error.name == "AbortError"){
                            this.cancelDownload()
                            reject("cancelled")
                            return
                        }
                        // Else: FileSystemWritableFileStream not supported by the browser, continue and use the locally constructed blob
                    }
                }

                if(!this.version) {
                    // Load the latest or requested version if its not given
                    this.version = await (version_id ? this.get_version(version_id) : await this.get_latest_version(ns_file.id))
                }

                // Download generations after each other
                let generations = []
                let downloaded_length = 0
                for(var i=0 ; i<this.version.generations.length ; ++i){
                    const gen = this.version.generations[i]
                    
                    let generation_data = await this._download_generation(gen, this.version)
                    downloaded_length += generation_data.byteLength

                    if(downloaded_length > this.version.size){
                        // Cut padding off the last generation
                        const useful_length = generation_data.byteLength - (downloaded_length - this.version.size)
                        generation_data = generation_data.slice(0, useful_length)
                    }

                    if(filestream){
                        // Write the generation to the local file
                        await filestream.write(generation_data)
                        //console.log(`[filestream] wrote ${generation_data.byteLength} bytes`)
                    }
                    else {
                        // Add the generation to the list for blob creation
                        // Might run out of memory!
                        generations.push(generation_data)
                    }
                }
                // All generations are downloaded
                const after = performance.now()
                const time_ms = Math.round(after-before)
                const speed_kbps = Math.round((this.version.size*8) / time_ms) // kilobit per sec
                console.info(`${this.ns_file.name} downloaded in ${(time_ms/1000).toFixed(2)} sec, end-to-end speed: ${speed_kbps} kbps`)

                if(filestream){
                    // Finish the local file
                    filestream.close()

                    resolve({
                        mime_type: this.mime_type,
                        size: this.version.size,
                        ns_file: ns_file,
                        saved_to_disk: true
                    })
                }
                else {
                    // Construct a single blob from the separate generations and serve it locally
                    var blob = new Blob(generations, {type: this.mime_type})
                    
                    if(Utils.is_mobile()){
                        // Ugly hack for mobile
                        const file_url = await MobileDownload.get_url(blob, ns_file.name)
                            .then(res => `${MobileDownload.BASE_URL}/${res.body}`)
                            .catch(err => {
                                console.error("Error getting file url: ", err)
                                throw "Error getting download URL for mobile download: " + err
                            })
    
                        resolve({
                            mime_type: this.mime_type,
                            size: this.version.size,
                            download_url: file_url,
                            ns_file: ns_file
                        })
                    }
                    else{
                        // Desktop browser download, return blob url
                        resolve({
                            mime_type: this.mime_type,
                            size: this.version.size,
                            blob_url: window.URL.createObjectURL(blob),
                            ns_file: ns_file
                        })
                    }
                }
            } catch(err){
                console.error(err)
                reject(err)
            }
        })
    },

    this.cancelDownload = function(){
        this.cancelled = true

        if(this.download_worker){
            this.download_worker.terminate()
        }
        if(this.download_file_reject){
            this.ns_file.loading = false
            delete this.ns_file.cancel
            this.download_file_reject({cancelled: true})
        }
    }

    this._download_generation = async (generation, file_version) => {
        return new Promise((resolve, reject) => {
            try{
                // Create worker to download, decode and decrypt generation
                this.download_worker = new Worker(worker_path)
                if(!this.download_worker){
                    console.error("Download worker could not be loaded from path: ", worker_path)
                    reject()
                }

                this.download_worker.onmessage = (msg) => {
                    const message = msg.data

                    switch(message.event){

                        case "bytes_downloaded":
                            {
                                // Progress report, newly downloaded bytes: message.bytes
                                const progress_increase_percent = (message.bytes / this.version.size) * 100 * 0.8
                                this.ns_file.loading += (this.ns_file.loading + progress_increase_percent) > 100 ? 0 : progress_increase_percent
                            }
                            break;

                        case "please_decrypt_this_thx":
                            // The worker cannot use WebCrypto, try to decrypt here on the main thread
                            AES.decrypt(message.data, message.iv, message.key).then(decrypt_result => {
                                // Encryption successful
                                const decrypted_data = decrypt_result
                                try{
                                    this.download_worker.postMessage({
                                        command: 'decrypt_ready',
                                        data: decrypted_data,
                                    }, [decrypted_data])
                                } catch(e){
                                    // Cannot transfer data, try copying
                                    this.download_worker.postMessage({
                                        command: 'decrypt_ready',
                                        data: decrypted_data
                                    })
                                }
                            }).catch(err => {
                                console.error("Error decrypting data on main thread")
                                reject(err)
                            })

                            break;

                        case "ready":
                            this.download_worker.terminate()
                            resolve(message.data)
                            // Report packet transfer times asynchronously
                            StorageBackendMonitorService.report_packet_transfers(message.packet_transfers)
                            break;

                        case "failed":
                            // Download failed
                            this.download_worker.terminate()
                            reject();
                            break;
                        
                        case "report_error":
                            this.report_error(message.error)
                            break;

                        default:
                            console.warn("Unknown event received from worker: " + message.event)
                            break;
                    }
                }

                this.download_worker.postMessage({
                    command: 'download',
                    generation: generation,
                    file_version: file_version
                })


            } catch(err){
                console.error("Error using download_gen_worker")
                reject(err)
            }

        })
    }

    this.get_latest_version = async (file_id) => {
        return new Promise((resolve, reject) => {
            try{
                FileVersionService.download_latest(file_id).then(res => {
                    if(this.cancelled){ reject({cancelled: true}) }
                    resolve(res.body)
                }).catch(err => {
                    console.error("Error downloading latest FileVersion of file #" + file_id)
                    reject(err)
                })
            } catch(err){
                console.error("Error calling FileVersion Service")
                reject(err)
            }
        })
    }

    this.get_version = async (version_id) => {
        return new Promise((resolve, reject) => {
            try{
                FileVersionService.download_version(version_id).then(res => {
                    if(this.cancelled){ reject({cancelled: true}) }
                    resolve(res.body)
                }).catch(err => {
                    console.error("Error downloading latest FileVersion #" + version_id)
                    reject(err)
                })
            } catch(err){
                console.error("Error calling FileVersion Service")
                reject(err)
            }
        })
    }

    this.report_error = (error) => {
        StorageBackendMonitorService.report_failed_transfer(error)
    }

}

export { FileDownloader }