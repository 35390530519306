<template>
<span v-if="entity" class="d-inline-block mr-2 mb-2 bg-gray-lighter px-2 py-1">
    <div class="d-flex justify-content-center align-items-center ">
        <span class="mr-2">
            <img v-if="is_file" class="thumb32" :src="entity.name | extension | filetype_img_src" />
            <i v-else class="ion-folder"></i>
        </span>
        <span>{{ entity.name }}</span>
    </div>
</span>
</template>

<script>

import { Utils } from '@/helpers/utils.js'
import { NamespaceService } from '@/services/namespace-service.js'
export default {

    props: ["entity"],

    computed: {
        is_file(){
            return this.entity.entity_type === NamespaceService.ENTITY_TYPE_FILE
        }
    }
}
</script>
<style scoped>
i{
    font-size: 32px;
    line-height: 32px;
}

.theme-dark .shared-file{
    background: #2f3c43;
    color: white
}

.file-container{
    display:flex;
    align-items: center;
    justify-content: center;
}
</style>
