<template>
<div class="sharing_status">
    <i v-if="loading" class="ion-load-c spin"></i>
    <div v-else>
        <!--<pre>{{ file.shares | json }}</pre>-->
        <section v-if="shares && shares.length > 0" >
            <table class="table table-sm table-striped d-none d-xl-block" style="width: fit-content">
                <thead>
                    <th>Created</th>
                    <th>Shared by</th>
                    <!--<th>Files in share</th>-->
                    <th>Expires</th>
                    <th>Download limit</th>
                    <th>Active</th>
                    <th>Share link</th>
                    <th>Password</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="share in file.shares" :key="share.id" :class="{'text-muted': !share.active}">
                        <td>{{ share.created | timestamp_rel }}</td>
                        <td>
                            <user-box :userId="share.created_by" :team="user.team" :pullLeft="true"></user-box>
                        </td>
                        <!-- TODO enable me when file multiselect is implemented
                        <td>
                            <i v-if="share.contents.length === 1">Just this</i>
                            <span v-else>This and {{ share.contents.length-1 }} more</span>
                        </td>
                        -->
                        <td>
                            <span v-if="share.expire">{{ share.expire | timestamp_rel }}</span>
                            <span v-else>Never</span>
                        </td>
                        <td>
                            {{ share.download_limit ? share.download_limit : 'No limit' }}
                        </td>
                        <td>
                            {{ share.active ? 'Yes' : 'No' }}
                        </td>
                        <td>
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm" style="width: 100px" v-model="share.link" />
                                <button class="btn btn-sm btn-secondary btn-gradient" title="Copy link to clipboard" @click="to_clipboard(share.link)"><i class="ion-clipboard icon-lg"></i></button>
                            </div>
                        </td>
                        <td>
                            <div class="input-group" v-if="share.password">
                                <input type="text" class="form-control form-control-sm" style="width: 100px" v-model="share.password" />
                                <button class="btn btn-sm btn-secondary btn-gradient"  title="Copy password to clipboard" @click="to_clipboard(share.password)"><i class="ion-clipboard icon-lg"></i></button>
                            </div>
                            <div v-else><i>No password</i></div>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div class=" d-block d-xl-none">
                <div v-for="(share, idx) in shares" :key="share.id">
                    <div class="share-header text-bold d-flex align-items-center justify-content-between mt-2 mb-2">
                        <span>Share {{ idx+1 }}</span>

                        <div class="d-flex align-items-center justify-content-center mb-1">
                            <button class="btn btn-sm text-sm btn-outline-warning mr-1" @click="disable_share(share)" :disabled="share.disable_loading">
                                <i class="ion-alert-circled mr-1"></i>
                                Disable
                                <i v-if="share.disable_loading" class="ion-load-c ml-1"></i>
                            </button>

                            <a class="btn text-sm btn-sm btn-primary" :href="share.link" target="_BLANK">
                                <i class="ion-share icon-lg"></i>
                                <span class="ml-1">Open</span>
                            </a>
                        </div>
                   </div>
                    <div class="share-details ml-2">
                        <div v-if="share.error" class="alert alert-danger alert-sm text-sm" v-html="share.error"></div>
                        <div>
                            <b>Created by: </b>
                            <user-box v-if="user && user.team" :team="user.team" :user-id="share.created_by"></user-box>
                        </div>
                        <div>
                            <b>Expires: </b> <span v-if="share.expire" class="text-lower">{{ share.expire | timestamp_rel }}</span><span v-else>Never</span>
                        </div>
                        <!--
                        <div>
                            <b>Files: </b>
                            <span v-if="share.contents.length === 1">Just this file</span>
                            <span v-else>{{ share.contents.length }} files</span>
                        </div>
                        -->
                        <div>
                            <b>Password: </b>
                            <input v-if="share.password" type="text" class="form-control form-control-sm" style="width: 100px" v-model="share.password" />
                            <span v-else>None</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div v-else class="text-success text-center">
            <i class="ion-locked mr-2"></i>This file is not shared
        </div>
    </div>

</div>
</template>

<script>

import { ShareService } from '@/services/share-service.js'
import { Utils } from '@/helpers/utils.js'

import userBox from '@/components/user-box.vue'

export default {
    props: ["user", "file", "shares_num_changed", "shares_updated"],
    components: { userBox },
    data(){
        return {
            loading: false,
            shares: null
        }
    },
    watch: {
        shares(){
            if(this.shares && this.shares_num_changed){
                this.shares_num_changed(this.shares.length)
            }
        }
    },

    created(){
        this.load_shares()
    },

    methods: {

        load_shares(){
            this.loading = true
            ShareService.get_shares_of_file(this.file.id).then(res => {
                this.loading = false
                let shares = res.body.filter(s => { return s.active })

                shares.forEach(share => {
                    share.link = this.$options.filters.share_link(share.alphakey)
                    share.disable_loading = false
                    share.error = false
                }, this)

                if(this.shares_updated){
                    this.shares_updated(shares)
                }

                this.shares = shares

            }).catch(err => {
                console.error(err)
                this.loading = false
                Utils.show_error("Failed to load active shares of " + this.file.name)
            })
        },

        disable_share(share){
            share.disable_loading = true
            share.error = false
            ShareService.revoke_share(share.id).then(() => {
                // No need to set loading to false, after reloading the shares list this share should be out
                this.load_shares()
            }).catch(err => {
                console.error("Error disabling share ", share)
                console.error(err)
                share.error = "Failed to disable share"
                if(err.body && err.body.message){
                    share.error += ": " + err.body.message
                }
            })
        },

        to_clipboard(str){
            Utils.to_clipboard(str)
        }
    }

}
</script>
<style scoped>

    .sharing_status .table{
        width: auto; margin: 0px auto
    }

    .sharing_status .table thead th{
        font-size: 12pt;
        text-transform: uppercase;
        border: 0px;
        text-align: center
    }

    .sharing_status .table td{
        padding-left: 15px;
        padding-right: 15px;
    }


    .share-header{
        border-bottom: 1px solid #ebebeb
    }
    .share-details{
        line-height: 2em
    }

</style>
