// Cross-platform, hardware accelerated AES-GCM encrypt and decrypt using JS WebCrypto API.
// Works in: Chrome, Firefox, Opera, Safari (on macOS and iOS) and Microsoft Edge. Doesn't work on Internet Explorer!
const AES = {

    // AES encryption mode
    AES_MODE: "AES-GCM",
    // Length of the AES-GCM initialization vector in bytes (GCM: 12 bytes, CBC: 16 bytes)
    AES_IV_LENGTH: 12,
    // Length of the AES passphrase in bytes (32 bytes = 256 bits -> AES256)
    AES_PASS_LENGTH: 32,
    // Length of authentication tag
    AUTH_TAG_LENGTH: 128,


    _is_microsoft_edge(){
        return (navigator.userAgent.indexOf("Edge/") >= 0)
    },

    is_crypto_supported(){
        return typeof(crypto) !== "undefined" || typeof(msCrypto) !== "undefined"
    },

    encrypt: async (buffer) => {
        let crypto_api = null
        if(typeof(crypto) !== "undefined"){
            crypto_api = crypto
        }
        /*
        else if(typeof(msCrypto) !== "undefined"){
            crypto_api = msCrypto
        }
        */

        if(!crypto_api){
            throw "WebCrypto not supported"
        }

        // Generate init vector
        const iv_arr = crypto_api.getRandomValues(new Uint8Array(AES.AES_IV_LENGTH))
        // Generate encryption key
        const key_arr = crypto_api.getRandomValues(new Uint8Array(AES.AES_PASS_LENGTH))

        const algorithm = {
            name: AES.AES_MODE,
            iv: iv_arr,
            tagLength: AES.AUTH_TAG_LENGTH,
            additionalData: AES._is_microsoft_edge() ? null : new Uint8Array(0)
        }
        // Hash: Microsoft Edge fails if hash with the 'name' attribute is not set. It's not a problem that the key is not actually a hashed string but generated directly
        // Additional data: Edge fails if an empty array is passed, Chrome fails if null is passed.

        // Create a CryptoKey object passed to encrypt()
        const key = await crypto_api.subtle.importKey('raw', key_arr.buffer, AES.AES_MODE, false, ['encrypt'])

        // Encrypt
        const encrypted_buffer = await crypto_api.subtle.encrypt(algorithm, key, buffer)

        return {
            encrypted_arr: new Uint8Array(encrypted_buffer),
            iv: iv_arr,
            key: key_arr
        }
    },


    decrypt: async (encrypted_arr, iv, key_arr) => {
        let crypto_api = null
        if(typeof(crypto) !== "undefined"){
            crypto_api = crypto
        }
        /*
        else if(typeof(msCrypto) !== "undefined"){
            crypto_api = msCrypto
        }
        */

        if(!crypto_api){
            throw "WebCrypto not supported"
        }

        if(iv.byteLength != AES.AES_IV_LENGTH){
            throw "Init Vector length should be "+AES.AES_IV_LENGTH+", found: "+iv.byteLength
        }
        if(key_arr.byteLength != AES.AES_PASS_LENGTH){
            throw "Passphrase length should be "+AES.AES_PASS_LENGTH+", found: "+key_arr.byteLength
        }

        // Import decryption key
        const key = await crypto_api.subtle.importKey('raw', key_arr, AES.AES_MODE, false, ['decrypt'])

        // Decrypt
        const algorithm = {
            name: AES.AES_MODE,
            iv: iv,
            tagLength: AES.AUTH_TAG_LENGTH
        }
        const decrypted_buffer = await crypto_api.subtle.decrypt(algorithm, key, encrypted_arr)
        return new Uint8Array(decrypted_buffer)
    }
}

if(typeof(module) !== "undefined"){
    // We need to export it when consumed by something that Webpack builds,
    // but cannot be exported when used in a vanilla JS Worker
    module.exports = AES
}