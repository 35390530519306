import { StorageBackendService } from '@/services/storage_backend-service.js'
import { NamespaceService } from '@/services/namespace-service.js'
import { FileVersionService } from '@/services/fileversion-service.js'
import { StorageBackendMonitorService } from '@/services/storagebackendmonitor-service.js'
import { Utils } from '@/helpers/utils.js'

import AES from "@/assets/js/aes.js"
import md5 from 'md5'

// Define the worker location as a relative path, this will be used only in runtime
const worker_path = 'assets/js/workers/upload_generation_worker.js'

// Max generation size
const GENERATION_SIZE = 125829120 // 120 MB
//const GENERATION_SIZE = 5829120 // ~5 MB

// Uploads a single file
function FileUploader() {

    this.ns_file = null
    this.userfile = null
    this.config = null

    this.upload_worker = null

    this.total_bytes_to_upload = 0

    this.upload_file_reject = null
    this.cancelled = false

    // Returns a Promise that resolves when the file finished uploading
    this.uploadFile = (ns_file, userfile, config) => {
        if(this.ns_file || this.userfile){ throw "This FileUploader instance is already uploading a file!" }

        // Start the loading bar
        ns_file.loading = 0.5

        // Hook up the cancel function
        ns_file.cancel = () => { this.cancelUpload() }

        // Cache ns_file and userfile
        this.ns_file = ns_file
        this.userfile = {
            name: userfile.name,
            size: userfile.size
        }
        this.config = config

        return new Promise(async (resolve, reject) => {
            try{
                const before = performance.now()
                // Cache reject function so we can reject the promise when the user wants to cancel upload
                this.upload_file_reject = reject
                
                // Construct packet name prefix 
                const fileversion_hash_base =  `${this.userfile.name}-${this.userfile.size}-${Math.round(Utils.timestamp_sec())}`
                const fileversion_hash = md5(fileversion_hash_base)
                //console.info("The file will be uploaded in %d generations", generations_num)
                
                // Descriptors of each generation will be added here
                let generations = []
                
                // Slice the file to generations and upload them after each other
                const generations_num = Math.ceil(userfile.size / GENERATION_SIZE)
                for(var gen_idx=0 ; gen_idx<generations_num ; ++gen_idx){

                    const segment_start_offset = gen_idx * GENERATION_SIZE
                    const segment_end_offset = Math.min(this.userfile.size, (gen_idx+1) * GENERATION_SIZE)
                    // Load the file slice data
                    const file_slice = userfile.slice(segment_start_offset, segment_end_offset)

                    try{
                        // Upload the generation and wait for it to finish
                        const metadata = {
                            idx: gen_idx,
                            name_hash: fileversion_hash
                        }
                        //console.info("Starting generation %d", gen_idx)
                        const gen_descriptor = await this._upload_generation(file_slice, metadata)
                        //console.info("Finished generation %d", gen_idx)
                        generations.push(gen_descriptor)

                    } catch(err){
                        console.error("Error uploading generation: ", err)
                        reject(err)
                        return
                    }
                } // At this point every generation finished uploading successfully
                
                if(this.ns_file.id < 0){
                    // Create a File record if this is a new file (not a new version of an existing file)
                    const new_file = await this.create_file(this.userfile.name, this.ns_file.parent_id, this.ns_file.mime_type)

                    // Add new attributes from 'new_file' to 'file'
                    Object.assign(this.ns_file, new_file)
                    // Set file ID
                    this.ns_file.id = new_file.id
                }

                // Create new fileversion
                const new_version = await this.create_fileversion(this.ns_file.id, this.userfile.size, '', generations)

                // Print stats
                const after = performance.now()
                const time_ms = Math.round(after-before)
                const speed_kbps = Math.round((userfile.size*8) / time_ms) // Kilobit per sec
                console.info(`${this.ns_file.name} uploaded in ${(time_ms/1000).toFixed(2)} sec, end-to-end speed: ${speed_kbps} kbps`)
                
                resolve(new_version)
            } catch(err){
                console.error("Error in FileUploader: ", err)
                reject(err)
            }
        })
    },

    this.cancelUpload = function(){
        this.cancelled = true

        if(this.upload_worker){
            this.upload_worker.terminate()
        }
        if(this.upload_file_reject){
            this.upload_file_reject({cancelled: true})
        }
    }

    this._upload_generation = async (file_slice, metadata) => {
        return new Promise((resolve, reject) => {
            try{
                if(this.cancelled){ reject() }

                // Get upload links
                const generations_num = 1
                StorageBackendService.get_upload_links(metadata.name_hash, generations_num)
                .then(res => {
                    if(res.body.length < generations_num){
                        throw "StorageBackend Service returned upload links for "+res.body.length+" generations, requested for "+generations_num+". Aborting."
                    }
                    // Requested just 1 generation, the links are in the first
                    let links = res.body[0]
                    // set the correct generation index
                    links.generation_idx = metadata.idx
                    //console.info("%d upload links received", links.packets.length)
                    return links
                })
                .then(links => {
                    if(this.cancelled){ 
                        // Upload was cancelled during we were waiting for the signed URLs
                        reject({cancelled: true}) 
                        return
                    }

                    // Calculate total upload size so we can report progress percent correctly
                    const redundancy_factor = links.packets.length / (links.packets.length - this.config.redundant_num)
                    this.total_bytes_to_upload = this.userfile.size * redundancy_factor

                    // Create worker to read, encrypt, encode and upload generation
                    
                    this.upload_worker = new Worker(worker_path)
                    if(!this.upload_worker){
                        throw "Upload worker could not be loaded from path: " + worker_path
                    }

                    this.upload_worker.onmessage = (msg) => {
                        const message = msg.data

                        //console.info(message)

                        switch(message.event){

                            case "bytes_uploaded":
                                {
                                    // Total size of the generation: this.userfile.size * redundancy_factor
                                    // Now downloaded: message.bytes
                                    const progress_increase_percent = (message.bytes/this.total_bytes_to_upload)*100
                                    this.ns_file.loading += (this.ns_file.loading + progress_increase_percent) > 100 ? 0 : progress_increase_percent
                                }
                                break;

                            case "please_encrypt_this_thx":
                                // The worker cannot use WebCrypto, try to encrypt here on the main thread
                                AES.encrypt(message.data).then(encrypt_result => {
                                    // Encryption successful
                                    const encrypted_data = encrypt_result.encrypted_arr
                                    try{
                                        this.upload_worker.postMessage({
                                            command: 'encrypt_ready',
                                            data: encrypted_data,
                                            iv: encrypt_result.iv,
                                            key: encrypt_result.key,
                                        }, [encrypted_data])
                                    }catch(e){
                                        try{
                                            // Cannot transfer data, try copying
                                            this.upload_worker.postMessage({
                                                command: 'encrypt_ready',
                                                data: encrypted_data,
                                                iv: encrypt_result.iv,
                                                key: encrypt_result.key,
                                            })
                                        } catch(err){
                                            console.error("Error sending data to the main thread for encryption", err)
                                        }
                                    }
                                }).catch(err => {
                                    console.error("Error encrypting data on main thread", err)
                                    reject(err)
                                })

                                break;

                            case "ready":
                                // Report packet transfer times, wait for the response (otherwise the request body is not sent fully)
                                this.upload_worker.terminate()
                                resolve(message.generation)
                                StorageBackendMonitorService.report_packet_transfers(message.packet_transfers)
                                break;

                            case "failed":
                                // Upload failed
                                this.upload_worker.terminate()
                                reject();
                                break;
                            
                            case "report_error":
                                this.report_error(message.error)
                                break;

                            default:
                                console.warn("Unknown event received from worker: " + message.event)
                                break;
                        }
                    }

                    this.upload_worker.postMessage({
                        command: 'upload',
                        file_slice: file_slice,
                        links: links,
                        config: this.config
                    })

                }).catch(err => {
                    console.error("Error requesting upload links for generation #" + metadata.idx, err)
                    throw "Error requesting upload links for generation #" + metadata.idx
                })
            } catch(err){
                console.error("Error uploading generation!", err)
                reject(err)
            }

        })
    }

    this.create_file = async (name, parent_id, mime_type) => {
        return new Promise((resolve, reject) => {
            try{
                NamespaceService.create_file(name, parent_id, mime_type).then(res => {
                    if(this.cancelled){ reject({cancelled: true}) }
                    const new_file = res.body
                    resolve(new_file)
                }).catch(err => {
                    console.error("Error creating File", err)
                    reject(err)
                })
            } catch(err){
                console.error("Error calling Namespace Service", err)
                reject(err)
            }
        })
    }

    this.create_fileversion = async (file_id, size, hash, generations) => {
        return new Promise((resolve, reject) => {
            try{
                FileVersionService.create_version(file_id, size, hash, 0, generations).then(res => {
                    if(this.cancelled){ reject({cancelled: true}) }
                    const new_version = res.body
                    resolve(new_version)
                }).catch(err => {
                    console.error("Error creating FileVersion", err)
                    reject(err)
                })
            } catch(err){
                console.error("Error calling FileVersion Service", err)
                reject(err)
            }
        })
    }

    this.report_error = (error) => {
        StorageBackendMonitorService.report_failed_transfer(error)
    }
}

export { FileUploader }