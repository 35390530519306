import { render, staticRenderFns } from "./team_setup.vue?vue&type=template&id=69a5f50a&scoped=true"
import script from "./team_setup.vue?vue&type=script&lang=js"
export * from "./team_setup.vue?vue&type=script&lang=js"
import style0 from "./team_setup.vue?vue&type=style&index=0&id=69a5f50a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a5f50a",
  null
  
)

export default component.exports