<template>
<section class="section-container" id="recycle_bin">
    <div class="container pt-3">

		<!-- Description -->
		<div class="row pt-3" v-if="false">
			<div class="col-1 "></div>
				<div class="col-12 col-lg-10">
					<div class="cardbox text-white bg-gradient-warning b0">
						<div class="cardbox-body text-center text-bold text-12">
							Deleted files and directories are stored here for 30 days.
							<br/>
							You can recover them during this period, afterwards they are deleted automatically.
						</div>
					</div>
			</div>
		</div>

		<div class="row" id="files">

			<div class="col-12">

				<!-- Nav tabs-->
				<ul v-if="user && user.is_admin" class="nav nav-tabs mb-4 justify-content-center" role="tablist" id="namespace_selector">
					<li class="nav-item" role="presentation"><a class="nav-link text-gray" :class="{'active': !is_admin_folder()}" href="#home" aria-controls="home" role="tab" data-toggle="tab" @click="set_admin_folder(false)"><i class="ion-document-text mr-2"></i>Team Files </a></li>
					<li class="nav-item" role="presentation"><a class="nav-link text-gray" :class="{'active': is_admin_folder()}" href="#profile" aria-controls="profile" role="tab" data-toggle="tab" @click="set_admin_folder(true)"><i class="ion-lock-combination mr-2"></i>Admin Folder</a></li>
				</ul>

				<deleted-groups :user="user" ref="deletedGroups"></deleted-groups>

			</div>
		</div>



    </div>
</section>
</template>

<script>


import { NamespaceService } from '@/services/namespace-service.js'

import deletedGroups from '@/components/deleted_groups.vue'


export default {
    props: ["user"],
	components: {
		deletedGroups
	},

	data: function() {
        return {
        }
	},

	created: function(){
        NamespaceService.ns_type = NamespaceService.NS_TYPE_DEFAULT
    },

	methods: {

		is_admin_folder(){
			return NamespaceService.ns_type === NamespaceService.NS_TYPE_ADMIN_FOLDER
		},

		set_admin_folder(is_admin_folder){
            NamespaceService.ns_type = is_admin_folder ? NamespaceService.NS_TYPE_ADMIN_FOLDER : NamespaceService.NS_TYPE_DEFAULT
            this.$refs.deletedGroups.reload()
		},
	},

}
</script>

<style scoped>
	.icon{
        font-size: 14pt;
        line-height: 1;
    }

	thead th{
        text-transform: uppercase;
        white-space: nowrap;
        border-top: none
    }

    .filename{
        word-break: break-all
    }

	#namespace_selector li a.active{
		background-color: #f6fbfe
	}
    #namespace_selector li a:hover{
        color: black
    }

    .theme-dark #namespace_selector li a:hover{
        color: white
    }
    .theme-dark #namespace_selector li a.active{
        background-color: #2f3c43
    }
</style>
