<template>
<section class="section-container" id="team_members">
    <div class="container-fluid">

		<!-- Team info box -->
		<div class="row" v-if="user && user.team_settings && (user.team_settings.name || user.team_settings.url)">
            <div class="col-3 ">&nbsp;</div>
            <div class="col-md-6">
                <div class="cardbox text-white bg-gradient-primary b0">
                    <div class="cardbox-body text-center">
                        <div class="text-bold text-lg">{{ user.team_settings.name }}</div>
                        <div class="" v-if="user.team_settings.url"><a :href="user.team_settings.url | fix_url" target="_BLANK" rel="noopener" class="text-white  "><em>{{ user.team_settings.url }}</em> <i class="ion-share ml-2" ></i></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
		<!-- User boxes -->
        <div class="row" v-if="user && user.team">

            <div class="col-sm-12 col-md-6 col-xl-4" v-for="member in user.team" :key="member.id">
                <div class="cardbox">
                    <div class="pb-1" :class="member.name | highlight_class"></div>
                    <div class="cardbox-body">
                        <div class="d-flex">
                            <div>
                                <div class="lead mb-2">{{ member.name }}<small v-if="member.is_admin" class="text-gray-light ml-2">(Admin)</small></div>
                                <p><span>{{ member.job_title || '&nbsp;' }}</span></p>
                                <p><em class="ion-email mr-2 icon-lg text-primary"></em><span>{{ member.email_address }}</span></p>
                            </div>
                            <div class="ml-auto"><img class="shadow-z5 thumb80 rounded fit-cover" :src="member.avatar_url" alt="user"></div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</section>
</template>

<script>
//import { UserService } from '@/services/user-service.js'
import { Utils } from '@/helpers/utils.js'

const box_highlights = ["bg-gradient-warning", "bg-gradient-primary", "bg-gradient-info", "bg-gradient-success", "bg-gradient-danger"]

export default {

    props: ['user'],

    filters: {
        fix_url: url => {
            if(!url.startsWith("http://") && !url.startsWith("https://")){
                return "http://" + url
            }
            return url;
        },

        highlight_class(name){
            return Utils.string_to_color(name, box_highlights)
        }
    }

}
</script>