import Vue from 'vue'

import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import * as Sentry from "@sentry/vue";

Vue.use(VueRouter)
Vue.use(VueResource)


import App from './App.vue'
import loginView from './components/login.vue'
import registerView from './components/register.vue'
import joinTeamView from './components/join-team.vue'

import adminFolderView from './views/admin_folder.vue'
import filesView from './views/files.vue'
import recycleBinView from './views/recycle_bin.vue'
import sharesView from './views/shares.vue'
import clientSpacesView from './views/client_spaces.vue'
import settingsView from './views/settings.vue'
import teamMembersView from './views/team_members.vue'
import installAppsView from './views/install_apps.vue'
import bugReportView from './views/bug_report.vue'
import upgradeTeamView from './views/upgrade_team.vue'
import teamSettingsView from './views/team_settings.vue'
import userManagementView from './views/user_management.vue'
import teamSetupView from './views/team_setup.vue'
import shareApp from './components/share_app.vue'
import backendPerformancePage from './views/backend-performance.vue'
import notRecognizedRouteView from './views/not-recognized-route.vue'
import clientSpaceApp from './views/client_space_app.vue'
import clientSpaceJoinView from './client-space-app/join-form'
import previewApp from './components/preview-app.vue'

// Container for login, register and join forms
import externalRegisterFormsContainer from './view-containers/register-forms-container' 

import { bus, events } from './helpers/event_bus.js'
import { Utils } from './helpers/utils.js'
import { TokenCache } from './helpers/token-cache.js'
import { AuthService } from './services/auth-service.js'
import { UserService } from './services/user-service.js'
import { FileVersionService } from './services/fileversion-service.js'
import { StorageBackendService } from './services/storage_backend-service.js'
import { ClientSpacesService } from './services/client_spaces-service.js'

//jQuery is injected via WebPack ProvidePlugin in vue.config.js
//import '@/assets/css/animate.css'
import '@/assets/css/ionicons/css/ionicons.css'
import '@/assets/css/colors.css'
import "@/assets/vendor/bootstrap-4.0.0/css/bootstrap.min.css"
import "@/assets/vendor/bootstrap-4.0.0/js/bootstrap.bundle.min.js" // includes Popper.js
import "@/assets/css/app.css"
//import "@/assets/js/app.js"

// List the router names that will be handled separately (e.g. the main wrapper is NOT app.vue)
// Route names are used as title labels on the UI, write them nicely!
const ROUTE_LOGIN_NAME = "Login"
//const ROUTE_RECOVER_PSW_NAME = "Recover Password"
const ROUTE_REGISTER_NAME = "Register"
const ROUTE_REGISTER_FREETRIAL_NAME = "Sign up to free trial"
const ROUTE_JOIN_TEAM_NAME = "Join Team"

const ROUTE_FILES_NAME = "Team Files"
const ROUTE_RECYCLE_BIN_NAME = "Recycle Bin"
const ROUTE_SHARES_NAME = "Shares"
const ROUTE_CLIENT_SPACES_NAME = "Client Spaces"
const ROUTE_ADMIN_FOLDER_NAME = "Admin Folder"
const ROUTE_SETTINGS_NAME = "My Settings"
const ROUTE_TEAM_NAME = "Team Members"
const ROUTE_INSTALL_NAME = "Install Apps"
const ROUTE_BUGREPORT_NAME = "Bug Report"
const ROUTE_UPGRADE_NAME = "Choose your subscription"

//const ROUTE_CLOUD_SELECTOR_NAME = "Cloud Selector"
const ROUTE_TEAM_SETUP_NAME = "Team Setup"
const ROUTE_TEAM_SETTINGS_NAME = "Team Settings"
const ROUTE_USER_MANAGEMENT_NAME = "User Management"

const ROUTE_SHARE_WEBAPP = "SkyFlok Shared Files"
const ROUTE_CLIENT_SPACES_WEBAPP = "SkyFlok Client Space"
const ROUTE_CLIENT_SPACE_JOIN = "Join Client Space"
const ROUTE_PREVIEW_APP = "File Preview"
const ROUTE_BACKEND_PERFORMANCE_PAGE = "Backend Performance"



/*
  List all routes that shouldn't render the default app layout where the user
  is logged in and the menu bar is on the left

  Used in the render() function to decide whether to render a special
  layout or the default one
*/
const special_routes = [
    {
        name: ROUTE_LOGIN_NAME,
        view: loginView
    },
    {
        name: ROUTE_SHARE_WEBAPP,
        view: shareApp
    },
    {
        name: ROUTE_CLIENT_SPACES_WEBAPP,
        view: clientSpaceApp
    },
    {
        name: ROUTE_PREVIEW_APP,
        view: previewApp
    },
    {
        /* TODO use externalRegisterFormsContainer */
        name: ROUTE_JOIN_TEAM_NAME,
        view: joinTeamView
    },
    {   /* TODO use externalRegisterFormsContainer */
        name: ROUTE_CLIENT_SPACE_JOIN,
        view: clientSpaceJoinView
    },
    {
        name: ROUTE_REGISTER_FREETRIAL_NAME,
        view: externalRegisterFormsContainer
    },
    {
        name: ROUTE_REGISTER_NAME,
        view: externalRegisterFormsContainer
    },
    {
        name: ROUTE_BACKEND_PERFORMANCE_PAGE,
        view: backendPerformancePage
    }
]


// List routes where the user does not have to be logged in
const routes_without_login = [
    ROUTE_LOGIN_NAME,
    ROUTE_TEAM_SETUP_NAME,
    ROUTE_REGISTER_NAME,
    ROUTE_REGISTER_FREETRIAL_NAME,
    ROUTE_JOIN_TEAM_NAME,
    ROUTE_SHARE_WEBAPP,
    ROUTE_CLIENT_SPACES_WEBAPP,
    ROUTE_PREVIEW_APP,
    ROUTE_BACKEND_PERFORMANCE_PAGE
]

const BASE_PATH = process.env.VUE_APP_BASE_PATH + '/'

const LOGIN_PATH = 'login'
const JOIN_TEAM_PATH = 'join-team'
const SHARE_APP_PATH = 'share'
const PREVIEW_APP_PATH = 'preview'
const TEAM_SETUP_PATH = 'team-setup'

const CLIENT_SPACE_APP = ClientSpacesService.CLIENT_SPACE_APP_PATH

const features = {
    client_spaces: true
}


let router = new VueRouter({
    routes: [

        { name: ROUTE_LOGIN_NAME, path: `/${LOGIN_PATH}`, component: loginView},
        { name: ROUTE_REGISTER_NAME, path: '/register', component: registerView },
        { name: ROUTE_REGISTER_FREETRIAL_NAME, path: '/register_trial', component: registerView },

        { name: ROUTE_JOIN_TEAM_NAME, path: '/'+JOIN_TEAM_PATH, component: joinTeamView },
        { name: ROUTE_SHARE_WEBAPP, path: '/'+SHARE_APP_PATH, component: shareApp },
        { name: ROUTE_CLIENT_SPACES_WEBAPP, path: '/'+CLIENT_SPACE_APP, component: clientSpaceApp },
        { name: ROUTE_CLIENT_SPACE_JOIN, path: '/join-space', component: clientSpaceJoinView },
        { name: ROUTE_PREVIEW_APP, path: '/preview', component: previewApp },
        { name: ROUTE_BACKEND_PERFORMANCE_PAGE, path: '/backend-performance', component: backendPerformancePage },

        { name: ROUTE_FILES_NAME, path: '/files', component: filesView, props: { is_admin_folder: false } },
        { name: ROUTE_RECYCLE_BIN_NAME, path: '/recycle_bin', component: recycleBinView, props: { is_admin_folder: false } },
        { name: ROUTE_SHARES_NAME, path: '/shares', component: sharesView },
        { name: ROUTE_CLIENT_SPACES_NAME, path: '/client_spaces', component: clientSpacesView },
        { name: ROUTE_ADMIN_FOLDER_NAME, path: '/admin_folder', component: adminFolderView },
        { name: ROUTE_SETTINGS_NAME, path: '/settings', component: settingsView },
        { name: ROUTE_INSTALL_NAME, path: '/install', component: installAppsView },
        { name: ROUTE_BUGREPORT_NAME, path: '/bugreport', component: bugReportView },
        { name: ROUTE_UPGRADE_NAME, path: '/upgrade-team', component: upgradeTeamView },
        
        { name: ROUTE_TEAM_NAME, path: '/team', component: teamMembersView },

        { name: ROUTE_TEAM_SETTINGS_NAME, path: '/team-settings', component: teamSettingsView },
        { name: ROUTE_TEAM_SETUP_NAME, path: '/team-setup', component: teamSetupView },
        { name: ROUTE_USER_MANAGEMENT_NAME, path: '/user-management', component: userManagementView },

        { name: '', path: '/', redirect: '/files' },
        { path: '*', name: '', component: notRecognizedRouteView }

    ],
    hashbang: false,
    mode: 'history',
    base: BASE_PATH,
    root: '/',
    saveScrollPosition: false
});

Sentry.init({
    Vue,
    dsn: "https://1251e5376a1f4325ab3c9a4a9b679b08@o278410.ingest.us.sentry.io/1499475",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.05, // Sample rate of all sessions
    replaysOnErrorSampleRate: 1.0, // Sample rate of sessions with JS errors

});
Sentry.setTag("git_last_commit_id", __GIT_INFO__.hash)
Sentry.setTag("git_branch", __GIT_INFO__.branch)
  

// Paths that should not redirect to /login when any request comes back with HTTP 401 (Unauthorized)
const no_redirect_paths = [
    LOGIN_PATH,
    JOIN_TEAM_PATH,
    SHARE_APP_PATH,
    CLIENT_SPACE_APP,
    PREVIEW_APP_PATH,
    TEAM_SETUP_PATH
]

Vue.http.interceptors.push(function(request, next) {
    next(function(response){
        if(response.status === 401){
            // Check if the path is a special app that should not be redirected to /login
            const full_path = window.location.pathname
            const path_prefix = router.options.base
            const relative_path = full_path.slice(path_prefix.length, full_path.length)
            if(no_redirect_paths.indexOf(relative_path) < 0){
                // Nope, redirect
                bus.$emit(events.LOGOUT)
            }
        }
    })
});

Vue.filter('avatar_url', function(user){ return Utils.avatar_url(user) })
Vue.filter('timestamp', function(time){ return Utils.timestamp(time) })
Vue.filter('timestamp_rel', function(time){ return Utils.timestamp_rel(time) })
Vue.filter('timestamp_date', function(time){ return Utils.timestamp_date(time) })
Vue.filter('timestamp_time', function(time){ return Utils.timestamp_time(time) })
Vue.filter('timestamp_time_date', function(time){ return Utils.timestamp_date(time)+", "+Utils.timestamp_time(time) })
Vue.filter('json', function(obj){ return JSON.stringify(obj, null, 2) })
Vue.filter('join', function(arr, separator){ return arr.join(separator ? separator : ',') })
Vue.filter('path', function(path_str){ return Utils.path(path_str) })
Vue.filter('format_bytes', function(bytes, round){ return Utils.format_bytes(bytes, round) })
Vue.filter('extension', function(filename){ return Utils.get_file_extension(filename) })
Vue.filter('filetype_img_src', function(extension){ return Utils.filetype_img_src(extension) })
Vue.filter('share_link', function(share_key){  return window.location.origin + BASE_PATH + SHARE_APP_PATH + "?key=" + share_key })
Vue.filter('client_space_link', function(key){  return window.location.origin + BASE_PATH + CLIENT_SPACE_APP + "?key=" + key })
Vue.filter('file_preview_link', function(blob_url, viewer_name, file_name, mime_type){ return window.location.origin + BASE_PATH + PREVIEW_APP_PATH + "?url=" + encodeURIComponent(blob_url) + "&viewer=" + viewer_name + "&name=" + encodeURIComponent(file_name) + "&type=" + encodeURIComponent(mime_type) })
Vue.filter('join_team_link', function(team){ return window.location.origin + BASE_PATH + JOIN_TEAM_PATH + "?key="+team.join_key })
Vue.filter('login_link', function(){ return window.location.origin + BASE_PATH + "login" })
Vue.filter('round_up_100', function(num){ return Utils.round_up_100(num) })

/*
// To use VueGoogleMaps, add the following to 'dependencies' in package.json:
// "vue2-google-maps": "^0.10.7"

import * as VueGoogleMaps from "vue2-google-maps"
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDd1uHcI91v3EkmJgmpWe34ANQw3wsnZm8',
        libraries: 'places'
    },
    installComponents: true
});
*/

// start app
new Vue({
    el: "#app",
    data() {
        return {
            user: null
        }
    },

    http: {
        timeout: 1000
    },

    created() {
        var self = this

        // Check if there's a valid user (if the route requires one)
        if(this.$route.name && routes_without_login.indexOf(this.$route.name) >= 0){
            // no logged in user needed
        }
        else if(this.user === null){
            let cached_token = TokenCache.get()
            if(!cached_token){

                // Redirect to login if there's no logged in user
                // Preserve the original requested URL, so we can redirect to it after successful login
                router.push('/login?return_to='+encodeURI(window.location.href))
            }
            else{

                if(window.location.pathname === router.options.base){
                    // Send to /files if the path is empty
                    router.push("/files")
                }
                this.init_user(cached_token, false, false)
            }
        }

        // Register event listeners for events coming from children components
        bus.$on(events.LOGIN_SUCCESSFUL, token => {

            // Store the token in the local cache
            TokenCache.store(token)

            // Load user details
            this.init_user(token, "/files", true)
        })

        bus.$on(events.LOGOUT, function(){
            // Reset the user
            this.user = null

            // Clear all caches, except for the device ID and MSP portal stuff
            for(var key in localStorage){ 
                if(localStorage.hasOwnProperty(key) && key != Utils.device_id_key && !key.startsWith('MSP-')){ 
                    localStorage.removeItem(key)
                } 
            }

            // Redirect to login if not there yet
            if(router.currentRoute.path != '/login'){
                router.push('/login')
            }
        })
        
        bus.$on(events.USED_STORAGE_CHANGED, function(){
            self.refresh_storage_use()
        })

        bus.$on(events.RELOAD_TEAM, function(){
            self.refresh_team()
        })

        bus.$on(events.RELOAD_TEAM_SETTINGS, function(){
            self.get_company_settings()
        })

        bus.$on(events.USER_PROFILE_CHANGED, function(new_attributes){
            for(var key in new_attributes){
                if(key !== 'id' && new_attributes[key] !== null && Object.hasOwnProperty(new_attributes, key)){
                    self.user[key] = new_attributes[key]
                }
            }
            // Propagate the change to 'Team' too
            new_attributes.id = self.user_id
            bus.$emit(events.TEAM_MEMBER_PROFILE_CHANGED, new_attributes)
        })

        bus.$on(events.TEAM_MEMBER_PROFILE_CHANGED, function(new_attributes){
            if(!self.user || !self.user.team){ return }
            let member = self.user.team.find(m => { return m.user_id === new_attributes.id})
            if(!member){ return; }

            // Change the attributes we got
            for(var key in new_attributes){
                if(key !== 'id' && new_attributes.hasOwnProperty(key)){
                    member[key] = new_attributes[key]
                }
            }
        })

        bus.$on(events.RELOAD_BUCKETS, function(){
            self.load_buckets()
        })

        bus.$on(events.NAVIGATE_TO, function(page_url){
            router.push(page_url)
        })

        bus.$on(events.RELOAD_2FA_STATUS, function(){
            self.load_2fa_status(false)
        })
    },

    methods: {

        init_user(token, redirect, is_fresh_login){
            // Let's see if the cached token is still valid
            UserService.get_me().then(resp => {
                // Store logged in user
                let user = resp.body
                user.avatar_url = Utils.avatar_url(user)

                AuthService.authenticate_token(token).then(res => {
                    if(!res.ok){ console.error("Failed to authenticate token: HTTP "+res.status, res.body); bus.$emit(events.LOGOUT); return; }

                    const roles = res.body
                    user.roles = roles.roles
                    user.is_admin = user.roles.indexOf("OA") >= 0

                    // Publish the user object now when we have the roles
                    this.user = user

                    this.refresh_team()
                    this.refresh_storage_use()
                    this.get_company_settings()
                    this.load_buckets()

                    this.load_2fa_status(is_fresh_login)

                    if(redirect){
                        router.push(redirect)
                    }
                })
            }).catch(() => {
                // The cached token expired, clear it and redirect to login
                bus.$emit(events.LOGOUT)
            })
        },

        refresh_team: function(){
            UserService.get_my_team(true).then(res => {
                if(!res.ok){ console.error("Failed to get my team: HTTP "+res.status, res.body); return; }

                // Set missing avatar URL
                let team = res.body
                team.forEach(member => {
                    member.avatar_url = Utils.avatar_url(member)
                }, this)

                if(!this.user){ return }
                this.$set(this.user, "team", team)
                bus.$emit(events.TEAM_LOADED)
            }).catch(err => { console.error("Failed to get my team: HTTP "+err.status, err.body); })
        },

        refresh_storage_use: function(){
            FileVersionService.get_company_storage_use().then(res => {
                if(!res.ok){ console.error("Failed to get company storage use: HTTP "+res.status, res.body); return; }
                if(!this.user){ return }
                this.$set(this.user, "team_storage_used", res.body.bytes - 0)
            }).catch(err => {
                console.error("Failed to get company storage use: HTTP "+err.status, err.body);
            })
        },

        load_buckets(){
            // Load buckets
            StorageBackendService.get_buckets().then( res => {
                const buckets = res.body
                this.$set(this.user, 'buckets', buckets)
                if(buckets.length === 0){
                    // navigate to Cloud Selector and show welcome box
                    router.push('/team-setup')
                }

            }, err_resp => {
                console.error("Failed to retrieve buckets: ", err_resp)
            })
        },

        get_company_settings(){
            UserService.get_company_settings().then(res => {
                const settings = res.body
                this.$set(this.user, "team_settings", settings)
            }).catch(err => { console.error("Failed to get team settings: HTTP "+err.status, err.body); })
        },

        load_2fa_status(show_warning){
            AuthService.get_2fa_status().then(res => {
                const status = res.body
                if(status.has_2fa && !status.verified){
                    if(show_warning){
                        Utils.show_warning("You have started setting up two-factor authentication, but haven't finished verifying your phone! Go to <b>My Settings</b> to complete the setup!")
                    }
                    // Show a warning icon in the menu at /settings
                    if(!this.user.menu_warning){
                        this.user.menu_warning = []
                    } 
                    this.user.menu_warning.push('settings')
                }
                else{

                    if(this.user.menu_warning && this.user.menu_warning.indexOf('settings') >= 0){
                        const idx = this.user.menu_warning.indexOf('settings')
                        this.user.menu_warning.splice(idx, 1)
                    }

                    if(!status.has_2fa){
                        // Show info?
                    }
                }

                
            }).catch(err => { console.error("Failed to get 2FA status: HTTP "+err.status, err.body); })
        }

    },

    router,

    render(h){
        // Check if the route is special (e.g. its container is not App.vue)
        // Using externalRegisterFormsContainer:
        // - Set externalRegisterFormsContainer as the 'view' in special_routes
        // - Add a route rule with 'component' set as the content you want to render within externalRegisterFormsContainer
        const special_route = special_routes.find(r => r.name == this.$route.name)
        if(special_route){
            return h(special_route.view, { props: { user: this.user } })
        }

        /* Very important to pass the logged in user in props so children components can access it if they want to */
        return h(App, { props: { user: this.user, features: features } })
    },
    
});

/*
import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
*/