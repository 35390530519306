<template>
    <section>
        <div class="c-pointer" @click="basic_info_open = !basic_info_open">
            <span class="text-12">Basic info</span>
            <i class="ml-2" :class="{'ion-chevron-up': basic_info_open, 'ion-chevron-down': !basic_info_open }"></i>
        </div>

        <table v-show="basic_info_open" class="table table-sm mt-2 file-details-table mb-0">
            <tbody>
                <tr>
                    <th>Name</th>
                    <td>{{ file.name }}</td>
                </tr>
                <tr>
                    <th>Size</th>
                    <td>{{ file.size | format_bytes }} <small>({{ Number(file.size).toLocaleString() }} bytes)</small></td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td class="text-monospace break-word">{{ file.mime_type }}</td>
                </tr>
            </tbody>
        </table>


        <section v-if="!hide_shares">
            <div class="c-pointer mt-4" @click="sharing_open = !sharing_open">
                <span class="text-12">Sharing<span v-if="shares_num !== null" class="ml-2 badge badge-primary">{{ shares_num }}</span></span>
                <i class="ml-2" :class="{'ion-chevron-up': sharing_open, 'ion-chevron-down': !sharing_open }"></i>
            </div>
            <div v-show="sharing_open" class="mt-2">
                <file-sharing-status
                    :user="user"
                    :file="file"
                    :shares_num_changed="(num) => { shares_num = num }">
                </file-sharing-status>
            </div>
        </section>

        <section v-if="!hide_versions">
            <div class="c-pointer mt-4" @click="versions_open = !versions_open">
                <span class="text-12">Versions<span v-if="versions_num !== null" class="ml-2 badge badge-primary">{{ versions_num }}</span></span>
                <i class="ml-2" :class="{'ion-chevron-up': versions_open, 'ion-chevron-down': !versions_open }"></i>
            </div>
            <div v-show="versions_open" class="mt-2">
                <file-versions-table
                    :file="file"
                    :user="user"
                    :modifiedBoxExternal="modifiedBoxExternal"
                    :download_file_fn="download_file_fn"
                    :open_file_fn="null"
                    :versions_num_changed="(num) => { versions_num = num }"
                    >
                </file-versions-table>
            </div>
        </section>

    </section>
</template>

<script>

import fileSharingStatus from '@/components/file-sharing-status.vue'
import fileVersionsTable from '@/components/file-versions-table.vue'

export default {
    props: ['file', 'user', 'hide_shares', 'hide_versions', 'download_file_fn', 'modifiedBoxExternal'],

    components: {
        fileSharingStatus,
        fileVersionsTable
    },

    data(){
        return {
            basic_info_open: true,
            sharing_open: false,
            versions_open: false,

            versions_num: null,
            shares_num: null
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.file-details-table th{
    text-align: right
}
.file-details-table td{
    text-align: left
}
</style>
