<template>
  <div class="layout-container"  :class="{'hidden': sidebar_hidden}">

    <!-- sidebar-->
    <aside v-if="!is_team_setup" class="sidebar-container">
        <div class="p-3 c-pointer text-muted"  @click="sidebar_hidden = true" style="position: absolute;" title="Close sidebar"><em class="ion-chevron-left"></em></div>
        <div class="brand-header d-flex align-items-center justify-content-center">

            <router-link to="/files" @click.capture="menu_click">
                <img v-if="show_team_logo" :src="user.team_settings.logo_url" style="max-height: 50px; max-width: 200px">
                <img v-else src="@/assets/img/skyflok_logo.png" width="130"/>
            </router-link>
        </div>

        <div class="sidebar-content">
            <div class="sidebar-toolbar">
                <div class="sidebar-toolbar-background"></div>
                <div class="sidebar-toolbar-content text-center">
                    <router-link to="/settings" @click.capture="menu_click" style="text-decoration: none; color: white" title="Click to edit your settings">
                        <img v-if="user" class="rounded-circle thumb64 fit-cover" style="box-shadow: 0 0 5px 0 #888" :src="user.avatar_url" alt="Profile">
                        <div class="mt-3">
                            <div class="lead text-bolder">{{ user ? user.name : '' }}</div>
                            <div class="">{{ user ? user.company_name : '' }}</div>
                        </div>
                    </router-link>
                </div>
            </div>

            <nav class="sidebar-nav">
                <ul>
                <!--
                <li>
                    <div class="sidebar-nav-heading">MENU</div>
                </li>
                -->
                <li :class="{'active': path === '/files', 'disabled': no_buckets}" @click.capture="menu_click($event, 'files')">
                    <router-link to="/files">
                        <span class="nav-icon">
                            <em class="ion-document-text"></em>
                        </span>
                        <span>Team Files</span>
                    </router-link>
                </li>
                <li class="recycle_bin" :class="{'active': path === '/recycle_bin', 'disabled': no_buckets, 'dragover': dragover_recycle_bin}" @drop.prevent="drop_to_bin($event)" @dragover.prevent="dragover_bin($event)" @dragleave.prevent="dragover_recycle_bin = false" @click.capture="menu_click">
                    <router-link to="/recycle_bin">
                        <span class="nav-icon">
                            <em class="ion-trash-a"></em>
                        </span>
                        <span>Recycle Bin</span>
                    </router-link>
                </li>

                <li v-if="share_enabled"  :class="{'active': path === '/shares', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/shares">
                    <span class="nav-icon">
                        <em class="ion-link"></em>
                    </span>
                    <span>Shares</span>
                    </router-link>
                </li>

                <li v-if="client_spaces_enabled" :class="{'active': path === '/client_spaces', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/client_spaces">
                        <span class="nav-icon"><em class="ion-filing"></em></span>
                        <span>Client Spaces</span>
                    </router-link>
                </li>

                <li v-if="client_spaces_enabled && is_admin" :class="{'active': path === '/admin_folder', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/admin_folder">
                        <span class="nav-icon">
                            <em class="ion-lock-combination"></em>
                        </span>
                        <span>Admin Folder</span>
                    </router-link>
                </li>

                 <li :class="{'active': path === '/team', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/team">
                    <span class="nav-icon">
                        <em class="ion-person-stalker"></em>
                    </span>
                    <span>Team Members</span>
                    </router-link>
                </li>

                <li :class="{'active': path === '/settings', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/settings">
                        <span class="nav-icon"><em class="ion-wrench"></em></span>
                        <span>My Settings</span>
                        <span v-if="user && user.menu_warning && user.menu_warning.indexOf('settings') >= 0" class="float-right nav-label pr-3"><span class="badge-rounded badge-warning">!</span></span>
                    </router-link>
                </li>

                <li v-if="is_admin">
                    <div class="sidebar-nav-heading text-muted pb-1">TEAM MANAGEMENT</div>
                </li>

                <li v-if="is_admin && user.team_settings && user.team_settings.is_free_trial" :class="{'active': path === '/upgrade-team', 'bg-gradient-warning text-bold text-gray-dark': path !== '/upgrade-team'}" @click.capture="menu_click">
                    <router-link to="/upgrade-team">
                        <span class="nav-icon" >
                            <em class="ion-star" :class="{'text-gray-dark': path !== '/upgrade-team'}"></em>
                        </span>
                        <span>Upgrade Account</span>
                    </router-link>
                </li>

                <li v-if="is_admin" :class="{'active': path === '/team-settings', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/team-settings">
                    <span class="nav-icon">
                        <em class="ion-gear-a"></em>
                    </span>
                    <span>Team Settings</span>
                    </router-link>
                </li>

                <li v-if="is_admin" :class="{'active': path === '/user-management', 'disabled': no_buckets}" @click.capture="menu_click">
                    <router-link to="/user-management">
                    <span class="nav-icon">
                        <em class="ion-person-add"></em>
                    </span>
                    <span>User Management</span>
                    </router-link>
                </li>

                </ul>
            </nav>
            <div class="nav-footer">
                
                <div v-if="false && data_locations && data_locations.length > 0" class="mb-3" id="data-locations">
                    <location-flags :locations="data_locations" :disable_popover="is_mobile"></location-flags>
                </div>

                <div v-if="user && user.team_settings">
                    <div v-if="user.team_settings.max_storage !== null"  class="usage_container mb-5">
                        <!-- Limited storage -->
                        <div class="storage_used bg-gradient-primary" :style="{'width': Math.max(0, Math.round((user.team_storage_used / user.team_settings.max_storage) * 100)) + '%' }"></div>
                        {{ user.team_storage_used | format_bytes }} of {{ user.team_settings.max_storage | format_bytes }} used
                    </div>
                    <div v-else>
                        <!-- Unlimited storage -->
                        {{ user.team_storage_used | format_bytes }} storage used
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-center">
                    <div class="mr-1"><button class="btn btn-sm text-sm" @click="set_theme('theme-default')">Bright<i v-if="!theme || theme=='theme-default'" class="ion-checkmark ml-1"></i></button></div>
                    <div class="ml-1"><button class="btn btn-sm btn-outline-secondary text-sm" @click="set_theme('theme-dark')">Dark<i v-if="theme=='theme-dark'" class="ion-checkmark ml-1"></i></button></div>
                </div>

                <div v-if="show_team_logo" class="text-center mt-4">
                    <img src="@/assets/img/skyflok_logo.png" height="30"/>
                </div>
            </div>
        </div>
    </aside>
    <div class="sidebar-layout-obfuscator"></div>

    <!-- Main section-->
    <main class="main-container">
    <!-- top navbar-->
    <header v-if="!is_team_setup" class="header-container" :class="{'hidden': file_dragged}">
        <global-alert :alert="alert"></global-alert>
        <nav>
            <ul v-if="sidebar_hidden">
                <li @click="sidebar_hidden = false" title="Open sidebar">
                    <a class="" href="#"><span><em class="ion-chevron-right"></em></span></a>
                </li>
            </ul>
            <ul class="d-inline-block d-lg-none text-left">
                <li><a class="sidebar-toggler menu-link menu-link-close" href="#"><span><em></em></span></a></li>
            </ul>
            <ul class="d-none d-xs-block text-left">
                <li><a class="covermode-toggler menu-link menu-link-close" href="#"><span><em></em></span></a></li>
            </ul>

            <h2 v-if="!is_mobile && !hide_route_name" class="header-title mr-auto">
                {{ $route.name }}
                <span v-if="false" class="d-inline-block badge badge-sm badge-secondary">
                    <span class="d-block d-sm-none">XS</span>
                    <span class="d-none d-sm-block d-md-none">SM</span>
                    <span class="d-none d-md-block d-lg-none">MD</span>
                    <span class="d-none d-lg-block d-xl-none">LG</span>
                    <span class="d-none d-xl-block">XL</span>
                </span>
                <i v-if="header_info_icon" 
                    class="ml-1 ion-information-circled p-0 c-help" style="font-size: .7em" 
                    data-container="body"
                    data-toggle="popover"
                    data-placement="bottom"
                    :data-html="true"
                    :data-trigger="is_mobile ? 'click' : 'hover'"
                    :data-content="header_info_icon"></i>
            </h2>


            <div class="float-right mr-2">

                <search-box></search-box>

                <div class="dropdown d-inline-block ml-2" v-show="user">
                    <a class="dropdown-toggle text-gray" href="#" data-toggle="dropdown">
                        <img v-if="user" class="header-user-image fit-cover initial32" :src="user.avatar_url">
                    </a>

                    <div class="dropdown-menu dropdown-menu-right dropdown-scale mt-4">

                        <router-link to="/settings" class="dropdown-item">
                            <i class="ion-gear-a icon-lg text-primary mr-3"></i>My Settings
                        </router-link>

                        <router-link to="/bugreport" class="dropdown-item">
                            <i class="ion-bug icon-lg text-primary mr-3"></i>Feedback, Bug Report
                        </router-link>
                        <!--
                        <a class="dropdown-item" style="cursor: pointer" @click.prevent.stop="false">
                            <i class="ion-help-buoy icon-lg text-primary mr-3"></i>Help Center <span class="badge badge-success" title="We are still working on the Help Center, it's coming soon!">Soon!</span>
                        </a>

                        <router-link to="/install" class="dropdown-item" @click.prevent.stop="false">
                            <i class="ion-android-desktop icon-lg text-primary mr-3"></i>Install Apps <span class="badge badge-success" title="We are still working on our Apps, it's coming soon!">Soon!</span>
                        </router-link>
                        -->

                        <a class="dropdown-item c-pointer" href="https://www.skyflok.com/legal" target="_BLANK" rel="noopener">
                            <i class="ion-quote icon-lg text-primary mr-3"></i>Terms &amp; Privacy<i class="ion-share ml-1"></i>
                        </a>

                        <div class="dropdown-divider" role="presentation"></div>

                        <a class="dropdown-item c-pointer text-danger" @click="logout()">
                            <i class="ion-log-out icon-lg mr-3"></i>Log out
                        </a>
                    </div>
                </div>
            </div>


        </nav>
    </header>

    <!-- Reminder of team plan expiration within the last 14 days -->
    <div v-if="plan_expires_days >= 0 && plan_expires_days <= 14" class="bottom-container bg-gradient-warning text-white py-3">
        <div class="text-center text-bold">
            Your {{ user.team_settings.plan_key == 'skyflok-trial' ? "free trial" : 'team subscription'}} will end {{ plan_expires_days | days_rel }}!
            <a class="ml-2 text-gray-darker" href="https://www.skyflok.com/#Pricing" target="_BLANK" rel="noopener">Upgrade <i class="ion-share ml-1"></i></a>
        </div>
    </div>

      <router-view :user="user" :route="$route" :features="features"></router-view>
    </main>

  </div>
</template>

<script>
import { bus, events } from '@/helpers/event_bus.js'
import { AuthService } from '@/services/auth-service.js'
import { NamespaceService } from '@/services/namespace-service.js'
import { ShareService } from '@/services/share-service.js'
import { Utils } from '@/helpers/utils.js'
import { StorageLocationService } from '@/services/storage_backend-service.js';

import globalAlert from '@/components/global-alert.vue'
import searchBox from '@/components/search-box.vue'
import locationFlags from '@/components/data-location-flags.vue'



export default {
    components: {
        globalAlert,
        searchBox,
        locationFlags
    },
	props: ['user', 'features', 'hide_route_name'],

    data(){
        return {
            alert: { },
            dragover_recycle_bin: false,
            file_dragged: false,
            data_locations: null,
            sidebar_hidden: false,

            theme: localStorage["theme"]
        }
    },

    computed: {
        path(){ return this.$route ? this.$route.path : undefined },

        no_buckets(){
            return this.user && this.user.buckets && this.user.buckets.length == 0
        },

        client_spaces_enabled(){
            return this.user && this.user.team_settings && this.user.team_settings.config.client_spaces_enabled === true
        },
        share_enabled(){
            return this.user && this.user.team_settings && this.user.team_settings.config.share_enabled === true
        },

        is_admin(){
            return this.user && this.user.is_admin
        },

        is_mobile(){
            return Utils.is_mobile()
        },

        is_team_setup(){
            // The team setup wizard is open
            return this.path == '/team-setup'
        },

        plan_expires_days() {
            if(!this.user || !this.user.team_settings){
                return -1
            }
            let date = new Date()
            let dateOfExpiring = new Date(this.user.team_settings.plan_expires * 1000)
            let expires_in = Math.abs(Math.round((dateOfExpiring.getTime() - date.getTime())/(1000*60*60*24)))
            return expires_in
        },

        show_team_logo(){
            return this.user && this.user.team_settings && this.user.team_settings.plan_key === 'skyflok' && this.user.team_settings.logo_url
        },

        header_info_icon(){

            switch(this.path)
            {
                case '/client_spaces':
                    return "Client Spaces are <b>secure, confidential shared folders</b> between you and your clients, where\
                    both of you can upload and download files.<br/><br/>\
                    It's a simple but very <b>secure alternative of emailing files to each other</b>, and you don't even need to\
                    make your clients Team Members."
                
                case "/recycle_bin":
                    return "Deleted files and directories are stored here for <b>30 days.</b><br/><br/>You can recover them during this period, afterwards they are deleted automatically."
                
                case "/admin_folder":
                    return "This is a safe folder that is only visible and accessible for <b>Team Administrators</b>.<br/><br/>\
                        It's an ideal place for things you don't want to share with the whole team, like passwords, invoices, \
                        contracts, Non-Disclosure Agreements and similar <b>confidential files</b>."
                
                default: return false
            }
        }

    },

    created(){
        var self = this
        bus.$on(events.ERROR, (msg, sticky) => { this.alert = { type: "danger", html: msg, keep: sticky } })
        bus.$on(events.SUCCESS, (msg, sticky) => { this.alert = { type: "success", html: msg, keep: sticky } })
        bus.$on(events.WARNING, (msg, sticky) => { this.alert = { type: "warning", html: msg, keep: sticky } })

        // When a file or folder is being dragged, we need to hide the header bar on the top
        // otherwise the page won't scroll up automatically
        bus.$on(events.FILE_FOLDER_DRAG_START, function(){
            self.file_dragged = true
        })
        bus.$on(events.FILE_FOLDER_DRAG_END, function(){
            self.file_dragged = false
        })
        
        if(this.is_team_setup){
            // Hide sidebar in team setup
            this.sidebar_hidden = true
        }
        else{
            // Not team setup, load info that appears in the sidebar

            // Trigger refresh of used storage
            bus.$emit(events.USED_STORAGE_CHANGED)

            // Load the bucket locations
            StorageLocationService.list_my_team_locations().then(res => {
                const locations = res.body
                this.data_locations = locations
            }).catch(err => {
                console.error("Error loading buckets: ", err)
            })
        }


    },

    mounted(){
        if(localStorage && localStorage["theme"]){
            this.set_theme(localStorage["theme"])
        }

        const bg_container = document.querySelector(".sidebar-toolbar-background")
        if(bg_container){
            var now = new Date();
            const bg_img = (now.getHours() >= 6 && now.getHours() < 20) ? 'assets/img/profile_bg_day.png' : 'assets/img/profile_bg_night.png'
            bg_container.style.background = "url('"+bg_img+"')"
        }


        var layoutContainer = $('.layout-container');
        var $body = $('body');
        // Handler to toggle sidebar visibility on mobile
        $('.sidebar-toggler').click(function(e) {
            e.preventDefault();
            layoutContainer.toggleClass('sidebar-visible');
            // toggle icon state
            $(this).parent().toggleClass('active');
        });
        // Close sidebar when click on backdrop
        $('.sidebar-layout-obfuscator').click(function(e) {
            e.preventDefault();
            $body.removeClass('sidebar-cover-visible'); // for use with cover mode
            layoutContainer.removeClass('sidebar-visible'); // for use on mobiles
            // restore icon
            $('.sidebar-toggler').parent().removeClass('active');
        });

        // escape key closes sidebar on desktops
        $(document).keyup(function(e) {
            if (e.keyCode === 27) {
                $body.removeClass('sidebar-cover-visible');
            }
        });

        // Handler to toggle sidebar visibility on desktop
        $('.covermode-toggler').click(function(e) {
            e.preventDefault();
            $body.addClass('sidebar-cover-visible');
        });

        $('.sidebar-close').click(function(e) {
            e.preventDefault();
            $body.removeClass('sidebar-cover-visible');
        });

        // remove desktop offcanvas when app changes to mobile
        // so when it returns, the sidebar is shown again
        window.addEventListener('resize', function() {
            if (window.innerWidth < 768) {
                $body.removeClass('sidebar-cover-visible');
            }
        });
    },

    methods: {

        dragover_bin(){
            this.dragover_recycle_bin = true
        },

        drop_to_bin(evt){
            const dropped = JSON.parse(evt.dataTransfer.getData("text"))
            this.dragover_recycle_bin = false
            if(!dropped || !dropped.id || !dropped.entity_type){ return }

            if(dropped.entity_type === NamespaceService.ENTITY_TYPE_FILE){
                // If a file is deleted, check if it's shared
                ShareService.get_shares_of_file(dropped.id).then(res => {
                    if(res.body.length ===  0 || confirm(dropped.name + " is shared, if you delete it people will lose access. Do you want to delete?")){
                        NamespaceService.delete_entity(dropped.id).then(() => {
                            bus.$emit(events.SHOW_ALERT, {message: dropped.name + " moved to Recycle Bin" })
                            // Send an event to File List to remove it
                            bus.$emit(events.REMOVE_FROM_FILELIST, dropped)

                        }).catch(err => {
                            console.error(err)
                            bus.$emit(events.SHOW_ALERT, {message: "Failed to move " + dropped.name + " to Recycle Bin: " + err.body.message, type: "danger"})
                        })
                    }
                })
            }
            else{
                // A folder is deleted
                NamespaceService.delete_entity(dropped.id).then(() => {
                    bus.$emit(events.SHOW_ALERT, {message: dropped.name + " moved to Recycle Bin" })
                    // Send an event to File List to remove it
                    bus.$emit(events.REMOVE_FROM_FILELIST, dropped)

                }).catch(err => {
                    console.error(err)
                    bus.$emit(events.SHOW_ALERT, {message: "Failed to move " + dropped.name + " to Recycle Bin: " + err.body.message, type: "danger"})
                })
            }
        },

        set_theme: function(theme){
            document.body.className = theme
            if(localStorage){ localStorage["theme"] = theme }
            this.theme = theme
        },

        logout: function() {
            AuthService.logout().then(() => {
                bus.$emit(events.LOGOUT)
            }, err_resp => {
                console.error("Failed to log out", err_resp)
            })

        },

        menu_click(e, page){
            if(this.no_buckets){
                e.preventDefault()
                e.stopPropagation()
            }
            else{
                // close sidebar if it's open
                $('.layout-container').removeClass('sidebar-visible');
                $('.sidebar-toggler').parent().removeClass('active');

                if(page === 'files'){
                    bus.$emit(events.FILELIST_CHANGE_FOLDER_TO, '#')
                }
            }
        }
    },

    filters: {
        days_rel(days_num){
            switch(days_num){
                case 0: return 'today';
                case 1: return 'tomorrow';
                case -1: return 'yesterday';
            }

            if(days_num > 0){
                return 'in ' + days_num + ' days'
            }
            else{
                return days_num + ' ago'
            }
        }
    }
}


</script>


<style lang="css">
    .nowrap{ white-space: nowrap; }
    .c-default{ cursor: default !important }
    .c-help{ cursor: help }
    .c-pointer{ cursor: pointer }
    .c-not-allowed{ cursor: not-allowed }
    .text-underline{ text-decoration: underline }
    .text-upper{ text-transform: uppercase }
    .text-lower{ text-transform: lowercase }
    .text-monospace{ font-family: monospace }
    .text-bold{ font-weight: bold }
    .text-italic{ font-style: italic }
    .text-justified{ text-align: justify }
    .text-serif{ font-family: serif; }
    .text-12{ font-size: 1.2em !important }
    .text-14{ font-size: 1.4em !important }
    .text-20{ font-size: 2em }
    .v-middle{ vertical-align: middle }
    .break-word{ word-break: break-word }
    .text-sm {font-size: 0.85em;}
    .width-0{ width: 0px }
    .width-25p{ width: 25% }
    .width-50p{ width: 50% !important }
    .width-75p{ width: 75% }
    .width-80p{ width: 80% }
    .margin-auto{ margin: 0px auto }
    .no-hover{ display: none }
    .hover-available{ display: none }
    .color-skyflok{ color: #008080 }
    @media(hover: none) {
        .no-hover{ display: inherit }
    }
    @media(hover: hover) {
        .hover-available{ display: inherit }
    }

    .color-cc{ color: #57362b }
    .color-skyflok{ color: #008080 }
    .bg-skyflok{ background-color: #008080 !important }
    
    .gap-1{ gap: .25rem }
    .gap-2{ gap: .5rem }
    .gap-3{ gap: 1rem }
    .gap-4{ gap: 1.5rem }
    .gap-5{ gap: 3rem }
    
    .cloud-provider-logo{
        max-width: 100px; 
        max-height: 40px;
        mix-blend-mode: color-burn;
    }

    .table.table-skyflok{
        border: 1px solid #828282 !important
    }
    .table.table-skyflok th{
        background: rgba(0, 128, 128, 0.2);
        border-top: 1px solid #828282;
        color: black
    }
    .table.table-skyflok th,
    .table.table-skyflok td{
        border-bottom: 1px solid #828282 !important;
        padding: .6rem
    }
    .table.table-skyflok tr.selected-row td{
        background: rgba(0, 128, 128, 0.05);
    }


    .alert.alert-sm{ padding: 5px 8px }

    .alert.alert-secondary{
        border-color: #ccc; background: #efefef
    }

    .form-control-inverse:focus {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid white;
    }

    .form-control-danger{
        border-color: #FF5252
    }

    .form-control-success{
        border-color: #4CAF50
    }

    

    #data-locations{ opacity: .75; transition: opacity linear .2s }
    .sidebar-container:hover #data-locations{ opacity: 1 }

    .sidebar-nav ul li a{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .sidebar-nav li.disabled{
        color: grey;
        background: rgba(162, 162, 162, 0.09);
        font-style: italic
    }
    .sidebar-nav li.disabled a{
        cursor: not-allowed !important
    }

    .sidebar-nav li:hover a{
        cursor: pointer;
        transition: 0.1s border-width linear;
        border-color: #008080 !important;
    }

    .sidebar-nav .active a{
        font-weight: bold
    }
    .sidebar-nav .active .nav-icon em{
        color: white
    }

    .sidebar-nav li.recycle_bin.dragover,
    .sidebar-nav li.recycle_bin.dragover a em{
        background: #F44336;
        color: white !important;
        font-weight: bold
    }

    /*
    .sidebar-nav ul > li.active {
        background: #1E88E5;
    }
    */
    .sidebar-nav ul > li.active a {
        background: #008080 !important;
        border-width: 0px !important;
        font-weight: bold;
    }

    .nav-caret.open_submenu{
        display: block;
        width: 50px;
        padding-left: 22px;
        margin-right: -22px;
    }

    .nav-caret.open_submenu:hover{
        background: grey
    }

    .main-container{
        height: 100%;
        transition: all .2s linear
    }


    .header-container{
        position: fixed;
        /*width: calc(100% - 260px);*/
        margin-left: 0px;
        background-color: rgba(255, 255, 255, .8) !important
    }

    .header-container.hidden{
        display:none
    }
    .theme-dark .header-container{
        background-color: rgba(53, 68, 76, .8) !important
    }

    /*
    .section-container{
        margin-top: 60px
    }
    */

    .bottom-container{
        position: fixed;
        bottom: 0;
        width: calc(100% - 260px);
        margin-left: 0px;
        /*
        background-color: rgba(255, 255, 255, .8) !important;
        */
        z-index: 1;
    }

    .layout-container.hidden .sidebar-container{
        transform: translate3d(-100%, 0, 0)
    }

    .layout-container.hidden .main-container{
        margin-left: 0px
    }
    .layout-container.hidden header{
        width: 100%
    }

    .custom-control-input:checked~.custom-control-indicator{
        background-color: #008080;
    }


    @media(max-width: 992px){
        /* Remove the top padding when there's no header */

        .header-container{
            width: 100%
        }
        .bottom-container{
            width: 100%
        }

        .modal-title{
            font-size: 1em
        }
        .modal-header{
            padding: 8px 15px
        }

    }

    .btn{
        cursor: pointer;
    }

    .btn-skyflok{
        background: #008080;
        color: white;
    }
    .btn-skyflok:hover{
        background: #019999;
        color: white
    }
    .btn-skyflok-outline{
        background: white;
        border: 1px solid #008080;
        color: #008080;
    }

    .layout-container{ cursor: default }

    .header-container li{ max-height: 60px }

    .noselect{
        user-select: none;
    }
    .alert i.icon{
        font-size: 14pt;
        margin-right: .5em;
    }


  .nav-footer{
    margin: 20px;
    text-align: center;
    font-size: 9pt;
  }
  .usage_container{
    height: 12px;
    opacity: 1;
    background: #2d3c43;
    font-size: 9pt
  }
  .storage_used{
    height: 12px;
    width: 0%;
    margin-bottom: 4px
  }
/*
  .sidebar-container{
      width: 260px
  }
  .main-container,
  .header-container{
      margin-left: 260px
  }
  */

    #client_space_logos_carousel {
        width: 580px;
        height: 110px;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
    }

    @media (max-width: 992px) {
        /* switch to vertically scrolling grid of images */
        #client_space_logos_carousel{
            width: 100%;
            white-space: normal;
            overflow-y: scroll;
            overflow-x: hidden
        }

        body{
            font-size: .85em
        }

        .display-1{
            font-size: 4em
        }
        .text-md{
            font-size: 1.5em
        }
        .file-icon{
            max-height: 30px
        }


    }

    .header-container > nav > .header-title {
        max-width: inherit
    }

    i.spin{
        display: inline-block;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    .table td,
    .table th{
        border-top: 1px solid rgba(0, 0, 0, 0.045) !important
    }
    .table thead th{ border-top: 0px !important }
    .theme-dark .table td,
    .theme-dark .table th{
        border-top: 1px solid rgba(255, 255, 255, 0.2)
    }

    .fit-cover{
        object-fit: cover;
    }
    .fit-contain{
        object-fit: contain;
    }

    .wd-sm.fit-cover{
        /* fit-cover needs a specified height too */
        height: 150px;
    }

    .popover-title{
        color: white;
        background-color: #263238
    }

    .popover ul,
    .popover ol{
        padding-left: 2em
    }

    .popover li{
        margin-bottom: 1em
    }


    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .loader {
        margin: 0px auto;
        font-size: 10px;
        position: relative;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    .sidebar-content::-webkit-scrollbar{
        width: 5px;
        height: 8px;
        background-color: transparent; /* or add it to the track */
    }
    .sidebar-content::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
    }


  @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }
    @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }

    .complience-badges .badge{
        font-size: 1em
    }

    

    .no-text-select{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
    }

</style>