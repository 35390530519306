<template>
<section v-if="!success">
    <div class="alert alert-warning text-bold">
        You are about to weaken the security of your account!<br/>
        Proceed only if you have a good reason, for example your phone is lost.
    </div>

    <form @submit.prevent="submit_delete()" class="text-center">

        <div class="d-flex align-items-center justify-content-center mt-2" style="margin: 0px auto">
            <div class="mr-2">Password:</div>
            <input type="password" class="form-control width-50p" v-model="password">
        </div>

        <div class="mt-2">
            <checkbox :value="failsafe" label="I understand the risk of disabling two-factor authentication" @changed="newval => failsafe=newval" />
        </div>
        
        <div class="d-flex align-items-center justify-content-center mt-3">
            <button class="btn btn-secondary mr-1" @click.prevent="()=>{ $emit('cancelled') }">Cancel</button>
            
            <button class="btn btn-danger btn-gradient ml-1" :disabled="!form_ok || loading">
                Disable two-factor authentication
                <i v-if="loading" class="ion-load-c spin ml-1"></i>
            </button>
        </div>
        <div v-if="error" class="text-danger">{{ error }}</div>
    </form>
</section>
</template>
<script>
import { AuthService } from '@/services/auth-service'
import checkbox from '../checkbox.vue'

import md5 from 'md5'

export default {
    props: ['show_success', 'hash_password'],

    components: {checkbox},

    data(){
        return {
            failsafe: false,
            password: "",

            loading: false,
            success: false,
            error: null,
        }
    },

    computed: {
        form_ok(){
            return this.failsafe && this.password.length
        }
    },
    
    methods: {
        submit_delete(){
            if(!this.form_ok){
                return
            }

            this.error = false
            this.loading = true

            let password = this.password
            if(this.hash_password){
                password = md5(password)
            }

            AuthService.disable_2fa(password).then(() => {
                this.loading = false
                this.success = true
                this.show_success("Two-Factor Authentication disabled.")
                this.$emit('disabled')
                this.password = false
            }).catch(err => {
                this.loading = false
                console.error(err)
                this.error = err.body.message 
            })
        }
    }

}
</script>