<template>
    <section>
        <div v-if="versions_limit >= 0" class="fw alert alert-sm alert-info ">
            <i class="ion-information-circled mr-1"></i>
            <b>Maximum {{ versions_limit+1 }} file versions are stored.</b>
            Upgrade your plan for unlimited versions.
        </div>

        <section v-if="versions">
            <!-- Table that shows in the file row panel, on desktop and tablet -->
            <table class="table table-sm d-none d-lg-block mx-auto" id="fileversions-table" style="width: fit-content">
                <thead>
                    <th class="text-center width-0">Version</th>
                    <th class="text-center">Created</th>
                    <th class="text-center">Uploader</th>
                    <th class="text-center">Size</th>
                    <th class="width-0"></th>
                </thead>
                <tbody>
                    <tr v-for="(version,idx) in versions" :key="version.id">
                        <td class="text-center" :class="{'text-bold': idx === versions.length-1}">
                            <span v-if="idx === versions.length-1">Latest</span>
                            <span v-else>{{idx+1}}.</span>
                        </td>
                        <td class="text-center">{{ version.timestamp | timestamp_time_date }}</td>
                        <td class="text-center"><user-box v-if="user && user.team"  :external-actor="modifiedBoxExternal" :team="user.team" :user-id="version.user_id"></user-box></td>
                        <td class="nowrap text-center">{{ version.size | format_bytes }}</td>
                        <td v-if="download_file_fn" class="width-0">
                            <button class="btn text-sm btn-flat" :class="[file.viewer.btn_class ]" v-if="file.viewer && open_file_fn" :disabled="open_file_fn === null" @click.prevent="open_file_fn(file, (version.id || version.version))" :title="'Open this version with ' + file.viewer.label">
                                {{ file.viewer.verb }} <i class="icon-lg ml-2" :class="file.viewer.icon_class"></i>
                            </button>
                            <button class="btn text-sm btn-sm btn-primary" v-if="download_file_fn" @click="download_file_fn(file, (version.id || version.version))"  :disabled="file.loading">
                                <i :class="{'ion-arrow-down-a': !file.loading, 'ion-load-c spin': file.loading}" class="icon-lg"></i>
                                <span class="ml-2">Download</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- List that shows in the modal dialog on phones -->
            <div class="d-block d-lg-none">
                <div v-for="(version, idx) in versions" :key="version.id">
                    <div class="version-header text-bold d-flex align-items-center justify-content-between mt-2 mb-2">
                        <span>Version {{ idx+1 }}</span>
                        <button v-if="download_file_fn" @click="download_file_fn(file, (version.id || version.version))" :disabled="file.loading" class="btn text-sm btn-sm btn-primary mb-1">
                            <i :class="{'ion-arrow-down-a': !file.loading, 'ion-load-c spin': file.loading}" class="icon-lg"></i>
                            <span class="ml-1">Download</span>
                        </button>
                    </div>
                    <div class="version-details ml-2">
                        <div>
                            <b>Uploader: </b>
                            <user-box v-if="user && user.team" :external-actor="modifiedBoxExternal" :team="user.team" :user-id="version.user_id"></user-box>
                        </div>
                        <div>
                            <b>Created: </b> {{ version.timestamp | timestamp_time_date }}
                        </div>
                        <div>
                            <b>Size: </b> {{ version.size | format_bytes }}
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <i v-else class="ion-load-c spin"></i>

    </section>
</template>
<script>

import { FileVersionService } from '@/services/fileversion-service.js'

import userBox from './user-box.vue'

export default {
    props: ['file', 'user', 'modifiedBoxExternal', 'open_file_fn', 'download_file_fn', 'versions_num_changed'],
    components: {
        userBox
    },

    data(){
        return {
            versions: null
        }
    },

    computed: {
        versions_limit(){
            if(this.user && this.user.team_settings && this.user.team_settings.config){
                return this.user.team_settings.config.versioning_keep_versions
            }
            return -1
        }
    },

    created(){
        if(!this.versions){
            FileVersionService.get_file_history(this.file.id).then(res => {
                let versions = res.body
                this.versions = versions
                if(this.versions_num_changed){
                    this.versions_num_changed(versions.length)
                }
            })
        }
    },

    destroyed(){
        this.versions = null
    }
}
</script>
<style scoped>
#fileversions-table tr,
#fileversions-table td{
    background-color: white !important
}
thead th{
    text-align: center
}

.version-header{
    border-bottom: 1px solid #ebebeb
}
.version-details{
    line-height: 2em
}
</style>
